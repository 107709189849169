import React, { useEffect, useState } from "react";
import {
	Flex,
	Text,
	Button,
	Icon,
	AvatarWrapper,
	breakpoints,
	useMediaQuery,
} from "@mightybot/web-ui";
import {
	LogoutButton,
	LogoutSection,
	GeneralContainer,
	GeneralHeader,
	GeneralContent,
	UserProfileSection,
} from "./styled";
import { useEnterprise, useLogout, useMe, useUserInfo } from "@mightybot/core";
import { useNavigate } from "react-router-dom";
import EditProfileDialog from "./editProfileDialog";
import UserInfoCard from "./userInfoCard";
import Toast from "../Toast";

export default function General() {
	const navigate = useNavigate();
	const { data: user } = useMe();
	const { userInfo, updateUserInfo, saveError } = useUserInfo();
	const { enterpriseData, refetch } = useEnterprise();
	const [logout] = useLogout();
	const [isEditDialogOpen, setEditDialogOpen] = useState(false);
	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState({
		title: "",
		description: "",
		status: "success" as "success" | "error" | "info",
	});
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const handleLogout = async () => {
		try {
			await logout();
			navigate("/login");
		} catch (error) {
			console.error("Logout failed:", error);
			setToastData({
				title: "Logout Failed",
				description: "An error occurred during logout. Please try again.",
				status: "error",
			});
			setOpenToast(true);
		}
	};

	useEffect(() => {
		if (saveError) {
			setToastData({
				title: "Save Failed",
				description: "Failed to save user info. Please try again.",
				status: "error",
			});
			setOpenToast(true);
		}
	}, [saveError]);

	const handleDialogClose = async () => {
		setEditDialogOpen(false);
		try {
			await refetch?.();
		} catch (error) {
			console.error("Failed to refresh enterprise data:", error);
		}
	};

	return (
		<GeneralContainer direction="column" justify="center">
			{!isMobile && (
				<GeneralHeader align="center" justify="between">
					<Text size="6" weight="medium">
						General
					</Text>
				</GeneralHeader>
			)}

			<GeneralContent direction="column">
				<UserProfileSection>
					<AvatarWrapper
						src={user?.profile_pic ?? ""}
						size={isMobile ? "6" : "8"}
						alt={user?.name || "User"}
						radius="full"
					/>
					<Flex
						direction="column"
						justify="between"
						style={{
							marginLeft: isMobile ? "0" : "20px",
							width: "100%",
							gap: "20px",
							height: "100%",
						}}
					>
						<Text size="5" weight="medium">
							{user?.name || "N/A"}
						</Text>
						<Flex
							wrap="wrap"
							direction="column"
							style={{
								gap: isMobile ? "8px" : "20px",
								width: "100%",
								height: isMobile ? "auto" : "160px",
							}}
						>
							<UserInfoCard
								icon={<Icon.EnvelopeSimple size={16} />}
								label="Email"
								value={user?.email}
								link={`mailto:${user?.email}`}
							/>
							<UserInfoCard
								icon={<Icon.Buildings size={16} />}
								label="Company"
								value={enterpriseData?.name}
								link={
									enterpriseData
										? `https://${enterpriseData.host_domain}`
										: undefined
								}
							/>
							<UserInfoCard
								icon={<Icon.Briefcase size={16} />}
								label="Job"
								value={userInfo?.title}
								link={undefined}
							/>
							<UserInfoCard
								icon={<Icon.Globe size={16} />}
								label="Domain"
								value={enterpriseData?.host_domain}
								link={
									enterpriseData
										? `https://${enterpriseData.host_domain}`
										: undefined
								}
							/>
						</Flex>
					</Flex>
					<Button
						onClick={() => setEditDialogOpen(true)}
						style={{
							alignSelf: isMobile ? "center" : "flex-start",
							color: "var(--mb-color-light-blue-8)",
							background: "transparent",
							cursor: "pointer",
							marginTop: isMobile ? "16px" : "0",
						}}
					>
						Edit Details
					</Button>
				</UserProfileSection>

				<LogoutSection direction="column" align="start" style={{ gap: "10px" }}>
					<LogoutButton variant="ghost" onClick={handleLogout}>
						<Icon.SignOut size={16} weight="bold" color="#DC3D43" />
						<Text weight="medium">Logout</Text>
					</LogoutButton>
					<Text
						style={{
							color: "var(--mb-color-light-gray-9)",
							fontSize: 12,
							padding: isMobile ? "0 16px" : "0",
						}}
					>
						Logging out will end your current session and return you to the
						login screen.
					</Text>
				</LogoutSection>
			</GeneralContent>

			<EditProfileDialog
				isOpen={isEditDialogOpen}
				onClose={handleDialogClose}
				userName={userInfo?.name || ""}
				jobTitle={userInfo?.title || ""}
				updateUserInfo={updateUserInfo}
				setToastData={setToastData}
				setOpenToast={setOpenToast}
				userRole={user?.role}
				enterpriseName={enterpriseData?.name}
			/>

			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				type={toastData.status}
				description={toastData.description}
			/>
		</GeneralContainer>
	);
}
