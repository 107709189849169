import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
	DropdownMenu,
	Text,
	Flex,
	Icon,
	Badge,
	CustomReactMarkdown,
	breakpoints,
	useMediaQuery,
	Button,
	Checkbox,
	Dialog,
	Input,
	Skeleton,
} from "@mightybot/web-ui";
import {
	ContentHeader,
	ContentWrapper,
	ContentContainer,
	TaskCardContainer,
	TaskCheckbox,
} from "./styled";
import {
	Task,
	useTasks,
	usePersonTasks,
	ContentStatusEnum,
	TaskResponse,
	ContentStatus,
	useTasksList,
	isCRMTask,
	normalizeTask,
} from "@mightybot/core";
import { formatTimestamp, formatDate } from "../../../utils/helpers";
import { InfiniteScroll } from "../../People/InfiniteScroll";
import { TipTapEditor } from "../../Editor/TipTapEditor";
import { ContentCardSkeleton } from "./ContentSkeleton";
import DateRangePicker from "../../DateRangePicker/DateRangePicker";

interface CreateTaskDialogProps {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	personId: string;
	initialData: Task | TaskResponse;
}

const CreateTaskDialog: React.FC<CreateTaskDialogProps> = ({
	open,
	onOpenChange,
	personId,
	initialData,
}) => {
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [dueDate, setDueDate] = useState<Date | null>(null);
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [status, setStatus] = useState<ContentStatus>(ContentStatusEnum.DRAFT);
	const { createTask, updateTask, isLoading } = useTasks({
		collectionId: personId,
		skip: !open,
	});

	const normalizedTask = initialData ? normalizeTask(initialData) : null;

	useEffect(() => {
		if (open && initialData) {
			setTitle(normalizedTask?.title ?? "");
			setContent(normalizedTask?.content ?? "");
			setDueDate(
				normalizedTask?.content_metadata?.due_date
					? new Date(normalizedTask.content_metadata.due_date)
					: null,
			);
			setStatus(normalizedTask?.status ?? ContentStatusEnum.DRAFT);
		} else if (!open) {
			setTitle("");
			setContent("");
			setDueDate(null);
			setStatus(ContentStatusEnum.DRAFT);
		}
	}, [open, initialData]);

	const handleSubmit = async () => {
		try {
			const contentMetadata = {
				...(dueDate && { due_date: dueDate.toISOString() }),
			};

			if (initialData) {
				await updateTask(normalizedTask?.id ?? "", {
					title,
					content,
					status,
					content_metadata: contentMetadata,
				});
			} else {
				await createTask({
					collection_id: personId,
					title,
					content,
					status,
					content_metadata: contentMetadata,
				});
			}
			onOpenChange(false);
		} catch (error) {
			console.error(
				`Failed to ${initialData ? "update" : "create"} task:`,
				error,
			);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && e.ctrlKey && !isLoading && title && content) {
			e.preventDefault();
			handleSubmit();
		}
	};

	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Content
				size="3"
				style={{
					width: "700px",
					display: "flex",
					flexDirection: "column",
					gap: "10px",
				}}
				onKeyDown={handleKeyDown}
			>
				<Dialog.Title>{initialData ? "Edit" : "Create"} Task</Dialog.Title>
				<Dialog.Description>
					{initialData ? "Update" : "Add a new"} task for this person
				</Dialog.Description>

				<Flex direction="column" gap="3">
					<Input
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						placeholder="Enter task title"
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault(); // Prevent form submission
							}
						}}
					/>
					<Flex direction="column" gap="2">
						<Text size="2">Task Description</Text>
						<TipTapEditor
							key={`${normalizedTask?.id}-${open}`}
							content={content}
							onChange={setContent}
							placeholder="Write your task description here..."
							defaultValue={normalizedTask?.content}
						/>
					</Flex>
					<Flex direction="column" gap="2">
						<Text size="2">Due Date</Text>
						<Flex gap="2" align="center">
							<Button
								variant="white-bg"
								onClick={() => setIsDatePickerOpen(true)}
								style={{ width: "100%" }}
							>
								{dueDate
									? formatDate(dueDate.toISOString())
									: "Select due date"}
							</Button>
							{dueDate && (
								<Icon.X
									size={16}
									onClick={() => setDueDate(null)}
									style={{ cursor: "pointer" }}
								/>
							)}
						</Flex>
						{isDatePickerOpen && (
							<DateRangePicker
								startDate={dueDate}
								endDate={null}
								onChange={([date]) => {
									setDueDate(date);
									setIsDatePickerOpen(false);
								}}
								singleDate={true}
								isOpen={isDatePickerOpen}
								onClose={() => setIsDatePickerOpen(false)}
								style={{ width: "100%" }}
							/>
						)}
					</Flex>
					<Flex align="center" gap="2">
						<Checkbox
							checked={status === ContentStatusEnum.COMPLETED}
							onCheckedChange={(checked) =>
								setStatus(
									checked
										? ContentStatusEnum.COMPLETED
										: ContentStatusEnum.DRAFT,
								)
							}
							style={{
								cursor: isCRMTask(initialData) ? "not-allowed" : "pointer",
							}}
							disabled={isCRMTask(initialData)}
						/>
						<Text size="2">Mark as completed</Text>
					</Flex>
				</Flex>

				<Flex justify="between" align="center" style={{ marginTop: "16px" }}>
					<Text style={{ color: "var(--mb-gray-9)", fontSize: "9px" }}>
						Press Ctrl + Enter to {initialData ? "update" : "create"}
					</Text>
					<Flex gap="2" align="center">
						<Dialog.Close>
							<Button variant="white-bg">Cancel</Button>
						</Dialog.Close>
						<Button
							disabled={!title || !content || isLoading}
							onClick={handleSubmit}
						>
							{isLoading
								? initialData
									? "Updating..."
									: "Creating..."
								: initialData
									? "Update Task"
									: "Create Task"}
						</Button>
					</Flex>
				</Flex>
			</Dialog.Content>
		</Dialog.Root>
	);
};

interface TaskCardProps {
	task: Task | TaskResponse;
	onDelete: () => void;
	onEdit: () => void;
}

const TaskCard: React.FC<TaskCardProps> = ({ task, onDelete, onEdit }) => {
	const normalizedTask = normalizeTask(task);
	const isCRM = isCRMTask(task);
	const isCompleted = normalizedTask.status?.toLowerCase() === "completed";
	const { updateTask } = useTasks({
		taskId: !isCRM ? normalizedTask.id : undefined,
	});

	const handleStatusChange = async () => {
		try {
			const newStatus =
				normalizedTask.status === ContentStatusEnum.COMPLETED
					? ContentStatusEnum.DRAFT
					: ContentStatusEnum.COMPLETED;

			await updateTask(normalizedTask.id, {
				status: newStatus,
			});
		} catch (error) {
			console.error("Failed to update task status:", error);
		}
	};

	const handleTaskClick = (e: React.MouseEvent<HTMLDivElement>) => {
		console.log("Task clicked");
	};

	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const formatContent = (task: typeof normalizedTask) => {
		const escapedContent =
			task.content
				?.replace(/<[^>]*>/g, "")
				.replace(/</g, "&lt;")
				.replace(/>/g, "&gt;") ?? "";

		if (task.title) {
			return `# ${task.title}\n\n${escapedContent}`;
		}
		return escapedContent || " ";
	};

	return (
		<TaskCardContainer
			onClick={handleTaskClick}
			tabIndex={0}
			onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
				if (e.key === "Enter") {
					handleTaskClick(e as any);
				}
			}}
			style={{
				cursor: "pointer",
			}}
		>
			<Flex justify="between">
				<Flex style={{ gap: 6 }}>
					<TaskCheckbox
						size="3"
						checked={isCompleted}
						onCheckedChange={handleStatusChange}
						onClick={(e) => e.stopPropagation()}
						style={{
							borderRadius: "var(--mb-radius-full)",
							cursor: "pointer",
							color:
								normalizedTask.status === ContentStatusEnum.COMPLETED
									? "#f1ffc7"
									: "var(--mb-gray-1)",
							background:
								normalizedTask.status === ContentStatusEnum.COMPLETED
									? "#f1ffc7"
									: "var(--mb-gray-1)",
						}}
					/>
					{!isTablet && (
						<Badge
							radius="full"
							style={{
								textDecoration: isCompleted ? "line-through" : "none",
							}}
						>
							{normalizedTask.content_type}
						</Badge>
					)}
				</Flex>
			</Flex>
			<CustomReactMarkdown
				styles={{
					content: {},
					paragraph: {
						marginBottom: "0.5rem",
						wordBreak: "break-word",
						textDecoration: isCompleted ? "line-through" : "none",
					},
					heading: {
						marginBottom: "0px",
						marginTop: "0px",
						textDecoration: isCompleted ? "line-through" : "none",
						fontSize: "16px",
					},
				}}
			>
				{formatContent(normalizedTask)}
			</CustomReactMarkdown>
			{isTablet && (
				<Badge
					radius="full"
					style={{
						textDecoration: isCompleted ? "line-through" : "none",
					}}
				>
					{normalizedTask.content_type}
				</Badge>
			)}

			<Flex justify="between" align="center" style={{ padding: "4px 0px" }}>
				<Flex gap="3" align="center">
					<Text size="1" weight="light" style={{ color: "var(--mb-gray-9)" }}>
						{formatTimestamp(normalizedTask.created_ts ?? "")}
					</Text>
					{normalizedTask.content_metadata?.due_date && (
						<Text size="1" weight="light" style={{ color: "var(--mb-gray-9)" }}>
							Due: {formatDate(normalizedTask.content_metadata.due_date)}
						</Text>
					)}
				</Flex>
				{!isCRM && (
					<Flex style={{ gap: 15 }}>
						<DropdownMenu.Root>
							<DropdownMenu.Trigger onClick={(e) => e.stopPropagation()}>
								<Icon.DotsThree style={{ cursor: "pointer" }} />
							</DropdownMenu.Trigger>
							<DropdownMenu.Content>
								<DropdownMenu.Item
									onClick={onEdit}
									style={{ cursor: "pointer" }}
								>
									<Text>Edit</Text>
								</DropdownMenu.Item>
								<DropdownMenu.Item
									onClick={onDelete}
									style={{ cursor: "pointer" }}
								>
									<Text>Delete</Text>
								</DropdownMenu.Item>
							</DropdownMenu.Content>
						</DropdownMenu.Root>
					</Flex>
				)}
			</Flex>
		</TaskCardContainer>
	);
};

export const Tasks: React.FC<{
	id: string;
	email: string;
	hubspot_id?: string;
}> = ({ id, email, hubspot_id }) => {
	const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
	const [selectedTask, setSelectedTask] = useState<
		Task | TaskResponse | undefined
	>();
	const [page, setPage] = useState(1);
	const [source, setSource] = useState<"crm" | "both" | "internal">("both");

	// Internal Tasks
	const {
		tasks: internalTasks,
		hasMore: internalHasMore,
		isLoading: internalLoading,
		isFetching: internalFetching,
		total: internalTotal,
	} = useTasksList({
		collectionId: id,
		page,
		pageSize: 10,
	});

	// CRM Tasks
	const {
		tasks: crmTasks,
		isLoading: crmLoading,
		isFetching: crmFetching,
		hasMore: crmHasMore,
		total: crmTotal,
	} = usePersonTasks(email, {
		source: source as "crm" | "internal",
		hubspot_id,
	});

	const { deleteTask } = useTasks({ collectionId: id });

	// Combine tasks based on source
	const tasks = useMemo(() => {
		if (source === "internal") return internalTasks;
		if (source === "crm") return crmTasks;
		return [...crmTasks, ...internalTasks];
	}, [internalTasks, crmTasks, source]);

	const isLoading =
		source === "internal"
			? internalLoading
			: source === "crm"
				? crmLoading
				: internalLoading || crmLoading;
	const isFetching =
		source === "internal"
			? internalFetching
			: source === "crm"
				? crmFetching
				: internalFetching || crmFetching;
	const hasMore =
		source === "internal"
			? internalHasMore
			: source === "crm"
				? crmHasMore
				: internalHasMore || crmHasMore;
	const total =
		source === "internal"
			? internalTotal
			: source === "crm"
				? crmTotal
				: internalTotal + crmTotal;

	const handleLoadMore = useCallback(async () => {
		if (!isLoading && !isFetching && hasMore) {
			setPage((prev) => prev + 1);
		}
	}, [isLoading, isFetching, hasMore]);

	if (isLoading && tasks.length === 0) {
		return (
			<ContentWrapper>
				<ContentHeader>
					<Flex justify="between" align="center" style={{ width: "100%" }}>
						<Skeleton width={"120px"} height={"24px"} />
						<Skeleton width={"100px"} height={"32px"} />
					</Flex>
				</ContentHeader>
				<ContentContainer>
					<Flex direction="column" gap="3" style={{ width: "100%" }}>
						{[1, 2, 3].map((i) => (
							<ContentCardSkeleton key={i} />
						))}
					</Flex>
				</ContentContainer>
			</ContentWrapper>
		);
	}

	return (
		<ContentWrapper>
			<ContentHeader>
				<Flex justify="between" align="center" style={{ width: "100%" }}>
					<Flex align="center" gap="3">
						<Text size="3" weight="medium">
							Tasks{" "}
							<Text as="span" style={{ color: "var(--mb-gray-9)" }}>
								({total})
							</Text>
						</Text>
						<Badge
							radius="full"
							onClick={() =>
								setSource((prev) => {
									if (prev === "both") return "crm";
									if (prev === "crm") return "internal";
									return "both";
								})
							}
							style={{ cursor: "pointer" }}
						>
							{source === "both"
								? "All"
								: source === "crm"
									? "CRM"
									: "Internal"}
						</Badge>
					</Flex>
					<Button
						variant="ghost"
						size="2"
						onClick={() => setIsCreateDialogOpen(true)}
						disabled={source === "crm"}
					>
						<Icon.Plus size="16" />
						Create Task
					</Button>
				</Flex>
			</ContentHeader>
			<ContentContainer>
				<InfiniteScroll
					data={tasks}
					isLoading={isLoading || isFetching}
					error={null}
					hasMore={hasMore ?? false}
					onLoadMore={handleLoadMore}
					showNoMoreItems={false}
					style={{
						width: "100%",
						gap: "10px",
						overflow: "hidden",
						height: "100%",
					}}
					renderItems={(items) =>
						items.map((task) => (
							<TaskCard
								key={(task as Task).task_id ?? (task as TaskResponse).id}
								task={task}
								onDelete={async () => {
									if (isCRMTask(task)) return;
									try {
										await deleteTask(task.id);
									} catch (error) {
										console.error("Failed to delete task:", error);
									}
								}}
								onEdit={() => {
									setSelectedTask(task);
									setIsCreateDialogOpen(true);
								}}
							/>
						))
					}
				/>
			</ContentContainer>

			<CreateTaskDialog
				open={isCreateDialogOpen}
				onOpenChange={(open) => {
					setIsCreateDialogOpen(open);
					if (!open) setSelectedTask(undefined);
				}}
				personId={id}
				initialData={selectedTask as Task | TaskResponse}
			/>
		</ContentWrapper>
	);
};
