import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Flex,
	Icon,
	Text,
	Input,
	Button,
	Select,
	Checkbox,
	TextArea,
	DynamicTextArea,
	Switch,
	Popover,
} from "@mightybot/web-ui";
import {
	useAutopilotConfig,
	Autopilot,
	User,
	useEnterpriseUsers,
	EnterpriseResponse as Enterprise,
	useEnterprises,
	useAutopilots,
} from "@mightybot/core";
import { capitalize } from "../../utils/helpers";
import {
	CRON_KEYS as CRON_KEYS_CONST,
	INTERVAL_UNITS,
} from "../../constants/autopilot";
import { Cron, Interval } from "@mightybot/core";
import { Card } from "../AutopilotList/Card";
import { useNavigate } from "react-router-dom";
import { UUID } from "crypto";
import MultiSelectionDialog from "../MultiSelectionDialog";
import { debounce } from "lodash";
import { SearchResults, SearchResultItem } from "../Share/styled";

type SelectorProps = {
	defaultValue: string;
	data: any[];
	onChange: (value: string) => void;
	label: string;
	placeholder: string;
};

const AutoPilotFilterExamples = `1. Email domain and attendee count:
"@mightybot.ai" in event_inside_org_attendee_emails and event_attendee_count > 1

2. Date range check:
(event_start_time before "01-01-2025") and (event_end_time after "04-12-2024")

3. Complex condition with multiple checks:
 (event_attendee_count > 1) and ("team" in event_meeting_description)

4. Checking for specific email domains:
(event_attendee_count != 0) and (("@gmail.com" in event_inside_org_attendee_emails) or ("@example.com" in event_inside_org_attendee_emails))

5. Using 'in' operator with a list:
("Client" in meeting_class  )  and ( user_email in ['john@example.com', 'jane@example.com'])

6. Checking boolean values:
(event_external_meeting is True) or (event_external_meeting == "true")

7. Numeric comparisons:
event_attendee_count == 2 or event_attendee_count >= 5

8. Using negation:
! (event_external_meeting is "true")

9. Nested negation:
! (!(("@gmail.com" in event_inside_org_attendee_emails) or ("@example.com" in event_inside_org_attendee_emails)))

10. Combining multiple conditions:
(event_meeting_title is "standup") and (event_attendee_count > 1) and ("team" in event_meeting_description) and (event_start_time after "06-01-2024")

Available operators:
- Comparison: is, in, ==, !=, >, <, >=, <=
- Date: before, after
- Logical: and, or
- Negation: ! (before parentheses)

**for exact match use "==" or for case insensitive use "is"
Use parentheses () to group conditions and control precedence.
String comparisons are case-insensitive using "is" or "in".
Date format for comparisons: MM-DD-YYYY`;

const Selector = ({
	defaultValue,
	data,
	onChange,
	label,
	placeholder,
}: SelectorProps) => {
	const [selectedValue, setSelectedValue] = useState(defaultValue);

	useEffect(() => {
		setSelectedValue(defaultValue);
	}, [defaultValue]);

	return (
		<Flex
			align="center"
			justify="between"
			style={{ width: "100%" }}
			id={`autopilot-selector-${label}`}
			key={label}
		>
			<Select.Root
				defaultValue={selectedValue}
				value={selectedValue}
				onValueChange={(value) => {
					setSelectedValue(value);
					onChange(value);
				}}
			>
				<Select.Trigger
					// @ts-ignore
					placeholder={placeholder}
					id={`autopilot-selector-trigger-${label}`}
					style={{
						width: "100%",
						boxShadow: "none",
						cursor: "pointer",
						border: "1px solid #E0E0E0",
						height: "42px",
					}}
				/>
				<Select.Content>
					<Select.Group>
						<Select.Label>{label}</Select.Label>
						{data?.map((item) => (
							// @ts-ignore
							<Select.Item
								key={item.id}
								value={item.id}
								id={`autopilot-selector-item-${item.id}`}
							>
								{item.label}
							</Select.Item>
						))}
					</Select.Group>
				</Select.Content>
			</Select.Root>
		</Flex>
	);
};

type AutopilotFormProps = {
	autopilot: Partial<Autopilot>;
	setAutopilotFormData: (data: Partial<Autopilot>) => void;
	autopilotFormData: Partial<Autopilot> | undefined;
	onSaveAutopilot: () => Promise<void>;
	onDraftSave: () => Promise<void>;
	enterpriseId: UUID | undefined;
	formDataChanged?: boolean;
};

export type CronField = {
	id: keyof Cron;
	label: string;
};

const CRON_KEYS = CRON_KEYS_CONST as CronField[];

const isActionBasedEvent = (
	eventName: string,
	configEvents?: { id: string; label: string; is_action_based: boolean }[],
) => {
	if (!eventName || !configEvents) return false;
	const event = configEvents.find((event) => event.id === eventName);
	return event?.is_action_based ?? false;
};

const filterActionAutopilots = (
	autopilots: Autopilot[] | undefined,
	configEvents?: { id: string; label: string; is_action_based: boolean }[],
) => {
	return (autopilots ?? []).filter((ap) => {
		const eventName = ap.config?.event_name?.[0];
		return eventName ? isActionBasedEvent(eventName, configEvents) : false;
	});
};

const getAutopilotUrl = (enterpriseId: UUID | undefined, autopilotId: UUID) => {
	return `/app/admin/enterprise/${enterpriseId}/autopilot/${autopilotId}/edit`;
};

const AutopilotForm = ({
	autopilot,
	setAutopilotFormData,
	autopilotFormData,
	onSaveAutopilot,
	onDraftSave,
	enterpriseId,
	formDataChanged,
}: AutopilotFormProps) => {
	const navigate = useNavigate();
	const { data: config } = useAutopilotConfig();
	const { data: enterpriseUsers } = useEnterpriseUsers(enterpriseId as UUID);
	const { enterprisesData: enterprises } = useEnterprises();
	const [allAutopilots, setAllAutopilots] = useState<Autopilot[]>([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResultsOpen, setSearchResultsOpen] = useState(true);

	const searchActionAutopilotInputRef = useRef<HTMLInputElement>(null);
	const searchActionAutopilotsRef = useRef<HTMLDivElement>(null);

	const {
		autopilots: initialAutopilots,
		isLoading: isLoadingInitialAutopilots,
	} = useAutopilots({
		enterpriseId: enterpriseId as UUID,
		page: 1,
		perPage: 100,
		search: "",
		isActionAP: true,
	});

	useEffect(() => {
		if (initialAutopilots) {
			setAllAutopilots(initialAutopilots);
		}
	}, [initialAutopilots]);

	const handleSearch = (query: string) => {
		setSearchQuery(query);
	};
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchActionAutopilotInputRef.current &&
				!searchActionAutopilotInputRef.current.contains(event.target as Node) &&
				!searchActionAutopilotsRef.current?.contains(event.target as Node)
			) {
				setSearchResultsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const displayedAutopilots = useMemo(() => {
		if (!searchQuery) {
			return allAutopilots;
		}
		return allAutopilots.filter(
			(ap) =>
				ap.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
				(ap.config?.interactive_notification_config?.trigger_question || "")
					.toLowerCase()
					.includes(searchQuery.toLowerCase()),
		);
	}, [searchQuery, allAutopilots]);

	const isLoadingAutopilots = isLoadingInitialAutopilots;
	const autopilotUsers = autopilotFormData?.users ?? [];
	const autopilotEnterpriseIds = autopilotFormData?.shared_enterprise_ids ?? [];
	const [enableInteractiveNotifications, setEnableInteractiveNotifications] =
		useState(false);
	useEffect(() => {
		const hasChainedAutopilots =
			!!autopilotFormData?.config?.interactive_notification_config
				?.chained_autopilots?.length;
		setEnableInteractiveNotifications(hasChainedAutopilots);
	}, [autopilotFormData]);

	const [multiSelectionDialogOpen, setMultiSelectionDialogOpen] =
		useState(false);
	const [multiSelectionDialogData, setMultiSelectionDialogData] = useState<{
		initialData: User[] | Enterprise[];
		data: User[] | Enterprise[];
		onSubmit?: (selectedData: User[] | Enterprise[]) => void;
		title?: string;
	}>({
		initialData: [],
		data: [],
	});

	const [isSaving, setIsSaving] = useState(false);
	const [validationError, setValidationError] = useState("");

	const validateInteractiveNotifications = useCallback(() => {
		if (!autopilotFormData?.config?.event_name?.[0]) return "";

		const isAction = autopilotFormData.config.event_name[0]
			.toLowerCase()
			.includes("action");
		if (isAction) {
			if (
				!autopilotFormData?.config?.interactive_notification_config
					?.trigger_question
			) {
				return "Please enter a trigger question";
			}
		} else if (
			enableInteractiveNotifications &&
			!autopilotFormData?.config?.interactive_notification_config
				?.chained_autopilots?.length
		) {
			return "Please select at least one action autopilot";
		}
		return "";
	}, [
		autopilotFormData?.config?.event_name,
		autopilotFormData?.config?.interactive_notification_config,
		enableInteractiveNotifications,
	]);

	useEffect(() => {
		const error = validateInteractiveNotifications();
		setValidationError(error);
	}, [
		validateInteractiveNotifications,
		autopilotFormData?.config?.interactive_notification_config,
		enableInteractiveNotifications,
	]);

	const handleSaveAutopilot = async () => {
		const error = validateInteractiveNotifications();
		if (error) {
			setValidationError(error);
			return;
		}

		const isActionAutopilot = autopilotFormData?.config?.event_name?.[0]
			?.toLowerCase()
			.includes("action");
		const shouldRemoveConfig =
			!enableInteractiveNotifications ||
			(isActionAutopilot &&
				!autopilotFormData?.config?.interactive_notification_config
					?.trigger_question) ||
			(!isActionAutopilot &&
				!autopilotFormData?.config?.interactive_notification_config
					?.chained_autopilots?.length);

		let formToSave = { ...autopilotFormData };
		if (shouldRemoveConfig) {
			formToSave = {
				...autopilotFormData,
				config: {
					...autopilotFormData?.config,
					interactive_notification_config: undefined,
				},
			};
		}

		setIsSaving(true);
		await onSaveAutopilot();
		setIsSaving(false);
	};

	useEffect(() => {}, [autopilotFormData?.users]);

	const onSelectUsers = (selectedUsers: User[]) => {
		setAutopilotFormData({
			...autopilotFormData,
			users: selectedUsers,
		});
		setMultiSelectionDialogOpen(false);
	};

	const onSelectEnterprises = (selectedEnterprises: Enterprise[]) => {
		setAutopilotFormData({
			...autopilotFormData,
			shared_enterprise_ids: selectedEnterprises.map(
				(enterprise) => enterprise.id as UUID,
			),
		});
		setMultiSelectionDialogOpen(false);
	};

	const onRemoveUser = (userId: string) => {
		setAutopilotFormData({
			...autopilotFormData,
			users: autopilotUsers?.filter((user) => user.id !== userId),
		});
	};

	const onRemoveEnterprise = (enterpriseId: UUID) => {
		setAutopilotFormData({
			...autopilotFormData,
			shared_enterprise_ids: autopilotEnterpriseIds?.filter(
				(id) => id !== enterpriseId,
			),
		});
	};
	const onAddMore = () => {
		setAutopilotFormData({
			...autopilotFormData,
			config: {
				...autopilotFormData?.config,
				messages: [
					...(autopilotFormData?.config?.messages ?? []),
					{
						prompt: "",
						variables: [],
					},
				],
			},
		});
	};

	const handleBroadcastToggle = (checked: boolean) => {
		setAutopilotFormData({
			...autopilotFormData,
			config: {
				...autopilotFormData?.config,
				broadcast_notification: checked,
			},
		});
	};

	const handleBroadcastRecipientsChange = (value: string) => {
		setAutopilotFormData({
			...autopilotFormData,
			config: {
				...autopilotFormData?.config,
				broadcast_recipients: value,
			},
		});
	};

	const onCronChange = (value: string, key: string) => {
		let cron = {
			...autopilotFormData?.config?.schedule?.cron,
		} as Cron;
		cron = {
			...cron,
			[key]: value,
		};
		setAutopilotFormData({
			...autopilotFormData,
			config: {
				...autopilotFormData?.config,
				schedule: {
					cron,
				},
			},
		});
	};

	const onPromptDelete = (index: number) => {
		let messages = autopilotFormData?.config?.messages;
		if (!messages) {
			return;
		}
		messages = messages.filter((_, i) => i !== index);
		setAutopilotFormData({
			...autopilotFormData,
			config: {
				...autopilotFormData?.config,
				messages,
			},
		});
	};

	const onPromptChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>,
		index: number,
	) => {
		let messages = [...(autopilotFormData?.config?.messages ?? [])];
		if (!messages) {
			messages = [];
		}
		messages[index] = {
			prompt: e.target.value,
			variables: [],
		};
		setAutopilotFormData({
			...autopilotFormData,
			config: {
				...autopilotFormData?.config,
				messages,
			},
		});
	};

	const onIntervalChange = (value: string, key: string) => {
		let interval = {
			...autopilotFormData?.config?.schedule?.interval,
		} as Interval;
		interval = {
			...interval,
			[key]: value,
		};

		setAutopilotFormData({
			...autopilotFormData,
			config: {
				...autopilotFormData?.config,
				schedule: {
					interval,
				},
			},
		});
	};

	const onTestRunClick = async () => {
		if (formDataChanged) {
			await onSaveAutopilot();
		}
		navigate(
			`/admin/enterprise/${enterpriseId}/autopilot/${autopilot.id}/test`,
		);
	};
	const [filterExpression, setFilterExpression] = useState(
		autopilot?.config?.filter || "",
	);
	const [filterError, setFilterError] = useState("");

	const validateFilterExpression = useCallback((expression: string) => {
		let parenCount = 0;
		const tokens = expression
			.split(/(\(|\)|\s+and\s+|\s+or\s+)/)
			.filter((token) => token.trim() !== "");

		for (let i = 0; i < tokens.length; i++) {
			const token = tokens[i];
			if (token === "(") parenCount++;
			if (token === ")") parenCount--;
			if (parenCount < 0) return "Mismatched parentheses";

			if (i > 0 && i < tokens.length - 1) {
				const prevToken = tokens[i - 1];
				const nextToken = tokens[i + 1];
				if (
					(token === "and" || token === "or") &&
					(prevToken === "(" ||
						nextToken === ")" ||
						prevToken === "and" ||
						prevToken === "or" ||
						nextToken === "and" ||
						nextToken === "or")
				) {
					return "Incorrect use of 'and' or 'or'";
				}
			}
		}
		if (parenCount !== 0) return "Mismatched parentheses";

		for (let i = 0; i < tokens.length - 1; i++) {
			if (tokens[i] === ")" && tokens[i + 1] === "(") {
				return "Missing 'and' or 'or' between parentheses";
			}
		}

		return "";
	}, []);
	const [showExamples, setShowExamples] = useState(false);

	const handleFilterChange = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			const newExpression = e.target.value;
			setFilterExpression(newExpression);
			const error = validateFilterExpression(newExpression);
			setFilterError(error);

			setAutopilotFormData({
				...autopilotFormData,
				config: {
					...autopilotFormData?.config,
					filter: newExpression,
				},
			});
		},
		[autopilotFormData, setAutopilotFormData, validateFilterExpression],
	);

	const selectedEnterprises = useMemo(() => {
		return enterprises?.filter((enterprise) =>
			autopilotEnterpriseIds?.includes(enterprise.id as UUID),
		);
	}, [enterprises, autopilotEnterpriseIds]);

	const handleEventChange = (value: string) => {
		const isActionEvent = isActionBasedEvent(value, config?.events);

		const tempConfig = {
			...autopilotFormData?.config,
			event_name: [value],
			schedule_type: undefined,
			schedule: undefined,
		};

		const shouldRemoveConfig =
			!enableInteractiveNotifications ||
			(isActionEvent &&
				!tempConfig.interactive_notification_config?.trigger_question) ||
			(!isActionEvent &&
				!tempConfig.interactive_notification_config?.chained_autopilots
					?.length);

		if (shouldRemoveConfig) {
			tempConfig.interactive_notification_config = undefined;
		}

		setAutopilotFormData({
			...autopilotFormData,
			config: tempConfig,
		});
	};

	const handleInteractiveNotificationsChange = (checked: boolean) => {
		setEnableInteractiveNotifications(!!checked);
		if (!checked) {
			// Clear the interactive notification config when disabling
			setAutopilotFormData({
				...autopilotFormData,
				config: {
					...autopilotFormData?.config,
					interactive_notification_config: undefined,
				},
			});
		}
		setValidationError("");
	};

	// State for controlling the select dropdown and search
	const [isActionAutopilotSelectOpen, setIsActionAutopilotSelectOpen] =
		useState(false);

	// Clear search when dropdown opens
	useEffect(() => {
		if (isActionAutopilotSelectOpen) {
			setSearchQuery("");
		}
	}, [isActionAutopilotSelectOpen]);

	// First, add a separate state for filtered autopilots
	const [filteredActionAutopilots, setFilteredActionAutopilots] = useState<
		Autopilot[]
	>([]);

	// Update useEffect to handle initial filtering
	useEffect(() => {
		if (displayedAutopilots) {
			const filtered = filterActionAutopilots(
				displayedAutopilots,
				config?.events,
			);
			setFilteredActionAutopilots(filtered);
		}
	}, [displayedAutopilots, config?.events]);

	// Add search filtering on top of the filtered results
	const searchedActionAutopilots = useMemo(() => {
		return filteredActionAutopilots.filter(
			(ap) =>
				ap.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
				(ap.config?.interactive_notification_config?.trigger_question || "")
					.toLowerCase()
					.includes(searchQuery.toLowerCase()),
		);
	}, [filteredActionAutopilots, searchQuery]);

	const onActionAutopilotSelect = (ap: Autopilot) => {
		const currentSelected =
			autopilotFormData?.config?.interactive_notification_config
				?.chained_autopilots ?? [];
		const newSelected = currentSelected.includes(ap.id)
			? currentSelected.filter((id) => id !== ap.id)
			: [...currentSelected, ap.id];

		setValidationError("");
		if (newSelected.length === 0) {
			setAutopilotFormData({
				...autopilotFormData,
				config: {
					...autopilotFormData?.config,
					interactive_notification_config: undefined,
				},
			});
			return;
		}

		setAutopilotFormData({
			...autopilotFormData,
			config: {
				...autopilotFormData?.config,
				interactive_notification_config: {
					...autopilotFormData?.config?.interactive_notification_config,
					chained_autopilots: newSelected,
				},
			},
		});
	};

	const onActionAutopilotRemove = (ap: Autopilot) => {
		const newSelected =
			autopilotFormData?.config?.interactive_notification_config?.chained_autopilots?.filter(
				(selectedId) => selectedId !== ap.id,
			) ?? [];
		if (newSelected.length === 0) {
			setAutopilotFormData({
				...autopilotFormData,
				config: {
					...autopilotFormData?.config,
					interactive_notification_config: undefined,
				},
			});
			return;
		}
		setAutopilotFormData({
			...autopilotFormData,
			config: {
				...autopilotFormData?.config,
				interactive_notification_config: {
					chained_autopilots: newSelected,
				},
			},
		});
	};

	return (
		<>
			<Flex
				direction="column"
				style={{ padding: 20, gap: "20px", overflow: "scroll" }}
			>
				<Flex style={{ gap: "20px" }} justify="between">
					<Flex
						direction="column"
						style={{ gap: "8px", width: "calc(50% - 10px)" }}
					>
						<Text weight={"bold"}>Short Description</Text>
						<Input
							placeholder="Enter the description"
							style={{
								height: "42px",
							}}
							defaultValue={autopilot?.description}
							onChange={(e) => {
								setAutopilotFormData({
									...autopilotFormData,
									description: e.target.value,
								});
							}}
						/>
					</Flex>
					<Flex
						style={{ gap: "8px", width: "calc(50% - 10px)" }}
						direction="column"
					>
						<Text weight={"bold"}>Agent Type</Text>
						<Selector
							defaultValue={autopilot?.config?.agent_type ?? ""}
							data={config?.agent_types ?? []}
							onChange={(value) => {
								setAutopilotFormData({
									...autopilotFormData,
									config: {
										...autopilotFormData?.config,
										agent_type: value,
									},
								});
							}}
							label="Agents"
							placeholder="Select an agent..."
						/>
					</Flex>
				</Flex>
				<Flex direction="column" style={{ gap: "8px" }}>
					<Text weight={"bold"}>Trigger</Text>
					<Flex style={{ gap: "20px" }}>
						<Flex style={{ width: "calc(50% - 10px)" }}>
							<Selector
								defaultValue={autopilot?.config?.execution_type ?? ""}
								data={config?.execution_types ?? []}
								onChange={(value) => {
									setAutopilotFormData({
										...autopilotFormData,
										config: {
											...autopilotFormData?.config,
											execution_type: value,
										},
									});
								}}
								label="Execution Type"
								placeholder="Select an execution type..."
							/>
						</Flex>
						{autopilotFormData?.config?.execution_type === "EVENT_BASED" && (
							<Flex style={{ width: "calc(50% - 10px)" }}>
								<Selector
									defaultValue={autopilot?.config?.event_name?.[0] ?? ""}
									data={config?.events ?? []}
									onChange={handleEventChange}
									label="Event"
									placeholder="Select an event..."
								/>
							</Flex>
						)}
						{autopilotFormData?.config?.execution_type === "RECURRING" && (
							<Flex style={{ width: "calc(50% - 10px)" }}>
								<Selector
									defaultValue={autopilot?.config?.schedule_type ?? ""}
									data={config?.schedule_types ?? []}
									onChange={(value) => {
										setAutopilotFormData({
											...autopilotFormData,
											config: {
												...autopilotFormData?.config,
												schedule_type: value,
												event_name: undefined,
											},
										});
									}}
									label="Schedule"
									placeholder="Select a schedule type..."
								/>
							</Flex>
						)}
					</Flex>
					{["CRON", "INTERVAL"].includes(
						autopilotFormData?.config?.schedule_type ?? "",
					) &&
						autopilotFormData?.config?.execution_type !== "EVENT_BASED" && (
							<Flex direction="column" style={{ gap: "8px" }}>
								{autopilotFormData?.config?.schedule_type === "CRON" && (
									<>
										<Text weight={"bold"}>Cron Based Schedule</Text>
										<Flex
											direction="row"
											style={{ gap: "8px", flexWrap: "wrap" }}
										>
											{CRON_KEYS.map((field) => (
												<Input
													//@ts-ignore
													defaultValue={
														autopilotFormData?.config?.schedule?.cron?.[
															field.id
														] ?? ""
													}
													placeholder={capitalize(field?.label)}
													value={
														autopilotFormData?.config?.schedule?.cron?.[
															field.id
														] ?? ""
													}
													onChange={(e) => {
														onCronChange(e.target.value, field?.id);
													}}
													style={{
														height: "42px",
													}}
												/>
											))}
										</Flex>
									</>
								)}
								{autopilotFormData?.config?.schedule_type === "INTERVAL" && (
									<>
										<Text weight={"bold"}>Interval Based Schedule</Text>
										<Flex direction="row" style={{ gap: "8px" }}>
											<Input
												style={{
													height: "42px",
												}}
												defaultValue={
													autopilotFormData?.config?.schedule?.interval?.[
														"interval"
													] ?? ""
												}
												placeholder="Interval (Number)"
												onChange={(e) => {
													onIntervalChange(e.target.value, "interval");
												}}
											/>
											<Selector
												defaultValue={
													autopilotFormData?.config?.schedule?.interval?.[
														"unit"
													] ?? ""
												}
												data={INTERVAL_UNITS}
												onChange={(value) => {
													onIntervalChange(value, "unit");
												}}
												label="Unit"
												placeholder="Select an unit..."
											/>
										</Flex>
									</>
								)}
							</Flex>
						)}
				</Flex>
				<Flex direction="column" style={{ gap: "8px" }}>
					<Text weight={"bold"}>Apps Required</Text>
					<Flex
						direction="row"
						style={{ flexWrap: "wrap", gap: "5px 20px" }}
						align="center"
					>
						{config?.apps.map((app) => (
							<Flex
								id={`autopilot-app-${app.id}`}
								direction="row"
								style={{ gap: "6px", cursor: "pointer" }}
								justify="center"
								align="center"
								key={app.id}
								onClick={() => {
									if (
										autopilotFormData?.config?.required_apps?.includes(app.id)
									) {
										setAutopilotFormData({
											...autopilotFormData,
											config: {
												...autopilotFormData?.config,
												required_apps:
													autopilotFormData?.config?.required_apps?.filter(
														(appId) => appId !== app.id,
													),
											},
										});
									} else {
										setAutopilotFormData({
											...autopilotFormData,
											config: {
												...autopilotFormData?.config,
												required_apps: [
													...(autopilotFormData?.config?.required_apps ?? []),
													app.id,
												],
											},
										});
									}
								}}
							>
								{/* @ts-ignore */}
								<Checkbox
									key={app.id}
									defaultChecked={
										autopilot?.config?.required_apps?.includes(app.id) ?? false
									}
									checked={
										autopilotFormData?.config?.required_apps?.includes(
											app.id,
										) ?? false
									}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										cursor: "pointer",
									}}
								/>
								<Text>{app.label}</Text>
							</Flex>
						))}
					</Flex>
				</Flex>
				<Flex direction="column" style={{ gap: "8px" }}>
					<Text weight={"bold"}>Interaction Settings</Text>
					{autopilotFormData?.config?.event_name?.[0] && (
						<Flex direction="column" style={{ gap: "8px" }}>
							{isActionBasedEvent(
								autopilotFormData.config.event_name[0],
								config?.events,
							) ? (
								<Flex direction="column" style={{ gap: "8px" }}>
									<Text weight="bold">Call to Action</Text>
									<Flex direction="column" style={{ gap: "4px" }}>
										<Input
											placeholder="Enter the call to action"
											value={
												autopilotFormData?.config
													?.interactive_notification_config?.trigger_question ??
												""
											}
											onChange={(e) => {
												if (e.target.value.length <= 30) {
													setValidationError("");
													setAutopilotFormData({
														...autopilotFormData,
														config: {
															...autopilotFormData?.config,
															interactive_notification_config: {
																trigger_question: e.target.value,
																chained_autopilots: undefined,
															},
														},
													});
												}
											}}
											maxLength={30}
											style={{ height: "42px" }}
											aria-describedby="question-char-count"
										/>
										<Flex justify="between" style={{ paddingTop: "2px" }}>
											<Text color="red" size="1">
												{validationError}
											</Text>
											<Text id="question-char-count" color="gray" size="1">
												{autopilotFormData?.config
													?.interactive_notification_config?.trigger_question
													?.length ?? 0}
												/30
											</Text>
										</Flex>
									</Flex>
								</Flex>
							) : (
								<>
									<Flex align="center" justify="start" style={{ gap: "8px" }}>
										<Checkbox
											checked={enableInteractiveNotifications}
											onCheckedChange={handleInteractiveNotificationsChange}
											style={{ cursor: "pointer" }}
										/>
										<Text>Enable Interactive Notifications</Text>
									</Flex>
									{enableInteractiveNotifications &&
										!autopilotFormData?.config?.event_name?.[0]
											?.toLowerCase()
											.includes("action") && (
											<Flex direction="column" id="action-autopilot-select">
												<Text weight="bold" style={{ marginBottom: "8px" }}>
													Action Autopilots
												</Text>
												<Input
													placeholder="Search action autopilots..."
													value={searchQuery}
													onChange={(e) => {
														handleSearch(e.target.value);
														if (!searchResultsOpen) {
															setSearchResultsOpen(true);
														}
														setTimeout(() => {
															searchActionAutopilotInputRef.current?.focus();
														}, 0);
													}}
													style={{
														height: "42px",
														width: "100%",
													}}
													ref={searchActionAutopilotInputRef}
												/>
												<Popover.Root
													open={searchQuery.length > 0 && searchResultsOpen}
													onOpenChange={(open) => {
														if (open) {
															searchActionAutopilotInputRef.current?.focus();
															setSearchResultsOpen(true);
														}
													}}
												>
													<Popover.Content
														side="bottom"
														align="start"
														style={{
															maxHeight: "200px",
															maxWidth: "1200px",
															width: `calc(${searchActionAutopilotInputRef.current?.offsetWidth}px)`,
															overflow: "auto",
															padding: 0,
															zIndex: 1000,
															position: "fixed",
															top:
																searchActionAutopilotInputRef.current?.getBoundingClientRect()
																	.bottom ?? 0,
															left: `calc(${
																searchActionAutopilotInputRef.current?.getBoundingClientRect()
																	.left ?? 0
															}px)`,
														}}
													>
														<SearchResults
															direction="column"
															style={{ width: "100%" }}
															ref={searchActionAutopilotsRef}
														>
															{isLoadingAutopilots ? (
																<Flex
																	align="center"
																	justify="center"
																	style={{ padding: "12px" }}
																>
																	Loading...
																</Flex>
															) : (
																searchedActionAutopilots.map((ap) => (
																	<SearchResultItem key={ap.id}>
																		<Flex
																			align="center"
																			justify="between"
																			style={{ width: "100%" }}
																		>
																			<Flex gap="2" align="center">
																				<Checkbox
																					checked={autopilotFormData?.config?.interactive_notification_config?.chained_autopilots?.includes(
																						ap.id,
																					)}
																					onCheckedChange={() => {
																						onActionAutopilotSelect(ap);
																					}}
																					style={{ flexShrink: 0 }}
																				/>
																				<Flex direction="column">
																					<Text weight="medium">{ap.name}</Text>
																					<Text
																						size="1"
																						style={{ color: "var(--gray-11)" }}
																					>
																						{ap.config
																							?.interactive_notification_config
																							?.trigger_question || ""}
																					</Text>
																				</Flex>
																			</Flex>
																		</Flex>
																	</SearchResultItem>
																))
															)}
														</SearchResults>
													</Popover.Content>
												</Popover.Root>

												{(autopilotFormData?.config
													?.interactive_notification_config?.chained_autopilots
													?.length ?? 0) > 0 && (
													<Flex
														direction="column"
														style={{ gap: "8px", marginTop: "16px" }}
													>
														<Text weight="bold">Selected Autopilots</Text>
														{autopilotFormData?.config?.interactive_notification_config?.chained_autopilots?.map(
															(id) => {
																const ap = allAutopilots?.find(
																	(a) => a.id === id,
																);
																if (!ap) return null;
																return (
																	<Flex
																		key={id}
																		direction="column"
																		style={{
																			gap: "4px",
																			padding: "8px",
																			backgroundColor: "var(--gray-2)",
																			borderRadius: "4px",
																		}}
																	>
																		<Flex justify="between" align="center">
																			<a
																				href={getAutopilotUrl(
																					enterpriseId,
																					ap.id,
																				)}
																				target="_blank"
																				rel="noopener noreferrer"
																				style={{
																					color: "var(--accent-9)",
																					cursor: "pointer",
																					textDecoration: "none",
																					fontWeight: 500,
																				}}
																				onClick={(e) => e.stopPropagation()}
																			>
																				{ap.name}
																			</a>
																			<Icon.X
																				style={{ cursor: "pointer" }}
																				onClick={() => {
																					onActionAutopilotRemove(ap);
																				}}
																			/>
																		</Flex>
																		<Text
																			size="2"
																			style={{ color: "var(--gray-11)" }}
																		>
																			{ap.config
																				?.interactive_notification_config
																				?.trigger_question || ""}
																		</Text>
																	</Flex>
																);
															},
														)}
													</Flex>
												)}
												{validationError && (
													<Text color="red" style={{ fontSize: "12px" }}>
														{validationError}
													</Text>
												)}
											</Flex>
										)}
								</>
							)}
						</Flex>
					)}
				</Flex>
				{autopilotFormData?.config?.execution_type === "EVENT_BASED" && (
					<Flex direction="column" style={{ gap: "8px" }}>
						<Text weight={"bold"}>Filter Expression</Text>
						<TextArea
							id="autopilot-filter-expression"
							placeholder="Enter filter expression"
							// defaultValue={autopilotFormData?.config?.filter ?? ""}
							value={autopilotFormData?.config?.filter ?? ""}
							onChange={handleFilterChange}
							style={{
								height: "102px",
								borderRadius: "4px",
								padding: "12px",
								width: "100%",
							}}
						/>
						{filterError && (
							<Text color="red" style={{ fontSize: "12px" }}>
								{filterError}
							</Text>
						)}
						{autopilotFormData?.config?.execution_type === "EVENT_BASED" && (
							<Flex direction="column" style={{ gap: "8px" }}>
								<Button
									variant="outline"
									size="1"
									onClick={() => setShowExamples(!showExamples)}
									style={{ alignSelf: "flex-start", marginTop: "8px" }}
								>
									{showExamples ? "Hide Examples" : "Show Examples"}
								</Button>
								{showExamples && (
									<Flex
										direction="column"
										style={{ gap: "8px", marginTop: "8px" }}
										id="autopilot-filter-examples"
									>
										<Text weight="bold">Examples:</Text>
										<pre
											style={{
												backgroundColor: "#f5f5f5",
												padding: "8px",
												borderRadius: "4px",
												whiteSpace: "pre-wrap",
												wordWrap: "break-word",
											}}
										>
											{AutoPilotFilterExamples}
										</pre>
									</Flex>
								)}
							</Flex>
						)}
						<Text style={{ fontSize: "12px", color: "gray" }}>
							Available operators:
							<br />
							- Comparison: is, in, ==, !=, &gt;, &lt;, &gt;=, &lt;=
							<br />
							- Date: before, after
							<br />
							- Logical: and, or
							<br />
							For negation, use ! before parentheses.
							<br />
							Use parentheses () to group conditions.
							<br />
						</Text>
					</Flex>
				)}
				<Flex direction="column" style={{ gap: "8px" }}>
					<Text weight={"bold"}>
						Instructions<sup>*</sup>
					</Text>
					{autopilotFormData?.config?.execution_type === "EVENT_BASED" &&
						autopilotFormData?.config?.event_name && (
							<Flex direction="column">
								<Text weight={"bold"}>Supported prompt variables:</Text>
								<Flex direction="row">
									<Text weight={"bold"}>Required:&nbsp;</Text>
									<Text>
										{config?.event_fields_mapping[
											autopilotFormData?.config?.event_name[0]
										]?.fields_required?.join(" | ")}
									</Text>
								</Flex>
								<Flex direction="row">
									<Text weight={"bold"}>Optional:&nbsp;</Text>
									<Text>
										{config?.event_fields_mapping[
											autopilotFormData?.config?.event_name[0]
										]?.fields_optional?.join(" | ")}
									</Text>
								</Flex>
							</Flex>
						)}
					{autopilotFormData?.config?.messages?.map((message, index) => (
						<Flex direction="row" key={index}>
							<DynamicTextArea
								textAreaId={`autopilot-prompt-${index}`}
								placeholder="Enter the autopilot prompt"
								value={message.prompt}
								onChange={(e) => {
									onPromptChange(e, index);
								}}
								style={{
									height: "102px",
									borderRadius: "4px 0px 0px 4px",
									padding: "8px",
									width: "calc(100% - 30px)",
									resize: "none",
									minHeight: "auto",
								}}
								maxLines={50}
								showDeleteTextArea={true}
								onDelete={() => {
									onPromptDelete(index);
								}}
								requiredVariables={
									autopilotFormData?.config?.event_name &&
									autopilotFormData?.config?.event_name.length > 0
										? config?.event_fields_mapping[
												autopilotFormData?.config?.event_name?.[0]
											]?.fields_required
										: []
								}
								optionalVariables={
									autopilotFormData?.config?.event_name &&
									autopilotFormData?.config?.event_name.length > 0
										? config?.event_fields_mapping[
												autopilotFormData?.config?.event_name?.[0]
											]?.fields_optional
										: []
								}
								enableAutoSuggest={true}
								autoSuggestIdentifier="{"
							/>
						</Flex>
					))}
					{/* @ts-ignore */}
					<Button
						variant="outline"
						style={{ width: "20%", cursor: "pointer" }}
						onClick={onAddMore}
					>
						<Icon.Plus size="20px" />
						<Text>Add More</Text>
					</Button>
				</Flex>
				<Flex direction="column" style={{ gap: "8px" }}>
					<Text weight="bold">Broadcast Notifications</Text>
					<Flex align="start" justify="start" style={{ gap: "8px" }}>
						<Switch
							checked={
								autopilotFormData?.config?.broadcast_notification || false
							}
							onCheckedChange={handleBroadcastToggle}
							style={{ cursor: "pointer" }}
						/>
						<Text>Enable Broadcast</Text>
					</Flex>

					{autopilotFormData?.config?.broadcast_notification && (
						<Flex direction="column" style={{ gap: "8px" }}>
							<Text weight="bold">Broadcast Recipients</Text>
							<Input
								placeholder="Enter recipient variables, e.g., {user_email}"
								value={autopilotFormData?.config?.broadcast_recipients || ""}
								onChange={(e) =>
									handleBroadcastRecipientsChange(e.target.value)
								}
								style={{ height: "42px" }}
							/>
						</Flex>
					)}
				</Flex>
				<Flex direction="column" style={{ gap: "8px" }}>
					<Text weight={"bold"}>Users</Text>
					<Flex
						direction="row"
						style={{ flexWrap: "wrap", gap: "8px" }}
						align="center"
					>
						{autopilotUsers?.length > 0 ? (
							<>
								{autopilotUsers?.map((user) => (
									<Card key={user.id} data={user} onRemove={onRemoveUser} />
								))}
								<Flex
									style={{ gap: "6px", cursor: "pointer" }}
									align="center"
									justify="center"
									onClick={() => {
										setMultiSelectionDialogData({
											data: enterpriseUsers ?? [],
											initialData: autopilotUsers ?? [],
											onSubmit: (selectedData) =>
												onSelectUsers(selectedData as User[]),
										});
										setMultiSelectionDialogOpen(true);
									}}
								>
									<Icon.Plus size="17px" weight="bold" />
									<Text style={{ fontSize: "12px" }}>Add More</Text>
								</Flex>
							</>
						) : (
							// @ts-ignore
							<Button variant="outline" style={{ width: "20%" }}>
								<Flex
									align="center"
									justify="center"
									style={{ gap: "6px", cursor: "pointer" }}
									onClick={() => {
										setMultiSelectionDialogData({
											data: enterpriseUsers ?? [],
											initialData: autopilotUsers ?? [],
											onSubmit: (selectedData) =>
												onSelectUsers(selectedData as User[]),
										});
										setMultiSelectionDialogOpen(true);
									}}
								>
									<Icon.Plus size="20px" />
									<Text>Add User</Text>
								</Flex>
							</Button>
						)}
					</Flex>
				</Flex>

				<Flex direction="column" style={{ gap: "8px" }}>
					<Text weight={"bold"}>Enterprises</Text>
					<Flex
						direction="row"
						style={{ flexWrap: "wrap", gap: "8px" }}
						align="center"
					>
						{autopilotEnterpriseIds?.length > 0 ? (
							<>
								{autopilotEnterpriseIds?.map((id) => {
									const enterprise = enterprises?.find(
										(enterprise: Enterprise) => enterprise.id === id,
									);
									if (!enterprise) return null;
									return (
										<Card
											key={id}
											data={enterprise}
											onRemove={onRemoveEnterprise}
										/>
									);
								})}
								<Flex
									style={{ gap: "6px", cursor: "pointer" }}
									align="center"
									justify="center"
									onClick={() => {
										setMultiSelectionDialogData({
											data: enterprises ?? [],
											initialData: selectedEnterprises ?? [],
											onSubmit: (selectedData) =>
												onSelectEnterprises(selectedData as Enterprise[]),
											title: "Select Enterprises",
										});
										setMultiSelectionDialogOpen(true);
									}}
								>
									<Icon.Plus size="17px" weight="bold" />
									<Text style={{ fontSize: "12px" }}>Add More</Text>
								</Flex>
							</>
						) : (
							<Button variant="outline" style={{ width: "20%" }}>
								<Flex
									align="center"
									justify="center"
									style={{ gap: "6px", cursor: "pointer" }}
									onClick={() => {
										setMultiSelectionDialogData({
											data: enterprises ?? [],
											initialData: selectedEnterprises ?? [],
											onSubmit: (selectedData) =>
												onSelectEnterprises(selectedData as Enterprise[]),
											title: "Select Enterprises",
										});
										setMultiSelectionDialogOpen(true);
									}}
								>
									<Icon.Plus size="20px" />
									<Text>Add Enterprise</Text>
								</Flex>
							</Button>
						)}
					</Flex>
				</Flex>

				<Flex style={{ gap: "8px", flexBasis: "100%" }}>
					<Button
						disabled={!formDataChanged || !!filterError || isSaving}
						style={{ cursor: "pointer", width: "20%" }}
						onClick={handleSaveAutopilot}
					>
						{isSaving ? "Saving..." : "Save"}
					</Button>
					{autopilot?.id && (
						<Button
							style={{
								cursor: "pointer",
								width: "20%",
							}}
							onClick={onTestRunClick}
						>
							Test Run
						</Button>
					)}
					{!autopilot?.id && (
						<Button
							style={{
								cursor: "pointer",
								width: "20%",
							}}
							onClick={onDraftSave}
						>
							Save Draft
						</Button>
					)}
				</Flex>
				<MultiSelectionDialog
					open={multiSelectionDialogOpen}
					onOpenChange={setMultiSelectionDialogOpen}
					handleClose={() => setMultiSelectionDialogOpen(false)}
					handleSubmit={
						multiSelectionDialogData?.onSubmit as (
							selectedData: User[] | Enterprise[],
						) => void
					}
					initialData={multiSelectionDialogData?.initialData}
					data={multiSelectionDialogData?.data}
					title={multiSelectionDialogData?.title}
				/>
			</Flex>
		</>
	);
};

export { AutopilotForm };
