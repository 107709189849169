import {
	useFavorites,
	PeopleStatusOptions,
	PeopleTypeOptions,
	Analytics,
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
	useFeatureFlag,
	usePeopleList,
	PEOPLE_PAGE_FEATURE,
} from "@mightybot/core";
import { PeopleContainer } from "./styled";
import Placeholder from "../../utils/Placeholder";
import { PlaceholderPeoplePage } from "@mightybot/core-ui";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
	Flex,
	Text,
	Icon,
	Checkbox,
	breakpoints,
	useMediaQuery,
} from "@mightybot/web-ui";
import { InfiniteScroll } from "./InfiniteScroll";
import {
	TableHeaderContainer,
	ActionButton,
	CategoryOption,
	TableContainer,
	ResponsiveContainer,
	TableScrollContainer,
	TableContent,
} from "./styled";
import GenericSearchBar from "../Search/GenericSearchBar";
import { PeopleFilters } from "./PeopleFilters";
import { PeopleTable } from "./PeopleTable";
import { capitalize } from "../../utils/helpers";
import { debounce } from "lodash";

export const PEOPLE_TYPE_OPTIONS: PeopleTypeOptions[] = [
	"CUSTOMER",
	"TEAM",
	"EXTERNAL",
];

const PER_PAGE = 20;

type BulkActionsProps = {
	selectedCount: number;
	onRemove: () => void;
	onStar: () => void;
	onAddTag: () => void;
	onReset: () => void;
};

const TableHeader = ({
	onSelectAll,
	areAllSelected,
}: {
	onSelectAll: (checked: boolean) => void;
	areAllSelected: boolean;
}) => {
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	return (
		<TableHeaderContainer
			p="8px 16px"
			align="center"
			width="100%"
			id="people-list-table-header"
			gap="10px"
		>
			<Flex>
				<Checkbox
					checked={areAllSelected}
					onCheckedChange={(checked) => onSelectAll(checked as boolean)}
				/>
			</Flex>
			<Flex style={{ flex: 0.4, fontSize: "13px" }}>Name & Email</Flex>
			{!isTablet && (
				<Flex style={{ flex: 0.3, fontSize: "13px" }}>Organization</Flex>
			)}
			<Flex style={{ flex: 0.3, fontSize: "13px" }}>Type & Status</Flex>
			<Flex style={{ flex: 0.4, fontSize: "13px" }}>Job Title</Flex>
		</TableHeaderContainer>
	);
};

const BulkActions = ({
	selectedCount,
	onRemove,
	onStar,
	onAddTag,
	onReset,
}: BulkActionsProps) => (
	<Flex align="center" gap="16px">
		<Text size="3" weight="bold">
			{selectedCount} Selected
		</Text>
		<ActionButton onClick={onReset}>
			<Icon.X size={16} />
			<Text className="button-text">Reset</Text>
		</ActionButton>
		<ActionButton onClick={onStar}>
			<Icon.Star size={16} />
			<Text className="button-text">Star</Text>
		</ActionButton>
	</Flex>
);

const People = () => {
	const isPeoplePageEnabled = useFeatureFlag(PEOPLE_PAGE_FEATURE);

	const [selectedStatuses, setSelectedStatuses] = useState<
		PeopleStatusOptions[]
	>([]);
	const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
	const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
	const [selectedDeals, setSelectedDeals] = useState<string[]>([]);
	const [selectedPeopleType, setSelectedPeopleType] = useState<
		PeopleTypeOptions | "All"
	>("All");

	const [searchQuery, setSearchQuery] = useState("");

	const [isInternalOnly, setIsInternalOnly] = useState(false);

	const { people, total, isLoading, error, refetch, page, setPage } =
		usePeopleList({
			per_page: PER_PAGE,
			search: searchQuery,
			companies: selectedCompanies,
			teams: selectedTeams,
			type: selectedPeopleType === "All" ? undefined : selectedPeopleType,
			internalOnly: isInternalOnly,
		});
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

	const [isStarred, setIsStarred] = useState(false);
	const [selectedPeople, setSelectedPeople] = useState<Set<string>>(new Set());
	const { favorites, toggleBulkFavorites } = useFavorites("PERSON");

	const filterDependencies = useMemo(
		() => ({
			companies: selectedCompanies,
			type: selectedPeopleType,
		}),
		[selectedCompanies, selectedPeopleType],
	);

	useEffect(() => {
		refetch();
	}, [refetch, filterDependencies]);

	useEffect(() => {
		Analytics.trackEvent(ANALYTICS_EVENTS.PEOPLE_LIST_VIEWED, {
			[ANALYTICS_PROPERTIES.PEOPLE_COUNT]: people?.length || 0,
		});
	}, [people?.length]);

	const filteredPeople = useMemo(() => {
		if (!people) return [];

		let filtered = [...people];
		if (isStarred) {
			filtered = filtered.filter((person) => favorites.includes(person.id));
		}
		return filtered;
	}, [people, isStarred, favorites]);

	// Update handleSelectAll to use filteredPeople
	const handleSelectAll = useCallback(
		(checked: boolean) => {
			Analytics.trackEvent(ANALYTICS_EVENTS.PEOPLE_BULK_ACTION, {
				[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: checked
					? "select_all"
					: "deselect_all",
				[ANALYTICS_PROPERTIES.SELECTED_COUNT]: checked ? people?.length : 0,
			});
			if (checked) {
				const allPeopleIds = new Set(filteredPeople?.map((p) => p.id));
				setSelectedPeople(allPeopleIds);
			} else {
				setSelectedPeople(new Set());
			}
		},
		[filteredPeople],
	);

	const handleSelectChange = useCallback((id: string, checked: boolean) => {
		if (checked) {
			Analytics.trackEvent(ANALYTICS_EVENTS.PEOPLE_SELECTED, {
				person_id: id,
			});
		}
		setSelectedPeople((prev) => {
			const newSelected = new Set(prev);
			if (checked) {
				newSelected.add(id);
			} else {
				newSelected.delete(id);
			}
			return newSelected;
		});
	}, []);

	const handleBulkRemove = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.PEOPLE_BULK_ACTION, {
			[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: "remove",
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedPeople.size,
		});
	};

	const handleBulkStar = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.PEOPLE_BULK_ACTION, {
			[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: "star",
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedPeople.size,
		});
		// Implement bulk star logic
		toggleBulkFavorites(Array.from(selectedPeople));
	};

	const handleBulkAddTag = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.PEOPLE_BULK_ACTION, {
			[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: "add_tag",
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedPeople.size,
		});
	};

	const handleCompanyChange = useCallback((companies: string[]) => {
		setPage(1);
		setSelectedCompanies(companies);
	}, []);

	const handleTeamsChange = useCallback((teams: string[]) => {
		setPage(1);
		setSelectedTeams(teams);
	}, []);

	const handleStatusesChange = useCallback(
		(statuses: PeopleStatusOptions[]) => {
			setPage(1);
			setSelectedStatuses(statuses);
		},
		[],
	);

	const handleDealsChange = useCallback((deals: string[]) => {
		setPage(1);
		setSelectedDeals(deals);
	}, []);

	const debouncedSearch = useCallback(
		debounce((query: string) => {
			setSearchQuery(query);
		}, 300),
		[],
	);

	const handleSearch = (query: string) => {
		setPage(1);
		debouncedSearch(query);
	};

	const handleLoadMore = useCallback(async () => {
		setPage((prev) => prev + 1);
	}, []);

	const handlePeopleTypeChange = useCallback(
		(peopleType: PeopleTypeOptions | "All") => {
			setPage(1);
			if (peopleType === "All") {
				setSelectedPeopleType("All");
			} else {
				setSelectedPeopleType(peopleType);
			}
		},
		[],
	);

	if (isPeoplePageEnabled === null) {
		return null;
	}

	if (!isPeoplePageEnabled) {
		return (
			<PeopleContainer>
				<Placeholder
					placeholder={PlaceholderPeoplePage}
					style={{ alignItems: "flex-start" }}
					imageStyle={{ height: "auto" }}
				/>
			</PeopleContainer>
		);
	}

	return (
		<ResponsiveContainer direction="column">
			<GenericSearchBar
				placeholder="Search People"
				onSearch={handleSearch}
				onSearchQueryChange={handleSearch}
				showPastSearches={false}
				size={isMobile ? "2" : "3"}
			/>
			<Flex
				align="center"
				width="100%"
				id="people-list-filters"
				style={{
					flexWrap: "wrap",
					gap: isTablet ? "6px" : "10px",
				}}
			>
				<PeopleFilters
					selectedCompanies={selectedCompanies}
					selectedTeams={selectedTeams}
					selectedStatuses={selectedStatuses}
					selectedDeals={selectedDeals}
					onCompaniesChange={handleCompanyChange}
					onTeamsChange={handleTeamsChange}
					onStatusesChange={handleStatusesChange}
					onDealsChange={handleDealsChange}
					isInternalOnly={isInternalOnly}
				/>
			</Flex>
			{selectedPeople.size > 0 ? (
				<BulkActions
					selectedCount={selectedPeople.size}
					onRemove={handleBulkRemove}
					onStar={handleBulkStar}
					onAddTag={handleBulkAddTag}
					onReset={() => setSelectedPeople(new Set())}
				/>
			) : (
				<Flex
					align="center"
					justify="between"
					gap="8px"
					style={{
						flexWrap: "wrap",
						width: "100%",
						padding: isTablet ? "0 10px" : undefined,
					}}
				>
					<Flex align="center" gap="8px" style={{ flexWrap: "wrap" }}>
						<Text size="3" weight="bold">
							People
						</Text>
						<Text size="1" weight="light">
							{total ?? 0}
						</Text>
						<Flex
							align="center"
							style={{ gap: isTablet ? "8px" : "16px", flexWrap: "wrap" }}
						>
							{
								<Flex
									align="center"
									style={{
										borderRadius: "var(--radius-pill)",
										border: "1px solid var(--mb-gray-5)",
										height: "28px",
									}}
								>
									{["All", ...PEOPLE_TYPE_OPTIONS].map((peopleType) => (
										<CategoryOption
											key={peopleType}
											active={
												selectedPeopleType === peopleType ? "true" : "false"
											}
											onClick={() =>
												handlePeopleTypeChange(peopleType as PeopleTypeOptions)
											}
										>
											{capitalize(peopleType)}
										</CategoryOption>
									))}
								</Flex>
							}
						</Flex>
						<ActionButton
							data-active={isStarred}
							onClick={() => setIsStarred(!isStarred)}
						>
							<Icon.Star size={16} weight={isStarred ? "fill" : "regular"} />
							<Text>Starred</Text>
						</ActionButton>
					</Flex>
					{/* <Flex
						justify="end"
						align="center"
						gap="8px"
						style={{
							cursor: "not-allowed",
							color: "var(--mb-blue-8)",
						}}
					>
						<Icon.TreeStructure size={16} />
						<Text>My Org Chart</Text>
					</Flex> */}
				</Flex>
			)}

			<TableContainer
				direction="column"
				id="people-list-table"
				style={{
					borderRadius: isTablet ? "4px" : "8px",
					padding: isTablet ? "0" : undefined,
				}}
			>
				{!isTablet && (
					<TableHeader
						onSelectAll={handleSelectAll}
						areAllSelected={selectedPeople.size === filteredPeople?.length}
					/>
				)}
				<TableScrollContainer
					style={{
						minWidth: isTablet ? "100%" : "800px",
					}}
				>
					<TableContent>
						<InfiniteScroll
							data={filteredPeople}
							isLoading={isLoading}
							error={error as Error | null}
							hasMore={page * PER_PAGE < (total ?? 0)}
							onLoadMore={handleLoadMore}
							renderItems={(items) => (
								<PeopleTable
									people={items}
									selectedPeople={selectedPeople}
									onSelectChange={handleSelectChange}
									isLoading={isLoading && !items.length}
								/>
							)}
							showNoMoreItems={true}
						/>
					</TableContent>
				</TableScrollContainer>
			</TableContainer>
		</ResponsiveContainer>
	);
};

export default React.memo(People);
