import React, { useState, useEffect } from "react";
import {
	Dialog,
	Button,
	Text,
	Flex,
	Icon,
	Checkbox,
	Spinner,
	Select,
	CustomReactMarkdown,
	breakpoints,
	useMediaQuery,
} from "@mightybot/web-ui";
import * as Styled from "./styled";
import {
	DocType,
	MeetingContentItem,
	Meeting,
	GenerateDocRequest,
	useModelSelection,
} from "@mightybot/core";
import { useTemplates, useDocuments } from "@mightybot/core";
import Toast from "../Toast";
import GenerateDocOutput from "./GenerateDocOutput";
import {
	ButtonGroup,
	FooterContainer,
	StyledSelect,
	TemplateSelect,
	TemplateSelectContainer,
} from "./styled";
import { GoogleDocs } from "@mightybot/core-ui";
import { UUID } from "crypto";

interface GenerateDocProps {
	meetingContent: MeetingContentItem[];
	showToastMessage: (
		title: string,
		description: string,
		status: "success" | "error",
	) => void;
	meeting: Meeting | {};
}

const GenerateDoc: React.FC<GenerateDocProps> = ({
	meetingContent,
	showToastMessage,
	meeting,
}) => {
	const [open, setOpen] = useState(false);
	const [activeTab] = useState<DocType>("GOOGLE_DOC");
	const [documentName, setDocumentName] = useState("");
	const [selectedOutlines, setSelectedOutlines] = useState<string[]>([]);
	const [showOutput, setShowOutput] = useState(false);
	const [documentUrl, setDocumentUrl] = useState("");
	const [selectedTemplate, setSelectedTemplate] = useState("");
	const [selectedTemplateVersion, setSelectedTemplateVersion] = useState("1.0");
	const { getSelectedModel } = useModelSelection();
	const model = getSelectedModel();

	const { templates, isLoading: isLoadingTemplates } = useTemplates();
	const { generateDocument, isLoading: isGenerating } = useDocuments();
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	// Auto-select first template when templates load
	useEffect(() => {
		if (templates?.length > 0 && !selectedTemplate) {
			setSelectedTemplate(templates[0].id);
			setSelectedTemplateVersion(templates[0].version);
		}
	}, [templates]);

	// Set initial document name when meeting or selected template changes
	useEffect(() => {
		const meetingTitle = (meeting as Meeting)?.title || "";
		const templateName =
			templates?.find((t) => t.id === selectedTemplate)?.name || "";
		const currentDate = new Date()
			.toLocaleDateString("en-GB", {
				day: "2-digit",
				month: "short",
				year: "numeric",
			})
			.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

		const newDocName =
			`${templateName} - ${meetingTitle} - ${currentDate}`.trim();
		setDocumentName(newDocName);
	}, [meeting, selectedTemplate, templates]);

	// Auto-select all outlines when dialog opens
	useEffect(() => {
		if (meetingContent) {
			setSelectedOutlines(meetingContent.map((item) => item.id));
		}
	}, [meetingContent]);

	const templateOptions =
		templates?.map((template) => ({
			label: template.name,
			value: template.id,
			version: template.version,
		})) || [];

	const handleOutlineToggle = (id: string) => {
		setSelectedOutlines((prev) =>
			prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id],
		);
	};

	const handleGenerate = async () => {
		if (!selectedOutlines.length || !selectedTemplate) {
			showToastMessage(
				"Missing Required Fields",
				"Please select content and a template to generate a document",
				"error",
			);
			return;
		}

		try {
			const request: GenerateDocRequest = {
				template_id: selectedTemplate as UUID,
				collection_id: meetingContent[0].collection_id,
				document_name: documentName,
				selected_content_ids: selectedOutlines.map((id) => id as UUID),
				document_type: activeTab,
				template_version: selectedTemplateVersion,
				name_model: model.id,
			};

			const result = await generateDocument(request);

			if (!result?.documentUrl) {
				throw new Error("No document URL returned");
			}

			setDocumentUrl(result.documentUrl);
			setShowOutput(true);
			showToastMessage(
				"Document Generated",
				"Your document has been generated successfully",
				"success",
			);
			setOpen(false);
		} catch (error) {
			showToastMessage(
				"Generation Failed",
				"Failed to generate document",
				"error",
			);
		}
	};

	const isDisabled = !selectedOutlines.length || !selectedTemplate;
	return (
		<>
			<Dialog.Root open={open} onOpenChange={setOpen}>
				<Dialog.Trigger>
					<Button
						disabled={isDisabled}
						onClick={() => setOpen(true)}
						style={{
							height: "34px",
							background: isDisabled
								? "var(--mb-color-light-gray-2)"
								: "var(--mb-blue-8)",
							cursor: isDisabled ? "not-allowed" : "pointer",
							borderRadius: "var(--radius-pill)",
							padding: isTablet ? "0 12px" : "0 17px",
						}}
					>
						<Icon.Lightning size={isTablet ? 16 : 20} />
						{!isTablet && "Generate doc"}
					</Button>
				</Dialog.Trigger>

				<Styled.Container>
					<Dialog.Title hidden>Generate Document</Dialog.Title>
					<Dialog.Close>
						<Styled.CloseButton>
							<Icon.X size={20} />
						</Styled.CloseButton>
					</Dialog.Close>
					<Flex direction="column" gap="16" style={{ height: "100%" }}>
						<Styled.TabContainer>
							<Styled.Tab active={true} onClick={() => {}}>
								<img
									src={GoogleDocs}
									alt="Google Docs"
									style={{ width: "25px", height: "34px" }}
								/>
								<Styled.TabContent>
									<Styled.TabTitle active={true}>
										Write to Google doc
									</Styled.TabTitle>
									<Styled.TabDescription>
										Create a detailed document
									</Styled.TabDescription>
								</Styled.TabContent>
							</Styled.Tab>
						</Styled.TabContainer>

						<Flex direction="column" style={{ margin: "16px 0" }}>
							<Text size="2" weight="medium">
								Document Name
							</Text>
							<Styled.DocumentNameInput
								value={documentName}
								onChange={(e) => setDocumentName(e.target.value)}
								placeholder="Meeting Summary of..."
							/>
						</Flex>

						<Styled.OutlinesContainer>
							<Text
								size="6"
								weight="medium"
								style={{
									lineHeight: "24px",
									position: "sticky",
									top: 0,
									background: "white",
									zIndex: 1,
								}}
							>
								All Outlines
							</Text>
							{meetingContent?.map((item) => (
								<Styled.OutlineItem key={item.id}>
									<Checkbox
										id={item.id}
										checked={selectedOutlines.includes(item.id)}
										onCheckedChange={() => handleOutlineToggle(item.id)}
									/>
									<Styled.OutlineContent>
										<Text style={{ marginTop: "-2px" }} weight="medium">
											{item.title}
										</Text>
										<div
											style={{
												overflow: "hidden",
												textOverflow: "ellipsis",
												display: "-webkit-box",
												WebkitLineClamp: 2,
												WebkitBoxOrient: "vertical",
												lineHeight: "1.4",
											}}
										>
											<CustomReactMarkdown
												styles={{
													content: {
														textOverflow: "ellipsis",
														whiteSpace: "normal",
														wordBreak: "break-word",
													},
													paragraph: {
														margin: 0,
														display: "inline",
													},
													heading: {
														margin: 0,
														fontWeight: 400,
														fontSize: 14,
													},
												}}
												disableLinks={true}
											>
												{String(item.content || "").split("\n\n")[0]}
											</CustomReactMarkdown>
										</div>
									</Styled.OutlineContent>
								</Styled.OutlineItem>
							))}
						</Styled.OutlinesContainer>

						<FooterContainer>
							<TemplateSelectContainer>
								{isLoadingTemplates ? (
									<Flex
										align="center"
										justify="center"
										style={{ height: "34px" }}
									>
										<Spinner size="16" />
									</Flex>
								) : (
									<StyledSelect
										value={selectedTemplate}
										onValueChange={(value: React.SetStateAction<string>) => {
											setSelectedTemplate(value);
											const selected = templates?.find((t) => t.id === value);
											setSelectedTemplateVersion(selected?.version || "1.0");
										}}
									>
										<Select.Trigger>
											<Flex align="center" gap="2">
												<Icon.FileLock size={20} />
												<Text>
													{templates?.find((t) => t.id === selectedTemplate)
														?.name || "Select template"}
												</Text>
											</Flex>
										</Select.Trigger>
										<Select.Content>
											{templateOptions.map((option) => (
												<Select.Item key={option.value} value={option.value}>
													{option.label}
												</Select.Item>
											))}
										</Select.Content>
									</StyledSelect>
								)}
							</TemplateSelectContainer>
							<ButtonGroup>
								<Dialog.Close>
									<Styled.CancelButton>Cancel</Styled.CancelButton>
								</Dialog.Close>
								<Styled.GenerateButton
									onClick={handleGenerate}
									disabled={
										!selectedOutlines.length ||
										!selectedTemplate ||
										isGenerating
									}
								>
									{isGenerating ? (
										<Spinner
											size={isTablet ? "14" : "16"}
											color="var(--mb-color-light-gray-1)"
										/>
									) : (
										<>
											<Icon.Lightning
												size={isTablet ? 16 : 20}
												color="var(--mb-color-light-gray-1)"
											/>
											Generate doc
										</>
									)}
								</Styled.GenerateButton>
							</ButtonGroup>
						</FooterContainer>
					</Flex>
				</Styled.Container>
			</Dialog.Root>

			{showOutput && (
				<Dialog.Root open={showOutput} onOpenChange={setShowOutput}>
					<GenerateDocOutput
						documentUrl={documentUrl}
						onClose={() => setShowOutput(false)}
						documentName={documentName}
					/>
				</Dialog.Root>
			)}
		</>
	);
};

export default GenerateDoc;
