import styled from "styled-components";
import { Checkbox, Flex, mediaQueries } from "@mightybot/web-ui";

export const Container = styled(Flex)`
	width: 100%;
	flex-direction: column;
	padding: 24px;
	gap: 32px;
`;

export const Section = styled(Flex)`
	width: 100%;
	flex-direction: column;
	gap: 16px;
`;

export const SectionTitle = styled(Flex)`
	width: 100%;
	height: 32px;
	align-items: center;
	border-bottom: 1px solid var(--gray-5);
`;

export const SectionContent = styled(Flex)`
	width: 100%;
	padding: 0 8px;
`;

export const Tag = styled(Flex)`
	padding: 4px 12px;
	background: var(--gray-3);
	border-radius: 16px;
	align-items: center;
	justify-content: center;
`;

export const ContentWrapper = styled(Flex)`
	flex-direction: column;
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	background: linear-gradient(
			180deg,
			var(--mb-blue-2) 0%,
			rgba(220, 232, 255, 0.2) 100%
		),
		var(--mb-gray-1);
`;

export const ContentHeader = styled(Flex)`
	width: 100%;
	padding: 20px 40px 12px 40px;
	position: sticky;
	justify-content: space-between;
	top: 0;
	z-index: 1;
	background: rgba(var(--mb-gray-1-rgb), 0.6);
	backdrop-filter: blur(8px);
	webkit-backdrop-filter: blur(8);

	${mediaQueries.tablet} {
		padding: 16px 10px 8px 10px;
	}
`;

export const ContentContainer = styled(Flex)`
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	padding: 0 40px 60px 40px;
	gap: 6px;

	/* Custom scrollbar styling */
	::-webkit-scrollbar {
		width: 8px;
	}

	::-webkit-scrollbar-track {
		background: var(--mb-gray-2);
		border-radius: 4px;
	}

	::-webkit-scrollbar-thumb {
		background: var(--mb-gray-5);
		border-radius: 4px;

		&:hover {
			background: var(--mb-gray-6);
		}
	}

	/* Firefox scrollbar styling */
	scrollbar-width: thin;
	scrollbar-color: var(--mb-gray-5) var(--mb-gray-2);

	${mediaQueries.tablet} {
		padding: 0 10px 10px 10px;
		height: calc(100% - 52px);
	}
`;

export const DealsCardContainer = styled(Flex)<{ isexpanded: boolean }>`
	justify-content: space-around;
	align-items: center;
	width: 100%;
	border-radius: ${({ isexpanded }) => (isexpanded ? "0 0" : "12px 12px")};
	box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
	padding: 10px 9px 6px 9px;
	border-width: 0px;
	background: var(--mb-gray-1);
	gap: 6px;
	max-height: 70px;
	flex-shrink: 0;
	${mediaQueries.tablet} {
		box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
		background: var(--mb-gray-1);
		border: 1px solid var(--mb-gray-4);
		border-bottom: ${({ isexpanded }) =>
			isexpanded ? "0" : "1px solid var(--mb-gray-4)"};
	}
`;

export const DealProperty = styled(Flex)`
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
`;

export const DealWrapper = styled.div`
	width: 100%;
	border-radius: 12px;
	overflow: hidden;
	box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
`;

export const ExpandedDealContent = styled.div<{ isexpanded: boolean }>`
	padding: 16px 24px;
	background-color: var(--mb-gray-1);
	border-top: 1px solid var(--mb-gray-3);
	overflow: hidden;
	max-height: ${({ isexpanded }) => (isexpanded ? "500px" : "0")};
	opacity: ${({ isexpanded }) => (isexpanded ? "1" : "0")};
	transition: all 0.3s ease-in-out;
	padding: ${({ isexpanded }) => (isexpanded ? "16px 24px" : "0 24px")};
	border-radius: 0 0 12px 12px;
	box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
	${mediaQueries.tablet} {
		border: 1px solid var(--mb-gray-4);
		border-top: 0;
	}
`;

export const NoteCardWrapper = styled.div`
	width: 100%;
	padding: 16px 24px;
	border-radius: 12px;
	background: white;
	box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
	margin-bottom: 6px;
`;

export const NoteCardContainer = styled(Flex)`
	justify-content: center;
	flex-direction: column;
	width: 100%;
	border-radius: 12px 12px;
	box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
	padding: 10px 9px 6px 9px;
	border-width: 0px;
	background: var(--mb-gray-1);
	gap: 6px;
	min-height: 70px;
	flex-shrink: 0;
	${mediaQueries.tablet} {
		box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
		background: var(--mb-gray-1);
		border: 1px solid var(--mb-gray-4);
		border-bottom: 0;
	}
`;

export const NoteCardFooter = styled(Flex)`
	width: 100%;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

export const NoteTag = styled(Flex)`
	padding: 4px 12px;
	border-radius: 12px;
	background: linear-gradient(90deg, #f3f6e9 0%, #e4edfc 100%);
	border: 1px solid var(--mb-gray-4);
`;

export const TaskCardContainer = styled(Flex)`
	justify-content: center;
	flex-direction: column;
	width: 100%;
	border-radius: 12px 12px;
	box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
	padding: 10px 9px 6px 9px;
	border-width: 0px;
	background: var(--mb-gray-1);
	gap: 6px;
	min-height: 70px;
	flex-shrink: 0;
	${mediaQueries.tablet} {
		box-shadow: 0px 2px 6px 0px rgba(30, 57, 112, 0.06);
		background: var(--mb-gray-1);
		border: 1px solid var(--mb-gray-4);
		border-bottom: 0;
	}
`;

export const TaskCheckbox = styled(Checkbox)`
	border-radius: 999px;
	&.rt-BaseCheckboxRoot:where(.rt-variant-surface):where(
			[data-state="checked"],
			[data-state="indeterminate"]
		)::before {
		background-color: #c4e75a;
		border-radius: 50%;
	}
	&.rt-BaseCheckboxRoot:where(.rt-variant-surface):where(
			[data-state="unchecked"]
		)::before {
		border-radius: 50%;
	}
`;
