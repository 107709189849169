import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	MeetingPresencePreferences as MeetingPresencePreferencesType,
	useMe,
	useUserPreferences,
} from "@mightybot/core";
import {
	Button,
	Flex,
	TextArea,
	Text,
	Spinner,
	Switch,
} from "@mightybot/web-ui";
import { SettingsOptionItem } from "../styled";
import { getHostDomain } from "../../../utils/email";
import Toast from "../../Toast";
import {
	MeetingsAndSummariesContainer,
	MeetingsAndSummariesHeader,
	MeetingsAndSummariesContent,
	OptionItem,
} from "./styled";

const validateExcludedDomains = (
	domains: string,
	internal_domain: string,
): [boolean, string] => {
	if (!domains.trim()) return [true, ""]; // Empty input is valid
	if (domains.includes(internal_domain))
		return [
			false,
			`Internal domain ${internal_domain} is already excluded. Please remove it from the list.`,
		];
	const domainRegex =
		/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
	return [
		domains.split(",").every((domain) => domainRegex.test(domain.trim())),
		"",
	];
};

function MeetingsAndSummaries() {
	const { userPreferences, updateUserPreferences, isLoadingUserPreferences } =
		useUserPreferences();
	const { data: user } = useMe();
	const hostDomain = getHostDomain(user?.email ?? "");

	const [excludedParticipantDomains, setExcludedParticipantDomains] =
		useState<string>(userPreferences.excludedParticipantDomains.join(","));

	const [internalMeetingBotEnabled, setInternalMeetingBotEnabled] =
		useState<boolean>(userPreferences.internalMeetingBotToggled ?? false);

	const [isValidDomains, setIsValidDomains] = useState(true);
	const [toastOpen, setToastOpen] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const isPrefencesChanged = useMemo(() => {
		return (
			internalMeetingBotEnabled !== userPreferences.internalMeetingBotToggled ||
			excludedParticipantDomains !==
				userPreferences.excludedParticipantDomains.join(",")
		);
	}, [internalMeetingBotEnabled, excludedParticipantDomains, userPreferences]);

	useEffect(() => {
		if (!isLoadingUserPreferences && userPreferences) {
			setInternalMeetingBotEnabled(
				userPreferences.internalMeetingBotToggled ?? false,
			);
			setExcludedParticipantDomains(
				userPreferences.excludedParticipantDomains.join(","),
			);
		}
	}, [userPreferences, isLoadingUserPreferences]);

	const updatePreferences = useCallback(
		async (preferences: Partial<MeetingPresencePreferencesType>) => {
			try {
				await updateUserPreferences({
					meeting_presence: preferences,
				}).unwrap();
				setToastMessage("Preferences updated successfully");
				setToastOpen(true);
			} catch (error) {
				setToastMessage("Failed to update preferences");
				setToastOpen(true);
				console.error(error);
			}
		},
		[updateUserPreferences],
	);

	const handleInternalMeetingBotToggle = useCallback(() => {
		setInternalMeetingBotEnabled(!internalMeetingBotEnabled);
	}, [internalMeetingBotEnabled]);

	const handleExcludedParticipantDomainsChange = (value: string) => {
		setExcludedParticipantDomains(value);
	};

	const handleSave = () => {
		setIsLoading(true);
		const [isValid, message] = validateExcludedDomains(
			excludedParticipantDomains,
			hostDomain ?? "",
		);
		setIsValidDomains(isValid);
		setToastMessage(message);
		setToastOpen(true);
		if (isValid) {
			let newDomains = excludedParticipantDomains
				.split(",")
				.map((domain) => domain.trim());
			newDomains = newDomains.filter(
				(domain) => domain !== null && domain !== "",
			);
			updatePreferences({
				internal_meeting_bot_enabled: internalMeetingBotEnabled,
				excluded_participant_domains: newDomains ?? [],
			});
		}
		setIsLoading(false);
	};

	const renderToggle = (
		label: string,
		toggled: boolean,
		onToggle: () => void,
	) => (
		<Flex direction="column" gap="1" style={{ width: "100%" }}>
			<Flex justify="between" align="center" style={{ width: "100%" }}>
				<Text weight="medium">{label}</Text>
				<Switch checked={toggled} onCheckedChange={onToggle} />
			</Flex>
		</Flex>
	);

	return (
		<MeetingsAndSummariesContainer>
			<MeetingsAndSummariesHeader>
				<Text weight="medium" size="4">
					Meetings & Summaries
				</Text>
			</MeetingsAndSummariesHeader>
			<MeetingsAndSummariesContent>
				<Flex direction="column" gap="3">
					<Text weight="medium" size="2">
						MightyBot joins meeting
					</Text>
					<OptionItem>
						{renderToggle(
							"Join Internal Meetings",
							internalMeetingBotEnabled,
							handleInternalMeetingBotToggle,
						)}
					</OptionItem>
				</Flex>
				<Flex direction="column" gap="2">
					<Text weight="medium" size="2">
						Exclude external domains to join meeting
					</Text>
					<TextArea
						style={{
							width: "100%",
							borderColor: !isValidDomains ? "red" : undefined,
							borderWidth: !isValidDomains ? 1 : undefined,
							borderRadius: !isValidDomains ? 6 : undefined,
							fontSize: "13px",
							height: "133px",
						}}
						placeholder="Add multiple domains by adding comma (,) separator"
						value={excludedParticipantDomains}
						onChange={(e) => {
							setIsValidDomains(true);
							handleExcludedParticipantDomainsChange(e.target.value);
						}}
						size="3"
					/>
				</Flex>
				<Button
					disabled={!isValidDomains || !isPrefencesChanged}
					onClick={handleSave}
					style={{ cursor: "pointer" }}
				>
					{isLoading ? "Saving..." : "Save"}
					{isLoading && <Spinner size="sm" />}
				</Button>
				<Toast
					open={toastOpen}
					setOpen={setToastOpen}
					title={isValidDomains ? "Success" : "Error"}
					description={toastMessage}
					type={isValidDomains ? "success" : "error"}
				/>
			</MeetingsAndSummariesContent>
		</MeetingsAndSummariesContainer>
	);
}

export default MeetingsAndSummaries;
