import styled from "styled-components";
import { Flex, mediaQueries } from "@mightybot/web-ui";

export const Container = styled(Flex)`
	flex-direction: column;
	height: 100%;
	width: 100%;
	background: white;
	overflow: hidden;

	${mediaQueries.tablet} {
		overflow-y: auto;
	}
`;

export const ContentWrapper = styled(Flex)`
	flex-direction: column;
	height: 100%;
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;
`;

export const HeaderSection = styled(Flex)`
	padding: 20px 30px;

	${mediaQueries.tablet} {
		padding: 16px;
	}
`;

export const MetadataSection = styled(Flex)`
	align-items: center;
	gap: 8px;

	${mediaQueries.tablet} {
		gap: 6px;
		flex-wrap: wrap;
	}
`;

export const VideoSection = styled.div`
	position: relative;
	width: 100%;
	margin-top: 10px;
	aspect-ratio: 16/9;
	background: black;
	border-radius: 8px;
	overflow: hidden;

	${mediaQueries.tablet} {
		margin-top: 8px;
		border-radius: 4px;
	}
`;

export const VideoContainer = styled(Flex)`
	flex-direction: column;
	flex: 1;
	border-right: 1px solid var(--gray-5);
	background: var(--gray-2);
	min-height: 0;
	padding: 10px 30px;

	${mediaQueries.tablet} {
		border-right: none;
		border-bottom: 1px solid var(--gray-5);
		padding: 10px 16px;
		flex: none;
	}
`;
export const DetailsSection = styled(Flex)`
	width: 400px;
	flex-direction: column;
	background: white;
	border-left: 1px solid var(--gray-5);
	overflow: hidden;

	${mediaQueries.tablet} {
		width: 100%;
		border-left: none;
		border-top: 1px solid var(--gray-5);
	}

	${mediaQueries.mobile} {
		height: auto;
		min-height: 300px;
	}
`;

export const MainContent = styled(Flex)`
	flex: 1;
	gap: 0;
	border-top: 1px solid var(--gray-5);
	min-height: 0;

	${mediaQueries.tablet} {
		flex-direction: column;
	}
`;
