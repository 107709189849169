import React, { useState } from "react";
import { Skeleton, Flex, useMediaQuery, breakpoints } from "@mightybot/web-ui";
import { useMeeting, useCompletionsHistory } from "@mightybot/core";
import MeetingOutline from "./MeetingOutline";
import MeetingChatsSidebar from "./MeetingChatsSidebar";
import { useParams } from "react-router-dom";
import { MobileSidebarContainer } from "./styled";
import { cn } from "../../utils/classNames";

interface MeetingDetailsSidebarProps {
	isSidebarOpen: boolean;
	toggleSidebar: () => void;
}

const MeetingDetailsSidebar: React.FC<MeetingDetailsSidebarProps> = ({
	isSidebarOpen,
	toggleSidebar,
}) => {
	const meetingId = useParams().meetingId;
	const { isLoading } = useMeeting(meetingId);
	const { completionHistory } = useCompletionsHistory(
		meetingId ?? "",
		"MEETING",
	);
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const [isMobileOutlineOpen, setIsMobileOutlineOpen] = useState(false);
	const [isMobileChatsOpen, setIsMobileChatsOpen] = useState(false);

	const toggleMobileOutline = () => {
		setIsMobileOutlineOpen(!isMobileOutlineOpen);
		if (!isMobileOutlineOpen) setIsMobileChatsOpen(false);
	};

	const toggleMobileChats = () => {
		setIsMobileChatsOpen(!isMobileChatsOpen);
		if (!isMobileChatsOpen) setIsMobileOutlineOpen(false);
	};

	if (isMobile) {
		return (
			<MobileSidebarContainer>
				<MeetingOutline
					toggleSidebar={toggleMobileOutline}
					isSidebarOpen={isMobileOutlineOpen}
					isMobile={true}
				/>
				{completionHistory && completionHistory.length > 0 && (
					<MeetingChatsSidebar
						toggleSidebar={toggleMobileChats}
						isSidebarOpen={isMobileChatsOpen}
						isMobile={true}
					/>
				)}
			</MobileSidebarContainer>
		);
	}

	return (
		<div className="flex flex-col gap-[10px] w-auto duration-300 ease-in-out h-full">
			{isLoading ? (
				<Flex direction="column" gap="2" style={{ padding: "16px" }}>
					<Skeleton width="100%" height="24px" />
					<Skeleton width="80%" height="16px" />
					<Skeleton width="90%" height="16px" />
					<Skeleton width="70%" height="16px" />
				</Flex>
			) : (
				<div className="flex flex-col h-full">
					<div
						className={cn(
							"transition-all duration-300",
							completionHistory && completionHistory.length > 0
								? "h-1/2 border-b border-gray-200"
								: "h-full",
						)}
					>
						<MeetingOutline
							toggleSidebar={toggleSidebar}
							isSidebarOpen={isSidebarOpen}
							isMobile={false}
						/>
					</div>
					{completionHistory && completionHistory.length > 0 && (
						<div className="h-1/2">
							<MeetingChatsSidebar
								toggleSidebar={toggleSidebar}
								isSidebarOpen={isSidebarOpen}
								isMobile={false}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default MeetingDetailsSidebar;
