import { Flex, Button, Text } from "@mightybot/web-ui";
import styled from "styled-components";
import { media, mediaMaxWidth } from "@mightybot/web-ui/src/util/mediaQueries";

export const PeopleContainer = styled(Flex)`
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	margin: auto;
`;

export const TableHeaderContainer = styled(Flex)`
	background: var(--mb-blue-1);
	position: sticky;
	top: 0;
	z-index: 1;
	border-bottom: 1px solid var(--mb-gray-5);
	min-height: 40px;
	align-items: center;
	border-radius: 8px 8px 0px 0px;

	${media.tablet} {
		padding: 8px 12px;
		gap: 8px;
	}

	${media.mobile} {
		padding: 8px;
		gap: 6px;
	}
`;

export const TableRowContainer = styled(Flex)`
	border-bottom: 1px solid var(--mb-gray-5);
	min-height: 46px;
	align-items: center;

	.hover-link:hover {
		color: var(--mb-blue-11);
		text-decoration: underline !important;
	}

	${media.tablet} {
		padding: 8px 12px;
		gap: 8px;
		min-height: 40px;
	}

	${media.mobile} {
		padding: 8px;
		gap: 6px;
	}
`;

export const FilterButton = styled(Button)`
	padding: 6px 12px;
	border-radius: 4px;
	font-size: 13px;
	height: 34px;
	color: var(--mb-gray-11);

	&[data-active="true"] {
		background: var(--mb-blue-4);
		color: var(--mb-blue-11);
		border-color: var(--mb-blue-6);
	}

	&:hover {
		background: var(--mb-gray-4);
	}

	${media.tablet} {
		padding: 4px 8px;
		font-size: 12px;
		height: 30px;
	}
`;

export const FilterContainer = styled(Flex)`
	padding: 8px 0;
	width: 100%;
	justify-content: space-between;

	${media.tablet} {
		flex-direction: column;
		padding: 30px 10px 10px 10px;
		gap: 6px;
	}
`;

export const CategoryOption = styled(Flex)<{ active?: string }>`
	padding: 0px 16px;
	border-radius: var(--radius-pill);
	cursor: pointer;
	font-size: 13px;
	align-items: center;
	height: 28px;
	background: ${(props) =>
		props.active === "true" ? "#C4E75A" : "transparent"};

	&:hover {
		background: ${(props) =>
			props.active === "true" ? "var(--mb-blue-4)" : "var(--mb-gray-4)"};
	}
`;

export const ActionButton = styled(Flex)`
	padding: 0px 12px;
	border-radius: 4px;
	cursor: pointer;
	align-items: center;
	gap: 4px;
	border-radius: var(--radius-pill);
	border: 1px solid var(--mb-gray-5);
	font-size: 13px;
	height: 28px;
	&:hover {
		background: var(--mb-gray-4);
	}

	&[data-active="true"] {
		color: var(--mb-blue-11);
		background: var(--mb-blue-4);
	}

	${media.tablet} {
		padding: 0px 8px;
		font-size: 12px;
		height: 26px;
	}

	${media.mobile} {
		.button-text {
			display: none;
		}
	}
`;

export const InfiniteScrollContainer = styled(Flex)`
	overflow-y: auto;
	height: 100vh;
	flex-direction: column;
	align-items: center;

	/* Webkit browsers (Chrome, Safari, etc.) */
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: #888;
		border-radius: 5px;
	}

	/* Firefox */
	scrollbar-width: thin;
	scrollbar-color: #888 transparent;
`;

export const Tag = styled(Flex)`
	align-items: center;
	height: 24px;
	padding: 2px 12px;
	border-radius: var(--radius-pill);
	background-color: var(--mb-gray-3);
	color: var(--mb-gray-11);
	font-size: 13px;
	white-space: nowrap;
	gap: 4px;
`;

export const TableContainer = styled(Flex)`
	flex: 1;
	border-radius: 8px;
	border: 1px solid var(--mb-gray-5);
	overflow: scroll;

	${media.tablet} {
		border-radius: 4px;
		border: none;
	}
`;

export const TableScrollContainer = styled(Flex)`
	width: 100%;
	min-width: 800px;
	overflow-x: auto;

	${media.tablet} {
		min-width: 100%;
	}
`;

export const TableContent = styled(Flex)`
	flex: 1;
	flex-direction: column;
	min-width: 100%;
`;

export const ResponsiveContainer = styled(Flex)`
	padding: 20px 80px;
	gap: 12px;
	height: 100vh;

	${media.laptop} {
		padding: 0px;
	}

	${media.tablet} {
		gap: 10px;
	}

	${media.mobile} {
		gap: 8px;
	}
`;

export const HideMobile = styled(Flex)`
	${media.tablet} {
		display: none;
	}
`;
