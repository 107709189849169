import styled from "styled-components";
import { RadioGroup, mediaQueries } from "@mightybot/web-ui";

export const UserNotificationContainer = styled.div`
	width: 100%;
	border: 1px solid var(--mb-color-dark-gray-7);
	border-radius: 8px;
	${mediaQueries.tablet} {
		border: none;
	}
`;

export const SectionTitle = styled.div`
	font-size: 22px;
	font-weight: 500;
	line-height: 26px;
	text-align: left;
	margin-bottom: 6px;
	padding-left: 20px;
	padding-top: 20px;
`;

export const Description = styled.p`
	font-weight: 400;
	line-height: 17px;
	text-align: left;
	color: var(--mb-color-light-gray-9);
	margin-bottom: 4px;
	padding-left: 20px;
	padding-right: 70px;
`;

export const SubDescription = styled.p`
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	text-align: left;
	color: var(--mb-color-light-gray-9);
	margin-bottom: 10px;
`;

export const SubSectionTitle = styled.div`
	font-size: 14px;
	font-weight: 545;
	line-height: 18px;
	text-align: left;
	margin-bottom: 10px;
	margin-top: 10px;
	padding-top: 10px;
`;

export const RadioGroupRoot = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 20px;
`;

export const RadioGroupItem = styled(RadioGroup.Item)`
	.rt-RadioGroupButton {
		height: 20px;
		width: 20px;
	}
`;

export const NotificationItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--mb-color-light-blue-1);
	padding: 10px;
	border-radius: 8px;
	margin-bottom: 10px;
`;

export const NotificationTitle = styled.h3`
	font-size: 16px;
	color: var(--mb-color-dark-gray-2);
	margin: 0;
`;

export const NotificationDescription = styled.p`
	font-size: 12px;
	color: var(--mb-color-dark-gray-5);
	margin: 0;
`;

export const SelectContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 20px;
	width: 40vw;
`;

export const SwitchContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	padding: 10px 0;
	border-top: 1px solid var(--mb-color-light-gray-6);
`;
