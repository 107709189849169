import React, { useEffect, useState } from "react";
import { Text } from "@mightybot/web-ui";
import { useBilling } from "@mightybot/core";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatDate } from "../../../utils/helpers";
import { Icon } from "@mightybot/web-ui";

import {
	SuccessContainer,
	SuccessCard,
	DetailRow,
	Button,
	HelpSection,
	ContentWrapper,
	InfoText,
	StatusIcon,
	StatusTitle,
	StatusDescription,
	ButtonsContainer,
} from "./styled";
import { PaymentFailed, PaymentSuccess } from "@mightybot/core-ui";

const PAYMENT_CHECK_DELAY = 10000; // Wait 10 seconds before checking

export const PaymentStatus = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const status = searchParams.get("status");
	const attemptedAmount = searchParams.get("amount");
	const currency = searchParams.get("currency") || "USD";
	const [hasCheckedPayment, setHasCheckedPayment] = useState(false);

	const {
		subscription,
		paymentTransaction,
		isPaymentTransactionLoading,
		isPaymentTransactionError,
		isLoading: isSubscriptionLoading,
		invoices,
		refetchPaymentTransaction,
	} = useBilling();

	useEffect(() => {
		// Check immediately when component mounts
		refetchPaymentTransaction();

		// Then check again after delay
		const timeoutId = setTimeout(() => {
			refetchPaymentTransaction().finally(() => {
				setHasCheckedPayment(true);
			});
		}, PAYMENT_CHECK_DELAY);

		return () => clearTimeout(timeoutId);
	}, [refetchPaymentTransaction]);

	// Show loading state while waiting to check payment
	if (
		!hasCheckedPayment ||
		isPaymentTransactionLoading ||
		isSubscriptionLoading
	) {
		return (
			<SuccessContainer>
				<ContentWrapper>
					<StatusTitle>Processing Payment...</StatusTitle>
					<StatusDescription>
						Please wait while we verify your payment status
					</StatusDescription>
				</ContentWrapper>
			</SuccessContainer>
		);
	}

	// Show failure if payment status is failed or (no payment found and URL status is failed)
	const isFailure =
		paymentTransaction?.status === "FAILED" ||
		(!paymentTransaction && status === "failed");

	if (isFailure) {
		return (
			<SuccessContainer style={{ background: "#FFF5F5" }}>
				<ContentWrapper>
					<StatusIcon src={PaymentFailed} alt="Payment Failed" />
					<StatusTitle>Payment Failed!</StatusTitle>
					<StatusDescription>
						We encountered an issue processing your payment.
						<br />
						Unfortunately, your payment could not be processed. Please check
						your payment details and try again.
					</StatusDescription>

					<SuccessCard>
						<DetailRow>
							<div className="label">Plan</div>
							<InfoText className="value">
								{subscription?.name} (
								{subscription?.billing_cycle === "monthly"
									? "Monthly"
									: "Yearly"}
								)
							</InfoText>
						</DetailRow>
						<DetailRow>
							<div className="label">Attempted Amount</div>
							<InfoText size="2" weight="medium" className="value">
								$
								{attemptedAmount
									? Number(attemptedAmount) / 100
									: subscription?.amount}{" "}
								{currency}
							</InfoText>
						</DetailRow>
					</SuccessCard>

					<ButtonsContainer>
						<Button
							variant="primary"
							onClick={() => navigate("/settings/billing")}
						>
							<Icon.ArrowCounterClockwise size={16} />
							Retry Payment
						</Button>

						<Button
							variant="secondary"
							onClick={() => navigate("/settings/billing")}
						>
							Enter another card
						</Button>
					</ButtonsContainer>
				</ContentWrapper>

				<HelpSection>
					<div>
						<div className="help-text">
							<strong>Need Help?</strong> <br />
							If you have any questions, feel free to contact us
						</div>
						<div className="buttons">
							<Button
								variant="secondary"
								onClick={() =>
									window.open("https://www.mightybot.ai/get-a-demo", "_blank")
								}
							>
								Contact us
							</Button>
							<Button
								variant="secondary"
								onClick={() =>
									window.open("https://www.mightybot.ai/help", "_blank")
								}
							>
								Explore help articles
							</Button>
						</div>
					</div>
				</HelpSection>
			</SuccessContainer>
		);
	}

	// Show success only if payment transaction is successful
	if (paymentTransaction?.status === "SUCCESS") {
		return (
			<SuccessContainer>
				<ContentWrapper>
					<StatusIcon src={PaymentSuccess} alt="Payment Successful" />
					<StatusTitle>Payment Successful!</StatusTitle>
					<StatusDescription>
						Thank you for subscribing to MightyBot Professional
					</StatusDescription>

					<SuccessCard>
						<DetailRow>
							<div className="label">Plan</div>
							<InfoText>
								{subscription?.name} (
								{subscription?.billing_cycle === "monthly"
									? "Monthly"
									: "Yearly"}
								)
							</InfoText>
						</DetailRow>
						<DetailRow>
							<div className="label">Amount paid</div>
							<InfoText>
								${paymentTransaction?.amount / 100}{" "}
								{paymentTransaction?.currency}
							</InfoText>
						</DetailRow>
						<DetailRow>
							<div className="label">Payment method</div>
							<InfoText>
								•••• {paymentTransaction?.card_last4 || "4242"}
							</InfoText>
						</DetailRow>
						<DetailRow>
							<div className="label">Next billing date</div>
							<InfoText>
								{formatDate(subscription?.next_billing_date || "")}
							</InfoText>
						</DetailRow>
					</SuccessCard>

					<ButtonsContainer>
						<Button variant="primary" onClick={() => navigate("/home")}>
							Go to Home
							<Icon.ArrowRight size={16} color="white" />
						</Button>

						<Button
							variant="secondary"
							onClick={() => window.open(invoices?.[0]?.pdf_link, "_blank")}
						>
							Download Invoice
							<Icon.Download size={16} />
						</Button>
					</ButtonsContainer>
				</ContentWrapper>

				<HelpSection>
					<div>
						<div className="help-text">
							<strong>Need Help?</strong> <br />
							If you have any questions, feel free to contact us
						</div>
						<div className="buttons">
							<Button
								variant="secondary"
								onClick={() =>
									window.open("https://www.mightybot.ai/get-a-demo", "_blank")
								}
							>
								Contact us
							</Button>
							<Button
								variant="secondary"
								onClick={() =>
									window.open("https://www.mightybot.ai/get-a-demo", "_blank")
								}
							>
								Explore help articles
							</Button>
						</div>
					</div>
				</HelpSection>
			</SuccessContainer>
		);
	}

	// If no payment found and status is not failed, show unknown status
	return (
		<SuccessContainer style={{ background: "#FFF5F5" }}>
			<ContentWrapper>
				<StatusIcon src={PaymentFailed} alt="Payment Status Unknown" />
				<StatusTitle>Payment Status Unknown</StatusTitle>
				<StatusDescription>
					We couldn't verify your recent payment status.
					<br />
					Please check your email for confirmation or contact support if you
					need assistance.
				</StatusDescription>

				<ButtonsContainer>
					<Button
						variant="primary"
						onClick={() => navigate("/settings/billing")}
					>
						Return to Billing
					</Button>

					<Button
						variant="secondary"
						onClick={() => window.open("/contact", "_blank")}
					>
						Contact Support
					</Button>
				</ButtonsContainer>
			</ContentWrapper>
		</SuccessContainer>
	);
};
