import styled from "styled-components";
import { Toast } from "@mightybot/web-ui";

export const StyledToastViewport = styled(Toast.ToastViewport)`
	--viewport-padding: 16px;
	position: fixed;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	padding: var(--viewport-padding);
	gap: 10px;
	width: 390px;
	max-width: 100vw;
	margin: 0;
	list-style: none;
	z-index: 2147483647;
	outline: none;
`;
export const StyledToastTitle = styled(Toast.Title)`
	grid-area: title;
	margin-bottom: 5px;
	font-weight: 500;
	color: var(--mb-color-text-primary);
	font-size: 15px;
`;

export const StyledToastDescription = styled(Toast.Description)`
	grid-area: description;
	margin: 0;
	color: var(--mb-color-text-secondary);
	font-size: 13px;
	line-height: 1.3;
`;

export const StyledToast = styled(Toast.Root)`
	border-radius: 6px;
	box-shadow:
		hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	padding: 15px;
	display: grid;
	grid-template-areas: "title action" "description action";
	grid-template-columns: auto max-content;
	column-gap: 15px;
	align-items: center;
	background: rgb(0 0 0 / 85%);
	color: white;
	z-index: 2147483647;

	&[data-state="open"] {
		animation: slideIn 200ms cubic-bezier(0.16, 1, 0.3, 1);
	}
	&[data-state="closed"] {
		animation: hide 100ms ease-in;
	}

	@keyframes hide {
		from {
			opacity: 1;
		}
		to {
			opacity: 0;
		}
	}

	@keyframes slideIn {
		from {
			transform: translateX(calc(100% + var(--viewport-padding)));
		}
		to {
			transform: translateX(0);
		}
	}
`;
