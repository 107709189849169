import styled from "styled-components";
import { Button, Flex, Text, TextArea, mediaQueries } from "@mightybot/web-ui";
import type { FlexProps } from "@radix-ui/themes";
import { Dialog } from "@mightybot/web-ui";
import { keyframes } from "styled-components";

export const TeamsContainer = styled(Flex)`
	width: 100%;
	height: calc(100vh - 80px);
	background: var(--mb-color-light-gray-1);
	border-radius: 12px;
	border: 1px solid var(--mb-color-light-gray-5);
	flex-direction: column;
	overflow: hidden;
	${mediaQueries.tablet} {
		padding: 0px 10px;
		border: none;
		border-radius: 0;
	}
`;

export const Header = styled(Flex)`
	padding: 20px;
	border-bottom: 1px solid var(--mb-color-light-gray-5);
	justify-content: space-between;
	align-items: center;
	${mediaQueries.tablet} {
		flex-direction: column;
		gap: 16px;

		> div {
			width: 100%;
		}
	}
	${mediaQueries.tablet} {
		padding: 0px;
	}
`;

export const HeaderTitle = styled(Flex)<FlexProps>`
	gap: 10px;
	align-items: baseline;
`;

export const SearchInput = styled.input`
	width: 200px;
	height: 32px;
	padding: 0 15px;
	background: var(--mb-color-light-gray-1);
	border-radius: 4px;
	border: 1px solid var(--mb-color-light-gray-6);
	color: var(--mb-color-light-gray-8);
	font-size: 14px;
	line-height: 18px;
	${mediaQueries.tablet} {
		width: 100%;
	}

	&::placeholder {
		color: var(--mb-color-light-gray-7);
	}
`;

export const AddMemberButton = styled(Button)`
	width: 141px;
	padding: 0 14px;
	height: 32px;
	background: var(--mb-color-light-blue-8);
	border-radius: 4px;
	color: var(--mb-color-light-gray-1);
	display: flex;
	align-items: center;
	gap: 6px;
	${mediaQueries.tablet} {
		width: auto;
	}
`;

export const TableHeader = styled(Flex)`
	padding: 8px 10px;
	border-bottom: 1px solid var(--mb-color-light-gray-5);
	position: sticky;
	top: 0;
	background: var(--mb-color-light-gray-1);
	z-index: 1;
	width: 100%;
`;

export const TableCell = styled(Text)`
	flex: 1;
	color: var(--mb-color-dark-gray-2);
	font-size: 13px;
	font-weight: 400;
	line-height: 17px;
	min-width: 0;

	&:first-child {
		flex: 2;
		padding-left: 0;
	}

	&:last-child {
		flex: 0.5;
		padding-right: 0;
	}
`;

export const TableRow = styled.div`
	display: flex;
	align-items: center;
	padding: 12px 10px;
	border-bottom: 1px solid var(--mb-color-light-gray-5);
	gap: 10px;
	min-height: 60px;
	width: 100%;
`;

export const UserInfo = styled(Flex)<FlexProps>`
	flex: 2 1 0;
	height: 31px;
	justify-content: flex-start;
	align-items: center;
	gap: 6px;
	${mediaQueries.tablet} {
		flex: 1;
		min-width: 200px;
	}
`;

export const UserDetails = styled(Flex)<FlexProps>`
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	/* Style for name */
	> *:first-child {
		color: var(--mb-color-light-gray-11);
		font-size: 13px;
		line-height: 17px;
	}

	/* Style for email */
	> *:last-child {
		color: var(--mb-color-light-gray-9);
		font-size: 12px;
		line-height: 16px;
	}
`;

interface StatusBadgeProps extends FlexProps {
	status: string;
}

export const StatusBadge = styled(Text)<StatusBadgeProps>`
	width: fit-content;
	min-width: 59px;
	height: 24px;
	padding: 0 12px;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 13px;
	line-height: 17px;
	font-weight: 400;

	${({ status }) => {
		switch (status.toLowerCase()) {
			case "active":
				return `
          background: var(--mb-color-dark-green-1);
          color: var(--mb-color-light-gray-11);
        `;
			case "pending":
				return `
          background: #FFF3DC;
          color: #805810;
        `;
			case "invited":
				return `
          background: var(--mb-color-light-gray-3);
          color: var(--mb-color-light-gray-7);
        `;
			default:
				return `
          background: #FFE5E5;
          color: #991B1B;
        `;
		}
	}}
`;
export const Avatar = styled(Flex)<{ bgColor?: string }>`
	width: 30px;
	height: 30px;
	border-radius: 9999px;
	background-color: ${({ bgColor }) =>
		bgColor || "var(--mb-color-light-blue-8)"};
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--mb-color-light-gray-1);
	overflow: hidden;
`;

export const ActionContainer = styled(Flex)`
	position: relative;
	display: flex;
	align-items: center;
`;

export const ActionButton = styled(Button)`
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 4px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background: var(--mb-color-light-gray-4);
	}
`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const StyledDialogContent = styled(Dialog.Content)`
	background-color: white;
	border-radius: 6px;
	box-shadow:
		hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
	z-index: 1001;
`;

export const StyledDialogTitle = styled(Dialog.Title)`
	display: flex;
	align-items: center;
`;
export const StyledTextArea = styled(TextArea)`
	width: 100%;
	height: 88px;
	padding: 12px;
	background: white;
	border-radius: 4px;
	border: 1px solid var(--mb-color-light-gray-6);
	color: var(--mb-color-light-gray-8);
	font-size: 14px;
	line-height: 18px;
	resize: none;

	&::placeholder {
		color: var(--mb-color-light-gray-7);
	}
`;

export const ButtonContainer = styled(Flex)<FlexProps>`
	justify-content: flex-end;
	gap: 15px;
	margin-top: 18px;
`;

export const TeamsContentContainer = styled.div`
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--mb-color-light-gray-5);
		border-radius: 4px;
	}
	${mediaQueries.tablet} {
		margin: 0 -16px;
		overflow-x: auto;
	}
`;

export const MenuContainer = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	background: white;
	border-radius: 6px;
	border: 1px solid var(--mb-color-light-gray-5);
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
	padding: 4px;
	z-index: 10;
	min-width: 150px;
	margin-top: 4px;

	button {
		width: 100%;
		padding: 8px 12px;
		background: transparent;
		border: none;
		border-radius: 4px;
		color: var(--mb-color-light-gray-11);
		font-size: 14px;
		text-align: left;
		cursor: pointer;

		&:hover {
			background: var(--mb-color-light-gray-3);
		}
	}
`;
