import React, { useCallback, useState } from "react";
import {
	DealProperty,
	DealsCardContainer,
	ContentContainer,
	ExpandedDealContent,
	DealWrapper,
	ContentWrapper,
	ContentHeader,
} from "./styled";
import {
	Deal,
	formatCurrency,
	useFavorites,
	usePersonDeals,
} from "@mightybot/core";
import {
	AvatarWrapper,
	Flex,
	Icon,
	Text,
	Badge,
	Button,
	Skeleton,
} from "@mightybot/web-ui";
import { InfiniteScroll } from "../../People/InfiniteScroll";
import { ContentCardSkeleton } from "./ContentSkeleton";
import { capitalize } from "lodash";

const DealCardSkeleton = () => (
	<DealWrapper>
		<DealsCardContainer isexpanded={false}>
			<DealProperty style={{ maxWidth: "270px", width: "100%" }}>
				<Flex direction="column" gap="2" style={{ width: "100%" }}>
					<Skeleton width="80%" height={"20px"} />
					<Flex gap="2" align="center">
						<Skeleton
							style={{ borderRadius: "50%" }}
							width={"20px"}
							height={"20px"}
						/>
						<Skeleton width="60%" height={"16px"} />
					</Flex>
				</Flex>
			</DealProperty>
			<DealProperty style={{ maxWidth: "270px", width: "100%" }}>
				<Flex direction="column" gap="2" style={{ width: "100%" }}>
					<Flex gap="2" align="center" style={{ width: "100%" }}>
						<Skeleton
							style={{ borderRadius: "50%" }}
							width={"24px"}
							height={"24px"}
						/>
						<Skeleton width="60%" height={"16px"} />
					</Flex>
					<Flex gap="2" style={{ width: "100%" }}>
						<Skeleton width="40%" height={"16px"} />
						<Skeleton width="40%" height={"16px"} />
					</Flex>
				</Flex>
			</DealProperty>
			<DealProperty style={{ maxWidth: "270px", width: "100%" }}>
				<Flex direction="column" gap="2" style={{ width: "100%" }}>
					<Skeleton width="40%" height={"16px"} />
					<Skeleton width="60%" height={"16px"} />
				</Flex>
			</DealProperty>
			<DealProperty style={{ maxWidth: "270px", width: "100%" }}>
				<Skeleton
					width={"80px"}
					height={"24px"}
					style={{ borderRadius: "9999px" }}
				/>
			</DealProperty>
		</DealsCardContainer>
	</DealWrapper>
);

const DealCard: React.FC<{ deal: Deal }> = React.memo(({ deal }) => {
	if (!deal) return null;
	const [expanded, setExpanded] = useState(false);

	const handleExpand = useCallback(() => {
		setExpanded((prev) => !prev);
	}, []);

	return (
		<DealWrapper key={deal.deal_id}>
			<DealsCardContainer isexpanded={expanded}>
				<DealProperty style={{ maxWidth: "270px", width: "100%" }}>
					<Flex align="center" gap="6px">
						<Text>{deal.deal_name}</Text>
						{/* <Icon.Star size="16" /> */}
					</Flex>
					{/* <Flex>
						<img
							style={{
								width: "20px",
								height: "20px",
								borderRadius: "50%",
							}}
							src={deal.deal_company_logo}
							alt={deal.deal_company}
						/>
						<Text style={{ color: "var(--mb-gray-9)" }}>
							{deal.deal_company}
						</Text>
					</Flex> */}
					<Flex align="center" gap="6px">
						<Text style={{ color: "var(--mb-gray-9)" }}>Close by</Text>
						<Text style={{ color: "var(--mb-gray-9)" }}>{deal.close_date}</Text>
					</Flex>
				</DealProperty>
				{/* <DealProperty style={{ maxWidth: "270px", width: "100%" }}>
					<Flex align="center" gap="6px">
						<AvatarWrapper
							src={deal.deal_owner?.profile_pic ?? ""}
							alt={deal.deal_owner?.name ?? ""}
							size="1"
							radius="full"
						/>
						<Text>{deal.deal_name}</Text>
					</Flex>
				</DealProperty> */}
				<DealProperty style={{ maxWidth: "270px", width: "100%" }}>
					<Flex align="center" gap="6px">
						<Text>Deal Amount</Text>
					</Flex>
					<Flex align="center" gap="6px">
						<Text style={{ color: "var(--mb-gray-9)" }}>
							{formatCurrency(deal.amount ?? 0, deal.deal_currency_code ?? "")}
						</Text>
					</Flex>
				</DealProperty>
				<DealProperty style={{ maxWidth: "270px", width: "100%" }}>
					<Badge radius="full" style={{ padding: "0px 12px", height: "24px" }}>
						{capitalize((deal.deal_stage ?? "").replace("_", " "))}
					</Badge>
				</DealProperty>
				<Icon.ArrowRight
					style={{ cursor: "pointer", flexShrink: 0 }}
					size="16"
					onClick={handleExpand}
				/>
			</DealsCardContainer>
			<ExpandedDealContent isexpanded={expanded}>
				<Flex direction="column" gap="24px">
					<Flex direction="column" gap="8px">
						<Text weight="medium">Deal Information</Text>
						<Flex gap="24px" wrap="wrap">
							{/* <Flex direction="column" gap="4px" style={{ minWidth: "200px" }}>
								<Text style={{ color: "var(--mb-gray-9)" }}>Probability</Text>
								<Text>{deal.deal_probability}%</Text>
							</Flex> */}
							<Flex direction="column" gap="4px" style={{ minWidth: "200px" }}>
								<Text style={{ color: "var(--mb-gray-9)" }}>Stage</Text>
								<Badge radius="full" style={{ width: "fit-content" }}>
									{deal.deal_stage}
								</Badge>
							</Flex>
							<Flex direction="column" gap="4px" style={{ minWidth: "200px" }}>
								<Text style={{ color: "var(--mb-gray-9)" }}>Amount</Text>
								<Text>
									{formatCurrency(
										deal.amount ?? 0,
										deal.deal_currency_code ?? "",
									)}
								</Text>
							</Flex>
							<Flex direction="column" gap="4px" style={{ minWidth: "200px" }}>
								<Text style={{ color: "var(--mb-gray-9)" }}>Close Date</Text>
								<Text>{deal.close_date}</Text>
							</Flex>
						</Flex>
					</Flex>

					<Flex direction="column" gap="8px">
						<Text weight="medium">Company Details</Text>
						<Flex gap="24px" wrap="wrap">
							<Flex direction="column" gap="4px" style={{ minWidth: "200px" }}>
								<Text style={{ color: "var(--mb-gray-9)" }}>Company</Text>
								{/* <Flex align="center" gap="6px">
									<img
										style={{
											width: "20px",
											height: "20px",
											borderRadius: "50%",
										}}
										src={deal.deal_company_logo}
										alt={deal.deal_company}
									/>
									<Text>{deal.deal_company}</Text>
								</Flex> */}
							</Flex>
							<Flex direction="column" gap="4px" style={{ minWidth: "200px" }}>
								<Text style={{ color: "var(--mb-gray-9)" }}>Deal Name</Text>
								<Text>{deal.deal_name}</Text>
							</Flex>
						</Flex>
					</Flex>

					{/* {deal.deal_owner && (
						<Flex direction="column" gap="8px">
							<Text weight="medium">Owner Information</Text>
							<Flex gap="24px" wrap="wrap">
								<Flex
									direction="column"
									gap="4px"
									style={{ minWidth: "200px" }}
								>
									<Text style={{ color: "var(--mb-gray-9)" }}>Owner</Text>
									<Flex align="center" gap="6px">
										<AvatarWrapper
											src={deal.deal_owner?.profile_pic ?? ""}
											alt={deal.deal_owner?.name ?? ""}
											size="1"
											radius="full"
										/>
										<Text>{deal.deal_owner?.name ?? ""}</Text>
									</Flex>
								</Flex>
								<Flex
									direction="column"
									gap="4px"
									style={{ minWidth: "200px" }}
								>
									<Text style={{ color: "var(--mb-gray-9)" }}>Email</Text>
									<Text>{deal.deal_owner?.email ?? ""}</Text>
								</Flex>
							</Flex>
						</Flex>
					)} */}
				</Flex>
			</ExpandedDealContent>
		</DealWrapper>
	);
});

export const Deals: React.FC<{ email: string; hubspot_id?: string }> = ({
	email,
	hubspot_id,
}) => {
	const { deals, isLoading, isFetching, page, setPage, hasMore, total } =
		usePersonDeals(email, { hubspot_id });

	const handleLoadMore = useCallback(async () => {
		if (!isLoading && !isFetching && hasMore) {
			setPage((prev) => prev + 1);
		}
	}, [isLoading, isFetching, hasMore, setPage]);

	if (isLoading && deals?.length === 0) {
		return (
			<ContentWrapper>
				<ContentHeader>
					<Flex justify="between" align="center" style={{ width: "100%" }}>
						<Skeleton width={"120px"} height={"24px"} />
						<Skeleton width={"100px"} height={"32px"} />
					</Flex>
				</ContentHeader>
				<ContentContainer>
					<Flex direction="column" gap="3" style={{ width: "100%" }}>
						{[1, 2, 3].map((i) => (
							<DealCardSkeleton key={i} />
						))}
					</Flex>
				</ContentContainer>
			</ContentWrapper>
		);
	}

	return (
		<ContentWrapper>
			<ContentHeader>
				<Text size="3" weight="medium">
					Deals{" "}
					<Text as="span" style={{ color: "var(--mb-gray-9)" }}>
						({total ?? deals?.length})
					</Text>
				</Text>
				<Button
					variant="ghost"
					size="2"
					disabled
					style={{ cursor: "not-allowed" }}
				>
					<Icon.Plus size="16" />
					Create Deal
				</Button>
			</ContentHeader>
			<ContentContainer>
				<InfiniteScroll
					data={deals}
					isLoading={isLoading || isFetching}
					error={null}
					hasMore={hasMore ?? false}
					onLoadMore={handleLoadMore}
					showNoMoreItems={false}
					renderItems={(items) =>
						items.map((deal) => <DealCard key={deal.deal_id} deal={deal} />)
					}
					style={{
						width: "100%",
						gap: "10px",
						overflow: "hidden",
						height: "100%",
					}}
				/>
			</ContentContainer>
		</ContentWrapper>
	);
};
