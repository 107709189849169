import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	useRef,
	useCallback,
} from "react";
import { useMeetingContent } from "@mightybot/core";
import { useNavigate, useLocation } from "react-router-dom";

interface MeetingContentNavigationContextType {
	activeItem: string | null;
	isScrollTriggeredByClick: boolean;
	handleSectionVisible: (visibleContentId: string) => void;
	handleMeetingOutlineItemClick: (contentId: string, title: string) => void;
	containerRef: React.RefObject<HTMLDivElement>;
	endOfContentRef: React.RefObject<HTMLDivElement>;
	scrollerRef: React.RefObject<HTMLDivElement>;
	scrollToBottom: () => void;
	updateUrlHash: (title: string) => void;
}

const MeetingContentNavigationContext = createContext<
	MeetingContentNavigationContextType | undefined
>(undefined);

export const MeetingContentNavigationProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const endOfContentRef = useRef<HTMLDivElement>(null);
	const scrollerRef = useRef<HTMLDivElement>(null);

	const scrollToBottom = useCallback(() => {
		if (containerRef.current) {
			containerRef.current.scrollTop = containerRef.current.scrollHeight;
		}
	}, []);
	const location = useLocation();

	const { meetingContent } = useMeetingContent();
	const [activeItem, setActiveItem] = useState<string | null>(null);
	const [isScrollTriggeredByClick, setIsScrollTriggeredByClick] =
		useState(false);
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (meetingContent && meetingContent.length > 0 && !activeItem) {
			setActiveItem(meetingContent[0].id);
		}
	}, [meetingContent, activeItem]);

	const handleSectionVisible = useCallback(
		(visibleContentId: string) => {
			if (!isScrollTriggeredByClick) {
				setActiveItem(visibleContentId);
			}
		},
		[isScrollTriggeredByClick],
	);

	const updateUrlHash = useCallback(
		(title: string) => {
			const hash = title.toLowerCase().replace(/\s+/g, "-");
			navigate(`${location.pathname}#${hash}`, { replace: true });
		},
		[navigate, location],
	);

	const handleMeetingOutlineItemClick = useCallback(
		(contentId: string, title: string) => {
			setActiveItem(contentId);
			setIsScrollTriggeredByClick(true);
			updateUrlHash(title);

			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				setIsScrollTriggeredByClick(false);
			}, 1000);
		},
		[updateUrlHash],
	);

	const value = {
		activeItem,
		isScrollTriggeredByClick,
		handleSectionVisible,
		handleMeetingOutlineItemClick,
		containerRef,
		endOfContentRef,
		scrollerRef,
		scrollToBottom,
		updateUrlHash,
	};

	return (
		<MeetingContentNavigationContext.Provider value={value}>
			{children}
		</MeetingContentNavigationContext.Provider>
	);
};

export const useMeetingContentNavigation = () => {
	const context = useContext(MeetingContentNavigationContext);
	if (context === undefined) {
		throw new Error(
			"useMeetingContentNavigation must be used within a MeetingContentNavigationProvider",
		);
	}
	return context;
};
