import styled from "styled-components";
import {
	Flex,
	Text,
	TextField,
	Popover,
	Box,
	mediaQueries,
	Button,
} from "@mightybot/web-ui";

export const SearchPlaceholderContainer = styled(Flex)`
	flex-direction: column;
	width: 100%;
	justify-content: center;
	align-items: start;
	overflow: hidden;
	margin: 0 auto;
`;

export const SearchContainer = styled(Flex)<{ isdialog?: string }>`
	height: ${(props) =>
		props.isdialog === "true" ? "calc(90vh - 100px)" : "100%"};
	width: ${(props) => (props.isdialog === "true" ? "100%" : "auto")};
	overflow: hidden;
	background-color: var(--mb-gray-1);
	border-radius: ${(props) => (props.isdialog === "true" ? "8px" : "0")};

	${mediaQueries.tablet} {
		flex-direction: column;
	}
`;

export const SearchInnerContainer = styled(Flex)<{
	issearchactive: string;
	isdialog: string;
}>`
	flex-direction: column;
	align-items: center;
	overflow-y: auto;
	scrollbar-width: thin;
	width: 100%;
	flex: 1;
	height: 100%;
	padding: ${(props) => (props.isdialog === "true" ? "0px 30px" : "0px")};
	padding-top: ${(props) =>
		props.issearchactive === "true" || props.isdialog === "true"
			? "0px"
			: "150px"};
	transition: padding-top 0.3s ease-in-out;
	background-color: var(--mb-gray-1);

	${mediaQueries.desktop} {
		padding: 0px 12px;
	}
	${mediaQueries.tablet} {
		padding: ${(props) => (props.isdialog === "true" ? "0px 16px" : "0px")};
		padding-top: ${(props) =>
			props.issearchactive === "true" || props.isdialog === "true"
				? "0px"
				: "60px"};
	}
`;

export const SearchHeader = styled(Text)`
	font-size: 22px;
	margin-bottom: 30px;
	position: relative;
`;

export const StickyContainer = styled(Flex)<{
	resultsscrolled: string;
	issearchactive: string;
	isdialog?: string;
}>`
	position: ${(props) =>
		props.issearchactive === "true" ? "sticky" : "relative"};
	top: ${(props) =>
		props.issearchactive === "true" || props.isdialog === "true"
			? "0"
			: "auto"};
	background-color: var(--mb-gray-1);
	z-index: 10;
	padding-top: 10px;
	width: 100%;
	max-width: ${(props) => (props.isdialog === "true" ? "100%" : "870px")};
	flex-direction: column;
	max-height: 130px;
	transition: all 0.3s ease;
	transform: translateY(
		${(props) => (props.resultsscrolled === "true" ? "-100%" : "0")}
	);

	${mediaQueries.tablet} {
		padding-top: 8px;
		max-height: none;
	}
`;

export const StyledBox = styled(Flex)`
	width: 100%;
	height: 58px;

	${mediaQueries.tablet} {
		height: 48px;
		padding: 20px 10px;
	}
`;

export const StyledTextField = styled(TextField.Root)<{ isexpanded: string }>`
	width: 100%;
	height: 100%;
	padding: 12px 20px;
	border-radius: ${(props) =>
		props.isexpanded === "true" ? "29px 29px 0 0" : "var(--radius-pill)"};
	box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
	border: 1px solid var(--mb-gray-6);
	border-bottom: ${(props) =>
		props.isexpanded === "true" ? "none" : "1px solid var(--mb-gray-6)"};
	&:focus-within {
		outline: none;
		border-color: var(--mb-gray-6);
		box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
	}

	${mediaQueries.tablet} {
		padding: 8px 12px;
		height: 48px;
	}
`;

export const FilterContainer = styled(Flex)`
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 16px 0px;
	margin: auto;

	${mediaQueries.tablet} {
		flex-wrap: wrap;
		gap: 8px;
		padding: 16px 12px;
	}
`;

export const FilterActionsContainer = styled(Flex)`
	gap: 10px;
	align-items: center;
	width: 100%;

	${mediaQueries.tablet} {
		flex-wrap: wrap;
		gap: 8px;
	}
`;

export const FilterItem = styled(Flex)`
	align-items: center;
	padding: 0px 14px;
	gap: 6px;
	height: 34px;
	cursor: pointer;
	border-radius: 4px;
	border: 1px solid var(--mb-gray-6);
	transition: background-color 0.2s ease;

	&:hover {
		background-color: var(--mb-gray-2);
	}

	${mediaQueries.mobile} {
		padding: 0px 10px;
		height: 32px;
		font-size: 13px;

		svg {
			width: 14px;
			height: 14px;
		}
	}
`;

export const ResultContainer = styled(Flex)<{
	islast: string;
	index: string;
	isselectionmode: string;
	ischecked: string;
	hasmultipleresults: string;
}>`
	align-items: flex-start;
	width: 100%;
	height: 100%;
	min-height: ${(props) =>
		props.hasmultipleresults === "true" ? "150px" : "auto"};
	max-height: 150px;
	transition:
		background-color 0.2s ease-out,
		padding 0.2s ease-out;
	border-right: 1px solid var(--mb-gray-5);
	border-left: 1px solid var(--mb-gray-5);
	border-bottom: ${(props) =>
		props.islast === "true" ? "1px solid var(--mb-gray-5)" : "none"};
	border-radius: ${(props) =>
		props.islast === "true" ? "0px 0px 8px 8px" : "0px"};
	border-top: ${(props) =>
		props.index === "0" ? "none" : "1px solid var(--mb-gray-5)"};
	cursor: ${(props) =>
		props.isselectionmode === "true" ? "pointer" : "default"};
	padding: 12px 10px 8px;
	background-color: ${(props) =>
		props.ischecked === "true" && props.isselectionmode === "true"
			? "var(--mb-blue-1)"
			: "var(--mb-gray-1)"};
`;

export const IconContainer = styled(Flex)`
	margin-top: 3px;
`;

export const ContentContainer = styled(Flex)`
	flex: 1;
	width: 100%;
	overflow: hidden;
	display: flex;
	gap: 9px;
	height: 100%;
	padding-left: 6px;
`;

export const TitleLink = styled.a`
	text-decoration: none;
	display: inline-block;
	color: var(--mb-blue-8);
	&:hover {
		text-decoration: underline;
	}
`;

export const ResultsSummaryContainer = styled(Flex)<{
	resultsscrolled: string;
	isdialog: string;
}>`
	position: sticky;
	top: ${(props) => (props.resultsscrolled === "true" ? "0px" : "130px")};
	padding-top: 10px;
	width: 100%;
	max-width: ${(props) => (props.isdialog === "true" ? "100%" : "870px")};
	background-color: var(--mb-gray-1);
	transition: top 0.3s ease;
	z-index: 1;
	${mediaQueries.tablet} {
		padding-top: ${(props) => (props.isdialog === "true" ? "5px" : "0px")};
	}
`;

export const ResultsSummaryInnerContainer = styled(Flex)<{
	isdialog: string;
}>`
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 10px;
	padding: 0px 10px;
	height: 50px;
	background-color: ${(props) =>
		props.isdialog === "true" ? "var(--mb-gray-1)" : "var(--mb-blue-1)"};
	border: 1px solid var(--mb-gray-5);
	border-radius: 8px 8px 0px 0px;

	${mediaQueries.tablet} {
		border-radius: ${(props) =>
			props.isdialog === "true" ? "8px 8px 0px 0px" : "0px"};
		border: ${(props) =>
			props.isdialog === "true" ? "1px solid var(--mb-gray-5)" : "none"};
	}
`;

export const SearchResultsContainer = styled(Flex)<{
	issearchactive: string;
	isdialog: string;
	hasresults: string;
}>`
	flex-direction: column;
	max-width: ${(props) => (props.isdialog === "true" ? "100%" : "870px")};
	width: 100%;
	max-height: ${(props) =>
		props.issearchactive === "true" ||
		(props.hasresults === "true" && props.isdialog === "true")
			? "none"
			: "0"};
	height: ${(props) =>
		props.isdialog === "true"
			? "auto"
			: props.hasresults === "true"
				? "auto"
				: "100%"};
	opacity: ${(props) =>
		props.issearchactive === "true" ||
		(props.hasresults === "true" && props.isdialog === "true")
			? "1"
			: "0"};
	transition: opacity 0.3s ease-in-out;
	padding-bottom: 10px;
	background-color: var(--mb-gray-1);

	${mediaQueries.tablet} {
		padding-bottom: 0px;
	}
`;

export const SearchResultsList = styled(Flex)`
	flex-direction: column;
	width: 100%;
`;

export const SnippetContent = styled.div<{
	slidedirection: "left" | "right" | null;
}>`
	height: 100%;
	max-height: 55px;
	line-height: 1.5;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	word-wrap: break-word;
	word-break: break-word;
	padding-right: 8px;
	flex: 1;
	animation: ${(props) =>
		props.slidedirection && `slide-${props.slidedirection} 0.3s ease-in-out`};

	@keyframes slide-left {
		from {
			opacity: 0;
			transform: translateX(20px);
		}
		to {
			opacity: 1;
			transform: translateX(0);
		}
	}

	@keyframes slide-right {
		from {
			opacity: 0;
			transform: translateX(-20px);
		}
		to {
			opacity: 1;
			transform: translateX(0);
		}
	}
`;

export const customMarkdownStyles = {
	heading: {
		fontSize: "12px",
		margin: "0px",
	},
	content: {
		userSelect: "text",
		overflow: "hidden",
		whiteSpace: "pre-wrap",
		fontSize: "12px",
		scrollbarWidth: "none",
		msOverflowStyle: "none",
		WebkitScrollbar: {
			display: "none",
		},
		color: "var(--mb-gray-9)",
		lineHeight: "18px",
		maxWidth: "100%",
		wordWrap: "break-word",
		wordBreak: "break-word",
	},
	paragraph: {
		marginBottom: "0",
		fontSize: "12px",
		lineHeight: "18px",
	},
	link: {
		color: "var(--mb-color-light-blue-8)",
		textDecoration: "underline",
		fontSize: "12px",
		cursor: "pointer",
	},
	code: {
		backgroundColor: "var(--mb-gray-3)",
		padding: "2px 4px",
		borderRadius: "4px",
		fontSize: "11px",
		fontFamily: "monospace",
		whiteSpace: "pre-wrap",
		wordBreak: "break-word",
		color: "var(--mb-gray-11)",
	},
};

export const PastSearchesContainer = styled(Flex)<{ isvisible: string }>`
	width: 100%;
	flex-direction: column;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background-color: white;
	border: ${(props) =>
		props.isvisible === "true" ? "1px solid #ccc" : "none"};
	border-top: none;
	max-height: ${(props) => (props.isvisible === "true" ? "300px" : "0")};
	overflow-y: auto;
	transition: max-height 0.3s ease-in-out;
	z-index: 1000;
	padding: ${(props) =>
		props.isvisible === "true" ? "0px 20px 20px 20px" : "0"};
	border-radius: ${(props) =>
		props.isvisible === "true" ? "0px 0px 20px 20px" : "0px"};
	box-shadow: ${(props) =>
		props.isvisible === "true"
			? "0px 2px 3px 0px rgba(0, 0, 0, 0.05)"
			: "none"};
`;

export const PastSearchItem = styled(Flex)<{ isselected?: string }>`
	padding: 8px 16px;
	cursor: pointer;
	align-items: center;
	gap: 8px;
	background-color: ${({ isselected }) =>
		isselected === "true" ? "var(--mb-gray-3)" : "transparent"};
	border-radius: 4px;
	margin: 0 4px;
	justify-content: space-between;

	&:hover {
		background-color: var(--mb-gray-3);
	}
`;

export const SearchQueryText = styled(Flex)`
	align-items: center;
	gap: 8px;
`;

export const ResultCount = styled.span`
	color: var(--mb-gray-8);
	font-size: 12px;
`;

export const AppFilterPill = styled(Flex)<{
	selected: boolean;
}>`
	gap: 6px;
	border-radius: var(--radius-pill);
	padding: 0px 12px;
	height: 28px;
	border: 1px solid var(--mb-gray-6);
	align-items: center;
	cursor: pointer;
	background-color: ${(props) =>
		props.selected ? "var(--mb-blue-2)" : "transparent"};
	border-color: ${(props) =>
		props.selected ? "var(--mb-blue-6)" : "var(--mb-gray-6)"};

	&:hover {
		border-color: var(--mb-blue-6);

		svg {
			color: var(--mb-red-7);
		}
	}

	svg {
		color: var(--mb-gray-8);
		transition: color 0.2s ease;
	}
`;

export const SelectedFiltersRow = styled(Flex)`
	width: 100%;
`;

export const OverlappingImagesContainer = styled(Flex)`
	position: relative;
	display: inline-flex;
	align-items: center;
	height: 20px;
`;

export const OverlappingImage = styled(Box)`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: white;
	border: 1px solid var(--mb-gray-3);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&:not(:first-child) {
		margin-left: -8px; // Controls how much images overlap
	}

	img {
		width: 14px;
		height: 14px;
		object-fit: contain;
	}

	&:hover {
		z-index: 2; // Bring hovered image to front
	}
`;

export const TimestampFilterOption = styled(Flex)<{ selected: boolean }>`
	flex-direction: column;
	padding: 8px 10px;
	transition: background-color 0.2s ease;
	background-color: ${(props) =>
		props.selected ? "var(--mb-gray-2)" : "transparent"};

	&:hover {
		background: var(--mb-gray-2);
	}
`;

export const CheckboxContainer = styled(Flex)<{ $isVisible: boolean }>`
	padding: 8px;
	opacity: 0;
	transform: translateX(-10px);
	transition:
		opacity 0.2s ease-out,
		transform 0.2s ease-out;

	${({ $isVisible }) =>
		$isVisible &&
		`
		opacity: 1;
		transform: translateX(0);
	`}
`;

export const StyledButton = styled(Button)`
	font-size: 13px;
	font-weight: 400;
	border-radius: var(--radius-pill);
	cursor: pointer;
	height: 34px;
	transition: background-color 0.2s ease;
`;

export const PeopleFilterContainer = styled(Flex)`
	width: 100%;
	max-height: 400px;
	overflow-y: auto;
`;

export const PersonRow = styled(Flex)`
	gap: 8px;
	cursor: pointer;
	height: 32px;
	transition: background-color 0.2s ease;
	padding: 10px;

	&:hover {
		background: var(--mb-gray-2);
	}
`;

export const ResultGroupContainer = styled(Flex)`
	align-items: center;
	width: 100%;
`;

export const NavigationButton = styled(Button)<{ position: "left" | "right" }>`
	position: absolute;
	${(props) => props.position}: -12px;
	top: 50%;
	transform: translateY(-50%);
	background: var(--mb-gray-1);
	border: 1px solid var(--mb-gray-5);
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.2s ease;

	${ResultGroupContainer}:hover & {
		opacity: 1;
	}

	&:hover {
		background: var(--mb-gray-2);
	}
`;

export const NavigationContainer = styled(Flex)`
	align-items: center;
	gap: 4px;
	border-radius: 6px;
	background: var(--mb-gray-2);
	width: fit-content;
	padding: 4px;
`;

export const MobileSidebarContainer = styled.div`
	position: sticky;
	top: 0;
	z-index: 11;
	width: 100%;
	background-color: var(--mb-gray-1);
	max-height: 100px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

export const AppFilterPreviewContainer = styled(Flex)`
	padding: 16px 0;
	justify-content: center;

	// Create rows with different widths for pyramid effect
	& > div {
		justify-content: center;
	}

	& > div:nth-child(1) {
		width: 100%;
	}
	& > div:nth-child(2) {
		width: 85%;
	}
`;

// Add these new styled components
export const MentionPopover = styled(Popover.Root)`
	border: 1px solid var(--mb-gray-6);
	border-radius: var(--radius-2);
	box-shadow: var(--shadow-2);
	overflow-y: auto;
	width: 100%;
`;

export const MentionPopoverContent = styled(Popover.Content)`
	padding: 4px;
	white-space: nowrap;
	max-height: 300px;
	overflow-y: auto;
`;

export const MentionItem = styled(Flex)<{ isselected: string }>`
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px;
	cursor: pointer;
	border-radius: var(--radius-2);
	transition: background-color 0.2s ease;
	background-color: ${(props) =>
		props.isselected === "true" ? "var(--mb-gray-4)" : "transparent"};

	&:hover {
		background-color: var(--mb-gray-4);
	}
`;

export const NoResultsItem = styled(Flex)`
	padding: 10px;
	text-align: center;
	color: var(--mb-gray-9);
	width: 100%;
`;
