import React, { useState, useEffect } from "react";
import { subMonths, addMonths } from "date-fns";
import {
	Button,
	Calendar,
	Flex,
	Icon,
	Text,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";
import { DatePickerWrapper, CalendarsContainer, ActionButtons } from "./styled";

interface DateRangePickerProps {
	startDate: Date | null;
	endDate: Date | null;
	onChange: (dates: [Date | null, Date | null]) => void;
	singleDate?: boolean;
	placeholderText?: string;
	maxDate?: Date;
	minDate?: Date;
	isOpen?: boolean;
	onClose?: () => void;
	style?: React.CSSProperties;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
	startDate,
	endDate,
	onChange,
	singleDate = false,
	placeholderText,
	maxDate,
	minDate,
	isOpen,
	onClose,
	style,
}) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const [leftMonth, setLeftMonth] = useState(
		singleDate ? new Date() : subMonths(new Date(), 1),
	);
	const [rightMonth, setRightMonth] = useState(
		singleDate ? new Date() : new Date(),
	);
	const [tempStartDate, setTempStartDate] = useState<Date | null>(startDate);
	const [tempEndDate, setTempEndDate] = useState<Date | null>(endDate);

	const handleDateSelect = (date: Date) => {
		if (singleDate) {
			setTempStartDate(date);
			setTempEndDate(null);
		} else {
			if (!tempStartDate || tempEndDate) {
				setTempStartDate(date);
				setTempEndDate(null);
			} else {
				if (date < tempStartDate) {
					setTempStartDate(date);
					setTempEndDate(tempStartDate);
				} else {
					setTempEndDate(date);
				}
			}
		}
	};

	const handleLeftMonthChange = (date: Date) => {
		setLeftMonth(date);
		if (!isTablet && !singleDate) {
			setRightMonth(addMonths(date, 1));
		}
	};

	const handleRightMonthChange = (date: Date) => {
		setRightMonth(date);
		if (!isTablet && !singleDate) {
			setLeftMonth(subMonths(date, 1));
		}
	};

	const handleApply = () => {
		onChange([tempStartDate, tempEndDate]);
		onClose?.();
	};

	const handleCancel = () => {
		setTempStartDate(startDate);
		setTempEndDate(endDate);
		onClose?.();
	};

	const handleClear = () => {
		setTempStartDate(null);
		setTempEndDate(null);
		onClose?.();
	};

	return (
		<DatePickerWrapper style={style}>
			<Flex direction="column" style={{ width: "100%" }}>
				<Flex
					justify="between"
					align="center"
					style={{ width: "100%", padding: "10px 16px" }}
				>
					<Text weight="medium">
						{singleDate ? "Select date" : "Select date range"}
					</Text>
					<Icon.X
						size={16}
						onClick={handleClear}
						style={{ cursor: "pointer" }}
					/>
				</Flex>

				<CalendarsContainer>
					{/* Left Calendar */}
					<Calendar
						selectedStartDate={tempStartDate}
						selectedEndDate={tempEndDate}
						onDateSelect={handleDateSelect}
						currentMonth={leftMonth}
						onMonthChange={handleLeftMonthChange}
						minDate={minDate}
						maxDate={maxDate}
					/>

					{/* Right Calendar - Only show for date range selection on non-mobile devices */}
					{!singleDate && !isTablet && (
						<Calendar
							selectedStartDate={tempStartDate}
							selectedEndDate={tempEndDate}
							onDateSelect={handleDateSelect}
							currentMonth={rightMonth}
							onMonthChange={handleRightMonthChange}
							minDate={minDate}
							maxDate={maxDate}
						/>
					)}
				</CalendarsContainer>

				<ActionButtons>
					<Button
						variant="white-bg"
						onClick={handleCancel}
						style={{ cursor: "pointer" }}
					>
						Cancel
					</Button>
					<Button onClick={handleApply} style={{ cursor: "pointer" }}>
						Apply
					</Button>
				</ActionButtons>
			</Flex>
		</DatePickerWrapper>
	);
};

export default DateRangePicker;
