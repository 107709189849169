import React from "react";
import { Outlet } from "react-router-dom";
import MetaTags from "../utils/MetaTags";
import { useInitAuth } from "@mightybot/core";

function RootRoute() {
	useInitAuth();
	return (
		<React.Fragment>
			<MetaTags />
			<Outlet />
		</React.Fragment>
	);
}

export default RootRoute;
