import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
	Button,
	Flex,
	Icon,
	Text,
	Skeleton,
	useMediaQuery,
} from "@mightybot/web-ui";
import { FilterButton, FilterContainer } from "./styled";
import FilterPopover from "../FilterPopover/FilterPopover";
import { PeopleStatusOptions, usePeopleFilterOptions } from "@mightybot/core";
import { GenericFilterContent } from "../FilterPopover/FilterContent/GenericFilterContent";
import { breakpoints } from "@mightybot/web-ui/src/util/mediaQueries";

type FilterState = {
	isOpen: boolean;
	selected: string[];
	temp: string[];
};

interface PeopleFiltersProps {
	onCompaniesChange: (companies: string[]) => void;
	onTeamsChange: (teams: string[]) => void;
	onDealsChange: (deals: string[]) => void;
	onStatusesChange: (statuses: PeopleStatusOptions[]) => void;
	selectedCompanies: string[];
	selectedTeams: string[];
	selectedDeals: string[];
	selectedStatuses: PeopleStatusOptions[];
	isInternalOnly: boolean;
}

const STATUS_OPTIONS = ["WARM", "COLD"];

const LoadingFilters = () => (
	<FilterContainer gap="8px">
		<Flex gap="8px">
			<Skeleton width="100px" height="34px" style={{ borderRadius: "4px" }} />
			<Skeleton width="80px" height="34px" style={{ borderRadius: "4px" }} />
			<Skeleton width="90px" height="34px" style={{ borderRadius: "4px" }} />
			<Skeleton width="120px" height="34px" style={{ borderRadius: "4px" }} />
		</Flex>
		<Flex gap="8px">
			<Skeleton width="120px" height="34px" style={{ borderRadius: "4px" }} />
			<Skeleton width="100px" height="34px" style={{ borderRadius: "4px" }} />
			<Skeleton width="140px" height="34px" style={{ borderRadius: "4px" }} />
		</Flex>
	</FilterContainer>
);

export const PeopleFilters: React.FC<PeopleFiltersProps> = ({
	onCompaniesChange,
	onTeamsChange,
	onDealsChange,
	onStatusesChange,
	selectedCompanies,
	selectedTeams,
	selectedDeals,
	selectedStatuses,
}) => {
	const { data: filterOptions, isLoading } = usePeopleFilterOptions();
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile})`);

	// Combine all filter states into a single object
	const [filterStates, setFilterStates] = useState<{
		company: FilterState;
		team: FilterState;
		deals: FilterState;
		status: FilterState;
	}>({
		company: {
			isOpen: false,
			selected: selectedCompanies,
			temp: selectedCompanies,
		},
		team: { isOpen: false, selected: selectedTeams, temp: selectedTeams },
		deals: { isOpen: false, selected: selectedDeals, temp: selectedDeals },
		status: {
			isOpen: false,
			selected: selectedStatuses,
			temp: selectedStatuses,
		},
	});

	// Update filter states when selected values change from parent
	useEffect(() => {
		setFilterStates((prev) => ({
			...prev,
			company: {
				...prev.company,
				selected: selectedCompanies,
				temp: selectedCompanies,
			},
			team: {
				...prev.team,
				selected: selectedTeams,
				temp: selectedTeams,
			},
			deals: {
				...prev.deals,
				selected: selectedDeals,
				temp: selectedDeals,
			},
			status: {
				...prev.status,
				selected: selectedStatuses,
				temp: selectedStatuses,
			},
		}));
	}, [selectedCompanies, selectedTeams, selectedDeals, selectedStatuses]);

	// Generic handlers for all filter types
	const handleFilterChange = useCallback(
		(filterType: keyof typeof filterStates) => {
			return {
				onSelect: (option: string) => {
					setFilterStates((prev) => ({
						...prev,
						[filterType]: {
							...prev[filterType],
							temp: prev[filterType].temp.includes(option as any)
								? prev[filterType].temp.filter((item) => item !== option)
								: [...prev[filterType].temp, option],
						},
					}));
				},
				onApply: () => {
					const newTemp = filterStates[filterType].temp;
					setFilterStates((prev) => ({
						...prev,
						[filterType]: {
							...prev[filterType],
							selected: newTemp,
							isOpen: false,
						},
					}));

					// Immediately call the appropriate onChange handler
					switch (filterType) {
						case "company":
							onCompaniesChange(newTemp);
							break;
						case "team":
							onTeamsChange(newTemp);
							break;
						case "deals":
							onDealsChange(newTemp);
							break;
						case "status":
							onStatusesChange(newTemp as PeopleStatusOptions[]);
							break;
					}
				},
				onCancel: () => {
					setFilterStates((prev) => ({
						...prev,
						[filterType]: {
							...prev[filterType],
							temp: prev[filterType].selected,
							isOpen: false,
						},
					}));
				},
				setIsOpen: (isOpen: boolean) => {
					setFilterStates((prev) => ({
						...prev,
						[filterType]: {
							...prev[filterType],
							isOpen,
						},
					}));
				},
			};
		},
		[
			filterStates,
			onCompaniesChange,
			onTeamsChange,
			onDealsChange,
			onStatusesChange,
		],
	);

	// Memoize filter buttons to prevent unnecessary re-renders
	const renderFilterButton = useCallback(
		(
			filterType: keyof typeof filterStates,
			label: string,
			options: string[],
		) => {
			const { isOpen, selected, temp } = filterStates[filterType];
			const handlers = handleFilterChange(filterType);

			return (
				<FilterPopover
					trigger={
						<FilterButton
							disabled={["status", "deals"].includes(filterType)}
							data-active={selected.length > 0}
							variant="white-bg"
							style={{
								cursor: ["status", "deals"].includes(filterType)
									? "not-allowed"
									: "pointer",
							}}
						>
							<Text weight="medium">{label}</Text>
							{selected.length > 0 && (
								<Text size="1" style={{ marginLeft: "4px" }}>
									({selected.length})
								</Text>
							)}
							<Icon.CaretDown size={16} />
						</FilterButton>
					}
					isOpen={isOpen}
					onOpenChange={handlers.setIsOpen}
					onApply={handlers.onApply}
					onCancel={handlers.onCancel}
				>
					<GenericFilterContent
						title={`Select ${label}`}
						options={options}
						selectedOptions={temp}
						handleOptionSelect={handlers.onSelect}
						handleSelectAll={() => {
							options.forEach(handlers.onSelect);
						}}
						handleReset={() => {
							temp.forEach(handlers.onSelect);
						}}
						showSearch={true}
					/>
				</FilterPopover>
			);
		},
		[filterStates, handleFilterChange],
	);

	// Show loading state if filter options are still loading
	if (isLoading) {
		return <LoadingFilters />;
	}

	return (
		<FilterContainer gap={isTablet ? "6px" : "8px"}>
			<Flex
				justify="between"
				gap={isTablet ? "6px" : "8px"}
				style={{
					flexWrap: isTablet ? "wrap" : "nowrap",
					width: "100%",
				}}
			>
				<Flex gap="8px">
					{/* Render filters in a more compact way on mobile */}
					{!isMobile ? (
						<>
							{renderFilterButton(
								"company",
								"Company",
								filterOptions?.organization ?? [],
							)}
							{/* {renderFilterButton(
								"team",
								"Team",
								filterOptions?.job_title ?? [],
							)} */}
							{/* {renderFilterButton("deals", "Deals", filterOptions?.deals ?? [])}
							{renderFilterButton(
								"status",
								"Status",
								filterOptions?.status ?? STATUS_OPTIONS,
							)} */}
						</>
					) : (
						<FilterButton
							variant="white-bg"
							onClick={() => {
								/* Show mobile filter drawer */
							}}
						>
							<Icon.FunnelSimple size={16} />
							<Text>Filters</Text>
						</FilterButton>
					)}
					{/* <Flex align="center" gap={isTablet ? "6px" : "8px"}>
						<Switch
							checked={isInternalOnly}
							onCheckedChange={onInternalOnlyChange}
							size="1"
						/>
						<Text style={{ color: "var(--mb-gray-9)" }}>Internal Only</Text>
					</Flex> */}
				</Flex>

				<Flex gap="8px" style={{ flexWrap: "wrap" }}>
					{/* <Button disabled variant="white-bg" style={{ cursor: "not-allowed" }}>
						<Icon.FunnelSimple size={16} />
						<Text>Last Active</Text>
						<Icon.CaretDown size={16} />
					</Button>
					<Button disabled variant="white-bg" style={{ cursor: "not-allowed" }}>
						<Text>Actions</Text>
						<Icon.CaretDown size={16} />
					</Button> */}
					<Button disabled style={{ cursor: "not-allowed" }}>
						<Icon.Plus size={16} />
						<Text>Create Contact</Text>
					</Button>
				</Flex>
			</Flex>
		</FilterContainer>
	);
};
