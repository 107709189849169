import React from "react";

import { Flex, Skeleton } from "@mightybot/web-ui";

export const ContentCardSkeleton = () => (
	<div
		style={{
			padding: "16px",
			border: "1px solid var(--mb-gray-3)",
			borderRadius: "8px",
			background: "white",
		}}
	>
		<Flex justify="between" style={{ marginBottom: "12px" }}>
			<Flex gap="2">
				<Skeleton
					style={{ borderRadius: "50%" }}
					width={"20px"}
					height={"20px"}
				/>
				<Skeleton width={"80px"} height={"20px"} />
			</Flex>
		</Flex>
		<Skeleton width="100%" height={"24px"} style={{ marginBottom: "8px" }} />
		<Skeleton width="80%" height={"20px"} style={{ marginBottom: "16px" }} />
		<Flex justify="between" align="center">
			<Skeleton width={"100px"} height={"16px"} />
			<Skeleton width={"24px"} height={"24px"} />
		</Flex>
	</div>
);
