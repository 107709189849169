import { breakpoints, Flex } from "@mightybot/web-ui";
import { styled } from "styled-components";

export const MeetingChatsContainer = styled(Flex)`
	flex-direction: column;
	gap: 24px;
	margin-top: 10px;
	margin-bottom: 10px;

	@media (max-width: ${breakpoints.tablet}) {
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 16px;
		margin-right: 16px;
	}
`;
