import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
	DropdownMenu,
	Text,
	Flex,
	Icon,
	Badge,
	CustomReactMarkdown,
	breakpoints,
	useMediaQuery,
	Button,
	Dialog,
	Input,
	Skeleton,
} from "@mightybot/web-ui";
import {
	ContentHeader,
	ContentWrapper,
	ContentContainer,
	NoteCardContainer,
} from "./styled";
import { Note, NoteResponse, useNotes, useNotesList } from "@mightybot/core";
import { formatTimestamp } from "../../../utils/helpers";
import { InfiniteScroll } from "../../People/InfiniteScroll";
import { TipTapEditor } from "../../Editor/TipTapEditor";
import { ContentCardSkeleton } from "./ContentSkeleton";
import { usePersonNotes, isCRMNote, normalizeNote } from "@mightybot/core";

interface CreateNoteDialogProps {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	personId: string;
	initialData: NoteResponse | Note;
}

const CreateNoteDialog: React.FC<CreateNoteDialogProps> = ({
	open,
	onOpenChange,
	personId,
	initialData,
}) => {
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const { createNote, updateNote, isLoading } = useNotes({
		collectionId: personId,
		skip: !open,
	});

	const normalizedNote = initialData ? normalizeNote(initialData) : null;

	useEffect(() => {
		if (open && initialData) {
			setTitle(normalizedNote?.title ?? "");
			setContent(normalizedNote?.content ?? "");
		} else if (!open) {
			setTitle("");
			setContent("");
		}
	}, [open, initialData]);

	const handleSubmit = async () => {
		try {
			if (initialData) {
				await updateNote(normalizedNote?.id ?? "", {
					collection_id: personId,
					title,
					content,
				});
			} else {
				await createNote({
					collection_id: personId,
					title,
					content,
					content_metadata: {},
				});
			}
			onOpenChange(false);
		} catch (error) {
			console.error(
				`Failed to ${normalizedNote ? "update" : "create"} note:`,
				error,
			);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && e.ctrlKey && !isLoading && title && content) {
			e.preventDefault();
			handleSubmit();
		}
	};

	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Content
				size="3"
				style={{
					width: "700px",
					display: "flex",
					flexDirection: "column",
					gap: "10px",
				}}
				onKeyDown={handleKeyDown}
			>
				<Dialog.Title>{normalizedNote ? "Edit" : "Create"} Note</Dialog.Title>
				<Dialog.Description>
					{normalizedNote ? "Update" : "Add a new"} note for this person
				</Dialog.Description>

				<Flex direction="column" gap="3">
					<Input
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						placeholder="Enter note title"
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault(); // Prevent form submission
							}
						}}
					/>
					<Flex direction="column" gap="2">
						<Text size="2">Note Content</Text>
						<TipTapEditor
							key={`${normalizedNote?.id}-${open}`}
							content={content}
							onChange={setContent}
							placeholder="Write your note here..."
							defaultValue={normalizedNote?.content}
						/>
					</Flex>
				</Flex>

				<Flex justify="between" align="center" style={{ marginTop: "16px" }}>
					<Text style={{ color: "var(--mb-gray-9)", fontSize: "9px" }}>
						Press Ctrl + Enter to {normalizedNote ? "update" : "create"}
					</Text>
					<Flex gap="2" align="center">
						<Dialog.Close>
							<Button variant="white-bg">Cancel</Button>
						</Dialog.Close>
						<Button
							disabled={!title || !content || isLoading}
							onClick={handleSubmit}
						>
							{isLoading
								? normalizedNote
									? "Updating..."
									: "Creating..."
								: normalizedNote
									? "Update Note"
									: "Create Note"}
						</Button>
					</Flex>
				</Flex>
			</Dialog.Content>
		</Dialog.Root>
	);
};

interface NoteCardProps {
	note: Note | NoteResponse;
	onDelete: () => void;
	onEdit: () => void;
}

const NoteCard: React.FC<NoteCardProps> = ({ note, onDelete, onEdit }) => {
	const handleNoteClick = (e: React.MouseEvent<HTMLDivElement>) => {
		console.log("Note clicked");
	};

	const normalizedNote = normalizeNote(note);
	const isCRM = isCRMNote(note);

	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const formatContent = (note: typeof normalizedNote) => {
		const escapedContent = note.content
			?.replace(/<[^>]*>/g, "")
			.replace(/</g, "&lt;")
			.replace(/>/g, "&gt;");

		if (note.title) {
			return `# ${note.title}\n\n${escapedContent}`;
		}
		return escapedContent;
	};

	return (
		<NoteCardContainer>
			<Flex justify="between">
				<Flex style={{ gap: 6 }}>
					<Icon.Note size="20" />
					{!isTablet && (
						<Badge radius="full">{normalizedNote.content_type}</Badge>
					)}
				</Flex>
			</Flex>
			<CustomReactMarkdown
				styles={{
					content: {},
					paragraph: {
						marginBottom: "0.5rem",
						wordBreak: "break-word",
					},
					heading: {
						marginBottom: "0px",
						marginTop: "0px",
						fontSize: "16px",
					},
				}}
			>
				{formatContent(normalizedNote)}
			</CustomReactMarkdown>
			{isTablet && <Badge radius="full">{normalizedNote.content_type}</Badge>}

			<Flex justify="between" align="center" style={{ padding: "4px 0px" }}>
				<Text size="1" weight="light" style={{ color: "var(--mb-gray-9)" }}>
					{formatTimestamp(normalizedNote.created_ts ?? "")}
				</Text>
				{!isCRM && (
					<Flex style={{ gap: 15 }}>
						<DropdownMenu.Root>
							<DropdownMenu.Trigger onClick={(e) => e.stopPropagation()}>
								<Icon.DotsThree style={{ cursor: "pointer" }} />
							</DropdownMenu.Trigger>
							<DropdownMenu.Content>
								<DropdownMenu.Item
									onClick={onEdit}
									style={{ cursor: "pointer" }}
								>
									<Text>Edit</Text>
								</DropdownMenu.Item>
								<DropdownMenu.Item
									onClick={onDelete}
									style={{ cursor: "pointer" }}
								>
									<Text>Delete</Text>
								</DropdownMenu.Item>
							</DropdownMenu.Content>
						</DropdownMenu.Root>
					</Flex>
				)}
			</Flex>
		</NoteCardContainer>
	);
};

export const Notes: React.FC<{
	id: string;
	email: string;
	hubspot_id?: string;
}> = ({ id, email, hubspot_id }) => {
	const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
	const [selectedNote, setSelectedNote] = useState<
		Note | NoteResponse | undefined
	>();
	const [page, setPage] = useState(1);
	const [source, setSource] = useState<"crm" | "both" | "internal">("both");

	// Internal Notes
	const {
		notes: internalNotes,
		hasMore: internalHasMore,
		isLoading: internalLoading,
		isFetching: internalFetching,
		total: internalTotal,
	} = useNotesList({
		collectionId: id,
		page,
		perPage: 10,
	});

	// CRM Notes
	const {
		notes: crmNotes,
		isLoading: crmLoading,
		isFetching: crmFetching,
		hasMore: crmHasMore,
		total: crmTotal,
	} = usePersonNotes(email, {
		source: source as "crm" | "internal",
		hubspot_id,
	});

	// Reset page when email or source changes
	useEffect(() => {
		setPage(1);
	}, [id]);

	const { deleteNote } = useNotes({ collectionId: id });

	// Combine notes based on source
	const notes = useMemo(() => {
		if (source === "internal") return internalNotes;
		if (source === "crm") return crmNotes;
		return [...crmNotes, ...internalNotes];
	}, [internalNotes, crmNotes, source]);

	const isLoading =
		source === "internal"
			? internalLoading
			: source === "crm"
				? crmLoading
				: internalLoading || crmLoading;
	const isFetching =
		source === "internal"
			? internalFetching
			: source === "crm"
				? crmFetching
				: internalFetching || crmFetching;
	const hasMore =
		source === "internal"
			? internalHasMore
			: source === "crm"
				? crmHasMore
				: internalHasMore || crmHasMore;
	const total =
		source === "internal"
			? internalTotal
			: source === "crm"
				? crmTotal
				: internalTotal + crmTotal;

	const handleLoadMore = useCallback(async () => {
		if (!isLoading && !isFetching && hasMore) {
			setPage((prev) => prev + 1);
		}
	}, [isLoading, isFetching, hasMore]);

	if (isLoading && notes.length === 0) {
		return (
			<ContentWrapper>
				<ContentHeader>
					<Flex justify="between" align="center" style={{ width: "100%" }}>
						<Skeleton width={"120px"} height={"24px"} />
						<Skeleton width={"100px"} height={"32px"} />
					</Flex>
				</ContentHeader>
				<ContentContainer>
					<Flex direction="column" gap="3" style={{ width: "100%" }}>
						{[1, 2, 3].map((i) => (
							<ContentCardSkeleton key={i} />
						))}
					</Flex>
				</ContentContainer>
			</ContentWrapper>
		);
	}

	return (
		<ContentWrapper>
			<ContentHeader>
				<Flex justify="between" align="center" style={{ width: "100%" }}>
					<Flex align="center" gap="3">
						<Text size="3" weight="medium">
							Notes{" "}
							<Text as="span" style={{ color: "var(--mb-gray-9)" }}>
								({total})
							</Text>
						</Text>
						<Badge
							radius="full"
							onClick={() =>
								setSource((prev) => {
									if (prev === "both") return "crm";
									if (prev === "crm") return "internal";
									return "both";
								})
							}
							style={{ cursor: "pointer" }}
						>
							{source === "both"
								? "All"
								: source === "crm"
									? "CRM"
									: "Internal"}
						</Badge>
					</Flex>
					<Button
						variant="ghost"
						size="2"
						onClick={() => setIsCreateDialogOpen(true)}
						disabled={source === "crm"}
					>
						<Icon.Plus size="16" />
						Create Note
					</Button>
				</Flex>
			</ContentHeader>
			<ContentContainer>
				<InfiniteScroll
					data={notes}
					isLoading={isLoading || isFetching}
					error={null}
					hasMore={hasMore ?? false}
					onLoadMore={handleLoadMore}
					showNoMoreItems={false}
					style={{
						width: "100%",
						gap: "10px",
						overflow: "hidden",
						height: "100%",
					}}
					renderItems={(items) =>
						items.map((note) => (
							<NoteCard
								key={(note as Note)?.note_id ?? (note as NoteResponse)?.id}
								note={note}
								onDelete={async () => {
									if (!note || isCRMNote(note)) return;
									try {
										await deleteNote(note.id);
									} catch (error) {
										console.error("Failed to delete note:", error);
									}
								}}
								onEdit={() => {
									setSelectedNote(note);
									setIsCreateDialogOpen(true);
								}}
							/>
						))
					}
				/>
			</ContentContainer>

			<CreateNoteDialog
				open={isCreateDialogOpen}
				onOpenChange={(open) => {
					setIsCreateDialogOpen(open);
					if (!open) setSelectedNote(undefined);
				}}
				personId={id}
				initialData={selectedNote as Note | NoteResponse}
			/>
		</ContentWrapper>
	);
};
