import {
	Meeting,
	useAgentSearch,
	useMeetingsListBidirectional,
	useSearchSelection,
	useGetMeetingsMetastoreIds,
	useFavorites,
	User,
	MeetingCategoryType,
	MeetingDepartmentType,
	Analytics,
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
	useGetTeamMembersQuery,
	TeamMember,
	MeetingInternalExternal,
} from "@mightybot/core";
import React, {
	useRef,
	useState,
	useCallback,
	useMemo,
	useEffect,
} from "react";
import {
	Flex,
	Text,
	Icon,
	Checkbox,
	breakpoints,
	useMediaQuery,
} from "@mightybot/web-ui";
import { InfiniteScroll } from "./InfiniteScroll";
import {
	TableHeaderContainer,
	ActionButton,
	CategoryOption,
	TableContainer,
	ResponsiveContainer,
	TableScrollContainer,
	TableContent,
} from "./styled";
import GenericSearchBar from "../Search/GenericSearchBar";
import { MeetingsListFilters } from "./MeetingsListFilters";
import { JoinMeetingDialog } from "../JoinMeetingDialog/JoinMeetingDialog";
import { MeetingsListTable } from "./MeetingsListTable";
import { UUID } from "crypto";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../../utils/helpers";

type BulkActionsProps = {
	selectedCount: number;
	onRemove: () => void;
	onStar: () => void;
	onAddTag: () => void;
	onChatWithMeetings: () => void;
	onReset: () => void;
};

const TableHeader = ({
	onSelectAll,
	areAllSelected,
}: {
	onSelectAll: (checked: boolean) => void;
	areAllSelected: boolean;
}) => {
	return (
		<TableHeaderContainer
			p="8px 16px"
			align="center"
			width="100%"
			id="meetings-list-table-header"
			gap="10px"
		>
			<Flex>
				<Checkbox
					checked={areAllSelected}
					onCheckedChange={(checked) => onSelectAll(checked as boolean)}
				/>
			</Flex>
			<Flex style={{ flex: 0.2, fontSize: "13px" }}>When</Flex>
			<Flex style={{ flex: 0.6, fontSize: "13px" }}>Meeting Name</Flex>
			<Flex style={{ flex: 0.3, fontSize: "13px" }}>Category</Flex>
			<Flex style={{ flex: 0.4, fontSize: "13px" }}>Type & Tags</Flex>
			<Flex style={{ flex: 0.4, fontSize: "13px" }}>
				<Text style={{ marginLeft: "-5px" }}>Participants</Text>
			</Flex>
			{/* <Flex style={{ flex: 0.8 }}>
				<Text weight="light" style={{ fontSize: "13px" }}>
					MightyBot Joins
				</Text>
				<Tooltip content="Meeting organizer can turn off MightyBot for everyone.">
					<Icon.Info size={16} />
				</Tooltip>
			</Flex> */}
		</TableHeaderContainer>
	);
};

const BulkActions = ({
	selectedCount,
	onRemove,
	onStar,
	onAddTag,
	onChatWithMeetings,
	onReset,
}: BulkActionsProps) => (
	<Flex align="center" gap="16px">
		<Text size="3" weight="bold">
			{selectedCount} Selected
		</Text>
		<ActionButton onClick={onReset}>
			<Icon.X size={16} />
			<Text className="button-text">Reset</Text>
		</ActionButton>
		<ActionButton onClick={onStar}>
			<Icon.Star size={16} />
			<Text className="button-text">Star</Text>
		</ActionButton>
		<ActionButton onClick={onChatWithMeetings}>
			<Icon.ChatTeardropText size={16} />
			<Text className="button-text">Chat</Text>
		</ActionButton>
	</Flex>
);

type MeetingTypeOptions = "Internal" | "External" | "All";

type MeetingsListProps = {
	preFilteredPeople?: string[];
	preFilteredType?: MeetingInternalExternal;
	title?: string;
	teamMembers?: string[];
};

type TeamData = {
	teams: Array<{
		id: string;
		name: string;
	}>;
};

const MeetingsList = ({
	preFilteredPeople,
	preFilteredType,
	title = "Meetings",
	teamMembers = [],
}: MeetingsListProps) => {
	const navigate = useNavigate();
	const isTeamView = window.location.pathname.includes("/team/");

	const { data: teamData } = useGetTeamMembersQuery();
	const [selectedTeam, setSelectedTeam] = React.useState<string | null>(null);
	const [selectedPeople, setSelectedPeople] = useState<Partial<User>[]>([]);
	const [selectedTeamEmails, setSelectedTeamEmails] = useState<string[]>([]);
	const [selectedCategories, setSelectedCategories] = useState<
		MeetingCategoryType[]
	>([]);
	const [selectedTags, setSelectedTags] = useState<MeetingDepartmentType[]>([]);
	const [selectedMeetingType, setSelectedMeetingType] =
		useState<MeetingTypeOptions>(isTeamView ? "External" : "All");
	const [dateFilter, setDateFilter] = useState<{ from?: Date; to?: Date }>({});
	const [searchQuery, setSearchQuery] = useState("");

	const { searchCounts } = useAgentSearch();
	const {
		data: meetings,
		isLoading,
		error,
		hasNextPage,
		hasPreviousPage,
		fetchNextPage,
		fetchPreviousPage,
		refetch,
	} = useMeetingsListBidirectional({
		initialCursor: new Date().toISOString(),
		perPage: 50,
		people:
			preFilteredPeople ||
			(selectedPeople.length > 0
				? (selectedPeople
						.map((person) => person.email)
						.filter(Boolean) as string[])
				: []),
		meetingCategory: selectedCategories,
		meetingDepartment: selectedTags,
		meetingInternalExternal:
			preFilteredType ||
			(selectedMeetingType === "All"
				? undefined
				: selectedMeetingType === "Internal"
					? "Internal"
					: "External"),
		dateRange: dateFilter.from && dateFilter.to ? dateFilter : undefined,
		searchQuery: searchQuery,
		team_members: isTeamView
			? selectedTeamEmails.length > 0
				? selectedTeamEmails
				: teamData?.members?.map((member) => member.email) || []
			: selectedTeamEmails,
	});
	const meetingsCount = searchCounts?.counts?.["Meetings"] ?? 0;
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const [isStarred, setIsStarred] = useState(false);
	const todayGroupRef = useRef<HTMLDivElement>(null);
	const [selectedMeetings, setSelectedMeetings] = useState<Set<string>>(
		new Set(),
	);
	const { setInitialSelectedResults } = useSearchSelection();
	const { getMeetingsMetastoreIds } = useGetMeetingsMetastoreIds();
	const { favorites, isFavoritesLoading, toggleFavorite, toggleBulkFavorites } =
		useFavorites("MEETING");

	const filterDependencies = useMemo(
		() => ({
			people: selectedPeople,
			teamEmails: selectedTeamEmails,
			categories: selectedCategories,
			tags: selectedTags,
			meetingType: selectedMeetingType,
			dateRange: dateFilter,
		}),
		[
			selectedPeople,
			selectedTeamEmails,
			selectedCategories,
			selectedTags,
			selectedMeetingType,
			dateFilter,
		],
	);

	useEffect(() => {
		refetch();
	}, [refetch, filterDependencies, isStarred]);

	useEffect(() => {
		Analytics.trackEvent(ANALYTICS_EVENTS.MEETINGS_LIST_VIEWED, {
			[ANALYTICS_PROPERTIES.MEETINGS_COUNT]: meetings?.length || 0,
		});
	}, [meetings?.length]);

	const handleDateChange = useCallback((range: { from?: Date; to?: Date }) => {
		setDateFilter(range);
	}, []);

	const scrollToToday = () => {
		const today = new Date().toDateString();
		const yesterday = new Date();
		yesterday.setDate(yesterday.getDate() - 1);

		// Try to find and scroll to today's group
		const todayElement = document.getElementById(`group-Today`);
		const yesterdayElement = document.getElementById(`group-Yesterday`);

		if (todayElement) {
			todayElement.scrollIntoView({ behavior: "smooth" });
		} else if (yesterdayElement) {
			yesterdayElement.scrollIntoView({ behavior: "smooth" });
		}
	};

	const meetingTypeOptions: MeetingTypeOptions[] = useMemo( () => {
		if ( isTeamView ) {
			return [ "External" ];
		}
		return [ "All", "Internal", "External" ];
	}, [ isTeamView ] );

	// Filter meetings based on isStarred
	const filteredMeetings = useMemo(() => {
		if (!meetings) return [];

		// When starred is selected, only show starred meetings
		if (isStarred) {
			return meetings.filter((meeting) => favorites.includes(meeting.id));
		}

		// Otherwise return all meetings that match other filters
		return meetings;
	}, [meetings, isStarred, favorites]);

	// Update handleSelectAll to use filteredMeetings
	const handleSelectAll = useCallback(
		(checked: boolean) => {
			Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_BULK_ACTION, {
				[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: checked
					? "select_all"
					: "deselect_all",
				[ANALYTICS_PROPERTIES.SELECTED_COUNT]: checked ? meetings?.length : 0,
			});
			if (checked) {
				const allMeetingIds = new Set(filteredMeetings?.map((m) => m.id));
				setSelectedMeetings(allMeetingIds);
			} else {
				setSelectedMeetings(new Set());
			}
		},
		[filteredMeetings],
	);

	const handleSelectChange = useCallback((id: string, checked: boolean) => {
		if (checked) {
			Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_SELECTED, {
				meeting_id: id,
			});
		}
		setSelectedMeetings((prev) => {
			const newSelected = new Set(prev);
			if (checked) {
				newSelected.add(id);
			} else {
				newSelected.delete(id);
			}
			return newSelected;
		});
	}, []);

	const handleBulkRemove = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_BULK_ACTION, {
			[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: "remove",
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedMeetings.size,
		});
	};

	const handleBulkStar = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_BULK_ACTION, {
			[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: "star",
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedMeetings.size,
		});
		// Implement bulk star logic
		toggleBulkFavorites(Array.from(selectedMeetings));
	};

	const handleBulkAddTag = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_BULK_ACTION, {
			[ANALYTICS_PROPERTIES.BULK_ACTION_TYPE]: "add_tag",
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedMeetings.size,
		});
	};

	const handleChatWithMeetings = async () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.MEETING_CHAT_STARTED, {
			[ANALYTICS_PROPERTIES.SELECTED_COUNT]: selectedMeetings.size,
		});
		const selectedMeetingsList = meetings?.filter((m) =>
			selectedMeetings.has(m.id),
		) as Meeting[];

		const meetingIdsArray = selectedMeetingsList.map((m) => m.id);

		const metastoreIds = await getMeetingsMetastoreIds(meetingIdsArray);

		const selectedResults = selectedMeetingsList.map((m) => ({
			id: metastoreIds.find((id) => id.meeting_id === m.id)
				?.metastore_id as UUID,
			app: "Meetings",
			title: m.title,
			time: m.start_time,
		}));

		// Filter out any results that don't have an id
		const filteredResults = selectedResults.filter((result) => result.id);
		setInitialSelectedResults(filteredResults);
		navigate("/chats");
	};

	const handlePeopleChange = useCallback((people: Partial<User>[]) => {
		setSelectedPeople(people);
	}, []);

	const handleTeamMembersChange = useCallback((emails: string[]) => {
		setSelectedTeamEmails(emails);
	}, []);

	const handleCategoryChange = useCallback(
		(categories: MeetingCategoryType[]) => {
			setSelectedCategories(categories);
		},
		[],
	);

	const handleTypeChange = useCallback((tags: MeetingDepartmentType[]) => {
		setSelectedTags(tags);
	}, []);

	const handleMeetingTypeChange = useCallback((type: MeetingTypeOptions) => {
		setSelectedMeetingType(type);
		// Reset starred filter when changing meeting type
		setIsStarred(false);
	}, []);

	const handleSearch = (query: string) => {
		setSearchQuery(query);
	};

	const handleStarredClick = useCallback(() => {
		const newStarredState = !isStarred;
		setIsStarred(newStarredState);

		// Reset other filters when enabling starred view
		if (newStarredState) {
			setSelectedMeetingType("All");
			setSelectedPeople([]);
			setSelectedCategories([]);
			setSelectedTags([]);
			setDateFilter({});
			setSearchQuery("");
		}
	}, [isStarred]);

	return (
		<ResponsiveContainer direction="column">
			<GenericSearchBar
				placeholder="Search meetings..."
				onSearch={handleSearch}
				onSearchQueryChange={handleSearch}
				showPastSearches={false}
			/>

			<Flex
				justify="between"
				align="center"
				width="100%"
				id="meetings-list-filters"
				style={{ flexWrap: "wrap", gap: "8px" }}
			>
				<MeetingsListFilters
					isTeamView={isTeamView}
					onDateChange={handleDateChange}
					selectedPeople={selectedPeople}
					selectedTeamEmails={selectedTeamEmails}
					selectedCategories={selectedCategories}
					selectedTags={selectedTags}
					onPeopleChange={handlePeopleChange}
					onTeamMembersChange={handleTeamMembersChange}
					onCategoryChange={handleCategoryChange}
					onTypeChange={handleTypeChange}
					refetch={refetch}
				/>
				<JoinMeetingDialog
					isSidebarOpen={true}
					radius="medium"
					triggerStyles={{ height: "34px" }}
				/>
			</Flex>
			<Flex
				align="center"
				gap="8px"
				id="meetings-list-actions"
				style={{ flexWrap: "wrap" }}
			>
				{selectedMeetings.size > 0 ? (
					<BulkActions
						selectedCount={selectedMeetings.size}
						onRemove={handleBulkRemove}
						onStar={handleBulkStar}
						onAddTag={handleBulkAddTag}
						onChatWithMeetings={handleChatWithMeetings}
						onReset={() => setSelectedMeetings(new Set())}
					/>
				) : (
					<>
						<Text size="3" weight="bold">
							Meetings
						</Text>
						<Text size="1" weight="light">
							{meetingsCount ?? 0}
						</Text>
						<Flex align="center" style={{ gap: isTablet ? "8px" : "16px" }}>
							{/* Today scroll button */}
							<ActionButton onClick={scrollToToday}>
								<Icon.CalendarBlank size={16} />
								<Text>Today</Text>
							</ActionButton>

							{
								<Flex
									align="center"
									style={{
										borderRadius: "var(--radius-pill)",
										border: "1px solid var(--mb-gray-5)",
										height: "28px",
									}}
								>
									{meetingTypeOptions.map((meetingType) => (
										<CategoryOption
											key={meetingType}
											active={
												selectedMeetingType === meetingType ? "true" : "false"
											}
											onClick={() => handleMeetingTypeChange(meetingType)}
										>
											{capitalize(meetingType)}
										</CategoryOption>
									))}
								</Flex>
							}

							{/* Starred meetings toggle */}
							<ActionButton
								data-active={isStarred}
								onClick={() => setIsStarred(!isStarred)}
							>
								<Icon.Star size={16} weight={isStarred ? "fill" : "regular"} />
								<Text>Starred</Text>
							</ActionButton>
						</Flex>
					</>
				)}
			</Flex>

			<TableContainer direction="column" id="meetings-list-table">
				<TableScrollContainer>
					<TableContent>
						<TableHeader
							onSelectAll={handleSelectAll}
							areAllSelected={
								selectedMeetings.size === filteredMeetings?.length &&
								filteredMeetings?.length > 0
							}
						/>
						<InfiniteScroll
							data={filteredMeetings}
							isLoading={isLoading || isFavoritesLoading}
							error={error}
							hasNextPage={hasNextPage}
							hasPreviousPage={hasPreviousPage}
							onFetchNextPage={fetchNextPage}
							onFetchPreviousPage={fetchPreviousPage}
							renderItems={(meetings: Meeting[] = []) => (
								<MeetingsListTable
									meetings={meetings}
									selectedMeetings={selectedMeetings}
									onSelectChange={handleSelectChange}
								/>
							)}
							enablePastScroll={true}
							initialPosition="group-Today"
							showNoMoreItems={false}
						/>
					</TableContent>
				</TableScrollContainer>
			</TableContainer>
		</ResponsiveContainer>
	);
};

export default React.memo(MeetingsList);
