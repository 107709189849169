import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ACCESS_TOKEN_TYPE, PosthogUtil, useLogin } from "@mightybot/core";
import {
	Analytics,
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
} from "@mightybot/core";
import Loading from "../Loading";

const AuthCallback = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [login] = useLogin();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isExistingUser, setIsExistingUser] = useState(false);
	const isSignupFlow = location.pathname.includes("/signup");

	const handleOAuthResponse = async () => {
		const hash = window.location.hash.substring(1);
		const params = new URLSearchParams(hash);
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get("code");
		const accessToken = params.get("access_token");
		const workspaceDomain = window.location.hostname;

		if (code) {
			window.parent.postMessage(
				{ type: "GOOGLE_AUTH_RESPONSE", response: { code } },
				"*",
			);
		}

		if (accessToken) {
			try {
				const loginResponse = await login({
					token: accessToken,
					token_type: ACCESS_TOKEN_TYPE,
					workspace_domain: workspaceDomain,
				}).unwrap();

				Analytics.trackEvent(ANALYTICS_EVENTS.USER_LOGGED_IN, {
					[ANALYTICS_PROPERTIES.SOURCE]: "oauth_callback",
					[ANALYTICS_PROPERTIES.WORKSPACE]: workspaceDomain,
					is_existing_user: loginResponse.data.is_existing_user,
				});
				window.dispatchEvent(
					new MessageEvent("message", {
						data: { type: "initiate_fcm_token" },
					}),
				);
				setIsLoggedIn(true);
				setIsExistingUser(loginResponse.data.is_existing_user);
			} catch (error: any) {
				Analytics.trackEvent(ANALYTICS_EVENTS.ERROR_OCCURRED, {
					[ANALYTICS_PROPERTIES.ERROR_TYPE]: "oauth_callback_error",
					[ANALYTICS_PROPERTIES.ERROR_MESSAGE]: error?.message,
					[ANALYTICS_PROPERTIES.SOURCE]: "oauth_callback",
				});

				// Check for 403 Forbidden error
				if (error?.status === 403 || error.data) {
					navigate("/signup", {
						replace: true,
						state: { error: "Access Denied" },
					});
					window.location.hash = "access-denied";
					return;
				}

				console.error("Login error:", error);
				navigate(isSignupFlow ? "/signup" : "/login", {
					state: { error: "Failed to login with Google" },
				});
			}
		} else {
			navigate(isSignupFlow ? "/signup" : "/login", {
				state: { error: "No access token received from Google" },
			});
		}
	};

	useEffect(() => {
		handleOAuthResponse();
	}, [login, navigate]);

	useEffect(() => {
		if (isLoggedIn) {
			const intendedDestination = localStorage.getItem("intendedDestination");
			if (intendedDestination) {
				localStorage.removeItem("intendedDestination");
				navigate(intendedDestination);
			} else {
				const isSignupFlow = localStorage.getItem("isSignupFlow");
				localStorage.removeItem("isSignupFlow");
				const navigateTo =
					!isExistingUser && isSignupFlow ? "/onboarding" : "/home";
				navigate(navigateTo);
			}
		}
	}, [isLoggedIn, isExistingUser, navigate]);

	return <Loading />;
};

export default AuthCallback;
