import styled from "styled-components";
import { Button, Flex, mediaQueries } from "@mightybot/web-ui";

export const Container = styled(Flex)`
	width: 100%;
	height: 100vh;
	background: white;
	border-right: 1px #e0e0e0 solid;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	display: inline-flex;
`;

export const Header = styled(Flex)`
	position: relative;
	height: 54px;
	padding: 10px;
	background-color: var(--gray-3);
	z-index: 1;
	align-items: center;
	justify-content: space-between;
`;

export const BackButton = styled(Button)`
	height: 34px;
	padding: 0 14px;
	background: var(--mb-blue-1);
	gap: 6px;
	color: var(--gray-11);
	&:hover {
		background: var(--mb-blue-2);
	}
`;

export const ProfileSection = styled(Flex)`
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: white;
	border-radius: 12px;
	padding: 0px 16px;
	z-index: 2;
	background: transparent;
	gap: 6px;
`;

export const Avatar = styled(Flex)`
	width: 80px;
	height: 80px;
	border-radius: 9999px;
	overflow: hidden;
	justify-content: center;
	align-items: center;
`;

export const ProfileInfo = styled(Flex)`
	align-self: stretch;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 6px;
	margin-top: -40px;
	width: 100%;
`;

export const Name = styled(Flex)`
	justify-content: center;
	align-items: center;
	gap: 6px;
	width: 100%;
`;

export const BackIcon = styled(Flex)`
	width: 20px;
	height: 20px;
	position: relative;
`;

export const BackIconInner = styled.div`
	width: 15px;
	height: 12.5px;
	left: 2.5px;
	top: 3.75px;
	position: absolute;
	background: #333333;
`;

export const ContentContainer = styled(Flex)`
	width: 100%;
	height: 100%;
	flex-direction: row;
	${mediaQueries.tablet} {
		flex-direction: column;
	}
`;

export const LeftSection = styled(Flex)`
	width: 100%;
	max-width: 480px;
	height: 100%;
	border-right: 1px solid var(--gray-5);
	flex-direction: column;
	${mediaQueries.tablet} {
		max-width: 100%;
		border-right: none;
	}
`;

export const RightSection = styled(Flex)`
	flex: 1;
	height: 100%;
	flex-direction: column;
	background: var(--gray-1);
`;

export const TabContainer = styled(Flex)`
	padding: 0 24px;
	height: 54px;
	align-items: center;
	background: white;
	border-bottom: 1px solid var(--gray-5);
`;

export const TabsRoot = styled(Flex)`
	width: 100%;
	height: 100%;
	flex-direction: column;
`;

export const TabsContainer = styled(Flex)`
	width: 100%;
	height: 100%;
	flex-direction: column;
	gap: 24px;
`;

export const TabContentContainer = styled(Flex)`
	width: 100%;
	height: 100vh;
	flex-direction: column;
	background: white;
	border: 1px solid var(--gray-5);
`;

export const ContactInfo = styled(Flex)`
	width: 100%;
	padding: 0px 16px;
	gap: 16px;
	justify-content: space-evenly;
	align-items: center;
`;

export const ContactItem = styled(Flex)`
	align-items: center;
	gap: 5px;
`;

export const IconWrapper = styled(Flex)`
	width: 16px;
	height: 16px;
	align-items: center;
	justify-content: center;
	color: var(--gray-9);
`;

export const TagsSection = styled(Flex)`
	width: 100%;
	padding: 0px 16px 10px 16px;
	gap: 5px;
	justify-content: center;
	align-items: center;
`;

export const Tag = styled(Flex)`
	padding: 4px 12px;
	background: var(--gray-3);
	border-radius: 16px;
	align-items: center;
	justify-content: center;
`;

export const Section = styled(Flex)`
	width: 100%;
	padding: 16px;
	flex-direction: column;
	gap: 16px;
	border-bottom: 1px solid var(--gray-5);
`;

export const SectionTitle = styled(Flex)`
	width: 100%;
	height: 32px;
	align-items: center;
`;

export const ContactLinks = styled(Flex)`
	width: 100%;
	height: 32px;
	align-items: center;
	border-bottom: 1px solid var(--gray-5);
	border-top: 1px solid var(--gray-5);
	height: 49px;
`;

export const ContactLink = styled(Flex)<{ last: boolean }>`
	align-items: center;
	display: flex;
	gap: 5px;
	border-right: ${({ last }) => (last ? "none" : "1px solid var(--gray-5)")};
	height: 100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const Highlight = styled(Flex)`
	flex-direction: column;
	gap: 6px;
	width: 100%;
`;
