import React, { useEffect, useState } from "react";
import { MeetingChatReplyContainer, CopyIcon } from "./styled";
import { CustomReactMarkdown, Flex, Icon, Tooltip } from "@mightybot/web-ui";

type MeetingChatReplyProps = {
	reply: string;
	isStreaming?: boolean;
};

const MeetingChatReply: React.FC<MeetingChatReplyProps> = ({
	reply,
	isStreaming,
}) => {
	const [isCopied, setIsCopied] = useState(false);

	const displayReply = isStreaming ? `${reply}•` : reply;

	const handleCopy = () => {
		navigator.clipboard.writeText(reply);
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 2000);
	};

	return (
		<MeetingChatReplyContainer>
			<Flex direction="column" width="100%">
				<CustomReactMarkdown>{displayReply}</CustomReactMarkdown>
				<Flex align="center" justify="between">
					<Flex justify="center" align="center" onClick={handleCopy}>
						<Tooltip content="Copy" side="top">
							{isCopied ? <Icon.Check /> : <CopyIcon />}
						</Tooltip>
						<span style={{ marginLeft: 4 }}>Copy text</span>
					</Flex>
					<Flex>
						<Icon.ThumbsUp style={{ cursor: "pointer", marginRight: "10px" }} />
						<Icon.ThumbsDown
							style={{ cursor: "pointer", marginRight: "2px" }}
						/>
					</Flex>
				</Flex>
			</Flex>
		</MeetingChatReplyContainer>
	);
};

export default MeetingChatReply;
