import React, { useState } from "react";
import {
	PaymentElement,
	useStripe,
	useElements,
	AddressElement,
} from "@stripe/react-stripe-js";
import { Text, Flex } from "@mightybot/web-ui";
import { useSubscribeMutation } from "@mightybot/core";
import { useNavigate } from "react-router-dom";
import { SubmitButton } from "./styled";
import { ERROR_MESSAGES } from "./errors";
import Toast from "../../../components/Toast";

interface PaymentFormProps {
	planId: string;
	billingCycle: "monthly" | "yearly";
	amount: number;
	onSuccess: () => void;
	onClose: () => void;
	onFailure: () => void;
	clientSecret: string;
	seats: number;
}

export const PaymentForm = ({
	planId,
	billingCycle,
	amount,
	onSuccess,
	onClose,
	onFailure,
	clientSecret,
	seats,
}: PaymentFormProps) => {
	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate();
	const [error, setError] = useState<string>();
	const [success, setSuccess] = useState<string>();
	const [loading, setLoading] = useState(false);
	const [isToastOpen, setIsToastOpen] = useState(false);
	const [isAddressComplete, setIsAddressComplete] = useState(false);
	const [isPaymentComplete, setIsPaymentComplete] = useState(false);
	const [addressFieldErrors, setAddressFieldErrors] = useState<{
		[key: string]: string;
	}>({});
	const [addressStarted, setAddressStarted] = useState(false);

	const [subscribe] = useSubscribeMutation();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setError(undefined);
		setSuccess(undefined);
		setIsToastOpen(false);

		if (!stripe || !elements) {
			setError("Payment processing not available");
			setIsToastOpen(true);
			return;
		}

		if (!isAddressComplete || !isPaymentComplete) {
			const errorFields = Object.values(addressFieldErrors).filter(Boolean);
			if (errorFields.length > 0) {
				setError(`Missing required fields: ${errorFields.join(", ")}`);
			} else {
				setError("Please complete all required fields");
			}
			setIsToastOpen(true);
			return;
		}

		setLoading(true);

		try {
			// 1. Submit the Payment Element before confirming setup
			const { error: submitError } = await elements.submit();
			if (submitError) {
				handlePaymentError(submitError.code || "unknown_error");
				onFailure();
				return;
			}

			// 2. Confirm Setup Intent with Payment Element
			const { setupIntent, error: setupError } = await stripe.confirmSetup({
				elements,
				clientSecret,
				confirmParams: {
					return_url: window.location.href,
				},
				redirect: "if_required",
			});

			if (setupError) {
				handlePaymentError(setupError.code || "unknown_error");
				onFailure();
				navigate(
					`/settings/billing/payment-status?status=failed&amount=${amount * 100}&currency=USD`,
				);
				return;
			}

			if (setupIntent?.status === "succeeded") {
				// 3. Create Subscription with seats
				try {
					await subscribe({
						plan_id: planId,
						payment_method_id: setupIntent.payment_method as string,
						billing_cycle: billingCycle,
						seats: seats,
					}).unwrap();

					setSuccess("Payment successful! Your subscription is now active.");
					setTimeout(() => {
						onSuccess();
						navigate("/settings/billing/payment-status");
					}, 3000);
				} catch (subscribeError: any) {
					const errorMessage =
						subscribeError?.data?.message || ERROR_MESSAGES.default;
					setError(errorMessage);
					setIsToastOpen(true);
					navigate(
						`/settings/billing/payment-status?status=failed&amount=${amount * 100}&currency=USD`,
					);
					onFailure();
					return;
				}
			} else {
				// Handle non-successful setup intent
				const errorMessage = "Payment setup was not completed successfully.";
				setError(errorMessage);
				setIsToastOpen(true);
				navigate(
					`/settings/billing/payment-status?status=failed&amount=${amount * 100}&currency=USD`,
				);
				onFailure();
			}
		} catch (err: any) {
			console.error("Payment process failed:", err);
			const errorMessage = err?.data?.message || ERROR_MESSAGES.default;
			setError(errorMessage);
			setIsToastOpen(true);
			navigate(
				`/settings/billing/payment-status?status=failed&amount=${amount * 100}&currency=USD`,
			);
		} finally {
			setLoading(false);
		}
	};

	const handlePaymentError = (code: string) => {
		const errorMessage =
			ERROR_MESSAGES[code as keyof typeof ERROR_MESSAGES] ||
			ERROR_MESSAGES.default;
		setError(errorMessage);
		setIsToastOpen(true);
		onFailure();
	};

	return (
		<form onSubmit={handleSubmit}>
			<Flex direction="column" gap="4">
				{success && <Text color="green">{success}</Text>}

				<div>
					<Text size="2" weight="medium" mb="2">
						Contact & Billing Information
					</Text>
					<div
						style={{
							padding: "16px",
							border: `1px solid ${addressStarted && Object.keys(addressFieldErrors).length > 0 ? "var(--mb-red-6)" : "var(--mb-gray-4)"}`,
							borderRadius: "8px",
							background: "white",
							marginBottom: "16px",
						}}
					>
						<AddressElement
							options={{
								mode: "billing",
								fields: {
									phone: "never",
								},
							}}
							onChange={(event) => {
								// Only set addressStarted if they've entered something in any field
								if (
									!addressStarted &&
									(event.value.name ||
										event.value.address?.line1 ||
										event.value.address?.city ||
										event.value.address?.state ||
										event.value.address?.postal_code ||
										event.value.address?.country)
								) {
									setAddressStarted(true);
								}

								setIsAddressComplete(event.complete);

								const newErrors: { [key: string]: string } = {};

								if (addressStarted) {
									if (!event.value.name) {
										newErrors.name = "Full name";
									}
									if (!event.value.address?.line1) {
										newErrors.line1 = "Street address";
									}
									if (!event.value.address?.city) {
										newErrors.city = "City";
									}
									if (!event.value.address?.state) {
										newErrors.state = "State";
									}
									if (!event.value.address?.postal_code) {
										newErrors.postal_code = "Postal code";
									}
									if (!event.value.address?.country) {
										newErrors.country = "Country";
									}
								}

								setAddressFieldErrors(newErrors);

								if (event.complete) {
									setError(undefined);
									setIsToastOpen(false);
									setAddressFieldErrors({});
								}
							}}
						/>
						{addressStarted && Object.keys(addressFieldErrors).length > 0 && (
							<Text size="1" color="red" mt="2">
								Please fill in: {Object.values(addressFieldErrors).join(", ")}
							</Text>
						)}
					</div>
				</div>

				<div>
					<Text size="2" weight="medium" mb="2">
						Card information
					</Text>
					<div
						style={{
							padding: "16px",
							border: `1px solid ${!isPaymentComplete && error ? "var(--mb-red-6)" : "var(--mb-gray-4)"}`,
							borderRadius: "8px",
							background: "white",
							marginBottom: "16px",
						}}
					>
						<PaymentElement
							options={{
								layout: "tabs",
							}}
							onChange={(event) => {
								setIsPaymentComplete(event.complete);
								if (event.complete) {
									setError(undefined);
									setIsToastOpen(false);
								}
							}}
						/>
					</div>
				</div>

				<SubmitButton
					type="submit"
					disabled={
						!stripe || loading || !isAddressComplete || !isPaymentComplete
					}
				>
					{loading ? "Processing..." : `Pay $${amount * seats}/${billingCycle}`}
				</SubmitButton>

				{error && (
					<>
						<Text color="red" size="2" mb="2" style={{ textAlign: "center" }}>
							{error}
						</Text>
						<Toast
							open={isToastOpen}
							setOpen={setIsToastOpen}
							title="Error"
							description={error}
						/>
					</>
				)}
			</Flex>
		</form>
	);
};
