import React, { useState } from "react";
import {
	Checkbox,
	Text,
	Icon,
	Flex,
	Badge,
	Button,
	IconButton,
	Tooltip,
	Skeleton,
	Popover,
	useMediaQuery,
	breakpoints,
	AlertDialog,
} from "@mightybot/web-ui";
import ShareClip from "../ShareClip/ShareClip";
import {
	Container,
	Header,
	ClipRow,
	EmptyState,
	HeaderContainer,
} from "./styled";
import { Snippet, useSnippets } from "@mightybot/core";
import EditSnippetDialog from "../EditSnippetDialog/EditSnippetDialog";
import { useParams } from "react-router-dom";

interface SharedClipsProps {}

const getStatusConfig = (status: string) => {
	switch (status.toLowerCase()) {
		case "completed":
			return {
				color: "green" as const,
				label: "Completed",
			};
		case "processing":
			return {
				color: "yellow" as const,
				label: "Processing",
			};
		case "pending":
			return {
				color: "yellow" as const,
				label: "Pending",
			};
		case "failed":
			return {
				color: "red" as const,
				label: "Failed",
			};
		default:
			return {
				color: "gray" as const,
				label: status,
			};
	}
};

interface ClipActionsProps {
	snippet: Snippet;
	setEditingSnippet: (snippet: Snippet) => void;
	onDeleteClick: (snippet: Snippet) => void;
	handleCopyUrl: (snippetId: string) => void;
	copiedId: string | null;
}

const ClipActions = ({
	snippet,
	setEditingSnippet,
	onDeleteClick,
	handleCopyUrl,
	copiedId,
}: ClipActionsProps) => {
	return (
		<Flex gap="2" align="center" className="flex-shrink-0">
			<Popover.Root>
				<Popover.Trigger>
					<IconButton variant="ghost" radius="full" size="1" color="gray">
						<Icon.DotsThree className="cursor-pointer" />
					</IconButton>
				</Popover.Trigger>
				<Popover.Content className="p-0">
					<Flex direction="column" gap="1">
						<Button
							variant="ghost"
							onClick={() => setEditingSnippet(snippet)}
							className="m-1 justify-start"
						>
							<Icon.Pencil className="w-4 h-4 mr-2 cursor-pointer" />
							Edit
						</Button>
						<Button
							variant="ghost"
							color="red"
							onClick={() => onDeleteClick(snippet)}
							className="m-1 justify-start"
						>
							<Icon.Trash className="w-4 h-4 mr-2 cursor-pointer" />
							Delete
						</Button>
					</Flex>
				</Popover.Content>
			</Popover.Root>
			<Tooltip
				content={
					snippet.status.toLowerCase() !== "completed"
						? "Video is still processing"
						: copiedId === snippet.id
							? "Copied!"
							: "Copy URL"
				}
			>
				<Button
					variant="ghost"
					onClick={() => handleCopyUrl(snippet.id)}
					radius="full"
					className="h-[34px] disabled:opacity-50"
					disabled={snippet.status.toLowerCase() !== "completed"}
					style={{
						cursor:
							snippet.status.toLowerCase() === "completed"
								? "pointer"
								: "not-allowed",
					}}
				>
					{copiedId === snippet.id ? (
						<Icon.Check className="w-4 h-4" />
					) : (
						<Icon.LinkSimple className="w-4 h-4" />
					)}
				</Button>
			</Tooltip>
		</Flex>
	);
};

const SharedClips: React.FC<SharedClipsProps> = ({}) => {
	const meetingId = useParams().meetingId;
	const { snippets, isLoadingList, refetchSnippets, deleteSnippet } =
		useSnippets({ sourceCollectionId: meetingId });
	const [selectedClips, setSelectedClips] = useState<Set<string>>(new Set());
	const [copiedId, setCopiedId] = useState<string | null>(null);
	const [editingSnippet, setEditingSnippet] = useState<Snippet | null>(null);
	const [snippetToDelete, setSnippetToDelete] = useState<Snippet | null>(null);
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const handleSelectAll = (checked: boolean) => {
		if (checked) {
			setSelectedClips(new Set(snippets?.map((snippet) => snippet.id) ?? []));
		} else {
			setSelectedClips(new Set());
		}
	};

	const handleSelectClip = (clipId: string, checked: boolean) => {
		const newSelected = new Set(selectedClips);
		if (checked) {
			newSelected.add(clipId);
		} else {
			newSelected.delete(clipId);
		}
		setSelectedClips(newSelected);
	};

	const handleCopyUrl = async (snippetId: string) => {
		try {
			const url = `${window.location.origin}/app/snippets/${snippetId}`;
			await navigator.clipboard.writeText(url);
			setCopiedId(snippetId);
			setTimeout(() => setCopiedId(null), 2000);
		} catch (err) {
			console.error("Failed to copy URL:", err);
		}
	};

	const handleDelete = async (snippetId: string) => {
		try {
			await deleteSnippet(snippetId);
			refetchSnippets();
			setSnippetToDelete(null);
		} catch (error) {
			console.error("Failed to delete snippet:", error);
		}
	};

	const handleShareClipClose = () => {
		refetchSnippets();
	};

	if (isLoadingList) {
		return (
			<Container>
				<Header align="center" justify="between">
					<Flex align="center" gap="4">
						<Skeleton className="h-5 w-5" />
						<Skeleton className="h-5 w-24" />
					</Flex>
					<Skeleton className="h-9 w-36" />
				</Header>

				{[1, 2, 3].map((i) => (
					<ClipRow key={i} align="center" justify="between">
						<Flex align="center" gap="4">
							<Skeleton className="h-5 w-5" />
							<Flex direction="column" style={{ flex: 1 }}>
								<Skeleton className="h-6 w-48 mb-2" />
								<Flex align="center" gap="2" mb="2">
									<Skeleton className="h-4 w-16" />
									<Skeleton className="h-4 w-4" />
									<Skeleton className="h-4 w-32" />
									<Skeleton className="h-4 w-4" />
									<Skeleton className="h-5 w-20" />
								</Flex>
								<Skeleton className="h-4 w-96" />
							</Flex>
						</Flex>
						<Skeleton className="h-[34px] w-[100px]" />
					</ClipRow>
				))}
			</Container>
		);
	}

	if (!snippets?.length) {
		return (
			<Container>
				<EmptyState>
					<Icon.VideoCamera className="w-12 h-12 text-gray-400" />
					<Flex direction="column">
						<Text size="5" weight="medium" className="mb-2">
							No clips created yet
						</Text>
						<Text color="gray" className="mb-6">
							Create your first clip to share with others
						</Text>
						<ShareClip onClose={handleShareClipClose} />
					</Flex>
				</EmptyState>
			</Container>
		);
	}

	return (
		<Container>
			<HeaderContainer>
				<Header align="center" justify="between">
					<Flex align="center" gap="4">
						<Checkbox
							checked={selectedClips.size === snippets.length}
							onCheckedChange={handleSelectAll}
						/>
						<Text size="2" weight="medium">
							{selectedClips.size
								? `${selectedClips.size} selected`
								: `${snippets.length} clips`}
						</Text>
					</Flex>
					<ShareClip onClose={handleShareClipClose} />
				</Header>
			</HeaderContainer>
			{snippets.map((snippet) => (
				<ClipRow
					key={snippet.id}
					align="center"
					justify="between"
					data-processing={snippet.status.toLowerCase() === "pending"}
				>
					<Flex align="center" gap="4" style={{ width: "100%" }}>
						<Checkbox
							checked={selectedClips.has(snippet.id)}
							onCheckedChange={(checked) =>
								handleSelectClip(snippet.id, checked as boolean)
							}
						/>
						<Flex direction="column" style={{ flex: 1 }}>
							<Flex align="center" justify="between" gap="2" className="w-full">
								<Text size="3" weight="medium" className="mb-1">
									{snippet.title}
								</Text>
								{isMobile && (
									<ClipActions
										snippet={snippet}
										setEditingSnippet={setEditingSnippet}
										onDeleteClick={setSnippetToDelete}
										handleCopyUrl={handleCopyUrl}
										copiedId={copiedId}
									/>
								)}
							</Flex>
							<Flex
								align="center"
								gap="2"
								mb="2"
								className="clip-metadata"
								wrap={{ initial: "wrap" }}
							>
								<Icon.Clock className="w-4 h-4 text-gray-400" />
								<Text size="1" className="text-[13px]" color="gray">
									{Math.floor(snippet.container_metadata.duration / 60)}m
								</Text>
								<Text className="color-[var(--mb-color-dark-gray-4)] text-[9px]">
									•
								</Text>
								<Icon.CalendarBlank className="w-4 h-4 text-gray-400" />
								<Text className="text-[13px]" color="gray">
									{new Date(snippet.created_ts).toLocaleString("en-US", {
										month: "short",
										day: "numeric",
										year: "numeric",
										hour: "numeric",
										minute: "2-digit",
										hour12: true,
									})}
								</Text>
								<Text className="color-[var(--mb-color-dark-gray-4)] text-[9px]">
									•
								</Text>
								{snippet.status.toLowerCase() !== "completed" && (
									<Badge variant="surface" radius="full" size="1">
										{getStatusConfig(snippet.status).label}
									</Badge>
								)}
							</Flex>
							<Text
								className="text-[13px]"
								color="gray"
								style={{ wordBreak: "break-word" }}
							>
								{snippet.description}
							</Text>
						</Flex>
					</Flex>
					{!isMobile && (
						<ClipActions
							snippet={snippet}
							setEditingSnippet={setEditingSnippet}
							onDeleteClick={setSnippetToDelete}
							handleCopyUrl={handleCopyUrl}
							copiedId={copiedId}
						/>
					)}
				</ClipRow>
			))}

			{editingSnippet && (
				<EditSnippetDialog
					snippetId={editingSnippet.id}
					initialTitle={editingSnippet.title}
					initialDescription={editingSnippet.description}
					videoUrl={editingSnippet.video_url}
					startTime={editingSnippet.container_metadata.start_time}
					endTime={editingSnippet.container_metadata.end_time}
					transcript={editingSnippet.structured_text}
					open={!!editingSnippet}
					onOpenChange={(open) => !open && setEditingSnippet(null)}
					onSuccess={() => {
						setEditingSnippet(null);
						refetchSnippets();
					}}
				/>
			)}

			<AlertDialog.Root
				open={!!snippetToDelete}
				onOpenChange={(open) => !open && setSnippetToDelete(null)}
			>
				<AlertDialog.Content style={{ maxWidth: 450 }}>
					<AlertDialog.Title>Delete Clip</AlertDialog.Title>
					<AlertDialog.Description size="2">
						Are you sure you want to delete "{snippetToDelete?.title}"? This
						action cannot be undone.
					</AlertDialog.Description>

					<Flex gap="3" mt="4" justify="end">
						<AlertDialog.Cancel>
							<Button variant="soft" color="gray">
								Cancel
							</Button>
						</AlertDialog.Cancel>
						<AlertDialog.Action>
							<Button
								variant="solid"
								color="red"
								onClick={() => handleDelete(snippetToDelete!.id)}
							>
								Delete
							</Button>
						</AlertDialog.Action>
					</Flex>
				</AlertDialog.Content>
			</AlertDialog.Root>
		</Container>
	);
};

export default SharedClips;
