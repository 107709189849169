import { Person, useFavorites, useMe } from "@mightybot/core";
import React, { useCallback } from "react";
import {
	Flex,
	Text,
	Icon,
	Tooltip,
	Checkbox,
	AvatarWrapper,
	Skeleton,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";
import { TableRowContainer, Tag, HideMobile } from "./styled";
import { Link, useNavigate } from "react-router-dom";
import { PeopleTags } from "./PeopleTags";

type PeopleRowProps = {
	person: Person;
	index: number;
	isSelected: boolean;
	onSelectChange: (id: string, checked: boolean) => void;
};

export const PeopleRow = React.memo(
	({ person, index, isSelected, onSelectChange }: PeopleRowProps) => {
		const navigate = useNavigate();
		const { isFavorite, toggleFavorite } = useFavorites("PERSON");
		const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

		const handlePersonClick = useCallback(() => {
			navigate(`/people/${person.id}/details`);
		}, [person.id, navigate]);

		const onStarClick = (e: React.MouseEvent<SVGSVGElement>) => {
			e.stopPropagation();
			toggleFavorite(person.id);
		};

		const personTags = [person.person_type, person.status].filter(
			Boolean,
		) as string[];

		return (
			<TableRowContainer
				p={isTablet ? "8px" : "8px 16px"}
				width="100%"
				gap={isTablet ? "6px" : "10px"}
			>
				<Flex>
					<Checkbox
						checked={isSelected}
						onCheckedChange={(checked) =>
							onSelectChange(person.id, checked as boolean)
						}
					/>
				</Flex>

				<Flex
					gap={isTablet ? "6px" : "10px"}
					align="center"
					style={{
						flex: isTablet ? 1 : 0.4,
						fontSize: isTablet ? "12px" : "13px",
						overflow: "hidden",
						cursor: "pointer",
						minWidth: isTablet ? "auto" : "150px",
					}}
				>
					<Link
						to={`/people/${person.id}/details`}
						style={{ textDecoration: "none" }}
					>
						<AvatarWrapper
							src={person.profile_pic ?? ""}
							alt={person.name ?? ""}
							radius="full"
							variant="solid"
							size={isTablet ? "1" : "2"}
						/>
					</Link>

					<Flex direction="column">
						<Link
							to={`/people/${person.id}/details`}
							style={{ textDecoration: "none" }}
						>
							<Text
								weight="medium"
								style={{
									color: "var(--mb-blue-8)",
									fontSize: "13px",
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									maxWidth: "100%",
								}}
							>
								{person.name}
							</Text>
						</Link>
						<a
							href={`mailto:${person.email}`}
							style={{
								color: "var(--mb-gray-9)",
								textDecoration: "none",
							}}
							className="hover-link"
						>
							<Text
								weight="medium"
								style={{
									color: "var(--mb-gray-9)",
									fontSize: "13px",
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
									maxWidth: "100%",
								}}
							>
								{person.email}
							</Text>
						</a>
					</Flex>

					<Icon.Star
						weight={isFavorite(person.id) ? "fill" : "regular"}
						size={16}
						style={{ cursor: "pointer" }}
						onClick={onStarClick}
						color="var(--mb-gray-7)"
					/>
				</Flex>

				{!isTablet && (
					<Text
						style={{
							flex: 0.3,
							fontSize: "13px",
							color: "var(--mb-gray-9)",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{person.organization}
					</Text>
				)}

				<HideMobile
					gap="5px"
					style={{
						flex: 0.3,
						fontSize: "13px",
						overflow: "hidden",
					}}
				>
					<PeopleTags tags={personTags} />
				</HideMobile>
				<Flex
					style={{
						flex: 0.4,
						fontSize: "13px",
						minWidth: "100px",
					}}
				>
					{person.job_title}
				</Flex>
			</TableRowContainer>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.isSelected === nextProps.isSelected &&
			prevProps.person.id === nextProps.person.id
		);
	},
);

export const PeopleRowSkeleton = () => {
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	return (
		<TableRowContainer p="8px 16px" width="100%" gap="10px">
			<Flex>
				<Skeleton width="20px" height="20px" style={{ borderRadius: "4px" }} />
			</Flex>

			<Flex
				gap="10px"
				align="center"
				style={{
					flex: 0.4,
					fontSize: "13px",
					overflow: "hidden",
					minWidth: "150px",
				}}
			>
				<Skeleton width="32px" height="32px" style={{ borderRadius: "50%" }} />
				<Flex direction="column" gap="4px" style={{ flex: 1 }}>
					<Skeleton width="140px" height="16px" />
					<Skeleton width="180px" height="16px" />
				</Flex>
				{!isTablet && <Skeleton width="16px" height="16px" />}
			</Flex>

			<HideMobile
				style={{
					flex: 0.3,
					fontSize: "13px",
					overflow: "hidden",
				}}
			>
				<Skeleton width="120px" height="16px" />
			</HideMobile>

			<HideMobile
				gap="5px"
				style={{
					flex: 0.3,
					fontSize: "13px",
					overflow: "hidden",
				}}
			>
				<Flex gap="4px">
					<Skeleton
						width="60px"
						height="24px"
						style={{ borderRadius: "12px" }}
					/>
					<Skeleton
						width="80px"
						height="24px"
						style={{ borderRadius: "12px" }}
					/>
				</Flex>
			</HideMobile>

			<Flex
				style={{
					flex: 0.4,
					fontSize: "13px",
					minWidth: "100px",
				}}
			>
				<Skeleton width="140px" height="16px" />
			</Flex>
		</TableRowContainer>
	);
};
