import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Text, Button, Checkbox, Flex } from "@mightybot/web-ui";
import {
	CONNECT_APPS_REMINDER_IGNORED_KEY,
	useIntegrationConnect,
	useIntegrations,
	useUserPreferences,
} from "@mightybot/core";
import * as Styled from "./styled";
import { CRMConnect } from "@mightybot/core-ui";
import {
	ReminderPopup,
	Subtitle,
	Title,
	ButtonsContainer,
	CheckboxContainer,
	IllustrationContainer,
	ModalOverlay,
} from "./styled";

const RECOMMENDED_APPS = ["slack_app", "salesforce_app", "hubspot_app"];
const MAX_REMINDERS = 3;
const REMINDER_INTERVAL_DAYS = 2;

export const ConnectAppsReminder = () => {
	const [showReminder, setShowReminder] = useState(false);
	const pathname = useLocation().pathname;
	const navigate = useNavigate();
	const [dontShowAgain, setDontShowAgain] = useState(false);

	const { data: integrations, isLoading: isLoadingIntegrations } =
		useIntegrations();
	const { dbUserPreferences, isLoadingUserPreferences, updateUserPreferences } =
		useUserPreferences();

	const { connectApp, disconnectApp } = useIntegrationConnect();

	const missingApps =
		integrations?.filter(
			(app) => RECOMMENDED_APPS.includes(app.id) && !app.is_connected,
		) || [];

	useEffect(() => {
		const dontShowFromStorage =
			localStorage.getItem(CONNECT_APPS_REMINDER_IGNORED_KEY) === "true";
		if (dontShowFromStorage) {
			setShowReminder(false);
			return;
		}

		if (
			!isLoadingIntegrations &&
			!isLoadingUserPreferences &&
			missingApps.length > 0 &&
			!pathname.includes("/settings")
		) {
			const reminder = dbUserPreferences?.connect_apps_reminder;
			const reminderCount = reminder?.reminder_count || 0;
			const lastReminderDate = reminder?.last_reminder_date
				? new Date(reminder.last_reminder_date)
				: null;
			const now = new Date();
			const daysSinceLastReminder = lastReminderDate
				? (now.getTime() - lastReminderDate.getTime()) / (1000 * 60 * 60 * 24)
				: Infinity;

			if (
				!reminder?.dismissed &&
				!reminder?.dont_show_again &&
				reminderCount < MAX_REMINDERS &&
				daysSinceLastReminder >= REMINDER_INTERVAL_DAYS
			) {
				setShowReminder(true);
			}
		}
	}, [
		isLoadingIntegrations,
		isLoadingUserPreferences,
		missingApps.length,
		pathname,
		dbUserPreferences,
	]);

	const handleSkip = () => {
		setShowReminder(false);
		const currentReminder = dbUserPreferences?.connect_apps_reminder || {};
		updateUserPreferences({
			connect_apps_reminder: {
				dismissed: false,
				dont_show_again: dontShowAgain,
				reminder_count: (currentReminder?.reminder_count || 0) + 1,
				last_reminder_date: new Date().toISOString(),
			},
		}).catch((error) => {
			console.error("Error updating preferences:", error);
		});

		if (dontShowAgain) {
			localStorage.setItem(CONNECT_APPS_REMINDER_IGNORED_KEY, "true");
		}
	};

	const handleDismiss = () => {
		setShowReminder(false);
		localStorage.setItem(CONNECT_APPS_REMINDER_IGNORED_KEY, "true");
		updateUserPreferences({
			connect_apps_reminder: {
				dismissed: true,
				dont_show_again: true,
				last_reminder_date: new Date().toISOString(),
			},
		}).catch((error) => {
			console.error("Error dismissing reminder:", error);
		});
	};

	const handleDontShowAgainChange = (value: boolean) => {
		setDontShowAgain(value);
		if (value) {
			handleDismiss();
		}
	};

	const handleConnectApps = () => {
		setShowReminder(false);
		navigate("/settings/connect");
	};

	if (
		!showReminder ||
		isLoadingIntegrations ||
		isLoadingUserPreferences ||
		missingApps.length === 0
	) {
		return null;
	}

	return (
		<ModalOverlay>
			<ReminderPopup>
				<Title>Connect your CRM & Slack</Title>
				<Subtitle>
					Automate your daily tasks and intelligently retrieve & utilize CRM
					apps & Slack data.
				</Subtitle>

				<ButtonsContainer>
					<CheckboxContainer>
						<Checkbox
							checked={dontShowAgain}
							onCheckedChange={handleDontShowAgainChange}
							style={{ cursor: "pointer" }}
						/>
						<Text size="1">Don't show this again</Text>
					</CheckboxContainer>

					<Button
						variant="white-bg"
						size="1"
						onClick={handleSkip}
						style={{
							width: "110px",
							height: "34px",
							padding: "0px 13px",
							fontSize: "14px",
							fontWeight: "400",
							lineHeight: "18px",
						}}
					>
						Skip for now
					</Button>

					<Button
						variant="primary"
						size="1"
						onClick={handleConnectApps}
						style={{
							width: "123px",
							height: "34px",
							padding: "0px 14px",
							fontSize: "14px",
							fontWeight: "400",
							lineHeight: "18px",
						}}
					>
						Connect apps
					</Button>
				</ButtonsContainer>

				<IllustrationContainer>
					<img src={CRMConnect} alt="" />
				</IllustrationContainer>
			</ReminderPopup>
		</ModalOverlay>
	);
};
