import React from "react";
import { capitalize } from "lodash";
import { Tooltip, Text } from "@mightybot/web-ui";
import { Tag } from "./styled";

const STATUS_COLORS = {
	WARM: {
		background: "#FCE3C0",
	},
	COLD: {
		background: "#D4E3F2",
	},
} as const;

export const PeopleTags = ({ tags }: { tags: string[] }) => {
	return tags.map((tag) => {
		// Check if the tag is a status that needs special coloring
		const statusColor = STATUS_COLORS[tag as keyof typeof STATUS_COLORS];

		return (
			<Tooltip key={tag} content={tag}>
				<Tag
					style={
						statusColor
							? {
									backgroundColor: statusColor.background,
								}
							: {
									backgroundColor: "var(--mb-blue-3)",
									color: "var(--mb-gray-11)",
								}
					}
				>
					<Text
						style={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
							maxWidth: "100px",
						}}
					>
						{capitalize(tag)}
					</Text>
				</Tag>
			</Tooltip>
		);
	});
};
