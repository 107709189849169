import React from "react";
import {
	Dialog,
	Button,
	Text,
	Flex,
	Icon,
	useMediaQuery,
	breakpoints,
} from "@mightybot/web-ui";
import * as Styled from "./styled";

interface GenerateDocOutputProps {
	documentUrl: string;
	onClose: () => void;
	documentName?: string;
}

const GenerateDocOutput: React.FC<GenerateDocOutputProps> = ({
	documentUrl,
	onClose,
	documentName,
}) => {
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const handleCopyLink = () => {
		navigator.clipboard.writeText(documentUrl);
	};

	const handleOpenDoc = () => {
		window.open(documentUrl, "_blank");
	};

	return (
		<Styled.GenerateDocContainer>
			<Dialog.Close>
				<Styled.CloseButton>
					<Icon.X size={20} />
				</Styled.CloseButton>
			</Dialog.Close>

			<Flex direction="column" gap="4">
				<Flex direction="column" gap="1">
					<Flex align="center" gap="2" wrap={isTablet ? "wrap" : "nowrap"}>
						<Icon.CheckCircle size={isTablet ? 24 : 32} />
						<Text size={isTablet ? "3" : "4"} weight="medium">
							Doc saved successfully
						</Text>
					</Flex>
				</Flex>

				<Flex
					style={{
						padding: isTablet ? "6px" : "8px",
						border: "1px solid var(--mb-color-light-gray-4)",
						borderRadius: "4px",
					}}
				>
					<Text
						size={isTablet ? "1" : "2"}
						style={{
							wordBreak: "break-all",
							cursor: "pointer",
							color: "var(--mb-color-light-blue-10)",
							textDecoration: "underline",
						}}
						onClick={handleOpenDoc}
						role="link"
					>
						{documentName || documentUrl}
					</Text>
				</Flex>

				<Flex gap="2" direction={isTablet ? "column" : "row"}>
					<Button variant="soft" onClick={handleCopyLink} style={{ flex: 1 }}>
						<Icon.Copy size={isTablet ? 16 : 20} />
						Copy Link
					</Button>
					<Button onClick={handleOpenDoc} style={{ flex: 1 }}>
						<Icon.ArrowLineUpRight size={isTablet ? 16 : 20} />
						Open doc
					</Button>
				</Flex>
			</Flex>
		</Styled.GenerateDocContainer>
	);
};

export default GenerateDocOutput;
