import React from "react";
import Layout from "../components/Layout";
import TeamMeetingsList from "../components/TeamMeetingsList/TeamMeetingsList";
import MeetingDetails from "../components/MeetingDetails";
import { useNavigate, useParams } from "react-router-dom";

const TeamMeetingsRoute: React.FC = () => {
	const { view, meetingId } = useParams();
	const navigate = useNavigate();

	if (!view && meetingId) {
		navigate(`/team/meetings/${meetingId}/details`);
	}

	return (
		<Layout>
			{view === "details" || view === "preview" ? (
				<MeetingDetails />
			) : (
				<TeamMeetingsList />
			)}
		</Layout>
	);
};

export default TeamMeetingsRoute;
