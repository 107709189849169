import React from "react";
import {
	Flex,
	Text,
	Icon,
	Skeleton,
	Button,
	IconButton,
} from "@mightybot/web-ui";
import { CompletionHistoryItem, useCompletionsHistory } from "@mightybot/core";
import { useParams, useNavigate } from "react-router-dom";
import { cn } from "../../utils/classNames";
import { format } from "date-fns";
import capitalize from "lodash/capitalize";
import { useMeetingContentNavigation } from "../../contexts/MeetingContentNavigationContext";
import { scrollToElement } from "../../utils/helpers";

type MeetingChatsSidebarProps = {
	toggleSidebar: () => void;
	isSidebarOpen: boolean;
	isMobile: boolean;
};

const MeetingChatsSidebar = ({
	toggleSidebar,
	isSidebarOpen,
	isMobile,
}: MeetingChatsSidebarProps) => {
	const meetingId = useParams().meetingId;
	const {
		completionHistory,
		currentCompletions,
		updateCurrentCompletionsItem,
	} = useCompletionsHistory(meetingId ?? "", "MEETING");
	const navigate = useNavigate();
	const { containerRef } = useMeetingContentNavigation();

	const scrollToChat = (chatId: string) => {
		const chatElement = document.getElementById(`chat-${chatId}`);
		if (containerRef.current && chatElement) {
			scrollToElement(containerRef.current, chatElement);
		}
	};

	const renderChatItems = () => {
		if (!completionHistory) {
			return (
				<div className="flex flex-col gap-[2px]">
					{[...Array(5)].map((_, index) => (
						<div
							key={index}
							className="flex items-center justify-between p-[8px_16px]"
						>
							<Skeleton width="80%" height="20px" />
							<Skeleton width="16px" height="16px" />
						</div>
					))}
				</div>
			);
		}

		// Group chats by date
		const groupedChats = completionHistory.reduce((acc: any, chat) => {
			const date = format(new Date(chat.created_at), "MMM dd, yyyy");
			if (!acc[date]) {
				acc[date] = [];
			}
			acc[date].push(chat);
			return acc;
		}, {});

		return (
			<div
				className={cn(
					"flex gap-[2px] overflow-y-auto h-full",
					isMobile
						? "flex-row pl-5 pr-[14px] py-4"
						: "flex-col pl-5 pr-[14px] py-2",
				)}
			>
				{Object.entries(groupedChats).map(([date, chats]: [string, any]) => (
					<div key={date}>
						<Text size="2" weight="medium" className="text-text-secondary mb-2">
							{date}
						</Text>
						{chats.map((chat: CompletionHistoryItem, index: number) => (
							<Button
								key={chat.id || `chat-${index}`}
								variant="ghost"
								onClick={() => {
									updateCurrentCompletionsItem(chat);
									if (chat.id) {
										scrollToChat(chat.id);
									}
								}}
								className={cn(
									"flex justify-between items-center w-full px-2 rounded my-1 transition-colors bg-white cursor-pointer",
									currentCompletions?.[0]?.id === chat.id
										? "text-light-blue-8"
										: "text-text-primary hover:bg-gray-50",
								)}
							>
								<div className="flex items-center gap-2 flex-shrink-0 min-w-0 flex-1">
									<Icon.ChatCircleDots
										className={cn(
											"w-4 h-4 flex-shrink-0",
											currentCompletions?.[0]?.id === chat.id
												? "text-light-blue-8"
												: "text-text-primary",
										)}
									/>
									<span className="truncate font-medium w-full text-left">
										{capitalize(chat.user_question)}
									</span>
								</div>
								{!isMobile && (
									<div
										className={cn(
											"w-2 h-2 rounded-sm flex-shrink-0 ml-2",
											currentCompletions?.[0]?.id === chat.id
												? "bg-blue-eight"
												: "bg-white",
										)}
									/>
								)}
							</Button>
						))}
					</div>
				))}
			</div>
		);
	};

	if (isMobile) {
		return (
			<>
				<Button
					variant="white-bg"
					onClick={toggleSidebar}
					style={{ borderRadius: 0 }}
					className={cn(
						"w-full flex items-center justify-between p-4",
						isSidebarOpen && "border-b border-gray-200",
						isMobile && "px-[10px]",
					)}
				>
					<Text size="3" weight="medium">
						Chats
					</Text>
					<Icon.CaretDown
						className={cn(
							"transform transition-transform duration-300",
							isSidebarOpen ? "rotate-180" : "rotate-0",
						)}
					/>
				</Button>
				<div
					style={{ background: "white" }}
					className={cn(
						"transition-all duration-300 overflow-hidden",
						isSidebarOpen ? "max-h-screen" : "max-h-0",
					)}
				>
					{renderChatItems()}
				</div>
			</>
		);
	}

	return (
		<div
			className={cn(
				"transition-all duration-300",
				isSidebarOpen ? "w-[270px]" : "w-[0px]",
			)}
		>
			{isSidebarOpen ? (
				<div className="flex flex-col w-full overflow-hidden">
					<div className="flex items-center justify-between py-4 pl-5 pr-[10px] border-b border-solid border-gray-200 border-x-0">
						<span className="text-lg font-medium flex-1">Chats</span>
						<Icon.ArrowLineLeft
							className="cursor-pointer"
							onClick={toggleSidebar}
						/>
					</div>
					<div className="flex flex-col w-full border-b border-solid border-gray-200 border-x-0 border-t-0">
						{renderChatItems()}
					</div>
				</div>
			) : (
				<div className="pt-3 pl-2 absolute top-[150px] z-10">
					<IconButton
						onClick={toggleSidebar}
						className="bg-blue-one rounded-full cursor-pointer"
					>
						<Icon.ChatCircleDots className="h-5 w-5 text-text-primary" />
					</IconButton>
				</div>
			)}
		</div>
	);
};

export default MeetingChatsSidebar;
