import React from "react";
import { MightyBotLogo } from "@mightybot/core-ui";
import { Button, Flex } from "@mightybot/web-ui";

interface MaintenancePageProps {
	title?: string;
	message?: string;
	buttonText?: string;
	redirectPath?: string;
	containerWidth?: string;
	showSupport?: boolean;
}

const MaintenancePage: React.FC<MaintenancePageProps> = ({
	title = "Page Under Maintenance",
	message = "We're working on making this better for you. Please check back later.",
	buttonText = "Go to Homepage",
	redirectPath = "/app/home",
	containerWidth = "60%",
	showSupport = true,
}) => {
	return (
		<Flex
			justify="center"
			align="center"
			style={{ height: "100vh", width: "100%" }}
		>
			<Flex
				justify="center"
				align="center"
				direction="column"
				style={{
					height: "400px",
					border: "1px solid #e0e0e0",
					borderRadius: "16px",
					width: containerWidth,
				}}
			>
				<Flex
					justify="between"
					align="center"
					direction="column"
					style={{ height: "100%" }}
				>
					<Flex
						style={{ padding: "40px", gap: "20px" }}
						direction="column"
						align="center"
						justify="center"
					>
						<img
							src={MightyBotLogo as string}
							alt="Mightybot Logo"
							style={{ width: "40px", height: "40px" }}
						/>
						<p style={{ fontSize: "22px", fontWeight: "500" }}>{title}</p>
						<p style={{ fontSize: "14px", fontWeight: "400" }}>{message}</p>
						<Button
							onClick={() =>
								(window.location.href = `${window.location.origin}${redirectPath}`)
							}
							style={{
								borderRadius: "22px",
								width: "300px",
								height: "44px",
								cursor: "pointer",
							}}
						>
							{buttonText}
						</Button>
						{showSupport && (
							<p style={{ fontSize: "14px", fontWeight: "400" }}>
								If you need immediate assistance, please contact us at{" "}
								<a
									href="mailto:support@mightybot.ai"
									style={{ color: "var(--mb-blue-8)" }}
								>
									support@mightybot.ai
								</a>
							</p>
						)}
					</Flex>
				</Flex>
				<Flex
					align="center"
					justify="center"
					style={{
						backgroundColor: "var(--gray-2)",
						width: "100%",
						borderRadius: "0px 0px 16px 16px",
						color: "var(--gray-9)",
						padding: "20px",
					}}
				>
					<p>© MightyBot. All rights reserved.</p>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default MaintenancePage;
