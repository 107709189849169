import React, { useEffect, useState } from "react";
import { Text, Flex, Skeleton } from "@mightybot/web-ui";
import { ContentHeader, ContentWrapper, ContentContainer } from "./styled";
import FeedCard from "../../Home/FeedCard";
import {
	FeedItem as CoreFeedItem,
	useFeed,
	useStructuredSearch,
	NLSearchResult,
} from "@mightybot/core";
import { InfiniteScroll } from "../../People/InfiniteScroll";
import { ContentCardSkeleton } from "./ContentSkeleton";

export type FeedItem = Omit<
	CoreFeedItem,
	"apps" | "links" | "company_names"
> & {
	app: string;
	link: string;
	company_name: string;
};

type ActivitiesProps = {
	email: string;
};

const convertSearchResultToFeedItem = (result: NLSearchResult): FeedItem => {
	return {
		title: result.title || "",
		description: result.snippet || "",
		timestamp: result.time || new Date().toISOString(),
		app: result.app || "",
		link: result.link || "",
		company_name: "",
	};
};

export const Activities: React.FC<ActivitiesProps> = ({ email }) => {
	const [allActivities, setAllActivities] = useState<
		FeedItem[] | Partial<FeedItem>[]
	>([]);
	const [searchResults, setSearchResults] = useState<FeedItem[]>([]);

	const { data: feedData, isLoading: isFeedLoading } = useFeed({
		page: 1,
		per_page: 200,
		person_email: email,
	});

	const { executeStructuredSearch, isLoading: isSearchLoading } =
		useStructuredSearch();

	// First effect: Handle search data fetching
	useEffect(() => {
		const fetchStructuredData = async () => {
			try {
				const searchResponse = await executeStructuredSearch({
					page: 1,
					per_page: 200,
					search_queries: [
						{
							common_facet_filters: {
								people_emails: [email],
								apps: ["Slack", "Gmail", "Google Calendar", "Meetings"],
								date_range: {
									start_time: new Date(
										Date.now() - 90 * 24 * 60 * 60 * 1000,
									).toISOString(),
									end_time: new Date().toISOString(),
								},
							},
						},
					],
				});

				const searchFeedItems = (searchResponse?.results || []).map(
					convertSearchResultToFeedItem,
				);
				setSearchResults(searchFeedItems);
			} catch (error) {
				console.error("Error fetching structured search data:", error);
			}
		};

		fetchStructuredData();
	}, [email, executeStructuredSearch]);

	// Second effect: Combine feed and search results when either updates
	useEffect(() => {
		const feedItems = feedData?.items || [];

		const combinedActivities = [...feedItems, ...searchResults]
			.filter((item) => item.title || item.description)
			.sort(
				(a, b) =>
					new Date(b.timestamp || "").getTime() -
					new Date(a.timestamp || "").getTime(),
			);

		setAllActivities(combinedActivities);
	}, [feedData, searchResults]);

	const isLoading = isFeedLoading || isSearchLoading;

	if (isLoading && allActivities.length === 0) {
		return (
			<ContentWrapper>
				<ContentHeader>
					<Flex justify="between" align="center" style={{ width: "100%" }}>
						<Skeleton width={"120px"} height={"24px"} />
					</Flex>
				</ContentHeader>
				<ContentContainer>
					<Flex direction="column" gap="3" style={{ width: "100%" }}>
						{[1, 2, 3].map((i) => (
							<ContentCardSkeleton key={i} />
						))}
					</Flex>
				</ContentContainer>
			</ContentWrapper>
		);
	}

	return (
		<ContentWrapper>
			<ContentHeader>
				<Text size="3" weight="medium">
					Activities{" "}
					<Text as="span" style={{ color: "var(--mb-gray-9)" }}>
						({allActivities.length})
					</Text>
				</Text>
			</ContentHeader>
			<ContentContainer>
				<InfiniteScroll
					data={allActivities}
					isLoading={isLoading}
					error={null}
					hasMore={false}
					onLoadMore={async () => {}}
					showNoMoreItems={false}
					renderItems={(items) =>
						items.map((activity, index) => (
							<FeedCard
								key={index}
								feed={activity as FeedItem}
								last={index === items.length - 1}
								onDismiss={() => {}}
								type="activity"
							/>
						))
					}
					style={{
						width: "100%",
						gap: "10px",
						overflow: "hidden",
						height: "100%",
					}}
				/>
			</ContentContainer>
		</ContentWrapper>
	);
};
