import React, { useMemo } from "react";
import { useGetTeamMembersQuery, TeamMember } from "@mightybot/core";
import MeetingsList from "../MeetingsList/MeetingsList";

const TeamMeetingsList = () => {
	const { data: teamData } = useGetTeamMembersQuery();

	const teamEmails = useMemo(
		() => teamData?.members?.map((member) => member.email) || [],
		[teamData],
	);

	return (
		<div>
			<MeetingsList title="Team External Meetings" teamMembers={teamEmails} />
		</div>
	);
};

export default TeamMeetingsList;
