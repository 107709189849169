import React from "react";
import { Dialog, Button, Text, Flex, Icon } from "@mightybot/web-ui";
import { DialogContent } from "./styled";
import { useLocation, useNavigate } from "react-router-dom";

interface UnauthenticatedDialogProps {
	open: boolean;
	onOpenChange: (open: boolean) => void;
	title?: string;
	description?: string;
}

const UnauthenticatedDialog: React.FC<UnauthenticatedDialogProps> = ({
	open,
	onOpenChange,
	title,
	description,
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Dialog.Root open={open} onOpenChange={onOpenChange}>
			<Dialog.Content
				asChild
				style={{
					zIndex: 1000,
					maxWidth: "600px",
					width: "100%",
					backgroundColor: "var(--mb-blue-1)",
				}}
			>
				<DialogContent style={{ gap: "20px" }}>
					<Flex justify="end" align="center" className=" w-full">
						<Dialog.Close>
							<Icon.X className="text-mb-gray-2 cursor-pointer" />
						</Dialog.Close>
					</Flex>
					<Text size="7" weight="medium">
						{title || "Ready to Elevate Your Workflow?"}
					</Text>
					<Text size="3" color="gray">
						{description ||
							"Experience seamless AI automation tailored for you."}
					</Text>
					<Flex
						direction="column"
						gap="2"
						style={{ width: "100%", maxWidth: "300px" }}
					>
						<Button
							variant="white-bg"
							style={{
								height: "44px",
								borderRadius: "var(--radius-pill)",
								width: "100%",
							}}
							onClick={() => {
								localStorage.setItem(
									"intendedDestination",
									location.pathname + location.search,
								);
								navigate("/login");
							}}
						>
							Login
						</Button>
						<Button
							style={{
								height: "44px",
								borderRadius: "var(--radius-pill)",
								width: "100%",
							}}
							onClick={() => {
								localStorage.setItem(
									"intendedDestination",
									window.location.href,
								);
								navigate("/signup");
							}}
						>
							Signup for free
						</Button>
					</Flex>
					<Flex
						gap="2"
						align="center"
						justify="center"
						className="cursor-pointer hover:underline hover:text-mb-blue-2 transition-all duration-300 w-full mt-2"
						onClick={() => {
							window.open("https://mightybot.ai", "_blank");
						}}
					>
						<Text color="gray">Learn more about MightyBot</Text>
						<Icon.ArrowRight />
					</Flex>
				</DialogContent>
			</Dialog.Content>
		</Dialog.Root>
	);
};

export default UnauthenticatedDialog;
