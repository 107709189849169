import { Flex, Icon, Card } from "@mightybot/web-ui";
import { motion } from "framer-motion";

import { styled } from "styled-components";

export const Dot = styled(Flex)`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: var(--mb-color-light-gray-9);
`;

export const ChatUserMessageContainer = styled(Flex)`
	display: grid;
	grid-template-columns: auto 1fr auto;
	gap: 6px;
	align-items: center;
	width: 100%;
`;

export const ChatReplyContainer = styled(Flex)`
	gap: 6px;
	background-color: var(--mb-color-light-blue-1);
	padding: 16px 20px 16px 20px;
	border-radius: 12px;
	margin-left: 38px;
`;

export const CopyIcon = styled(Icon.Copy)`
	cursor: pointer;
	&:hover {
		background-color: var(--gray-1);
	}
`;

export const ChatContainer = styled(Flex)`
	width: 100%;
`;

export const TypingIndicator = styled.span`
	display: inline-block;
	animation: blink 1s step-end infinite;
	margin-left: 2px;

	@keyframes blink {
		0%,
		100% {
			opacity: 1;
		}
		50% {
			opacity: 0;
		}
	}
`;

export const MessageBackground = styled.div`
	padding: 8px 12px;
	border-radius: 24px;
	background-color: var(--mb-color-light-gray-3);
	width: fit-content;
	max-width: 100%;
	word-break: break-word;
	padding: 12px 20px;
`;

export const customMarkdownStyles = {
	content: {
		fontSize: "14px",
		fontWeight: "light",
		whiteSpace: "pre-wrap",
	},
	paragraph: {
		marginBottom: 0,
	},
};

export const RetryButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 16px;
	background: #f5f5f5;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	cursor: pointer;
	font-size: 14px;

	&:hover {
		background: #ebebeb;
	}
`;

export const COTCard = styled(Card)`
	padding: 8px 12px;
	background-color: var(--mb-blue-1);
	border: none;
`;

export const AccordionCard = styled(Card)`
	background-color: var(--mb-gray-1);
	border: none;
`;

export const ChatMessageContent = styled.div`
	font-size: var(--default-font-size);
	line-height: 1.5;
	white-space: pre-wrap;
	word-break: break-word;

	p {
		margin: 0;
	}
`;

export const SpinningIcon = styled(Icon.Spinner)`
	animation: spin 2s linear infinite;
	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

export const ProgressBarContainer = styled.div`
	width: 100%;
	height: 4px;
	background-color: #f0f0f0;
	border-radius: 2px;
	overflow: hidden;
`;

export const ProgressBar = styled(motion.div)`
	height: 100%;
	background-color: var(--mb-color-dark-blue-8);
	border-radius: 2px;
`;
