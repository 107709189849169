import styled from "styled-components";
import { Dialog, Flex, mediaQueries } from "@mightybot/web-ui";

export const DialogRoot = styled(Dialog.Root)`
	.rt-BaseDialogScrollPadding,
	.rt-DialogScrollPadding {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 16px;
	}
`;

export const DialogContent = styled(Dialog.Content)`
	width: 100%;
	max-width: 1200px;
	height: 90vh;
	max-height: 90vh;
	padding: 0;
	background: white;
	border-radius: 12px;
	overflow: hidden;

	${mediaQueries.tablet} {
		max-height: none;
	}
`;

export const Content = styled(Flex)`
	overflow: hidden;

	${mediaQueries.tablet} {
		flex-direction: column;
	}
`;

export const VideoSection = styled.div`
	position: relative;
	width: 100%;
	margin-top: 10px;
	aspect-ratio: 16/9;
	background: black;
	border-radius: 8px;
	overflow: hidden;

	${mediaQueries.tablet} {
		margin-top: 8px;
		border-radius: 4px;
	}
`;

export const DetailsSection = styled.div`
	width: 400px;
	height: 100%;
	background: white;
	overflow: hidden;

	${mediaQueries.tablet} {
		width: 100%;
		height: 50%;
		min-height: 300px;
	}

	${mediaQueries.mobile} {
		height: 60%;
	}
`;

export const MetadataSection = styled(Flex)`
	align-items: center;
	gap: 8px;

	${mediaQueries.tablet} {
		gap: 6px;
		flex-wrap: wrap;
	}
`;
