import styled from "styled-components";
import { Flex, Button, mediaQueries } from "@mightybot/web-ui";

export const ConnectContainer = styled(Flex)`
	width: 100%;
	background: var(--mb-color-light-gray-1);
	border-radius: 12px;
	border: 1px solid var(--mb-color-light-gray-5);

	${mediaQueries.tablet} {
		padding: 0;
		border: none;
		border-radius: 0;
	}
`;

export const AppHeader = styled(Flex)`
	padding: 20px;
	border-bottom: 1px solid var(--mb-color-light-gray-5);

	${mediaQueries.tablet} {
		flex-direction: column;
		gap: 16px;
		padding: 16px;
	}
`;

export const AppContainer = styled(Flex)`
	${mediaQueries.tablet} {
		padding: 16px;
	}
`;

export const AppCardContainer = styled(Flex)`
	padding: 16px;
	border-bottom: 1px solid var(--mb-color-light-gray-5);
	min-height: 72px;

	${mediaQueries.tablet} {
		flex-direction: column;
		gap: 16px;
		padding: 16px;

		> div:first-child {
			width: 100%;
		}
	}
`;

export const StyledInput = styled.input`
	width: 240px;
	height: 32px;
	padding: 0 12px;
	border: 1px solid var(--mb-color-light-gray-5);
	border-radius: 4px;
	font-size: 14px;

	${mediaQueries.tablet} {
		width: 100%;
	}

	&::placeholder {
		color: var(--mb-color-light-gray-7);
	}
`;

export const ImageContainer = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	overflow: hidden;
	background: white;

	${mediaQueries.tablet} {
		width: 48px;
		height: 48px;
	}
`;

export const ConnectButton = styled(Button)`
	min-width: 100px;
	height: 32px;
	border-radius: 4px;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	${mediaQueries.tablet} {
		width: 100%;
		height: 40px;
	}
`;
