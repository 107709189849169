export const professionalFeatures = [
	"Personalized Home feed shows signals from your apps & meetings our AI agents discover",
	"MightyBot Wingman records meetings, answers any questions real-time, provides summaries",
	"Unified Search across all your apps & data",
	"AI Chat with all your data",
	"Precise research tool (Web/LinkedIn)",
	"Automations work via integrations with Slack, Hubspot, Salesforce, Google Workspace (Gmail, Calendar, Drive, Docs, Sheets), and Linear.",
];

export const enterpriseFeatures = [
	"Advanced security options",
	"Dedicated account support",
	"Team Autopilot workflow automations",
	"Forecasting & personalized coaching reports",
	"Win/Loss analysis reports",
	"Generates research reports & PRDs",
	"Builds detailed client reports (Consulting, Project, Marketing, Business Performance, others)",
];
