import styled from "styled-components";
import { Flex, mediaQueries } from "@mightybot/web-ui";

export const DatePickerWrapper = styled(Flex)`
	background: white;
	border-radius: 8px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

	${mediaQueries.tablet} {
		max-width: 100%;
		width: 100%;
	}
`;

export const CalendarsContainer = styled(Flex)`
	gap: 16px;
	padding: 0 16px;
	width: 100%;

	${mediaQueries.tablet} {
		flex-direction: column;
		gap: 8px;
	}
`;

export const ActionButtons = styled(Flex)`
	justify-content: flex-end;
	gap: 8px;
	padding: 10px 16px;
	background: rgba(237, 243, 255, 0.5);

	border-top: 1px solid var(--mb-gray-3);

	${mediaQueries.tablet} {
		padding: 12px;
	}
`;
