import { Person } from "@mightybot/core";
import React from "react";
import { Flex } from "@mightybot/web-ui";
import { PeopleRow, PeopleRowSkeleton } from "./PeopleRow";

interface PeopleTableProps {
	people: Person[];
	selectedPeople: Set<string>;
	onSelectChange: (id: string, checked: boolean) => void;
	isLoading?: boolean;
}

const SKELETON_COUNT = 20;

export const PeopleTable = React.memo(
	({ people, selectedPeople, onSelectChange, isLoading }: PeopleTableProps) => {
		if (isLoading) {
			return (
				<Flex
					direction="column"
					style={{ position: "relative", width: "100%" }}
				>
					{Array.from({ length: SKELETON_COUNT }).map((_, index) => (
						<PeopleRowSkeleton key={index} />
					))}
				</Flex>
			);
		}

		return (
			<Flex direction="column" style={{ position: "relative", width: "100%" }}>
				{people.map((person, index) => (
					<PeopleRow
						person={person}
						index={index}
						key={person.id}
						isSelected={selectedPeople.has(person.id)}
						onSelectChange={onSelectChange}
					/>
				))}
			</Flex>
		);
	},
);
