import React, { useState, useEffect, useCallback } from "react";
import {
	RadioGroup,
	Select,
	Switch,
	Flex,
	Checkbox,
	Text,
	Button,
	breakpoints,
	useMediaQuery,
} from "@mightybot/web-ui";
import {
	UserNotificationContainer,
	SectionTitle,
	SubSectionTitle,
	Description,
	SelectContainer,
	SubDescription,
	NotificationItem,
	NotificationTitle,
	NotificationDescription,
} from "./styled";
import Toast from "../Toast";
import { useUserPreferences, NotificationPreferences } from "@mightybot/core";

const startTimes = [
	{ value: "700", label: "7:00 AM" },
	{ value: "730", label: "7:30 AM" },
	{ value: "800", label: "8:00 AM" },
	{ value: "830", label: "8:30 AM" },
	{ value: "900", label: "9:00 AM" },
	{ value: "930", label: "9:30 AM" },
	{ value: "1000", label: "10:00 AM" },
	{ value: "1030", label: "10:30 AM" },
	{ value: "1100", label: "11:00 AM" },
];

const endTimes = [
	{ value: "1700", label: "5:00 PM" },
	{ value: "1730", label: "5:30 PM" },
	{ value: "1800", label: "6:00 PM" },
	{ value: "1830", label: "6:30 PM" },
	{ value: "1900", label: "7:00 PM" },
	{ value: "1930", label: "7:30 PM" },
	{ value: "2000", label: "8:00 PM" },
	{ value: "2030", label: "8:30 PM" },
	{ value: "2100", label: "9:00 PM" },
];

const notificationDays = [
	{ value: "WEEKDAYS", label: "Weekdays" },
	{ value: "WEEKEND", label: "Weekends" },
	{ value: "EVERYDAY", label: "Every day" },
];

const notificationFrequency = [
	{ value: "0", label: "As soon as task/updates ready" },
	{ value: "2", label: "Every 2 hour digest" },
	{ value: "4", label: "Every 4 hour digest" },
	{ value: "24", label: "Start and end of day digest" },
];

const UserNotification: React.FC = () => {
	const { userPreferences, updateUserPreferences } = useUserPreferences();
	// States for form inputs
	const [frequency, setFrequency] = useState(0);
	const [days, setDays] = useState("WEEKDAYS");
	const [startTime, setStartTime] = useState(800);
	const [endTime, setEndTime] = useState(1700);
	const [autopilotSettings, setAutopilotSettings] = useState({
		postMeetingSummary: false,
		preMeetingResearch: false,
		salesforceAddContacts: false,
		summarizeEmails: false,
	});
	const [slackNotificationsToggled, setSlackNotificationsToggled] =
		useState(false);
	const [hasChanges, setHasChanges] = useState(false);
	const [originalValues, setOriginalValues] = useState({
		frequency: 0,
		days: "WEEKDAYS",
		startTime: 800,
		endTime: 1700,
		slackNotificationsToggled: true,
	});
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	useEffect(() => {
		// Initialize form state from userPreferences
		if (userPreferences) {
			const initialAutopilotSettings = {
				postMeetingSummary: userPreferences.postMeetingSummaryToggled || false,
				preMeetingResearch: userPreferences.preMeetingResearchToggled || false,
				salesforceAddContacts:
					userPreferences.salesforceAddContactsToggled || false,
				summarizeEmails: userPreferences.summarizeEmailsToggled || false,
			};

			const initialValues = {
				frequency: userPreferences.frequency || 0,
				days: userPreferences.days || "WEEKDAYS",
				startTime: userPreferences.startTime || parseInt(startTimes[0].value),
				endTime: userPreferences.endTime || parseInt(endTimes[0].value),
				slackNotificationsToggled:
					userPreferences.slackNotificationsToggled || false,
			};

			setAutopilotSettings(initialAutopilotSettings);
			setFrequency(initialValues.frequency);
			setDays(initialValues.days);
			setStartTime(initialValues.startTime);
			setEndTime(initialValues.endTime);
			setSlackNotificationsToggled(initialValues.slackNotificationsToggled);
			setOriginalValues(initialValues);
		}
	}, [userPreferences]);

	useEffect(() => {
		const currentValues = {
			frequency,
			days,
			startTime,
			endTime,
			slackNotificationsToggled,
		};

		const changed = Object.keys(originalValues).some(
			(key) =>
				originalValues[key as keyof typeof originalValues] !==
				currentValues[key as keyof typeof currentValues],
		);

		setHasChanges(changed);
	}, [
		frequency,
		days,
		startTime,
		endTime,
		slackNotificationsToggled,
		originalValues,
	]);

	const updatePreferences = useCallback(
		async (preferences: Partial<NotificationPreferences>) => {
			try {
				await updateUserPreferences({
					notification: preferences,
				}).unwrap();

				// Update original values to match current state
				setOriginalValues({
					frequency,
					days,
					startTime,
					endTime,
					slackNotificationsToggled,
				});

				setToastData({
					title: "User Notifications",
					status: "success",
					description: "Preferences updated successfully",
				});
				setOpenToast(true);
			} catch (error) {
				console.error("Error submitting form:", error);
				setToastData({
					title: "User Notifications",
					status: "error",
					description: "Error Updating Preferences",
				});
				setOpenToast(true);
			}
		},
		[updateUserPreferences],
	);

	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState<{
		title: string;
		status: "success" | "error" | "info" | undefined;
		description: string;
	}>({
		title: "Feedback",
		status: "success",
		description: "",
	});

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		let pushNotifications = false;
		let emailNotifications = false;
		let webNotifications = false;
		if (frequency === 0) {
			pushNotifications = true;
			webNotifications = true;
		} else emailNotifications = true;

		updatePreferences({
			frequency: frequency,
			start_time: startTime,
			end_time: endTime,
			push_notifications: pushNotifications,
			email_notifications: emailNotifications,
			web_notifications: webNotifications,
			days: days,
			slack_notifications: slackNotificationsToggled,
		});
	};

	const handleSlackNotificationsToggled = (value: boolean) => {
		setSlackNotificationsToggled(value);
	};

	return (
		<UserNotificationContainer>
			<form onSubmit={handleSubmit}>
				{!isMobile && <SectionTitle>User Notifications</SectionTitle>}
				<Description
					style={{ paddingBottom: 20, borderBottom: "1px solid #ccc" }}
				>
					MightyBot extension only sends notifications for work ready to review,
					tasks it will do for you and reminders. Find them in the Home tab and
					update settings as needed.
				</Description>

				{/* Notification Frequency */}
				<div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
					<SubSectionTitle>Notification Frequency</SubSectionTitle>
					<RadioGroup.Root
						value={frequency.toString()}
						aria-label="Notification Frequency"
						onValueChange={(value) => setFrequency(parseInt(value))}
						style={{
							gap: "10px",
							display: "flex",
							flexDirection: "column",
							paddingBottom: 20,
							borderBottom: "1px solid #ccc",
						}}
					>
						{notificationFrequency.map((option) => (
							<Flex
								key={option.value}
								style={{ alignItems: "center", gap: "8px" }}
							>
								<RadioGroup.Item
									value={option.value}
									id={`r-${option.value}`}
								/>
								<label className="Label" htmlFor={`r-${option.value}`}>
									{option.label}
								</label>
							</Flex>
						))}
					</RadioGroup.Root>
				</div>
				{/* Send Notification To */}
				<Flex
					style={{
						paddingLeft: "20px",
						paddingRight: "20px",
					}}
					direction="column"
				>
					<SubSectionTitle>Send notification to</SubSectionTitle>
					<Flex style={{ gap: "40px", borderBottom: "1px solid #ccc" }}>
						<Flex
							key="chrome"
							style={{
								alignItems: "center",
								gap: "8px",
								paddingBottom: 20,
							}}
						>
							{/* <RadioGroup.Item value="chrome" id="chrome"/> */}
							<Checkbox
								key={1}
								checked={true}
								disabled
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									cursor: "pointer",
									color: "gray",
								}}
							/>
							<label
								className="Label"
								htmlFor="chrome"
								style={{
									fontSize: 14,
									fontWeight: 400,
									textAlign: "left",
									color: "var(--Text-disabled, #999999)",
								}}
							>
								{frequency === 0
									? "MightyBot Chrome Extension"
									: "Email digest"}
							</label>
						</Flex>
						<Flex
							key="slack"
							style={{
								alignItems: "center",
								gap: "8px",
								paddingBottom: 20,
							}}
						>
							{/* <RadioGroup.Item value="chrome" id="chrome"/> */}
							<Checkbox
								key={2}
								checked={true}
								disabled
								onCheckedChange={(value) => {
									handleSlackNotificationsToggled(value as boolean);
								}}
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									cursor: "pointer",
								}}
							/>
							<label
								className="Label"
								htmlFor="slack"
								style={{
									fontSize: 14,
									fontWeight: 400,
									textAlign: "left",
								}}
							>
								Slack
							</label>
						</Flex>
					</Flex>
				</Flex>

				{/* Weekly Schedule */}
				<div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
					<SubSectionTitle>Weekly schedule</SubSectionTitle>
					<SubDescription>
						You'll only receive notifications in the hours you choose. Outside
						of those times, notifications will be paused.
					</SubDescription>
					{/* <SelectContainer style={{ paddingBottom: 20, borderBottom: '1px solid #ccc' }}> */}
					<SelectContainer>
						<Select.Root value={days} onValueChange={(value) => setDays(value)}>
							<Select.Trigger
								placeholder="Select days..."
								style={{ flex: 1 }}
							/>
							<Select.Content>
								{" "}
								{/* Add scrolling here */}
								{notificationDays.map((option) => (
									<Select.Item key={option.value} value={option.value}>
										{option.label}
									</Select.Item>
								))}
							</Select.Content>
						</Select.Root>

						<Select.Root
							value={startTime.toString()}
							onValueChange={(value) => setStartTime(parseInt(value))}
						>
							<Select.Trigger
								placeholder="Select start time..."
								style={{ flex: 1 }}
							/>
							<Select.Content>
								{" "}
								{/* Add scrolling here */}
								{startTimes.map((time) => (
									<Select.Item key={time.value} value={time.value}>
										{time.label}
									</Select.Item>
								))}
							</Select.Content>
						</Select.Root>
						<Text>to</Text>

						<Select.Root
							value={endTime.toString()}
							onValueChange={(value) => setEndTime(parseInt(value))}
						>
							<Select.Trigger
								placeholder="Select end time..."
								style={{ flex: 1 }}
							/>
							<Select.Content>
								{" "}
								{/* Add scrolling here */}
								{endTimes.map((time) => (
									<Select.Item key={time.value} value={time.value}>
										{time.label}
									</Select.Item>
								))}
							</Select.Content>
						</Select.Root>
					</SelectContainer>
				</div>

				{/* Test Notification */}
				{/* <div style={{ borderBottom: '1px solid #ccc' }}>
          <SubSectionTitle>Test Notification</SubSectionTitle>
          <Description>Choose how notifications look and behave.</Description>
          <Button type="button">Test an example</Button>
        </div> */}

				{/* Autopilot Notification Subscription */}
				{/* <SubSectionTitle>Autopilot notification subscription</SubSectionTitle>
        <NotificationItem>
          <div>
            <NotificationTitle>Post Meeting Summary</NotificationTitle>
            <NotificationDescription>Autopilot to send post meeting summary</NotificationDescription>
          </div>
          <Switch
            style={{ cursor: "pointer" }}
            checked={autopilotSettings.postMeetingSummary}
            onCheckedChange={(value) => setAutopilotSettings(prev => ({ ...prev, postMeetingSummary: value }))}
            radius="full"
            id="post-meeting-toggle"
          />
        </NotificationItem>

        <NotificationItem>
          <div>
            <NotificationTitle>Pre meeting research and preparation</NotificationTitle>
            <NotificationDescription>Autopilot to send research for participants of the upcoming meeting</NotificationDescription>
          </div>
          <Switch
            style={{ cursor: "pointer" }}
            checked={autopilotSettings.preMeetingResearch}
            onCheckedChange={(value) => setAutopilotSettings(prev => ({ ...prev, preMeetingResearch: value }))}
            radius="full"
            id="pre-meeting-toggle"
          />
        </NotificationItem>

        <NotificationItem>
          <div>
            <NotificationTitle>Salesforce to add new contacts</NotificationTitle>
            <NotificationDescription>To add Salesforce contact as new contact found</NotificationDescription>
          </div>
          <Switch
            style={{ cursor: "pointer" }}
            checked={autopilotSettings.salesforceAddContacts}
            onCheckedChange={(value) => setAutopilotSettings(prev => ({ ...prev, salesforceAddContacts: value }))}
            radius="full"
            id="salesforce-toggle"
          />
        </NotificationItem>

        <NotificationItem>
          <div>
            <NotificationTitle>Summarize Emails</NotificationTitle>
            <NotificationDescription>Autopilot to summarize emails</NotificationDescription>
          </div>
          <Switch
            style={{ cursor: "pointer" }}
            checked={autopilotSettings.summarizeEmails}
            onCheckedChange={(value) => setAutopilotSettings(prev => ({ ...prev, summarizeEmails: value }))}
            radius="full"
            id="summarize-emails-toggle"
          />
        </NotificationItem> */}

				{/* Submit Button */}
				<Button
					style={{ marginLeft: "20px", marginBottom: "20px" }}
					type="submit"
					disabled={!hasChanges}
				>
					Submit
				</Button>
				<Toast
					open={openToast}
					setOpen={setOpenToast}
					title={toastData.title || "Autopilot"}
					type={toastData.status}
					description={toastData.description || ""}
				/>
			</form>
		</UserNotificationContainer>
	);
};

export default UserNotification;
