import React, {
	useEffect,
	useRef,
	useState,
	useCallback,
	useMemo,
} from "react";
import { useInView } from "react-intersection-observer";
import { Flex, Spinner } from "@mightybot/web-ui";
import { Meeting } from "@mightybot/core";
import { InfiniteScrollContainer } from "./styled";

interface InfiniteScrollProps {
	data: Meeting[] | any[];
	isLoading: boolean;
	error: Error | null;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	onFetchNextPage: () => Promise<void>;
	onFetchPreviousPage: () => Promise<void>;
	renderItems: (items: Meeting[] | any[]) => React.ReactNode;
	loadingComponent?: React.ReactNode;
	endMessage?: React.ReactNode;
	enablePastScroll?: boolean;
	initialPosition?: string;
	showNoMoreItems?: boolean;
}

export function InfiniteScroll({
	data,
	isLoading,
	error,
	hasNextPage,
	hasPreviousPage,
	onFetchNextPage,
	onFetchPreviousPage,
	renderItems,
	loadingComponent = <Spinner />,
	endMessage = <p>No more items to load</p>,
	showNoMoreItems = true,
	enablePastScroll = false,
	initialPosition,
}: InfiniteScrollProps) {
	const [showTopRef, setShowTopRef] = useState(false);
	const [isLoadingPrevious, setIsLoadingPrevious] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const lastScrollTop = useRef(0);
	const initialScrollApplied = useRef(false);
	const isLoadingRef = useRef(isLoading);
	const fetchingRef = useRef(false);
	const [hasScrolled, setHasScrolled] = useState(false);

	// Optimize intersection observer options
	const observerOptions = useMemo(
		() => ({
			threshold: 0.1, // Reduced threshold for better performance
			rootMargin: "200px", // Increased margin to fetch earlier
			root: null,
		}),
		[],
	);

	const [bottomRef, bottomInView] = useInView(observerOptions);
	const [topRef, topInView] = useInView(observerOptions);
	// Debounced scroll handler
	const handleScroll = useCallback(() => {
		if (!containerRef.current) return;

		const { scrollTop } = containerRef.current;
		setHasScrolled(true);

		if (scrollTop < lastScrollTop.current && scrollTop < 100) {
			setShowTopRef(true);
		}
		lastScrollTop.current = scrollTop;
	}, []);

	// Throttled scroll listener
	useEffect(() => {
		const container = containerRef.current;
		if (!container) return;

		let ticking = false;
		const scrollListener = () => {
			if (!ticking) {
				window.requestAnimationFrame(() => {
					handleScroll();
					ticking = false;
				});
				ticking = true;
			}
		};

		container.addEventListener("scroll", scrollListener, { passive: true });
		return () => container.removeEventListener("scroll", scrollListener);
	}, [handleScroll]);

	// Optimized initial scroll
	useEffect(() => {
		if (!data?.length || !initialPosition || initialScrollApplied.current)
			return;

		const container = containerRef.current;
		const element = container?.querySelector(`#${initialPosition}`);

		if (element && container) {
			requestAnimationFrame(() => {
				const elementTop = element.getBoundingClientRect().top;
				const containerTop = container.getBoundingClientRect().top;
				const scrollAmount = elementTop - containerTop;

				container.scrollTo({
					top: scrollAmount,
					behavior: "smooth",
				});

				initialScrollApplied.current = true;
			});
		}
	}, [data, initialPosition]);

	// Optimized fetch handlers
	const handleFetchNext = useCallback(async () => {
		if (fetchingRef.current || isLoadingRef.current || !hasNextPage) return;

		fetchingRef.current = true;
		try {
			await onFetchNextPage();
		} finally {
			fetchingRef.current = false;
		}
	}, [hasNextPage, onFetchNextPage]);

	const handleFetchPrevious = useCallback(async () => {
		if (fetchingRef.current || isLoadingRef.current || !hasPreviousPage) return;

		setIsLoadingPrevious(true);
		fetchingRef.current = true;
		try {
			await onFetchPreviousPage();
			setShowTopRef(false);
		} finally {
			setIsLoadingPrevious(false);
			fetchingRef.current = false;
		}
	}, [hasPreviousPage, onFetchPreviousPage]);

	// Update loading ref
	useEffect(() => {
		isLoadingRef.current = isLoading;
	}, [isLoading]);

	// Fetch triggers
	useEffect(() => {
		if (bottomInView && hasScrolled) {
			handleFetchNext();
		}
	}, [bottomInView, hasScrolled, handleFetchNext]);

	useEffect(() => {
		if (enablePastScroll && topInView && showTopRef) {
			handleFetchPrevious();
		}
	}, [enablePastScroll, topInView, showTopRef, handleFetchPrevious]);

	// Memoized render content
	const content = useMemo(() => {
		if (isLoading && !data?.length) return loadingComponent;
		if (error) return <p>Error fetching data: {error.message}</p>;

		return renderItems(data);
	}, [data, isLoading, error, loadingComponent, renderItems]);

	return (
		<InfiniteScrollContainer ref={containerRef}>
			{enablePastScroll && (
				<Flex
					justify="center"
					align="center"
					ref={topRef}
					style={{
						minHeight: showNoMoreItems ? "40px" : "0px",
						height: isLoadingPrevious ? "40px" : "auto",
					}}
				>
					{hasPreviousPage && isLoadingPrevious ? loadingComponent : null}
					{!hasPreviousPage && showNoMoreItems && endMessage}
				</Flex>
			)}

			{content}

			<Flex
				justify="center"
				align="center"
				ref={bottomRef}
				style={{
					minHeight: showNoMoreItems ? "40px" : "0px",
					height: hasNextPage && isLoading ? "40px" : "auto",
				}}
			>
				{hasNextPage && isLoading ? loadingComponent : null}
				{!hasNextPage && showNoMoreItems && endMessage}
			</Flex>
		</InfiniteScrollContainer>
	);
}
