import React from "react";
import {
	Flex,
	AvatarWrapper,
	Tooltip,
	CustomReactMarkdown,
	Text,
} from "@mightybot/web-ui";
import {
	ChatUserMessageContainer,
	customMarkdownStyles,
	MessageBackground,
} from "./styled";
import TruncatedMarkdown from "../TruncatedMarkdown";
import { ChatProps } from "./Chat";
import { formatTimestamp } from "../../utils/helpers";

interface ChatUserMessageProps
	extends Omit<ChatProps, "reply" | "isStreaming"> {}

const anonymousUser = {
	name: "Anonymous",
	profile_pic: "",
	email: "",
	id: "",
};

const ChatUserMessage: React.FC<ChatUserMessageProps> = ({
	timestamp,
	content,
	question,
	author,
}) => {
	const formattedQuestion = question.replace(/\\n/g, "\n");
	return (
		<ChatUserMessageContainer>
			<AvatarWrapper
				src={author?.profile_pic ?? anonymousUser.profile_pic}
				alt={author?.name ?? anonymousUser.name}
				radius="full"
				styles={{
					width: "32px",
					height: "32px",
				}}
			/>
			<Flex direction="column" gap="8" style={{ flex: 1, minWidth: 0 }}>
				<MessageBackground>
					<CustomReactMarkdown styles={customMarkdownStyles}>
						{formattedQuestion}
					</CustomReactMarkdown>
				</MessageBackground>
				{content && <TruncatedMarkdown content={content} singleLine />}
			</Flex>
		</ChatUserMessageContainer>
	);
};

export default ChatUserMessage;
