import styled from "styled-components";
import { Flex, mediaQueries } from "@mightybot/web-ui";
import { Text, Button as ButtonUI } from "@mightybot/web-ui";

export const HelpSection = styled.div`
	width: 100%;
	padding: 24px 40px;
	position: fixed;
	bottom: 0;
	left: 0;

	> div {
		max-width: 590px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		gap: 10px;
		justify-content: space-between;
	}

	.help-text {
		color: var(--mb-color-light-gray-8);
		font-size: 13px;
		line-height: 20px;

		strong {
			font-weight: 700;
		}
	}

	.buttons {
		display: flex;
		gap: 10px;
	}

	${mediaQueries.tablet} {
		padding: 24px;
	}

	${mediaQueries.mobile} {
		padding: 16px;

		> div {
			flex-direction: column;
			align-items: stretch;
			gap: 16px;
			text-align: center;
		}

		.buttons {
			flex-direction: column;
			gap: 8px;
		}
	}
`;

export const SuccessContainer = styled(Flex)`
	width: 100%;
	min-height: 100vh;
	margin: 0 auto;
	padding: 40px;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	position: relative;

	> div:not(${HelpSection}) {
		max-width: 590px;
		width: 100%;
	}

	${mediaQueries.tablet} {
		padding: 40px;

		> div {
			min-width: max-content;
		}
	}

	${mediaQueries.mobile} {
		padding: 24px 16px;
	}
`;

export const InfoText = styled(Text)`
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	color: var(--mb-color-dark-gray-2);
`;

export const ContentWrapper = styled(Flex)`
	width: 100%;
	max-width: 590px;
	margin: 0 auto;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	padding-bottom: 120px;
`;

export const StatusIcon = styled.img`
	width: 62px;
	height: 62px;
`;

export const StatusTitle = styled(Text).attrs({ size: "6", weight: "medium" })`
	text-align: center;
`;

export const StatusDescription = styled(Text).attrs({
	size: "2",
	color: "gray",
})`
	text-align: center;
`;

export const SuccessCard = styled(Flex)`
	align-self: stretch;
	padding: 20px;
	background: var(--mb-color-light-gray-1);
	border-radius: 8px;
	border: 1px solid var(--mb-color-light-gray-5);
	flex-direction: column;
	gap: 16px;

	${mediaQueries.mobile} {
		padding: 16px;
		gap: 12px;
	}
`;

export const DetailRow = styled(Flex)`
	align-self: stretch;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;

	.label {
		color: var(--mb-color-light-gray-9);
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
	}

	.value {
		color: var(--mb-color-light-gray-11);
		font-size: 16px;
		font-weight: 500;
		line-height: 22px;
		text-align: right;
	}

	${mediaQueries.mobile} {
		.label {
			font-size: 13px;
			line-height: 16px;
		}

		.value {
			font-size: 14px;
			line-height: 20px;
		}
	}
`;

export const Button = styled(ButtonUI)<{ variant?: "primary" | "secondary" }>`
	height: 34px;
	padding: 0 14px;
	border-radius: ${(props) => (props.variant === "primary" ? "999px" : "4px")};
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	cursor: pointer;

	${(props) =>
		props.variant === "primary"
			? `
    background: var(--mb-color-light-blue-8);
    color: var(--mb-color-light-gray-1);
    border: none;
  `
			: `
    background: var(--mb-color-light-gray-1);
    color: var(--mb-color-light-blue-8);
    border: 1px solid var(--mb-color-light-blue-8);
  `}

	${mediaQueries.mobile} {
		width: 100%;
		height: 40px;
		font-size: 13px;
	}
`;

export const ButtonsContainer = styled(Flex)`
	flex-direction: column;
	gap: 10px;
	width: 100%;
`;
