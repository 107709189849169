import styled from "styled-components";
import { Flex, Button, mediaQueries, Text } from "@mightybot/web-ui";
import { mediaMaxWidth } from "@mightybot/web-ui/src/util/mediaQueries";

export const GeneralContainer = styled(Flex)`
	border: 1px solid #e0e0e0;
	border-radius: 12px;
	width: 100%;
	overflow: hidden;

	${mediaQueries.tablet} {
		padding: 0;
		border: none;
		border-radius: 0;
		margin-top: 0;
	}
`;

export const GeneralHeader = styled(Flex)`
	padding: 0px 20px;
	border-bottom: 1px solid #e0e0e0;
	height: 72px;
	> * {
		flex-grow: 1;
	}
`;

export const GeneralContent = styled(Flex)`
	width: 100%;
	padding: 0px 20px 20px 20px;
	overflow: hidden;

	${mediaQueries.tablet} {
		flex-direction: column;
		gap: 16px;
		padding: 0;
	}
`;

export const UserProfileSection = styled(Flex)`
	width: 100%;
	padding: 30px 20px;
	border-radius: 8px;
	border-bottom: 1px solid #e0e0e0;
	position: relative;

	${mediaQueries.tablet} {
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 20px 16px;
		gap: 16px;

		> div {
			margin-left: 0;
			width: 100%;
		}
	}
`;

export const LogoutSection = styled(Flex)`
	width: 100%;
	padding: 20px 0px;
	border-radius: 8px;
	${mediaQueries.tablet} {
		width: 100%;

		button {
			width: 100%;
		}
	}
`;

export const LogoutButton = styled(Button)`
	background: #ffdce5;
	color: #dc3d43;
	padding: 10px 15px;
	margin: 0px;
	cursor: pointer;
`;

export const InfoCardContainer = styled(Flex)`
	gap: 12px;
	width: 50%;
	padding: 8px 0;

	${mediaQueries.tablet} {
		width: 100%;
		padding: 12px 16px;
		background: var(--mb-color-light-gray-1);
		border-radius: 8px;
		margin-bottom: 8px;
	}
`;

export const InfoContent = styled(Flex)`
	flex-direction: column;
	width: 100%;
	gap: 4px;

	${mediaQueries.tablet} {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex: 1;
		min-width: 0;
	}
`;

export const IconWrapper = styled(Flex)`
	margin-top: 1px;
	color: var(--mb-color-light-gray-9);
	flex-shrink: 0;

	${mediaQueries.tablet} {
		margin-top: 0;
		width: 24px;
		justify-content: center;
		align-items: center;
	}
`;

export const LabelText = styled(Text)`
	color: var(--mb-color-light-gray-9);
	white-space: nowrap;

	${mediaQueries.tablet} {
		white-space: normal;
		margin-right: 16px;
	}
`;

export const ValueLink = styled.a`
	text-decoration: none;
	color: var(--mb-color-light-blue-8);
	font-size: 16px;
	overflow: hidden;
	text-overflow: ellipsis;

	${mediaQueries.tablet} {
		text-align: right;
	}
`;

export const ValueText = styled(Text)`
	color: var(--mb-color-light-blue-8);
	overflow: hidden;
	text-overflow: ellipsis;

	${mediaQueries.tablet} {
		text-align: right;
		max-width: 50%;
	}
`;
