import React from "react";
import { PaymentStatus } from "../components/Billing/PaymentStatus/PaymentStatus";
import Layout from "../components/Layout";

const PaymentStatusRoute: React.FC = () => {
	return (
		<Layout>
			<PaymentStatus />
		</Layout>
	);
};

export default PaymentStatusRoute;
