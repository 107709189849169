import styled, { keyframes } from "styled-components";
import { Flex } from "@mightybot/web-ui";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
`;

export const ModalOverlay = styled(Flex)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
	justify-content: center;
	align-items: center;
	z-index: 1000; /* Ensure it's on top of other content */
	animation: ${fadeIn} 0.2s ease-out;
`;

export const Title = styled.div`
	color: #333333;
	font-size: 22px;
	font-weight: 500;
	line-height: 26px;
`;

export const Subtitle = styled.div`
	color: var(--mb-color-light-gray-9);
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
`;

export const ReminderPopup = styled(Flex)`
	background: white;
	border-radius: 20px;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
	padding: 20px;
	width: 692px;
	flex-direction: column;
	gap: 16px;
	animation: ${slideUp} 0.3s ease-out;

	${Title} + ${Subtitle} {
		margin-top: -11px;
	}
`;

export const ButtonsContainer = styled(Flex)`
	gap: 10px;
	align-items: center;
`;

export const CheckboxContainer = styled(Flex)`
	flex: 1;
	align-items: center;
	gap: 4px;
	color: #333333;
	font-size: 13px;
	font-weight: 400;
`;

export const IllustrationContainer = styled.div`
	position: relative;
	background: linear-gradient(0deg, #dce8ff 0%, #dce8ff 100%),
		linear-gradient(90deg, #f3f6e9 0%, #e4edfc 100%);
	border-radius: 10px;
	overflow: hidden;
	width: 100%;
	height: 383px;
`;

export const IllustrationText = styled.div`
	position: absolute;
	top: 152px;
	left: 27px;
	color: #333333;
	font-size: 26.42px;
	font-family: "FK Grotesk";
	font-weight: 700;
	line-height: 30.91px;
	word-wrap: break-word;

	span {
		color: #0554f2;
	}
`;

export const IllustrationImages = styled(Flex)`
	position: absolute;
	top: 31px;
	left: 329px;
`;

export const PrimaryImage = styled.img`
	width: 161.84px;
	height: 253.18px;
	box-shadow: 0px 6.74px 15.33px rgba(0, 0, 0, 0.1);
	border-radius: 6.13px;
	border: 0.61px #c1d3f6 solid;
	margin-right: 10px;
`;

export const SecondaryImageContainer = styled(Flex)`
	width: 204.75px;
	height: 318.17px;
	box-shadow: 0px 8.58px 19px rgba(0, 0, 0, 0.1);
	border-radius: 6.13px;
	overflow: hidden;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
`;

export const SecondaryImageHeader = styled(Flex)`
	align-self: stretch;
	height: 27.99px;
	padding-left: 10.18px;
	padding-right: 10.18px;
	background: #3b3b3b;
	border-top-left-radius: 6.36px;
	border-top-right-radius: 6.36px;
	justify-content: center;
	align-items: center;
	gap: 6.36px;
	color: white;
	font-size: 10.18px;
	font-family: "FK Grotesk Neue Trial";
	font-weight: 500;
	line-height: 14px;
	word-wrap: break-word;
`;

export const SecondaryImageContent = styled(Flex)`
	align-self: stretch;
	flex: 1 1 0%;
	padding: 9.81px;
	background: white;
	border-top-left-radius: 3.18px;
	border-top-right-radius: 3.18px;
	overflow: hidden;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	gap: 6.36px;
`;
