import React from "react";

import Layout from "../components/Layout";
import SnippetView from "../components/SnippetView";

export default function SnippetViewRoute() {
	return (
		<Layout>
			<SnippetView />
		</Layout>
	);
}
