import React from "react";
import PeopleDetails from "../components/PeopleDetails";
import Layout from "../components/Layout";

const PeopleRoute: React.FC = () => {
	return (
		<Layout>
			<PeopleDetails />
		</Layout>
	);
};

export default PeopleRoute;
