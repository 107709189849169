import React, { useState } from "react";
import { Text, Flex, Skeleton, Spinner } from "@mightybot/web-ui";
import {
	useBilling,
	useCancelSubscriptionMutation,
	useGetInvoicesQuery,
	useSetupPaymentMutation,
} from "@mightybot/core";

import { PaymentModal } from "./PaymentModal";
import {
	PurchasePlanButton,
	StatusBadge,
	TileSection,
	TileTableContainer,
	InvoiceHeader,
	BottomText,
} from "./styled";
import { InvoiceRow, InvoiceCell } from "./styled";
import {
	BillingContent,
	TileGridCard,
	TileGridLayout,
	TileTableLayout,
} from "./BillingContent";
import Toast from "../Toast";
import { PlanTiles } from "./PlanTiles/ PlanTiles";

const professionalFeatures = [
	"Integrations with Slack, Hubspot, Salesforce, Google Workspace (Gmail, Calendar, Docs, Sheets, Others)",
	"MightyBot Meeting Presence records, transcribes, and answers any questions about your Meetings",
	"Individual Autopilot workflow automations",
	"Advanced internal knowledge retrievals",
	"Precise human-like research tool (Web/LinkedIn)",
	"Comprehensive writer tool crafts 20+ page docs",
];

const enterpriseFeatures = [
	"Advanced security options",
	"Dedicated account support",
	"Team Autopilot workflow automations",
	"Forecasting & personalized coaching reports",
	"Win/Loss analysis reports",
	"Generates research reports & PRDs",
	"Builds detailed client reports (Consulting, Project, Marketing, Business Performance, others)",
];

const LoadingCard = () => (
	<TileGridCard>
		<Flex direction="column" gap="16px">
			<Skeleton width="160px" height="18px" />
			<Flex direction="column" gap="12px" mt="5px">
				<Skeleton height="16px" width="100%" />
				<Skeleton height="16px" width="100%" />
				<Skeleton height="16px" width="100%" />
				<Skeleton height="16px" width="100%" />
				<Skeleton height="16px" width="100%" />
			</Flex>
		</Flex>
	</TileGridCard>
);

const Billing: React.FC = () => {
	const [billingCycle, setBillingCycle] = useState<"monthly" | "yearly">(
		"monthly",
	);
	const [paymentModalOpen, setPaymentModalOpen] = useState(false);
	const [clientSecret, setClientSecret] = useState<string>("");
	const [isCreatingIntent, setIsCreatingIntent] = useState(false);
	const [isCancelling, setIsCancelling] = useState(false);

	const { plan, subscription, isLoading, isOnTrial, trialDaysRemaining } =
		useBilling();

	const { data: invoices } = useGetInvoicesQuery();
	const [cancelSubscription] = useCancelSubscriptionMutation();
	const [createSetupIntent] = useSetupPaymentMutation();
	const [openToast, setOpenToast] = useState(false);
	const [toastData, setToastData] = useState({
		title: "",
		description: "",
		status: "success" as "success" | "error" | "info",
	});

	const handleOpenPaymentModal = async () => {
		try {
			setIsCreatingIntent(true);
			const { client_secret } = await createSetupIntent().unwrap();
			setClientSecret(client_secret);
			setPaymentModalOpen(true);
		} catch (error) {
			console.error("Failed to create Setup Intent:", error);
			setToastData({
				title: "Payment Setup Failed",
				description: "Failed to initialize payment setup. Please try again.",
				status: "error",
			});
			setOpenToast(true);
			// Handle error appropriately (e.g., show a toast message)
		} finally {
			setIsCreatingIntent(false);
		}
	};

	const handleCancelSubscription = async () => {
		try {
			setIsCancelling(true);
			await cancelSubscription();
			setToastData({
				title: "Subscription Cancelled",
				description: "Your subscription has been successfully cancelled.",
				status: "success",
			});
			setOpenToast(true);
			window.location.reload();
		} catch (error) {
			console.error("Failed to cancel subscription:", error);
			// Handle error appropriately
		} finally {
			setIsCancelling(false);
		}
	};

	if (isLoading) {
		return <Skeleton width="100%" height="250px" loading />;
	}

	const getPrice = () => {
		if (!plan?.prices) return 0;
		return billingCycle === "yearly" ? plan.prices.yearly : plan.prices.monthly;
	};

	const price = getPrice();
	const currency = plan?.prices?.currency || "USD";

	const getSubscriptionStatus = () => {
		if (!subscription) {
			return <BottomText>Subscribe here to get started</BottomText>;
		}

		if (isOnTrial) {
			if (subscription.status === "CANCELLED") {
				return <BottomText>Your Free trial has been expired</BottomText>;
			}
			return (
				<BottomText>
					You're on a 7-day free trial with {trialDaysRemaining} days remaining.
				</BottomText>
			);
		}
		const nextBillingDate = subscription.next_billing_date
			? new Date(subscription.next_billing_date).toLocaleDateString("en-US", {
					month: "short",
					day: "numeric",
					year: "numeric",
				})
			: "";
		const cancelDate = subscription.cancelled_at
			? new Date(subscription.cancelled_at).toLocaleDateString("en-US", {
					month: "short",
					day: "numeric",
					year: "numeric",
				})
			: "";
		if (
			subscription.cancelled_at &&
			subscription.status === "ACTIVE" &&
			nextBillingDate
		) {
			return (
				<BottomText>
					Your cancellation will take effect on {nextBillingDate}. You can
					continue to use MightyBot until that date.
				</BottomText>
			);
		} else if (subscription.cancelled_at) {
			return (
				<BottomText>
					Your plan has been cancelled on {cancelDate}. Please subscribe to
					continue using MightyBot.
				</BottomText>
			);
		}

		return (
			<BottomText>
				You're on{" "}
				<Text size="2" weight="medium">
					Professional Plan ({subscription.billing_cycle})
				</Text>{" "}
				and next charge will be on {nextBillingDate} for{" "}
				<Text size="2" weight="medium">
					${subscription.amount}
				</Text>{" "}
			</BottomText>
		);
	};

	return (
		<BillingContent
			title="Billing"
			subHeader={
				<Flex justify="between" align="center" style={{ width: "100%" }}>
					<div>
						<Flex direction="column" gap="2">
							<Text size="3" weight="medium">
								Subscription Details
							</Text>
							{getSubscriptionStatus()}
						</Flex>
					</div>

					<Flex gap="2">
						{subscription?.status === "ACTIVE" && !isOnTrial && (
							<PurchasePlanButton
								onClick={
									subscription?.cancelled_at
										? () => {}
										: handleCancelSubscription
								}
								style={{ color: "red", borderColor: "red" }}
								disabled={isCancelling}
							>
								{isCancelling ? (
									<Flex align="center" gap="1">
										<Spinner color="red" size="16px" />
										<Text>Cancelling...</Text>
									</Flex>
								) : !subscription.cancelled_at ? (
									"Cancel Plan"
								) : (
									"Cancelled"
								)}
							</PurchasePlanButton>
						)}
						<PurchasePlanButton
							hidden={subscription?.status === "ACTIVE" && !isOnTrial}
							onClick={handleOpenPaymentModal}
							disabled={isCreatingIntent}
						>
							{isCreatingIntent ? (
								<Flex align="center" gap="1">
									<Spinner color="var(--mb-color-light-blue-8)" size="16px" />
									<Text>Processing...</Text>
								</Flex>
							) : (
								"Purchase Plan"
							)}
						</PurchasePlanButton>
					</Flex>
				</Flex>
			}
		>
			{/* <TileSection padding="20px 0px" align="center"> */}
			{/* Pricing Toggle */}
			{/* </TileSection> */}

			<PlanTiles
				plan={plan}
				billingCycle={billingCycle}
				subscription={subscription}
				isOnTrial={isOnTrial}
				isCreatingIntent={isCreatingIntent}
				onSubscribe={handleOpenPaymentModal}
			/>
			{/* Invoice History Section */}
			{invoices && invoices.length > 0 && (
				<TileSection padding="0">
					<InvoiceHeader size="3">Invoice history</InvoiceHeader>
					<TileTableContainer>
						<TileTableLayout headers={["Date", "Amount", "Seats", "Status"]}>
							{invoices.map((invoice) => (
								<InvoiceRow
									key={invoice.id}
									onClick={() => window.open(invoice.pdf_link, "_blank")}
								>
									<InvoiceCell isDate>
										{new Date(invoice.created_at).toLocaleDateString("en-US", {
											month: "short",
											day: "2-digit",
											year: "numeric",
										})}
									</InvoiceCell>
									<InvoiceCell>
										${(invoice.amount_due / 100).toFixed(2)}
									</InvoiceCell>
									<InvoiceCell>{invoice.seats}</InvoiceCell>
									<InvoiceCell>
										<StatusBadge status={invoice.status}>
											<Text>
												{invoice.status.charAt(0).toUpperCase() +
													invoice.status.slice(1).toLowerCase()}
											</Text>
										</StatusBadge>
									</InvoiceCell>
								</InvoiceRow>
							))}
						</TileTableLayout>
					</TileTableContainer>
				</TileSection>
			)}

			<PaymentModal
				isOpen={paymentModalOpen}
				onClose={() => setPaymentModalOpen(false)}
				planId={plan?.id || ""}
				planName={plan?.name || ""}
				seats={subscription?.total_seats || 1}
				billingCycle={billingCycle}
				amount={price || 0}
				clientSecret={clientSecret}
				onSuccess={() => {
					setPaymentModalOpen(false);
				}}
				onFailure={() => {
					setPaymentModalOpen(true);
				}}
			/>
			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				description={toastData.description}
				type={toastData.status}
			/>
		</BillingContent>
	);
};

export default Billing;
