import React, { useState, RefObject } from "react";
import { Flex } from "@mightybot/web-ui";
import MeetingChats from "../MeetingChats";
import {
	MeetingContentScrollerOuterContainer,
	MeetingContentScrollerInnerContainer,
} from "./styled";
import MeetingContentDetails from "./MeetingContentDetails";
import MeetingContentPreview from "./MeetingContentPreview";
import { useParams } from "react-router-dom";
import { cn } from "../../utils/classNames";

interface MeetingContentScrollerProps {
	endOfContentRef: React.RefObject<HTMLDivElement>;
	isTranscriptSidebarOpen: boolean;
}

export interface WidgetPosition {
	top: number;
	left: number;
}

const MeetingContentScroller: React.FC<MeetingContentScrollerProps> = ({
	endOfContentRef,
	isTranscriptSidebarOpen,
}) => {
	const { view } = useParams();
	const [showWidget, setShowWidget] = useState(false);

	return (
		<MeetingContentScrollerOuterContainer
			id="meeting-content-scroller"
			className={cn("px-[80px] pt-5 max-w-[1050px] mx-auto")}
			direction="column"
			justify="between"
		>
			<MeetingContentScrollerInnerContainer>
				<Flex
					direction="column"
					onMouseEnter={() => (view === "details" ? setShowWidget(true) : null)}
					style={{ width: "100%" }}
				>
					{view === "details" ? (
						<MeetingContentDetails showWidget={true} />
					) : (
						<MeetingContentPreview />
					)}
				</Flex>
				<Flex
					direction="column"
					onMouseEnter={() => setShowWidget(false)}
					style={{ position: "relative" }}
				>
					{view === "details" && <MeetingChats />}
					<div
						ref={endOfContentRef}
						id="end-of-content"
						style={{ height: "1px" }}
					/>
				</Flex>
			</MeetingContentScrollerInnerContainer>
		</MeetingContentScrollerOuterContainer>
	);
};

export default MeetingContentScroller;
