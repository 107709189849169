import React, { useEffect, useState, useRef } from "react";
import {
	StyledBox,
	StyledTextField,
	PastSearchesContainer,
	PastSearchItem,
	SearchQueryText,
	ResultCount,
} from "./styled";
import { Icon, TextField, IconButton, Separator } from "@mightybot/web-ui";
import { debounce } from "lodash";
import { SearchResult } from "@mightybot/core";
interface GenericSearchBarProps {
	placeholder?: string;
	onSearch: (query: string) => void;
	onPastSearchClick?: (result: SearchResult) => void;
	onSearchQueryChange?: (query: string) => void;
	searchResults?: SearchResult[];
	maxResults?: number;
	debounceTime?: number;
	showPastSearches?: boolean;
	size?: "1" | "2" | "3";
}

const GenericSearchBar: React.FC<GenericSearchBarProps> = ({
	placeholder = "Search...",
	onSearch,
	onPastSearchClick,
	onSearchQueryChange,
	searchResults = [],
	maxResults = 5,
	debounceTime = 300,
	showPastSearches = true,
	size = "3",
}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [isResultsVisible, setIsResultsVisible] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [isFocused, setIsFocused] = useState(false);

	const searchContainerRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const debouncedSearch = useRef(
		debounce((query: string) => {
			onSearchQueryChange?.(query);
		}, debounceTime),
	).current;

	useEffect(() => {
		if (searchQuery) {
			debouncedSearch(searchQuery);
		} else {
			debouncedSearch?.("");
		}
	}, [searchQuery, debouncedSearch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchContainerRef.current &&
				!searchContainerRef.current.contains(event.target as Node)
			) {
				setIsResultsVisible(false);
				setIsFocused(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		setIsResultsVisible(true);
		setSearchQuery(newValue);
		if (!newValue) {
			onSearchQueryChange?.("");
		}
	};

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (selectedIndex >= 0 && visibleSearchResults[selectedIndex]) {
				onPastSearchClick?.(visibleSearchResults[selectedIndex]);
			} else {
				onSearch(searchQuery);
			}
		} else if (e.key === "ArrowDown") {
			e.preventDefault();
			if (!isResultsVisible) {
				setIsResultsVisible(true);
				setIsFocused(true);
				setSelectedIndex(-1);
				return;
			}

			if (visibleSearchResults.length) {
				setSelectedIndex((prev) =>
					prev < visibleSearchResults.length - 1 ? prev + 1 : prev,
				);
			}
		} else if (e.key === "ArrowUp") {
			e.preventDefault();
			if (isResultsVisible && visibleSearchResults.length) {
				setSelectedIndex((prev) => (prev > -1 ? prev - 1 : -1));
				if (selectedIndex === 0) {
					setIsResultsVisible(false);
					setIsFocused(false);
				}
			}
		} else if (e.key === "Escape") {
			e.preventDefault();
			setIsResultsVisible(false);
			setIsFocused(false);
			setSelectedIndex(-1);
		}
	};

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleClear = () => {
		setSearchQuery("");
		setIsResultsVisible(false);
		setSelectedIndex(-1);
		onSearchQueryChange?.("");
	};

	const visibleSearchResults = searchResults.slice(0, maxResults);

	const isSearchResultsVisible =
		(isFocused || isResultsVisible) &&
		visibleSearchResults &&
		visibleSearchResults.length > 0;

	return (
		<StyledBox width="100%" position="relative" ref={searchContainerRef}>
			<StyledTextField
				ref={inputRef}
				placeholder={placeholder}
				size={size}
				value={searchQuery}
				onChange={handleInputChange}
				onKeyDown={handleKeyDown}
				onFocus={handleFocus}
				isexpanded={isSearchResultsVisible ? "true" : "false"}
			>
				<TextField.Slot>
					<Icon.MagnifyingGlass width="16" height="16" />
				</TextField.Slot>
				<TextField.Slot pr="3">
					{searchQuery && (
						<IconButton
							size="2"
							variant="ghost"
							onClick={handleClear}
							style={{ cursor: "pointer" }}
						>
							<Icon.X width="16" height="16" />
						</IconButton>
					)}
				</TextField.Slot>
			</StyledTextField>
			{showPastSearches && (
				<PastSearchesContainer
					isvisible={isSearchResultsVisible ? "true" : "false"}
				>
					{isSearchResultsVisible && (
						<Separator
							style={{
								width: "calc(100% - 24px)",
								marginBottom: "10px",
								flexShrink: 0,
								alignSelf: "center",
							}}
						/>
					)}
					{isSearchResultsVisible &&
						visibleSearchResults.map((result, index) => (
							<PastSearchItem
								key={index}
								onClick={() => onPastSearchClick?.(result)}
								isselected={index === selectedIndex ? "true" : "false"}
							>
								<SearchQueryText>
									<Icon.MagnifyingGlass width="16" height="16" />
									{result.query}
								</SearchQueryText>
								{result.results_count !== undefined && (
									<ResultCount>
										{result.results_count}{" "}
										{result.results_count === 1 ? "result" : "results"}
									</ResultCount>
								)}
							</PastSearchItem>
						))}
				</PastSearchesContainer>
			)}
		</StyledBox>
	);
};

export default GenericSearchBar;
