import styled from "styled-components";
import { Flex } from "@mightybot/web-ui";
import { breakpoints } from "@mightybot/web-ui";

export const SidebarContainer = styled.div`
	width: 270px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--mb-color-light-blue-1);
	border-radius: 16px;
	padding: 20px 10px 16px;
	height: 100%;

	@media (max-width: ${breakpoints.tablet}) {
		width: 100%;
		border-radius: 0;
		padding: 16px;
		height: auto;
		max-height: 50vh;
	}
`;

export const MobileSidebarContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: var(--mb-color-light-blue-1);
	padding: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;

	@media (max-width: ${breakpoints.mobile}) {
		border-radius: 0;
	}
`;

export const SidebarHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	border-bottom: 1px solid var(--border-primary);

	@media (max-width: ${breakpoints.tablet}) {
		padding: 12px;
	}
`;
