// @ts-nocheck
// Disable type checking for this file as the library is not typed
import React, { useState, useEffect } from "react";
import { IconButton, Skeleton } from "@mightybot/web-ui";
import { FrameCorners } from "@phosphor-icons/react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = React.forwardRef(
	(
		props: {
			options: any;
			onReady?: (player: any) => void;
			setCurrentTime: (time: number) => void;
			onExpand: () => void;
			isLoading?: boolean;
			src?: string;
			onError?: () => void;
			startTime?: number;
			endTime?: number;
			size?: "default" | "large";
		},
		ref,
	) => {
		const [isReady, setIsReady] = useState(false);
		const videoRef = React.useRef(null);
		const playerRef = ref || React.useRef(null);
		const initialized = React.useRef(false);
		const {
			options,
			onReady,
			setCurrentTime,
			onExpand,
			isLoading,
			src,
			onError,
			startTime,
			endTime,
			size = "default",
		} = props;

		useEffect(() => {
			if (!playerRef.current || !startTime || !endTime) return;

			const styleId = "vjs-range-highlight";
			let styleEl = document.getElementById(styleId);

			if (!styleEl) {
				styleEl = document.createElement("style");
				styleEl.id = styleId;
				document.head.appendChild(styleEl);
			}

			const duration = playerRef.current.duration();
			const startPercent = (startTime / duration) * 100;
			const endPercent = (endTime / duration) * 100;

			// Add handler for time updates
			const handleTimeUpdate = () => {
				const currentTime = playerRef.current.currentTime();
				if (currentTime < startTime) {
					playerRef.current.currentTime(startTime);
				} else if (currentTime >= endTime) {
					// Pause the video and return to start time when reaching end time
					playerRef.current.pause();
					playerRef.current.currentTime(startTime);
				}
			};

			playerRef.current.on("timeupdate", handleTimeUpdate);

			styleEl.textContent = `
				.video-js .vjs-play-progress:after {
					display: none;
				}
				.video-js .vjs-play-progress {
					background: none;
				}
				.video-js .vjs-progress-holder {
					position: relative;
				}
				.video-js .vjs-progress-holder:after {
					content: '';
					position: absolute;
					top: 0;
					left: ${startPercent}%;
					right: ${100 - endPercent}%;
					bottom: 0;
					background: var(--blue-8);
					pointer-events: none;
				}
				.video-js .vjs-progress-holder:before {
					content: '';
					position: absolute;
					top: -2px;
					left: ${endPercent}%;
					width: 8px;
					height: 8px;
					background: white;
					border-radius: 50%;
					transform: translateX(-50%);
					z-index: 2;
					pointer-events: none;
					box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
				}
				.video-js .vjs-progress-holder:hover:before {
					transform: translateX(-50%) scale(1.2);
					transition: transform 0.2s ease;
				}
			`;

			return () => {
				if (styleEl) {
					styleEl.remove();
				}
				if (playerRef.current) {
					playerRef.current.off("timeupdate", handleTimeUpdate);
				}
			};
		}, [startTime, endTime]);

		useEffect(() => {
			if (!src) return;

			if (playerRef.current && initialized.current) {
				// Just update the source if player already exists
				playerRef.current.src({ src });
				return;
			}

			// Initial setup only happens once
			if (initialized.current || playerRef.current) return;

			initialized.current = true;

			console.debug("initializing player");

			const videoElement = document.createElement("video-js");
			videoElement.classList.add("vjs-big-play-centered");
			videoRef.current.appendChild(videoElement);

			playerRef.current = videojs(videoElement, options, () => {
				videojs.log("player is ready");
				setIsReady(true);
				onReady && onReady(playerRef.current);
			});

			playerRef.current.on("error", () => {
				const error = playerRef.current.error();
				const mediaError = playerRef.current.error().message;

				// Check specifically for access denied errors
				const isAccessError =
					error.code === 4 && //
					(mediaError.includes("403") ||
						mediaError.includes("401") || // Unauthorized
						mediaError.includes("access_denied") ||
						mediaError.includes("permission"));

				if (isAccessError) {
					console.error("Video access error detected");
					onError && onError();
				}
			});

			playerRef.current.on("timeupdate", () => {
				setCurrentTime(playerRef.current.currentTime());
			});

			return () => {
				initialized.current = false;
				if (playerRef.current && !playerRef.current.isDisposed()) {
					playerRef.current.dispose();
					playerRef.current = null;
				}
			};
		}, [src]);

		if (!isReady && isLoading) {
			return (
				<div
					className={`w-full ${size === "large" ? "max-w-none" : "max-w-2xl"} mt-2`}
				>
					<Skeleton
						className={`aspect-video rounded-[4px] ${
							size === "large" ? "h-[500px]" : "h-[178px]"
						}`}
					/>
				</div>
			);
		}

		return (
			<div
				data-vjs-player
				className={`w-full ${
					size === "large" ? "max-w-none" : "max-w-2xl"
				} relative`}
				style={{ borderRadius: "12px", overflow: "hidden" }}
			>
				<div ref={videoRef} className="rounded-[12px] overflow-hidden" />
				<IconButton
					className="absolute top-2 right-2 z-10 cursor-pointer bg-white bg-opacity-70"
					onClick={onExpand}
				>
					<FrameCorners className="h-6 w-6" />
				</IconButton>
			</div>
		);
	},
);

export default VideoJS;
