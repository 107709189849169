import React from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import * as Sentry from "@sentry/react";

import {
	AdminRoute as Admin,
	AuthCallbackRoute as AuthCallback,
	AuthenticatedRoute as Authenticated,
	AuthRedirectRoute as AuthRedirect,
	AuthRoute as Auth,
	ChatsRoute as Chats,
	CompaniesRoute as Companies,
	DealsRoute as Deals,
	FeedbackPage as Feedback,
	HomeRoute as Home,
	MeetingsRoute as Meetings,
	OnboardingRoute as Onboarding,
	PaymentStatusRoute as PaymentStatus,
	PeopleRoute as People,
	RootRoute as Root,
	SearchRoute as Search,
	SettingsRoute as Settings,
	UnsubscribeRoute as Unsubscribe,
	WelcomeRoute as Welcome,
	SnippetViewRoute as SnippetView,
	PeopleDetailsRoute as PeopleDetails,
	TeamMeetingsRoute as TeamMeetings,
} from "./routes";

import ErrorFallback from "./components/ErrorFallback";

const routes = {
	auth: {
		signin: "/signin",
		login: "/login",
		signup: "/signup",
		callback: "/auth/callback",
		oauthCallback: "/oauth/callback",
	},
	onboarding: {
		root: "/onboarding",
		welcome: "/welcome",
	},
	app: {
		home: "/home",
		chats: "/chats",
		companies: "/companies",
		deals: "/deals",
		people: "/people",
		search: "/search",
		meetings: "/meetings",
		teamMeetings: {
			root: "/team/meetings",
			details: "/team/meetings/:meetingId/:view",
		},
		settings: "/settings",
		billing: {
			root: "/settings/billing",
			paymentStatus: "/settings/billing/payment-status",
		},
		snippets: "/snippets",
	},
	admin: {
		root: "/admin",
		enterprise: "/admin/enterprise",
	},
} as const;

export const createAppRouter = () => {
	const sentryCreateBrowserRouter =
		Sentry.wrapCreateBrowserRouter(createBrowserRouter);

	const routeConfig = [
		{
			path: "/",
			element: <Root />,
			errorElement: <ErrorFallback />,
			children: [
				{
					errorElement: <ErrorFallback />,
					children: [
						// Auth Routes
						{
							path: routes.auth.signin,
							element: <Auth />,
						},
						{
							path: routes.auth.login,
							element: <Auth />,
						},
						{
							path: routes.auth.signup,
							element: <Auth />,
						},
						{
							path: routes.auth.callback,
							element: <AuthCallback />,
						},
						{
							path: routes.auth.oauthCallback,
							element: <AuthCallback />,
						},

						// Onboarding Routes
						{
							path: routes.onboarding.root,
							element: <Onboarding />,
						},
						{
							path: routes.onboarding.welcome,
							element: <Welcome />,
						},
						{
							path: `${routes.app.snippets}/:snippetId`,
							element: <SnippetView />,
						},

						// Protected Routes with SubscriptionGuard
						{
							element: <Authenticated />,
							children: [
								// Main App Routes
								{
									path: routes.app.home,
									element: <Home />,
								},
								{
									path: routes.app.chats,
									element: <Chats />,
								},
								{
									path: `${routes.app.chats}/:chatId`,
									element: <Chats />,
								},
								{
									path: routes.app.companies,
									element: <Companies />,
								},
								{
									path: routes.app.deals,
									element: <Deals />,
								},
								{
									path: routes.app.people,
									element: <People />,
								},
								{
									path: `${routes.app.people}/:personId/details`,
									element: <PeopleDetails />,
								},
								{
									path: routes.app.search,
									element: <Search />,
								},
								{
									path: routes.app.meetings,
									element: <Meetings />,
								},
								{
									path: `${routes.app.meetings}/:meetingId`,
									element: <Meetings />,
								},
								{
									path: `${routes.app.meetings}/:meetingId/:view`,
									element: <Meetings />,
								},
								{
									path: routes.app.settings,
									element: <Settings />,
								},
								{
									path: `${routes.app.settings}/*`,
									element: <Settings />,
								},
								{
									path: routes.app.billing.paymentStatus,
									element: <PaymentStatus />,
								},
								{
									path: routes.app.teamMeetings.root,
									element: <TeamMeetings />,
								},
								{
									path: `${routes.app.teamMeetings.details}`,
									element: <TeamMeetings />,
								},

								// Admin Routes
								{
									path: routes.admin.root,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/:adminTab`,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/enterprise`,
									element: <Navigate to="/admin/enterprises" replace />,
								},
								{
									path: `${routes.admin.root}/:adminTab/:enterpriseId`,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/:adminTab/:autopilotId`,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/:adminTab/:autopilotId/edit`,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/:adminTab/:autopilotId/new`,
									element: <Admin />,
								},
								{
									path: `${routes.admin.root}/enterprise/:enterpriseId/autopilot/:autopilotId/:adminTab`,
									element: <Admin />,
								},

								// Utility Routes
								{
									path: "/email/unsubscribe",
									element: <Unsubscribe />,
								},
								{
									path: "/email/feedback",
									element: <Feedback />,
								},
								{
									path: "/integrations/*",
									element: <AuthRedirect />,
								},

								// Redirects
								{
									path: "/connect",
									element: <Navigate to="/settings/connect" replace />,
								},
							],
						},

						// 404 Route
						{
							path: "*",
							element: <ErrorFallback errorType="routeNotFound" />,
						},
					],
				},
			],
		},
	];

	return sentryCreateBrowserRouter(routeConfig, {
		basename: "/app",
		future: {
			v7_skipActionErrorRevalidation: true,
			v7_normalizeFormMethod: true,
			v7_routeLoaderData: true,
			v7_relativeSplatPath: true,
			v7_fetcherPersist: true,
			v7_partialHydration: true,
		},
	});
};
