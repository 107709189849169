import { Flex, mediaQueries } from "@mightybot/web-ui";
import styled, { keyframes } from "styled-components";

export const processingAnimation = keyframes`
	0% {
		background-position: 100% 0;
	}
	100% {
		background-position: -100% 0;
	}
`;

export const Container = styled(Flex)`
	flex-direction: column;
	width: 100%;
	background: white;
	padding: 24px;
	padding-top: 0px;
	margin-bottom: 50px;

	${mediaQueries.tablet} {
		padding: 16px;
		padding-top: 0px;
		margin-bottom: 0px;
	}

	${mediaQueries.mobile} {
		padding: 12px;
		padding-top: 0px;
	}
`;

export const HeaderContainer = styled(Flex)`
	position: sticky;
	top: 0;
	z-index: 10;
	background: white;
	width: 100%;
`;

export const Header = styled(Flex)`
	padding: 12px 16px;
	background: #f9fafb;
	border: 1px solid #e5e7eb;
	width: 100%;
	border-radius: 8px 8px 0 0;
	align-items: center;
	justify-content: space-between;

	${mediaQueries.tablet} {
		gap: 16px;
	}
`;

export const ClipRow = styled(Flex)`
	padding: 16px;
	border: 1px solid #e5e7eb;
	border-top: none;
	gap: 16px;
	position: relative;
	overflow: hidden;
	background: white;

	&[data-processing="true"] {
		background: linear-gradient(90deg, #fff 0%, #dce8ff 38.5%, #fff 100%);
		background-size: 200% 100%;
		animation: ${processingAnimation} 2s linear infinite;
	}

	&:last-child {
		border-radius: 0 0 8px 8px;
	}

	${mediaQueries.tablet} {
		flex-direction: column;
		gap: 16px;
		padding: 16px;

		.clip-metadata {
			align-items: flex-start;
			gap: 8px;
		}
	}

	${mediaQueries.mobile} {
		padding: 12px;

		.clip-actions {
			width: 100%;
			justify-content: flex-start;
			gap: 8px;
		}
	}
`;

export const EmptyState = styled(Flex)`
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 48px;
	text-align: center;
	background: #f9fafb;
	border-radius: 8px;
	border: 1px dashed #e5e7eb;
	gap: 16px;

	${mediaQueries.tablet} {
		padding: 32px 16px;
	}

	${mediaQueries.mobile} {
		padding: 24px 12px;
	}
`;
