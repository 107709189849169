import React from "react";
import { Flex, Spinner, Text } from "@mightybot/web-ui";
import { ContentWrapper } from "./styled";
import { AllAppsSVG } from "@mightybot/core-ui";
import { useIndexing } from "@mightybot/core";

const IndexingProgress: React.FC = () => {
	const { isIndexing, progress } = useIndexing();

	if (!isIndexing) return null;

	return (
		<ContentWrapper>
			<Flex align="center" gap="10">
				<Flex>
					<img
						src={AllAppsSVG}
						alt="Apps icon"
						style={{ width: 132, height: 32 }}
					/>
				</Flex>
				<Flex direction="column" gap="2" style={{ paddingLeft: "10px" }}>
					<Text
						style={{
							color: "var(--mb-color-dark-gray-2)",
							fontSize: "14px",
							fontWeight: "400",
							lineHeight: "18px",
							wordWrap: "break-word",
						}}
					>
						We are retrieving the data from your apps
					</Text>

					<Flex align="center" gap="1">
						<Spinner color="#054CDC" size="16px" weight="bold" />
						<Text
							style={{
								color: "var(--mb-color-dark-gray-4)",
								fontSize: "12px",
								fontWeight: "400",
								lineHeight: "16px",
								wordWrap: "break-word",
							}}
						>
							{progress}% completed
						</Text>
					</Flex>
				</Flex>
			</Flex>
		</ContentWrapper>
	);
};

export default IndexingProgress;
