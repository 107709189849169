import React, { useState, useMemo } from "react";
import { Checkbox, Flex, Icon, Text, Input } from "@mightybot/web-ui";
import { FilterContainer, FilterOption } from "./styled";
import { capitalize } from "lodash";

type GenericFilterContentProps<T> = {
	title: string;
	options: T[];
	selectedOptions: T[];
	handleOptionSelect: (option: T) => void;
	handleSelectAll: () => void;
	handleReset: () => void;
	getOptionLabel?: (option: T) => string;
	showSearch?: boolean;
};

export const GenericFilterContent = <T extends string>({
	title,
	options,
	selectedOptions,
	handleOptionSelect,
	handleSelectAll,
	handleReset,
	getOptionLabel = (option) => option,
	showSearch = false,
}: GenericFilterContentProps<T>) => {
	const [searchQuery, setSearchQuery] = useState("");
	const isAllSelected = options.length === selectedOptions.length;
	const hasSelections = selectedOptions.length > 0;

	// Filter options based on search query
	const filteredOptions = useMemo(() => {
		if (!searchQuery) return options;
		return options.filter((option) =>
			getOptionLabel(option).toLowerCase().includes(searchQuery.toLowerCase()),
		);
	}, [options, searchQuery, getOptionLabel]);

	return (
		<FilterContainer direction="column" gap="8px" p="12px">
			<Text weight="medium">{title}</Text>
			{showSearch && (
				<Input
					placeholder="Search..."
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
			)}
			<Flex direction="column" gap="6px" p="4px">
				<Flex justify="between" align="center">
					<FilterOption align="center" onClick={handleSelectAll}>
						<Checkbox checked={isAllSelected} />
						<Text weight="medium">Select All</Text>
					</FilterOption>
					{hasSelections && (
						<FilterOption align="center" onClick={handleReset}>
							<Icon.X />
							<Text weight="medium" style={{ color: "var(--mb-red-9)" }}>
								Reset
							</Text>
						</FilterOption>
					)}
				</Flex>

				{filteredOptions.map((option) => (
					<FilterOption
						key={option}
						align="center"
						onClick={() => handleOptionSelect(option)}
					>
						<Checkbox checked={selectedOptions.includes(option)} />
						<Text size="2">{capitalize(getOptionLabel(option))}</Text>
					</FilterOption>
				))}
			</Flex>
		</FilterContainer>
	);
};
