import React, { useState } from "react";
import { MeetingDetailsContainer } from "./styled";
import MeetingContent from "../MeetingContent";
import MeetingDetailsSidebar from "../MeetingDetailsSidebar";
import { MeetingContentNavigationProvider } from "../../contexts/MeetingContentNavigationContext";
import { isMobile } from "../../utils/helpers";
import MeetingContentTitle from "../MeetingContent/MeetingContentTitle";
import { breakpoints, useMediaQuery } from "@mightybot/web-ui";

interface MeetingDetailsProps {}

const MeetingDetails: React.FC<MeetingDetailsProps> = ({}) => {
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile());

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	return (
		<MeetingContentNavigationProvider>
			<div
				className={`flex flex-col h-full ${isTablet ? "overflow-hidden" : ""}`}
			>
				<MeetingContentTitle />
				<MeetingDetailsContainer
					className={isTablet ? "flex-1 overflow-hidden" : ""}
				>
					<MeetingDetailsSidebar
						isSidebarOpen={isSidebarOpen}
						toggleSidebar={toggleSidebar}
					/>
					<div
						className={`flex-1 flex flex-row ${isTablet ? "overflow-hidden" : ""}`}
					>
						<MeetingContent isSidebarOpen={isSidebarOpen} />
					</div>
				</MeetingDetailsContainer>
			</div>
		</MeetingContentNavigationProvider>
	);
};

export default MeetingDetails;
