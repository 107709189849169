import { Dialog, Flex, Button, Input } from "@mightybot/web-ui";
import styled from "styled-components";

export const DialogRoot = styled(Dialog.Root)``;

export const DialogTrigger = styled(Dialog.Trigger)``;

export const DialogContent = styled(Dialog.Content)`
	padding: 0px 0px 20px 0px;
`;

export const DialogTitle = styled(Dialog.Title)`
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--mb-gray-6);
	height: 60px;
	display: flex;
	padding: 0px 10px 0px 20px;
`;

export const DialogClose = styled(Dialog.Close)``;

export const InvitePeopleInput = styled(Input)`
	width: 100%;
	padding: 8px 12px;
	border-radius: 4px;
	font-size: 14px;
	height: 40px;

	&:focus {
		outline: none;
		border-color: var(--gray-8);
	}
`;

export const CopyButton = styled(Button)`
	padding: 8px 16px;
`;

export const SearchResults = styled(Flex)`
	max-height: 200px;
	overflow-y: auto;
`;

export const SearchResultItem = styled.div<{ disabled?: boolean }>`
	padding: 8px 12px;
	transition: background-color 0.2s;

	&:hover {
		background-color: ${(props) =>
			props.disabled ? "inherit" : "var(--mb-color-light-gray-2)"};
	}

	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
	pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const Role = styled(Flex)<{ color: string }>`
	padding: 4px 6px 4px 8px;
	align-items: center;
	justify-content: space-between;
	border-radius: 4px;
	background-color: ${(props) => `${props.color}`};
	font-size: 11px;
	width: 100%;
	max-width: 70px;
	flex-shrink: 0;
`;

export const UsersListContainer = styled(Flex)`
	margin-top: 12px;
	gap: 8px;
	max-height: 200px;
	overflow-y: auto;
`;
