import React from "react";
import NavBar from "../NavBar";
import {
	LayoutContainer,
	ContentContainer,
	InnerContentContainer,
	ScrollableContent,
} from "./styled";
import { SubscriptionOverlay } from "../Billing/SubscriptionOverlay/SubscriptionOverlay";
import { OnboardingOverlay } from "../Onboarding/OnboardingOverlay";
import { ConnectAppsReminder } from "../ConnectAppsReminder/ConnectAppsReminder";
import Tour from "../Tour";

interface LayOutProps {
	children: React.ReactNode;
}

const Layout: React.FC<LayOutProps> = ({ children }) => {
	return (
		<LayoutContainer>
			<NavBar />
			<Tour />
			<ContentContainer>
				<InnerContentContainer>
					<ScrollableContent>{children}</ScrollableContent>
					{/* <SubscriptionOverlay /> */}
					<ConnectAppsReminder />
					<OnboardingOverlay />
				</InnerContentContainer>
			</ContentContainer>
		</LayoutContainer>
	);
};

export default Layout;
