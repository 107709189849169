import React, { useState, useRef, useEffect } from "react";
import {
	Button,
	Flex,
	Text,
	Icon,
	breakpoints,
	useMediaQuery,
	AlertDialog,
} from "@mightybot/web-ui";
import {
	CopyButton,
	DialogContent,
	DialogTitle,
	DialogRoot,
	DialogTrigger,
	DialogClose,
} from "./styled";
import { AccessType, Meeting, Person, useMe, useShare } from "@mightybot/core";
import Toast from "../Toast/Toast";
import { GeneralAccess } from "./GeneralAccess";
import { InvitePeople } from "./InvitePeople";

interface ShareProps {
	trigger?: React.ReactNode;
	id?: string;
	shareData: Meeting | Person;
	onCopy: () => void;
	type: "meeting" | "person";
	notes?: string[];
}

const Share: React.FC<ShareProps> = ({
	trigger,
	id,
	shareData,
	onCopy,
	type = "meeting",
	notes,
}) => {
	if (!shareData) return null;
	const { data: user } = useMe();
	const [access, setAccess] = useState<AccessType>("private");
	const [selectedPeople, setSelectedPeople] = useState<string[]>([]);
	const [searchResultsOpen, setSearchResultsOpen] = useState(true);
	const { share, isSharing, sharedUsers, isLoadingUsers, refetchSharedUsers } =
		useShare(type, id);
	const inputRef = useRef<HTMLInputElement>(null);
	const searchResultsRef = useRef<HTMLDivElement>(null);

	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const searchContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				inputRef.current &&
				!inputRef.current.contains(event.target as Node) &&
				!searchResultsRef.current?.contains(event.target as Node)
			) {
				setSearchResultsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleSearchChange = () => {
		if (!searchResultsOpen) {
			setSearchResultsOpen(true);
		}
		setTimeout(() => {
			inputRef.current?.focus();
		}, 0);
	};

	const togglePersonSelection = (personId: string) => {
		setSelectedPeople((prev) =>
			prev.includes(personId)
				? prev.filter((id) => id !== personId)
				: [...prev, personId],
		);
	};

	const copyToClipboard = async () => {
		onCopy();
	};

	const [toastOpen, setToastOpen] = useState(false);
	const [toastMessage, setToastMessage] = useState({
		title: "",
		description: "",
		type: "success",
	});

	const [showAlert, setShowAlert] = useState(false);
	const [pendingAccessChange, setPendingAccessChange] =
		useState<AccessType | null>(null);

	const handleInvite = async (email: string) => {
		try {
			await share(id ?? "", "add", [email]);
			refetchSharedUsers();
			setSearchResultsOpen(false);
			setToastMessage({
				title: "Invited Successfully",
				description: `${email} has been invited to the ${type}`,
				type: "success",
			});
			setToastOpen(true);
		} catch (err) {
			console.error("Failed to invite user:", err);
			setToastMessage({
				title: "Invitation Failed",
				description: `Failed to invite user to the ${type}`,
				type: "error",
			});
			setToastOpen(true);
		}
	};

	const handleRemove = async (email: string) => {
		try {
			await share(id ?? "", "remove", [email]);
			refetchSharedUsers();
			setToastMessage({
				title: "Removed Successfully",
				description: `${email} has been removed from the ${type}`,
				type: "success",
			});
			setToastOpen(true);
		} catch (err) {
			console.error("Failed to remove user:", err);
			setToastMessage({
				title: "Remove Failed",
				description: `Failed to remove user from the ${type}`,
				type: "error",
			});
			setToastOpen(true);
		}
	};

	const onRadioChange = (e: React.MouseEvent<HTMLInputElement>) => {
		const newValue = e.currentTarget.value as AccessType;
		setPendingAccessChange(newValue);
		setShowAlert(true);
	};

	const handleConfirmAccessChange = async () => {
		if (!pendingAccessChange) return;

		setAccess(pendingAccessChange);
		handleOrganisationShare(pendingAccessChange === "organisation");
		setShowAlert(false);
		setPendingAccessChange(null);
	};

	const handleOrganisationShare = async (checked: boolean) => {
		if (!user?.enterprise_id) return;
		try {
			await share(
				id ?? "",
				checked ? "add" : "remove",
				undefined,
				user.enterprise_id,
			);
			setToastMessage({
				title: checked
					? "Shared with Organization"
					: "Removed Organization Access",
				description: checked
					? `${type} shared with your organization`
					: "Organization access removed",
				type: "success",
			});
			setToastOpen(true);
		} catch (err) {
			console.error("Failed to share with organisation:", err);
			setToastMessage({
				title: "Action Failed",
				description: "Failed to update organization access",
				type: "error",
			});
			setToastOpen(true);
		}
	};

	const isDisabled = !shareData || isSharing;
	const dialogTitle =
		type === "meeting"
			? `Share "${(shareData as Meeting).title}"`
			: `Share "${(shareData as Person).name}"`;

	const onOpenChange = (open: boolean) => {
		if (open) {
			inputRef.current?.focus();
			setSearchResultsOpen(true);
		}
	};

	return (
		<>
			<DialogRoot>
				<DialogTrigger>
					{trigger || (
						<Button
							size="2"
							radius="full"
							variant="white-bg"
							disabled={isDisabled}
							style={{
								height: "34px",
								background: isDisabled
									? "var(--mb-color-light-gray-2)"
									: "white",
								cursor: isDisabled ? "not-allowed" : "pointer",
								padding: "0 17px",
							}}
						>
							<Icon.ShareFat />
							{!isTablet && "Share"}
						</Button>
					)}
				</DialogTrigger>

				<DialogContent>
					<DialogTitle>
						<Text
							weight="medium"
							size="5"
							style={{
								maxWidth: "450px",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							}}
						>
							{dialogTitle}
						</Text>
						<DialogClose style={{ marginLeft: "auto" }}>
							<Icon.X size={24} style={{ cursor: "pointer" }} />
						</DialogClose>
					</DialogTitle>

					<Flex direction="column" gap="16px" p="20px" pt="0px">
						<GeneralAccess access={access} onRadioChange={onRadioChange} />
						<InvitePeople
							shareType={type}
							shareData={shareData}
							handleRemove={handleRemove}
							sharedUsers={sharedUsers?.users ?? []}
							searchContainerRef={searchContainerRef}
							searchResultsOpen={searchResultsOpen}
							inputRef={inputRef}
							searchResultsRef={searchResultsRef}
							handleSearchChange={handleSearchChange}
							handleInvite={handleInvite}
							isSharing={isSharing}
							onOpenChange={onOpenChange}
						/>

						{notes && (
							<Flex direction="column" gap="4px">
								<Text weight="medium" size="1">
									Note:
								</Text>
								{notes.map((note, index) => (
									<Flex key={index} gap="4px">
										<Text
											style={{
												color: "var(--gray-9)",
												whiteSpace: "pre-wrap",
												fontSize: "9px",
											}}
										>
											•
										</Text>
										<Text
											key={index}
											size="1"
											style={{
												color: "var(--gray-9)",
												whiteSpace: "pre-wrap",
											}}
										>
											{note}
										</Text>
									</Flex>
								))}
							</Flex>
						)}
					</Flex>

					<Flex gap="8px" justify="between" mt="4" px="20px">
						<DialogClose>
							<CopyButton variant="outline" onClick={copyToClipboard}>
								Copy Link
							</CopyButton>
						</DialogClose>
						<DialogClose>
							<Button>Done</Button>
						</DialogClose>
					</Flex>
				</DialogContent>
			</DialogRoot>
			<AlertDialog.Root open={showAlert} onOpenChange={setShowAlert}>
				<AlertDialog.Content style={{ maxWidth: "400px" }}>
					<AlertDialog.Title>Change Access Level</AlertDialog.Title>
					<AlertDialog.Description>
						{pendingAccessChange === "organisation"
							? "Are you sure you want to share this with everyone in your organization?"
							: "Are you sure you want to make this private?"}
					</AlertDialog.Description>
					<Flex gap="3" mt="4" justify="end">
						<AlertDialog.Cancel>
							<Button variant="outline">Cancel</Button>
						</AlertDialog.Cancel>
						<AlertDialog.Action>
							<Button onClick={handleConfirmAccessChange}>Confirm</Button>
						</AlertDialog.Action>
					</Flex>
				</AlertDialog.Content>
			</AlertDialog.Root>
			<Toast
				open={toastOpen}
				setOpen={setToastOpen}
				title={toastMessage.title}
				description={toastMessage.description}
				type={toastMessage.type as "success" | "error" | "info"}
			/>
		</>
	);
};

export default Share;
