import React from "react";
import { DataConsent, useUserPreferences } from "@mightybot/core";
import { Switch, Flex, Text } from "@mightybot/web-ui";
import {
	DataPreferencesContainer,
	DataPreferencesHeader,
	DataPreferencesContent,
	ToggleItem,
} from "./styled";

function DataPreferencesToggle() {
	const { userPreferences, updateUserPreferences, dbUserPreferences } =
		useUserPreferences();

	const updatePreferences = (preferences: any) => {
		let dataConsent: DataConsent[] = dbUserPreferences?.data_consent ?? [];
		if (
			!dataConsent ||
			(typeof dataConsent === "object" && Object.keys(dataConsent).length === 0)
		)
			dataConsent = [];
		dataConsent = [...dataConsent];
		Object.keys(preferences).forEach((key) => {
			const appId = key.replace("Toggled", "");
			const consentIndex = dataConsent?.findIndex(
				(consent: DataConsent) => consent.app_id === appId,
			);
			if (consentIndex !== -1) {
				dataConsent[consentIndex] = {
					...dataConsent[consentIndex],
					consent: preferences[key],
					consent_date: new Date().toISOString(),
					version: process.env.EXTENSION_VERSION ?? "",
				};
			} else {
				dataConsent.push({
					app_id: key.replace("Toggled", ""),
					consent: preferences[key],
					consent_date: new Date().toISOString(),
					version: process.env.EXTENSION_VERSION ?? "",
				});
			}
		});
		updateUserPreferences({ data_consent: dataConsent });
	};

	return (
		<DataPreferencesContainer>
			<DataPreferencesHeader>
				<Text weight="medium" size="4">
					Data collection preferences
				</Text>
			</DataPreferencesHeader>
			<DataPreferencesContent>
				<Flex direction="column" gap="3">
					<Text weight="medium" size="2">
						Categories and purpose of use
					</Text>
					<ToggleItem>
						<Flex direction="column" gap="1">
							<Text weight="regular" size="2">
								Quality & Performance Optimization
							</Text>
							<Text size="1" style={{ color: "var(--mb-color-gray-11)" }}>
								To test, debug, and evaluate user outputs and performance
								monitoring.
							</Text>
						</Flex>
						<Switch
							checked={userPreferences.qualityPerformanceToggled ?? false}
							onCheckedChange={() =>
								updatePreferences({
									langsmithToggled: !userPreferences.qualityPerformanceToggled,
									portkeyToggled: !userPreferences.qualityPerformanceToggled,
								})
							}
						/>
					</ToggleItem>
					<ToggleItem>
						<Flex direction="column" gap="1">
							<Text weight="regular" size="2">
								User Analytics
							</Text>
							<Text size="1" style={{ color: "var(--mb-color-gray-11)" }}>
								Event tracking, user session recordings, and heatmaps of product
								usage. This provides valuable behavioral insights.
							</Text>
						</Flex>
						<Switch
							checked={userPreferences.userAnalyticsToggled ?? false}
							onCheckedChange={() =>
								updatePreferences({
									posthogToggled: !userPreferences.userAnalyticsToggled,
								})
							}
						/>
					</ToggleItem>
				</Flex>
			</DataPreferencesContent>
		</DataPreferencesContainer>
	);
}

export default DataPreferencesToggle;
