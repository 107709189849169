import React from "react";
import {
	Text,
	Flex,
	Skeleton,
	breakpoints,
	useMediaQuery,
} from "@mightybot/web-ui";
import {
	ChatsHeaderOuterContainer,
	ChatsHeaderInnerContainer,
	ChatsDefaultTitle,
} from "./styled";
import { useConversation } from "@mightybot/core";
import { useParams } from "react-router-dom";
import { UUID } from "crypto";
import GenerateDoc from "../GenerateDoc";
import ModelSelection from "../ModelSelection";
import { formatTimestamp } from "../../utils/helpers";

const ChatsHeader: React.FC = () => {
	const { chatId: activeConversationId } = useParams();
	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const { conversation, isLoading } = useConversation(
		activeConversationId as UUID,
	);

	if (!activeConversationId)
		return <ChatsDefaultTitle>Ask a question or do a task</ChatsDefaultTitle>;

	return (
		<ChatsHeaderOuterContainer justify="between" align="center">
			<ChatsHeaderInnerContainer>
				<Flex align="center" style={{ width: "100%" }}>
					{isLoading ? (
						<>
							<Skeleton width="300px" height="24px" />
						</>
					) : (
						<Flex align="center" justify="between" style={{ width: "100%" }}>
							<Text
								weight="medium"
								size="4"
								style={{
									flex: 0.8,
									paddingRight: "10px",
									overflow: "hidden",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
								}}
							>
								{!conversation || !conversation.title
									? "New Chat"
									: conversation?.title}
							</Text>
							<Flex
								align="center"
								style={{
									gap: "5px",
								}}
							>
								<Flex justify="end" style={{ alignSelf: "center" }}>
									<Text
										weight="light"
										size="1"
										style={{
											color: "var(--mb-color-light-gray-9)",
											flexShrink: 0,
										}}
									>
										{formatTimestamp(conversation?.created_ts ?? "")}
									</Text>
								</Flex>
								<ModelSelection />
							</Flex>
						</Flex>
					)}
				</Flex>
				{/* {!isMobile && (
					<Flex align="start" justify="end" gap="2" style={{ width: "100%" }}>
						<Flex align="center" gap="2">
							{isLoading ? (
								<Skeleton width="120px" height="32px" />
							) : (
								<Flex align="center" gap="5px">
									<GenerateDoc />
									<ModelSelection />
								</Flex>
							)}
						</Flex>
					</Flex>
				)} */}
			</ChatsHeaderInnerContainer>
		</ChatsHeaderOuterContainer>
	);
};

export default ChatsHeader;
