import React, {
	useEffect,
	useRef,
	useState,
	useCallback,
	useMemo,
} from "react";
import { useInView } from "react-intersection-observer";
import { Flex, Spinner } from "@mightybot/web-ui";
import { InfiniteScrollContainer } from "./styled";

interface InfiniteScrollProps<T> {
	data: T[];
	isLoading: boolean;
	error: Error | null;
	hasMore: boolean;
	onLoadMore: () => Promise<void>;
	renderItems: (items: T[]) => React.ReactNode;
	loadingComponent?: React.ReactNode;
	endMessage?: React.ReactNode;
	showNoMoreItems?: boolean;
	style?: React.CSSProperties;
}

export function InfiniteScroll<T>({
	data,
	isLoading,
	error,
	hasMore,
	onLoadMore,
	renderItems,
	loadingComponent = <Spinner />,
	endMessage = <p>No more items to load</p>,
	showNoMoreItems = true,
	style,
}: InfiniteScrollProps<T>) {
	const containerRef = useRef<HTMLDivElement>(null);
	const isLoadingRef = useRef(isLoading);
	const fetchingRef = useRef(false);

	const observerOptions = useMemo(
		() => ({
			threshold: 0.1,
			rootMargin: "200px",
			root: null,
		}),
		[],
	);

	const [bottomRef, bottomInView] = useInView(observerOptions);

	const handleFetchNext = useCallback(async () => {
		if (fetchingRef.current || isLoadingRef.current || !hasMore) return;
		fetchingRef.current = true;
		try {
			await onLoadMore();
		} finally {
			fetchingRef.current = false;
		}
	}, [hasMore, onLoadMore]);

	useEffect(() => {
		isLoadingRef.current = isLoading;
	}, [isLoading]);

	useEffect(() => {
		if (bottomInView) {
			handleFetchNext();
		}
	}, [bottomInView, handleFetchNext]);

	const content = useMemo(() => {
		if (error) return <p>Error fetching data: {error.message}</p>;

		return renderItems(data);
	}, [data, isLoading, error, loadingComponent, renderItems]);

	return (
		<InfiniteScrollContainer ref={containerRef} style={style}>
			{content}

			<Flex
				justify="center"
				align="center"
				ref={bottomRef}
				style={{
					width: "100%",
					minHeight: showNoMoreItems ? "40px" : "0px",
					height: hasMore && isLoading ? "40px" : "auto",
					backgroundColor: "var(--gray-3)",
				}}
			>
				{hasMore && isLoading ? loadingComponent : null}
				{!hasMore && !isLoading && showNoMoreItems && endMessage}
			</Flex>
		</InfiniteScrollContainer>
	);
}
