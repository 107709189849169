import React, { useEffect, useRef, useState } from "react";
import { Flex, Skeleton, Text } from "@mightybot/web-ui";
import { Plus, DotsThree } from "@phosphor-icons/react";
import {
	TeamsContainer,
	Header,
	HeaderTitle,
	SearchInput,
	AddMemberButton,
	TableHeader,
	TableCell,
	TableRow,
	UserInfo,
	UserDetails,
	StatusBadge,
	Avatar,
	ActionButton,
	TeamsContentContainer,
	MenuContainer,
	ActionContainer,
} from "./styled";
import { AddMemberModal } from "./AddMemberModal";
import { useTeams, useMe } from "@mightybot/core";
import Toast from "../Toast";

const LoadingRow = () => (
	<TableRow>
		<UserInfo>
			<Skeleton width="40px" height="40px" style={{ borderRadius: "50%" }} />
			<UserDetails>
				<Skeleton width="120px" height="18px" />
				<Skeleton width="160px" height="16px" style={{ marginTop: "4px" }} />
			</UserDetails>
		</UserInfo>
		<TableCell>
			<Skeleton width="80px" height="18px" />
		</TableCell>
		<TableCell>
			<Skeleton width="100px" height="18px" />
		</TableCell>
		<TableCell>
			<Skeleton width="80px" height="24px" style={{ borderRadius: "12px" }} />
		</TableCell>
		<TableCell>
			<Skeleton width="24px" height="24px" />
		</TableCell>
	</TableRow>
);

export const Teams: React.FC = () => {
	const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
	const [openToast, setOpenToast] = useState(false);
	const { data: user } = useMe();
	const [toastData, setToastData] = useState<{
		title: string;
		status: "success" | "error" | "info";
		description: string;
	}>({
		title: "Team Management",
		status: "success",
		description: "",
	});

	const {
		members,
		invites,
		isLoading,
		searchQuery,
		setSearchQuery,
		addMember,
		removeMember,
		resendInvite,
		cancelInvite,
		isTeamAdmin,
		totalSeats,
		refetch,
		updateMemberRole,
	} = useTeams();

	const canManageTeam = user?.role === "OWNER" || user?.role === "ADMIN";
	const isGmailUser = user?.email?.toLowerCase().endsWith("@gmail.com");
	const userDomain = user?.email?.split("@")[1];

	const validateEmails = (emails: string[]): boolean => {
		if (isGmailUser) {
			setToastData({
				title: "Team Members",
				status: "error",
				description: "Non enterprise users cannot invite other members",
			});
			setOpenToast(true);
			return false;
		}

		// For enterprise users, check if all emails are from the same domain
		if (!isGmailUser && userDomain) {
			const invalidEmails = emails.filter(
				(email) => !email.toLowerCase().endsWith(`@${userDomain}`),
			);
			if (invalidEmails.length > 0) {
				setToastData({
					title: "Team Members",
					status: "error",
					description: `You can only invite users from your Enterprise domain (${userDomain}) only`,
				});
				setOpenToast(true);
				return false;
			}
		}

		return true;
	};

	const handleAddMember = async (emails: string[]) => {
		if (!canManageTeam) return;
		if (!validateEmails(emails)) return;

		try {
			const success = await addMember(emails);
			if (success) {
				setIsAddMemberModalOpen(false);
				setToastData({
					title: "Team Members",
					status: "success",
					description: `Successfully added ${emails.length} member${emails.length > 1 ? "s" : ""}`,
				});
				setOpenToast(true);
				await refreshMembers();
			} else {
				setToastData({
					title: "Team Members",
					status: "error",
					description: "Failed to add team member(s)",
				});
				setOpenToast(true);
			}
		} catch (error) {
			setToastData({
				title: "Team Members",
				status: "error",
				description: "Failed to add team member(s)",
			});
			setOpenToast(true);
		}
	};

	const handleRemoveMember = async (email: string) => {
		if (!canManageTeam) return;

		// Prevent removing self
		if (email === user?.email) {
			setToastData({
				title: "Team Members",
				status: "error",
				description: "You cannot remove yourself from the team",
			});
			setOpenToast(true);
			return;
		}

		// Find member to check if they're an owner
		const memberToRemove = members.find((m) => m.email === email);
		if (memberToRemove?.role === "OWNER") {
			setToastData({
				title: "Team Members",
				status: "error",
				description: "Team owner cannot be removed",
			});
			setOpenToast(true);
			return;
		}

		try {
			const success = await removeMember(email);
			if (success) {
				setToastData({
					title: "Team Members",
					status: "success",
					description: "Successfully removed team member",
				});
				setOpenToast(true);
				await refreshMembers();
			} else {
				setToastData({
					title: "Team Members",
					status: "error",
					description: "Failed to remove team member",
				});
				setOpenToast(true);
			}
		} catch (error) {
			setToastData({
				title: "Team Members",
				status: "error",
				description: "Failed to remove team member",
			});
			setOpenToast(true);
		}
	};

	const handleResendInvite = async (inviteId: string) => {
		if (!canManageTeam) return;
		try {
			const success = await resendInvite(inviteId);
			if (success) {
				setToastData({
					title: "Team Invites",
					status: "success",
					description: "Successfully resent invitation",
				});
				setOpenToast(true);
				await refreshMembers();
			} else {
				setToastData({
					title: "Team Invites",
					status: "error",
					description: "Failed to resend invitation",
				});
				setOpenToast(true);
			}
		} catch (error) {
			setToastData({
				title: "Team Invites",
				status: "error",
				description: "Failed to resend invitation",
			});
			setOpenToast(true);
		}
	};

	const handleCancelInvite = async (inviteId: string) => {
		if (!canManageTeam) return;
		try {
			const success = await cancelInvite(inviteId);
			if (success) {
				setToastData({
					title: "Team Invites",
					status: "success",
					description: "Successfully cancelled invitation",
				});
				setOpenToast(true);
				await refreshMembers();
			} else {
				setToastData({
					title: "Team Invites",
					status: "error",
					description: "Failed to cancel invitation",
				});
				setOpenToast(true);
			}
		} catch (error) {
			setToastData({
				title: "Team Invites",
				status: "error",
				description: "Failed to cancel invitation",
			});
			setOpenToast(true);
		}
	};

	const handleUpdateRole = async (email: string, role: string) => {
		if (!canManageTeam) return;

		try {
			const success = await updateMemberRole(email, role);
			if (success) {
				setToastData({
					title: "Team Members",
					status: "success",
					description: `Successfully updated member role to ${role.toLowerCase()}`,
				});
				setOpenToast(true);
				await refreshMembers();
			} else {
				setToastData({
					title: "Team Members",
					status: "error",
					description: "Failed to update member role",
				});
				setOpenToast(true);
			}
		} catch (error) {
			setToastData({
				title: "Team Members",
				status: "error",
				description: "Failed to update member role",
			});
			setOpenToast(true);
		}
	};

	const refreshMembers = async () => {
		try {
			await refetch();
		} catch (error) {
			console.error("Failed to refresh members", error);
		}
	};

	const ActionMenu: React.FC<{
		memberId: string;
		memberEmail?: string;
		inviteId?: string;
		memberRole?: string;
		onRemoveMember?: (email: string) => void;
		onResendInvite?: (id: string) => void;
		onCancelInvite?: (id: string) => void;
		onUpdateRole?: (email: string, role: string) => void;
	}> = ({
		memberId,
		memberEmail,
		inviteId,
		memberRole,
		onRemoveMember,
		onResendInvite,
		onCancelInvite,
		onUpdateRole,
	}) => {
		const [isOpen, setIsOpen] = useState(false);
		const menuRef = useRef<HTMLDivElement>(null);

		useEffect(() => {
			const handleClickOutside = (event: MouseEvent) => {
				if (
					menuRef.current &&
					!menuRef.current.contains(event.target as Node)
				) {
					setIsOpen(false);
				}
			};

			document.addEventListener("mousedown", handleClickOutside);
			return () =>
				document.removeEventListener("mousedown", handleClickOutside);
		}, []);

		// Don't show menu for self or owners
		const showMenu =
			canManageTeam &&
			((memberEmail &&
				memberRole !== "Admin" &&
				memberEmail !== user?.email &&
				memberRole !== "OWNER") ||
				inviteId);

		const handleRoleChange = (newRole: string) => {
			if (memberEmail && onUpdateRole) {
				onUpdateRole(memberEmail, newRole);
				setIsOpen(false);
			}
		};

		return (
			<ActionContainer ref={menuRef}>
				{showMenu ? (
					<>
						<ActionButton onClick={() => setIsOpen(!isOpen)}>
							<DotsThree color="var(--mb-gray-12)" size={24} />
						</ActionButton>
						{isOpen && (
							<MenuContainer>
								{memberEmail &&
									memberRole !== "OWNER" &&
									memberEmail !== user?.email && (
										<>
											{memberRole === "MEMBER" && (
												<button onClick={() => handleRoleChange("ADMIN")}>
													Make Admin
												</button>
											)}
											{memberRole === "ADMIN" && (
												<button onClick={() => handleRoleChange("MEMBER")}>
													Remove Admin
												</button>
											)}
											<button
												onClick={() => {
													onRemoveMember?.(memberEmail);
													setIsOpen(false);
												}}
											>
												Remove member
											</button>
										</>
									)}
								{inviteId && (
									<>
										<button
											onClick={() => {
												onResendInvite?.(inviteId);
												setIsOpen(false);
											}}
										>
											Resend Invite
										</button>
										<button
											onClick={() => {
												onCancelInvite?.(inviteId);
												setIsOpen(false);
											}}
										>
											Cancel Invite
										</button>
									</>
								)}
							</MenuContainer>
						)}
					</>
				) : null}
			</ActionContainer>
		);
	};
	return (
		<TeamsContainer>
			<Header>
				<HeaderTitle>
					{isLoading ? (
						<Skeleton width="200px" height="24px" />
					) : (
						<Text size="5" weight="medium">
							Team members ({members.length + invites.length}/{totalSeats})
						</Text>
					)}
				</HeaderTitle>
				<Flex gap="10px">
					<SearchInput
						placeholder="Search members"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
					{canManageTeam && !isGmailUser && (
						<AddMemberButton onClick={() => setIsAddMemberModalOpen(true)}>
							<Plus size={20} weight="bold" />
							Add member
						</AddMemberButton>
					)}
				</Flex>
			</Header>
			<TeamsContentContainer>
				<TableHeader>
					<TableCell>Name</TableCell>
					<TableCell>Role</TableCell>
					<TableCell>Date added</TableCell>
					<TableCell>Status</TableCell>
					{canManageTeam && <TableCell>Actions</TableCell>}
				</TableHeader>

				{isLoading ? (
					<>
						{Array(4)
							.fill(0)
							.map((_, index) => (
								<LoadingRow key={`loading-${index}`} />
							))}
					</>
				) : (
					<>
						{members.map((member) => (
							<TableRow key={member.id}>
								<UserInfo>
									<Avatar bgColor={member.avatarColor}>
										{member.name.charAt(0)}
									</Avatar>
									<UserDetails>
										<Text size="2" weight="regular">
											{member.name}
										</Text>
										<Text size="2" weight="regular" color="gray">
											{member.email}
										</Text>
									</UserDetails>
								</UserInfo>
								<TableCell>{member.role}</TableCell>
								<TableCell>
									{new Date(member.dateAdded).toLocaleDateString("en-US", {
										month: "short",
										day: "numeric",
										year: "numeric",
									})}
								</TableCell>

								<TableCell>
									<StatusBadge status={member.status}>
										{member.status}
									</StatusBadge>
								</TableCell>
								{canManageTeam && (
									<TableCell>
										<ActionMenu
											memberId={member.id}
											memberEmail={member.email}
											memberRole={member.role}
											onRemoveMember={handleRemoveMember}
											onUpdateRole={handleUpdateRole}
										/>
									</TableCell>
								)}
							</TableRow>
						))}

						{invites.map((invite) => (
							<TableRow key={invite.id} style={{ opacity: 0.7 }}>
								<UserInfo>
									<Avatar bgColor="var(--mb-color-light-gray-7)">
										{invite.email.charAt(0)}
									</Avatar>
									<UserDetails>
										<Text size="2">{invite.email}</Text>
									</UserDetails>
								</UserInfo>
								<TableCell>Pending</TableCell>
								<TableCell>
									{new Date(invite.invitedAt).toLocaleDateString("en-US", {
										month: "short",
										day: "numeric",
										year: "numeric",
									})}
								</TableCell>
								<TableCell>
									<StatusBadge status="pending">Invited</StatusBadge>
								</TableCell>
								{canManageTeam && (
									<TableCell>
										<ActionMenu
											memberId={invite.id}
											inviteId={invite.id}
											onResendInvite={handleResendInvite}
											onCancelInvite={handleCancelInvite}
										/>
									</TableCell>
								)}
							</TableRow>
						))}
					</>
				)}
			</TeamsContentContainer>

			<AddMemberModal
				open={isAddMemberModalOpen}
				onOpenChange={setIsAddMemberModalOpen}
				onSubmit={handleAddMember}
			/>
			<Toast
				open={openToast}
				setOpen={setOpenToast}
				title={toastData.title}
				description={toastData.description}
				type={toastData.status}
			/>
		</TeamsContainer>
	);
};

export default Teams;
