import { Flex, Text } from "@mightybot/web-ui";
import {
	InfoCardContainer,
	IconWrapper,
	InfoContent,
	LabelText,
	ValueLink,
	ValueText,
} from "./styled";
import React from "react";

interface UserInfoCardProps {
	icon: React.ReactNode;
	label: string;
	value: string | undefined;
	link?: string;
}

const UserInfoCard: React.FC<UserInfoCardProps> = React.memo(
	({ icon, label, value, link }) => {
		const handleClick = React.useCallback(
			(e: React.MouseEvent<HTMLAnchorElement>) => {
				if (!link) {
					e.preventDefault();
				}
			},
			[link],
		);

		return (
			<InfoCardContainer>
				<IconWrapper>{icon}</IconWrapper>
				<InfoContent>
					<LabelText size="2" weight="light">
						{label}
					</LabelText>
					{/* <Flex align="center" width="100%"> */}
					{link ? (
						<ValueLink
							href={link}
							onClick={handleClick}
							rel="noopener noreferrer"
							target="_blank"
							aria-label={`${label}: ${value}`}
						>
							<Text size="3">{value || "N/A"}</Text>
						</ValueLink>
					) : (
						<ValueText size="3">{value || "N/A"}</ValueText>
					)}
					{/* </Flex> */}
				</InfoContent>
			</InfoCardContainer>
		);
	},
);

UserInfoCard.displayName = "UserInfoCard";

export default UserInfoCard;
