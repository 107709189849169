import React from "react";
import { Text, Flex, Spinner } from "@mightybot/web-ui";
import { professionalFeatures, enterpriseFeatures } from "./planData";
import {
	PlanPrice,
	ContactSalesButton,
	TrialBadge,
	PlanFeatureList,
	TextSection,
} from "../styled";
import { TileGridCard, TileGridLayout } from "../BillingContent";
import { Subscription } from "@mightybot/core";

interface PlanTilesProps {
	plan?: {
		name: string;
		prices: {
			monthly: number;
			yearly: number;
			currency: string;
		};
		max_seats?: number;
	};
	billingCycle?: "monthly" | "yearly";
	subscription?: Subscription;
	isOnTrial?: boolean;
	isCreatingIntent?: boolean;
	onSubscribe?: () => void;
	showPricing?: boolean;
}

export const PlanTiles: React.FC<PlanTilesProps> = ({
	plan,
	billingCycle = "monthly",
	subscription,
	isOnTrial,
	isCreatingIntent,
	onSubscribe,
	showPricing = true,
}) => {
	const price =
		billingCycle === "yearly" ? plan?.prices?.yearly : plan?.prices?.monthly;

	return (
		<TileGridLayout>
			<TileGridCard>
				<Text
					size="2"
					style={{
						marginBottom: "10px",
						color: "var(--mb-color-dark-gray-2)",
						fontWeight: "400",
						textTransform: "uppercase",
						lineHeight: "18px",
						letterSpacing: "1.40px",
					}}
				>
					{plan?.name || "Professional"}
				</Text>
				{showPricing && (
					<PlanPrice>
						<Text
							weight="medium"
							style={{ lineHeight: "36px", fontSize: "44px" }}
						>
							${price}
						</Text>
						<Text size="2" color="gray">
							/{billingCycle === "yearly" ? "Year" : "Month"}/User
						</Text>
					</PlanPrice>
				)}
				{!subscription ||
				subscription?.cancelled_at ||
				isOnTrial ||
				subscription?.status !== "ACTIVE" ? (
					<ContactSalesButton onClick={onSubscribe} disabled={isCreatingIntent}>
						{isCreatingIntent ? (
							<Flex align="center" gap="1">
								<Spinner color="white" size="16px" />
								<Text style={{ marginLeft: "4px" }}>Processing...</Text>
							</Flex>
						) : (
							"Subscribe"
						)}
					</ContactSalesButton>
				) : (
					<TrialBadge>
						<Text size="3" weight="medium">
							Subscribed
						</Text>
					</TrialBadge>
				)}
				<Text style={{ marginBottom: "10px" }} size="2" color="gray">
					Maximum {plan?.max_seats ? plan?.max_seats : "10"} seats
				</Text>
				<TextSection>
					<Text style={{ textAlign: "center" }} weight="medium">
						Plans feature
					</Text>
					<PlanFeatureList>
						{professionalFeatures.map((feature, index) => (
							<li
								key={index}
								style={{
									listStyleType: "none",
									display: "flex",
									alignItems: "baseline",
								}}
							>
								<Text size="2" weight="medium" style={{ marginRight: "8px" }}>
									•
								</Text>
								<span>{feature}</span>
							</li>
						))}
					</PlanFeatureList>
				</TextSection>
			</TileGridCard>

			<TileGridCard>
				{/* Enterprise card content - similar structure */}
				<Text
					size="2"
					style={{
						marginBottom: "10px",
						color: "var(--mb-color-dark-gray-2)",
						fontWeight: "400",
						textTransform: "uppercase",
						lineHeight: "18px",
						letterSpacing: "1.40px",
					}}
				>
					Enterprise
				</Text>
				<PlanPrice>
					<Text
						weight="medium"
						style={{ lineHeight: "36px", fontSize: "44px" }}
					>
						Custom
					</Text>
				</PlanPrice>
				<ContactSalesButton
					onClick={() =>
						window.open(
							"https://www.mightybot.ai/get-a-demo",
							"_blank",
							"noopener,noreferrer",
						)
					}
				>
					Contact Sales
				</ContactSalesButton>
				<Text style={{ marginBottom: "10px" }} size="2" color="gray">
					Minimum 10 seats
				</Text>
				<TextSection>
					<Text style={{ textAlign: "center" }} weight="medium">
						Everything in Professional, plus:
					</Text>
					<PlanFeatureList>
						{enterpriseFeatures.map((feature, index) => (
							<li
								key={index}
								style={{
									listStyleType: "none",
									display: "flex",
									alignItems: "baseline",
								}}
							>
								<Text size="2" weight="medium" style={{ marginRight: "8px" }}>
									•
								</Text>
								<span>{feature}</span>
							</li>
						))}
					</PlanFeatureList>
				</TextSection>
			</TileGridCard>
		</TileGridLayout>
	);
};
