import React, { useEffect, useState } from "react";
import {
	Flex,
	Text,
	Popover,
	AvatarWrapper,
	Button,
	Icon,
} from "@mightybot/web-ui";
import { Role, SearchResults, UsersListContainer } from "./styled";
import { SearchResultItem } from "./styled";
import { InvitePeopleInput } from "./styled";
import {
	Meeting,
	MeetingAttendee,
	useMe,
	User,
	useRelevantPeople,
	Person,
} from "@mightybot/core";

interface InvitePeopleProps {
	searchContainerRef: React.RefObject<HTMLDivElement>;
	searchResultsOpen: boolean;
	inputRef: React.RefObject<HTMLInputElement>;
	searchResultsRef: React.RefObject<HTMLDivElement>;
	handleSearchChange: () => void;
	handleInvite: (email: string) => void;
	isSharing: boolean;
	onOpenChange: (open: boolean) => void;
	sharedUsers: Partial<User>[];
	shareData: Meeting | Person;
	handleRemove: (email: string) => void;
	shareType: "meeting" | "person";
}

interface UsersListProps {
	users: Partial<User>[];
	shareData: Meeting | Person;
	handleRemove: (email: string) => void;
	shareType: "meeting" | "person";
}

interface UserRole {
	type: "host" | "participant" | "shared";
	color: string;
}

const roleConfig: Record<UserRole["type"], { label: string; color: string }> = {
	host: { label: "Host", color: "var(--mb-blue-2)" },
	participant: { label: "Participant", color: "var(--mb-blue-2)" },
	shared: { label: "Shared", color: "var(--mb-blue-2)" },
};

const UsersList = ({
	users,
	shareData,
	handleRemove,
	shareType,
}: UsersListProps) => {
	if (!users?.length) return null;

	const getUserRole = (email: string): UserRole => {
		if (shareType === "person") {
			return { type: "shared", color: roleConfig.shared.color };
		}

		const isHost = (
			(shareData as Meeting).attendees as MeetingAttendee[]
		)?.some(
			(attendee: MeetingAttendee) =>
				attendee.email === email && attendee.organizer,
		);
		if (isHost) {
			return { type: "host", color: roleConfig.host.color };
		}

		const isParticipant = (
			(shareData as Meeting).attendees as MeetingAttendee[]
		)?.some(
			(attendee: MeetingAttendee) =>
				attendee.email === email && !attendee.organizer,
		);
		if (isParticipant) {
			return { type: "participant", color: roleConfig.participant.color };
		}

		return { type: "shared", color: roleConfig.shared.color };
	};

	return (
		<UsersListContainer direction="column">
			{users.map((user) => {
				const role = getUserRole(user.email ?? "");
				return (
					<Flex
						key={user.id}
						align="center"
						justify="between"
						style={{ width: "100%" }}
					>
						<Flex gap="2" align="center">
							<AvatarWrapper
								src={user.profile_pic || ""}
								alt={user.email ?? ""}
								size="1"
								radius="full"
								// fallback={user.email?.[0] || ""}
							/>
							<Flex direction="column">
								<Text weight="medium">{user.name || user.email}</Text>
								{user.name && (
									<Text size="1" style={{ color: "var(--mb-gray-7)" }}>
										{user.email}
									</Text>
								)}
							</Flex>
						</Flex>
						{role.type === "shared" ? (
							<Popover.Root>
								<Popover.Trigger>
									<Role
										color={role.color}
										style={{
											cursor: role.type === "shared" ? "pointer" : "default",
										}}
									>
										<Text style={{ fontSize: "11px" }}>
											{roleConfig[role.type].label}
										</Text>
										<Icon.CaretDown size={12} style={{ flexShrink: 0 }} />
									</Role>
								</Popover.Trigger>
								<Popover.Content style={{ padding: "8px", minWidth: "100px" }}>
									<Popover.Close>
										<Flex direction="column">
											<Button
												variant="ghost"
												size="1"
												onClick={() => handleRemove(user.email ?? "")}
												style={{
													justifyContent: "flex-start",
													color: "var(--mb-color-red-9)",
												}}
											>
												<Icon.Trash size={14} />
												Remove
											</Button>
										</Flex>
									</Popover.Close>
								</Popover.Content>
							</Popover.Root>
						) : (
							<Role color={role.color}>{roleConfig[role.type].label}</Role>
						)}
					</Flex>
				);
			})}
		</UsersListContainer>
	);
};

export const InvitePeople = ({
	searchContainerRef,
	searchResultsOpen,
	inputRef,
	searchResultsRef,
	handleSearchChange,
	handleInvite,
	isSharing,
	onOpenChange,
	sharedUsers,
	shareData,
	handleRemove,
	shareType,
}: InvitePeopleProps) => {
	const { data: user } = useMe();
	const [searchQuery, setSearchQuery] = useState("");

	const { people, isLoading: isLoadingPeople } = useRelevantPeople({
		searchTerm: searchQuery,
		enterpriseId: user?.enterprise_id ?? "",
		onlyEnterprisePeople: true,
	});

	const filteredPeople = React.useMemo(() => {
		if (!people || !sharedUsers) return people;

		const sharedEmails = new Set(
			sharedUsers.map((user) => user.email?.toLowerCase()),
		);

		return people.filter(
			(person) => !sharedEmails.has(person.email?.toLowerCase() ?? ""),
		);
	}, [people, sharedUsers]);

	const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value;
		setSearchQuery(query);
		handleSearchChange();
	};

	return (
		<Flex
			direction="column"
			gap="10px"
			ref={searchContainerRef}
			id="invite-people-container"
		>
			<Text>Invite People</Text>
			<InvitePeopleInput
				placeholder="Search people..."
				onChange={onSearchChange}
				value={searchQuery}
				ref={inputRef}
			/>
			<Popover.Root
				open={searchQuery.length > 0 && searchResultsOpen}
				onOpenChange={onOpenChange}
			>
				<Popover.Content
					side="bottom"
					align="start"
					style={{
						maxHeight: "200px",
						maxWidth: "600px",
						width: `calc(${inputRef.current?.offsetWidth}px + 24px)`,
						overflow: "auto",
						padding: 0,
						zIndex: 1000,
						position: "fixed",
						top: (inputRef.current?.getBoundingClientRect().bottom ?? 0) + 10,
						left: `calc(${
							inputRef.current?.getBoundingClientRect().left ?? 0
						}px - 12px)`,
					}}
				>
					<SearchResults
						direction="column"
						style={{ width: "100%" }}
						ref={searchResultsRef}
					>
						{filteredPeople.map((person) => (
							<SearchResultItem
								key={person.id}
								onClick={(e) => {
									e.stopPropagation();
									handleInvite(person.email ?? "");
								}}
								style={{ cursor: isSharing ? "not-allowed" : "pointer" }}
								disabled={isSharing}
							>
								<Flex
									align="center"
									justify="between"
									style={{ width: "100%" }}
								>
									<Flex gap="2" align="center">
										<AvatarWrapper
											src={person.profile_pic || ""}
											alt={person.name || person.email || ""}
											size="1"
											radius="full"
											fallback={person.name?.[0] || person.email?.[0] || ""}
										/>
										<Flex direction="column">
											<Text weight="medium">{person.name || person.email}</Text>
											{person.name && (
												<Text size="1" style={{ color: "var(--mb-gray-7)" }}>
													{person.email}
												</Text>
											)}
										</Flex>
									</Flex>
									{isSharing ? (
										<Text size="1" style={{ color: "var(--mb-gray-7)" }}>
											Inviting...
										</Text>
									) : (
										<Icon.Plus
											size={16}
											style={{ color: "var(--mb-gray-7)" }}
										/>
									)}
								</Flex>
							</SearchResultItem>
						))}
					</SearchResults>
				</Popover.Content>
			</Popover.Root>

			<Text weight="medium" mt="2">
				Shared With
			</Text>
			<UsersList
				users={sharedUsers}
				shareData={shareData}
				handleRemove={handleRemove}
				shareType={shareType}
			/>
		</Flex>
	);
};
