import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
	NavBarContainer,
	NavBarLogo,
	NavigationMenu,
	NavigationMenuLink,
	NavigationMenuItem,
	IconBackground,
	UserPopoverContainer,
	MobileNavBar,
	MobileNavBarContent,
	MobileNavBarOverlay,
	ProfileFlyout,
	NavItemLabel,
} from "./styled";
import { MightyBotLogo } from "@mightybot/core-ui";
import {
	AvatarWrapper,
	Icon,
	Flex,
	Tooltip,
	Popover,
	Button,
	breakpoints,
	useMediaQuery,
} from "@mightybot/web-ui";
import { useMe, useFeatureFlag, TEAM_MEETINGS } from "@mightybot/core";
import Profile from "./Profile";
import { internalNavLinks, navLinks } from "./NavData";
import ContactUsOverlay from "../ContactUsOverlay/ContactUsOverlay";
import {
	Analytics,
	ANALYTICS_EVENTS,
	ANALYTICS_PROPERTIES,
} from "@mightybot/core";
import UnauthenticatedDialog from "../UnauthenticatedDialog";

export interface NavLinkItem {
	to: string;
	Icon: React.ComponentType<any>;
	label: string;
	type?: "internal" | "external";
	className?: string;
}

interface UserPopoverProps {
	isPopoverOpen: boolean;
	setIsPopoverOpen: (open: boolean) => void;
	enabled?: boolean;
}

interface RenderNavLinksProps {
	links: NavLinkItem[];
	setIsMobileMenuOpen?: (open: boolean) => void;
	setShowAuthDialog?: (open: boolean) => void;
}

const RenderNavLinks = ({
	links,
	setIsMobileMenuOpen,
	setShowAuthDialog,
}: RenderNavLinksProps) => {
	const { data: user } = useMe();
	const location = useLocation();
	const isSnippetView = location.pathname.startsWith("/snippets");

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);
	const isActive = (to: string) => window.location.pathname === to;

	const handleNavLinkClick = (
		to: string,
		label: string,
		type: string | undefined,
	) => {
		Analytics.trackEvent(ANALYTICS_EVENTS.NAV_ITEM_CLICKED, {
			[ANALYTICS_PROPERTIES.NAV_ITEM_NAME]: label,
			[ANALYTICS_PROPERTIES.NAV_ITEM_TYPE]: type,
			[ANALYTICS_PROPERTIES.NAV_LOCATION]: to,
			[ANALYTICS_PROPERTIES.DEVICE_TYPE]: isMobile ? "mobile" : "desktop",
		});

		if (isMobile) {
			setIsMobileMenuOpen?.(false);
		}
	};

	const handleNavItemClick = (
		e: React.MouseEvent,
		to: string,
		label: string,
		type: string | undefined,
	) => {
		if (isSnippetView && !user) {
			e.preventDefault();
			setShowAuthDialog?.(true);
			return;
		}

		handleNavLinkClick(to, label, type);
	};

	return (
		<Flex direction="column" gap={isMobile ? "0px" : "16px"}>
			{links.map(({ to, Icon, label, type, className }) => (
				<NavigationMenuItem key={to}>
					{type === "internal" ? (
						<NavLink
							to={to}
							aria-label={label}
							style={{ textDecoration: "none" }}
							className={className}
							onClick={(e) => handleNavItemClick(e, to, label, type)}
						>
							{({ isActive }) => (
								<NavigationMenuLink
									active={isActive.toString()}
									direction="column"
									justify="center"
									align="center"
								>
									<IconBackground
										active={isActive.toString()}
										justify="center"
										align="center"
									>
										<Icon
											size={20}
											fill={isMobile ? "var(--gray-12)" : "var(--gray-1)"}
											weight={isActive ? "fill" : "regular"}
										/>
									</IconBackground>
									{label !== "Settings" && <NavItemLabel>{label}</NavItemLabel>}
								</NavigationMenuLink>
							)}
						</NavLink>
					) : (
						<a
							href={to}
							aria-label={label}
							target="_blank"
							rel="noopener noreferrer"
							onClick={(e) => handleNavItemClick(e, to, label, type)}
							style={{ textDecoration: "none" }}
						>
							<NavigationMenuLink
								active={isActive(to).toString()}
								direction="column"
								justify="center"
								align="center"
							>
								<Tooltip content={label}>
									<IconBackground
										active={isActive(to).toString()}
										justify="center"
										align="center"
									>
										<Icon
											size={20}
											fill={isMobile ? "var(--gray-12)" : "var(--gray-1)"}
										/>
									</IconBackground>
								</Tooltip>
							</NavigationMenuLink>
						</a>
					)}
				</NavigationMenuItem>
			))}
		</Flex>
	);
};

const UserPopover = ({
	isPopoverOpen,
	setIsPopoverOpen,
	enabled = true,
}: UserPopoverProps) => {
	const { data: user } = useMe();

	return (
		<UserPopoverContainer>
			<Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
				<Popover.Trigger disabled={!enabled}>
					<Button
						variant="ghost"
						style={{
							background: "none",
							border: "none",
							cursor: "pointer",
						}}
					>
						<AvatarWrapper
							alt={user?.name ?? "User"}
							src={user?.profile_pic ?? ""}
							radius="full"
							variant="solid"
							size="2"
						/>
					</Button>
				</Popover.Trigger>
				<Popover.Content style={{ padding: 0 }}>
					<ProfileFlyout>
						<Profile setIsPopoverOpen={setIsPopoverOpen} />
					</ProfileFlyout>
				</Popover.Content>
			</Popover.Root>
		</UserPopoverContainer>
	);
};

const NavBar: React.FC = () => {
	const { data: user } = useMe();
	const location = useLocation();
	const isSnippetView = location.pathname.startsWith("/snippets");
	const isTeamMeetingsEnabled = useFeatureFlag(TEAM_MEETINGS);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isClosing, setIsClosing] = useState(false);
	const [showAuthDialog, setShowAuthDialog] = useState(false);

	const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const enabledNavLinks = useMemo( () => {
		return navLinks
			.filter( ( link ) => user?.is_admin || link.label !== "Admin" )
			.filter( ( link ) => {
				if ( link.label === "Teams" ) {
					return isTeamMeetingsEnabled;
				}
				return true;
			} );
	}, [ user?.is_admin, isTeamMeetingsEnabled ] );

	const toggleMobileMenu = () => {
		const newState = !isMobileMenuOpen;
		Analytics.trackEvent(ANALYTICS_EVENTS.NAV_MOBILE_MENU_OPENED, {
			[ANALYTICS_PROPERTIES.MENU_STATE]: newState ? "opened" : "closed",
		});

		if (isMobileMenuOpen) {
			setIsClosing(true);
			setTimeout(() => {
				setIsMobileMenuOpen(false);
				setIsClosing(false);
			}, 300);
		} else {
			setIsMobileMenuOpen(true);
		}
	};

	useEffect(() => {
		if (isMobileMenuOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
		}

		return () => {
			document.body.style.overflow = "unset";
		};
	}, [isMobileMenuOpen]);

	const handleProfileClick = () => {
		Analytics.trackEvent(ANALYTICS_EVENTS.NAV_PROFILE_OPENED, {
			[ANALYTICS_PROPERTIES.DEVICE_TYPE]: isMobile ? "mobile" : "desktop",
		});
		if (isSnippetView && !user) {
			setShowAuthDialog(true);
		}
	};

	if (isMobile) {
		return (
			<React.Fragment>
				<MobileNavBar>
					<Flex align="center" gap="12px" style={{ width: "100%" }}>
						<Button
							variant="ghost"
							onClick={toggleMobileMenu}
							style={{ cursor: "pointer" }}
						>
							<Icon.List size={24} fill="var(--gray-1)" />
						</Button>
						<NavBarLogo
							src={MightyBotLogo}
							alt="MightyBot Logo"
							style={{ marginBottom: "0px" }}
						/>
					</Flex>
					{user && (
						<UserPopover
							isPopoverOpen={isPopoverOpen}
							setIsPopoverOpen={setIsPopoverOpen}
						/>
					)}
				</MobileNavBar>
				<MobileNavBarOverlay
					onClick={toggleMobileMenu}
					className={isMobileMenuOpen ? "open" : ""}
				>
					<MobileNavBarContent
						onClick={(e: React.MouseEvent) => e.stopPropagation()}
						className={isMobileMenuOpen ? (isClosing ? "closing" : "open") : ""}
					>
						<Icon.List
							size={24}
							fill="var(--gray-12)"
							onClick={toggleMobileMenu}
							style={{ cursor: "pointer", marginBottom: "10px" }}
						/>
						{/* <Icon.X
							size={20}
							onClick={toggleMobileMenu}
							style={{ cursor: "pointer", alignSelf: "flex-end" }}
						/> */}
						<NavigationMenu>
							<RenderNavLinks
								links={enabledNavLinks}
								setIsMobileMenuOpen={setIsMobileMenuOpen}
								setShowAuthDialog={setShowAuthDialog}
							/>
							<Flex
								direction="column"
								style={{ marginTop: "auto", width: "100%" }}
							>
								<Profile
									profileOptions={internalNavLinks.filter(
										(link) => link.label !== "Install",
									)}
								/>
							</Flex>
							<ContactUsOverlay />
						</NavigationMenu>
					</MobileNavBarContent>
				</MobileNavBarOverlay>
				<UnauthenticatedDialog
					open={showAuthDialog}
					onOpenChange={setShowAuthDialog}
				/>
			</React.Fragment>
		);
	}

	return (
		<NavBarContainer>
			<NavBarLogo src={MightyBotLogo} alt="MightyBot Logo" />
			<NavigationMenu style={{ gap: "16px" }}>
				<RenderNavLinks
					links={enabledNavLinks}
					setShowAuthDialog={setShowAuthDialog}
				/>
			</NavigationMenu>
			<Flex direction="column" align="center" style={{ marginTop: "auto" }}>
				<NavigationMenu style={{ gap: "10px", margin: "0px 4px 10px 4px" }}>
					<ContactUsOverlay />
					<RenderNavLinks
						links={internalNavLinks}
						setShowAuthDialog={setShowAuthDialog}
					/>
				</NavigationMenu>
				{user && (
					<UserPopover
						isPopoverOpen={isPopoverOpen}
						setIsPopoverOpen={setIsPopoverOpen}
					/>
				)}
			</Flex>
			<UnauthenticatedDialog
				open={showAuthDialog}
				onOpenChange={setShowAuthDialog}
			/>
		</NavBarContainer>
	);
};

export default NavBar;
