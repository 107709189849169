import React, { useState } from "react";
import { Popover, Icon, Text, Flex, Checkbox } from "@mightybot/web-ui";
import DateRangePicker from "../DateRangePicker";
import { FilterButton, FilterContainer } from "./styled";
import FilterPopover from "../FilterPopover/FilterPopover";
import PeopleFilterContent from "../FilterPopover/FilterContent/PeopleFilterContent";
import {
	PeopleFilterValue,
	User,
	MeetingCategoryType,
	MeetingDepartmentType,
	useFeatureFlag,
	MEETINGS_LIST_FILTERS_FEATURE,
	TeamMember,
	useGetTeamMembersQuery,
} from "@mightybot/core";
import { CategoryFilterContent } from "../FilterPopover/FilterContent/CategoryFilterContent";
import { TagFilterContent } from "../FilterPopover/FilterContent/TagFilterContent";
import { GenericFilterContent } from "../FilterPopover/FilterContent/GenericFilterContent";

type DateRange = {
	from: Date | undefined;
	to: Date | undefined;
};

type MeetingsListFiltersProps = {
	onDateChange: (range: DateRange) => void;
	onPeopleChange: (people: Partial<User>[]) => void;
	onTeamMembersChange: (emails: string[]) => void;
	onCategoryChange: (categories: MeetingCategoryType[]) => void;
	onTypeChange: (types: MeetingDepartmentType[]) => void;
	selectedPeople: Partial<User>[];
	selectedTeamEmails: string[];
	selectedCategories: MeetingCategoryType[];
	selectedTags: MeetingDepartmentType[];
	refetch: () => void;
	isTeamView: boolean;
};

export const MeetingsListFilters = ({
	onDateChange,
	onPeopleChange,
	onTeamMembersChange,
	onCategoryChange,
	onTypeChange,
	selectedPeople,
	selectedTeamEmails,
	selectedCategories,
	selectedTags,
	refetch,
	isTeamView,
}: MeetingsListFiltersProps) => {
	const enableMeetingsListFilters = useFeatureFlag(
		MEETINGS_LIST_FILTERS_FEATURE,
	);
	const { data: teamData } = useGetTeamMembersQuery();

	const [dateRange, setDateRange] = useState<DateRange>({
		from: undefined,
		to: undefined,
	});
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [isPeopleFilterOpen, setIsPeopleFilterOpen] = useState(false);
	const [isTeamMembersFilterOpen, setIsTeamMembersFilterOpen] = useState(false);
	const [isCategoryFilterOpen, setIsCategoryFilterOpen] = useState(false);
	const [isTypeFilterOpen, setIsTypeFilterOpen] = useState(false);
	const [tempSelectedPeople, setTempSelectedPeople] = useState<Partial<User>[]>(
		[],
	);
	const [tempSelectedTeamEmails, setTempSelectedTeamEmails] = useState<
		string[]
	>([]);
	const [tempSelectedCategories, setTempSelectedCategories] = useState<
		MeetingCategoryType[]
	>([]);
	const [tempSelectedTags, setTempSelectedTags] = useState<
		MeetingDepartmentType[]
	>([]);

	const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
		const [start, end] = dates;
		setDateRange({ from: start ?? undefined, to: end ?? undefined });
		onDateChange({ from: start ?? undefined, to: end ?? undefined });
		if (start && end) {
			setIsDatePickerOpen(false);
		}
	};

	const handlePersonSelect = (person: Partial<User>) => {
		setTempSelectedPeople([...tempSelectedPeople, person]);
	};

	const handleTeamMemberSelect = (email: string) => {
		setTempSelectedTeamEmails((prev) => {
			const exists = prev.includes(email);
			if (exists) {
				return prev.filter((e) => e !== email);
			}
			return [...prev, email];
		});
	};

	const handleApply = () => {
		onPeopleChange(tempSelectedPeople);
		setIsPeopleFilterOpen(false);
	};

	const handleTeamMembersApply = () => {
		const newTeamEmails = [...tempSelectedTeamEmails];
		onTeamMembersChange(newTeamEmails);
		setIsTeamMembersFilterOpen(false);
	};

	const handleCancel = () => {
		setTempSelectedPeople(selectedPeople);
		setIsPeopleFilterOpen(false);
	};

	const handleTeamMembersCancel = () => {
		setTempSelectedTeamEmails(selectedTeamEmails);
		setIsTeamMembersFilterOpen(false);
	};

	const isSelected = selectedPeople.length > 0;
	const isTeamMembersSelected = selectedTeamEmails.length > 0;

	const renderSelectedFilters = () => {
		if (!isSelected) return null;
		return (
			<Text size="1" style={{ marginLeft: "4px" }}>
				({selectedPeople.length})
			</Text>
		);
	};

	const renderSelectedTeamMembers = () => {
		if (!isTeamMembersSelected) return null;
		return (
			<Text size="1" style={{ marginLeft: "4px" }}>
				({selectedTeamEmails.length})
			</Text>
		);
	};

	const getDateButtonText = () => {
		if (!dateRange.from && !dateRange.to) return "Date";
		if (dateRange.from && dateRange.to) {
			return `${dateRange.from.toLocaleDateString()} - ${dateRange.to.toLocaleDateString()}`;
		}
		return "Date Selected";
	};

	const handleCategorySelect = (category: MeetingCategoryType) => {
		setTempSelectedCategories((prev) =>
			prev.includes(category)
				? prev.filter((c) => c !== category)
				: [...prev, category],
		);
	};

	const handleCategoryApply = () => {
		onCategoryChange(tempSelectedCategories);
		setIsCategoryFilterOpen(false);
	};

	const handleCategoryCancel = () => {
		setTempSelectedCategories(selectedCategories);
		setIsCategoryFilterOpen(false);
	};

	const handleTagSelect = (tag: MeetingDepartmentType) => {
		setTempSelectedTags((prev) =>
			prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag],
		);
	};

	const handleTagApply = () => {
		onTypeChange(tempSelectedTags);
		setIsTypeFilterOpen(false);
	};

	const handleTagCancel = () => {
		setTempSelectedTags(selectedTags);
		setIsTypeFilterOpen(false);
	};

	return (
		<FilterContainer gap="8px">
			<Popover.Root open={isDatePickerOpen} onOpenChange={setIsDatePickerOpen}>
				<Popover.Trigger>
					<FilterButton
						data-active={!!(dateRange.from || dateRange.to)}
						variant="white-bg"
						style={{ cursor: "pointer" }}
					>
						{getDateButtonText()}
						<Icon.CaretDown />
					</FilterButton>
				</Popover.Trigger>
				<Popover.Content
					style={{ width: "100%", maxWidth: "1000px", padding: 0 }}
				>
					<DateRangePicker
						startDate={dateRange.from ?? null}
						endDate={dateRange.to ?? null}
						onChange={handleDateRangeChange}
						onClose={() => setIsDatePickerOpen(false)}
						maxDate={new Date()}
						placeholderText={
							dateRange.from && dateRange.to
								? "Select date range"
								: "Select start date"
						}
					/>
				</Popover.Content>
			</Popover.Root>

			<FilterPopover
				trigger={
					<FilterButton
						data-active={selectedCategories.length > 0}
						variant="white-bg"
						style={{ cursor: "pointer" }}
					>
						<Text weight="medium">Category</Text>
						{selectedCategories.length > 0 && (
							<Text size="1" style={{ marginLeft: "4px" }}>
								({selectedCategories.length})
							</Text>
						)}
						<Icon.CaretDown size={16} />
					</FilterButton>
				}
				isOpen={isCategoryFilterOpen}
				onOpenChange={setIsCategoryFilterOpen}
				onApply={handleCategoryApply}
				onCancel={handleCategoryCancel}
			>
				<CategoryFilterContent
					selectedCategories={tempSelectedCategories}
					handleCategorySelect={handleCategorySelect}
				/>
			</FilterPopover>

			<FilterPopover
				trigger={
					<FilterButton
						data-active={selectedTags.length > 0}
						variant="white-bg"
						style={{ cursor: "pointer" }}
					>
						<Text weight="medium">Tag</Text>
						{selectedTags.length > 0 && (
							<Text size="1" style={{ marginLeft: "4px" }}>
								({selectedTags.length})
							</Text>
						)}
						<Icon.CaretDown size={16} />
					</FilterButton>
				}
				isOpen={isTypeFilterOpen}
				onOpenChange={setIsTypeFilterOpen}
				onApply={handleTagApply}
				onCancel={handleTagCancel}
			>
				<TagFilterContent
					selectedTags={tempSelectedTags}
					handleTagSelect={handleTagSelect}
				/>
			</FilterPopover>

			<FilterPopover
				trigger={
					<FilterButton
						data-active={isSelected}
						variant="white-bg"
						style={{ cursor: "pointer" }}
					>
						<Text weight="medium" style={{ flexShrink: 0 }}>
							People
						</Text>
						{renderSelectedFilters()}
						<Icon.CaretDown size={16} />
					</FilterButton>
				}
				isOpen={isPeopleFilterOpen}
				onOpenChange={(open) => setIsPeopleFilterOpen(open)}
				onApply={handleApply}
				onCancel={handleCancel}
			>
				<PeopleFilterContent
					selectedPeople={
						tempSelectedPeople.map((person) => person.id) as PeopleFilterValue
					}
					handlePersonSelect={handlePersonSelect}
				/>
			</FilterPopover>

			{isTeamView && teamData?.members && (
				<FilterPopover
					trigger={
						<FilterButton
							disabled={!enableMeetingsListFilters}
							data-active={isTeamMembersSelected}
							variant="white-bg"
							style={{ cursor: "pointer" }}
						>
							<Text weight="medium" style={{ flexShrink: 0 }}>
								Team Members
							</Text>
							{renderSelectedTeamMembers()}
							<Icon.CaretDown size={16} />
						</FilterButton>
					}
					isOpen={isTeamMembersFilterOpen}
					onOpenChange={(open) => setIsTeamMembersFilterOpen(open)}
					onApply={handleTeamMembersApply}
					onCancel={handleTeamMembersCancel}
				>
					<GenericFilterContent
						title="Team Members"
						options={teamData?.members?.map((member) => member.email) || []}
						selectedOptions={tempSelectedTeamEmails}
						handleOptionSelect={handleTeamMemberSelect}
						handleSelectAll={() => {
							const allEmails = teamData?.members?.map((m) => m.email) || [];
							if (tempSelectedTeamEmails.length === allEmails.length) {
								setTempSelectedTeamEmails([]);
							} else {
								setTempSelectedTeamEmails(allEmails);
							}
						}}
						handleReset={() => setTempSelectedTeamEmails([])}
						getOptionLabel={(email) => {
							const member = teamData?.members?.find((m) => m.email === email);
							return member ? `${member.name} (${email})` : email;
						}}
					/>
				</FilterPopover>
			)}
		</FilterContainer>
	);
};
