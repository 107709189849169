import React, { useRef, useEffect, useState } from "react";
import { cn } from "../../utils/classNames";
import {
	Icon,
	Flex,
	TextArea,
	Tooltip,
	IconButton,
	useMediaQuery,
} from "@mightybot/web-ui";
import {
	MeetingContentPromptInnerContainer,
	MeetingContentPromptOuterContainer,
} from "./styled";
import { useMeetingChat } from "../../contexts/MeetingChatContext";
import { useParams } from "react-router-dom";
import { useCompletionsHistory, useMe } from "@mightybot/core";
import { breakpoints } from "@mightybot/web-ui";

const INITIAL_HEIGHT = 100;

const MeetingContentPrompt: React.FC = () => {
	const { data: user } = useMe();
	const meetingId = useParams().meetingId;
	const { addCurrentCompletionsItems } = useCompletionsHistory(
		meetingId ?? "",
		"MEETING",
	);
	const { triggerStreamReply } = useMeetingChat();
	const [input, setInput] = useState("");
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const maxHeight = window.innerHeight + 100;
	const isTablet = useMediaQuery(`(max-width: ${breakpoints.tablet})`);

	const adjustHeight = (element: HTMLTextAreaElement) => {
		element.style.height = "auto";
		element.style.height = `${Math.min(element.scrollHeight, maxHeight)}px`;
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleSend();
		}
	};

	const handleSend = async () => {
		if (!input?.length) return;

		const trimmedInput = input.trim();
		setInput("");

		if (textAreaRef.current) {
			textAreaRef.current.style.height = `${INITIAL_HEIGHT}px`;
		}

		const completionCurrentItem = {
			id: Date.now().toString(),
			user_question: trimmedInput,
			timestamp: new Date().toISOString(),
			context: null,
			prompt: null,
			author: user,
		};

		addCurrentCompletionsItems(completionCurrentItem);
		triggerStreamReply();
	};

	return (
		<MeetingContentPromptOuterContainer direction="column" id="content-prompt">
			<MeetingContentPromptInnerContainer>
				<Flex
					style={{
						width: "100%",
						position: "relative",
						alignItems: "flex-end",
						gap: 8,
					}}
				>
					<TextArea
						ref={textAreaRef}
						id="chat-input"
						placeholder="Ask me anything about the meeting..."
						style={{
							width: "100%",
							minHeight: `${INITIAL_HEIGHT}px`,
							maxHeight: "30vh",
							height: "100%",
							padding: "8px",
							borderRadius: 12,
							backgroundColor: "var(--mb-gray-1)",
							border: "1px solid var(--mb-gray-4)",
							// height: "100%",
							overflow: "hidden",
							outline: "none",
							resize: "none",
						}}
						value={input}
						onChange={(e) => {
							setInput(e.target.value);
							adjustHeight(e.target);
						}}
						onKeyDown={handleKeyDown}
					/>
					<Tooltip content="Send">
						<IconButton
							id="chat-input-send"
							disabled={!input?.length}
							style={{
								width: 40,
								height: 34,
								borderRadius: 12,
							}}
							onClick={handleSend}
						>
							<Icon.PaperPlaneRight weight="fill" />
						</IconButton>
					</Tooltip>
				</Flex>
			</MeetingContentPromptInnerContainer>
		</MeetingContentPromptOuterContainer>
	);
};

export default MeetingContentPrompt;
