import styled from "styled-components";
import { Flex } from "@mightybot/web-ui";

export const DataPreferencesContainer = styled(Flex)`
	width: 100%;
	border-radius: 12px;
	overflow: hidden;
	border: 1px var(--mb-color-light-gray-5) solid;
	display: inline-flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`;

export const DataPreferencesHeader = styled(Flex)`
	align-self: stretch;
	padding: 16px 20px;
	border-bottom: 1px var(--mb-color-light-gray-5) solid;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	display: inline-flex;
`;

export const DataPreferencesContent = styled(Flex)`
	align-self: stretch;
	padding: 12px 20px 24px 20px;
	background: var(--mb-color-light-gray-1);
	flex-direction: column;
	justify-content: center;
	gap: 20px;
`;

export const ToggleItem = styled(Flex)`
	align-self: stretch;
	padding: 10px 16px;
	background: var(--mb-color-light-blue-1);
	border-radius: 12px;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	display: inline-flex;
`;
