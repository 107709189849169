import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect } from "react";
import { Button, Flex, Icon } from "@mightybot/web-ui";
import Placeholder from "@tiptap/extension-placeholder";

interface TipTapEditorProps {
	content: string;
	onChange: (content: string) => void;
	placeholder?: string;
	defaultValue?: string;
}

const MenuBar = ({ editor }: { editor: any }) => {
	if (!editor) {
		return null;
	}

	return (
		<Flex gap="2" style={{ padding: "8px 0" }}>
			<Button
				variant="ghost"
				size="1"
				onClick={() => editor.chain().focus().toggleBold().run()}
				disabled={!editor.can().chain().focus().toggleBold().run()}
				data-active={editor.isActive("bold")}
			>
				<Icon.TextB size="16" />
			</Button>
			<Button
				variant="ghost"
				size="1"
				onClick={() => editor.chain().focus().toggleItalic().run()}
				disabled={!editor.can().chain().focus().toggleItalic().run()}
				data-active={editor.isActive("italic")}
			>
				<Icon.TextItalic size="16" />
			</Button>
			<Button
				variant="ghost"
				size="1"
				onClick={() => editor.chain().focus().toggleStrike().run()}
				disabled={!editor.can().chain().focus().toggleStrike().run()}
				data-active={editor.isActive("strike")}
			>
				<Icon.TextStrikethrough size="16" />
			</Button>
			<Button
				variant="ghost"
				size="1"
				onClick={() => editor.chain().focus().toggleBulletList().run()}
				disabled={!editor.can().chain().focus().toggleBulletList().run()}
				data-active={editor.isActive("bulletList")}
			>
				<Icon.List size="16" />
			</Button>
			<Button
				variant="ghost"
				size="1"
				onClick={() => editor.chain().focus().toggleOrderedList().run()}
				disabled={!editor.can().chain().focus().toggleOrderedList().run()}
				data-active={editor.isActive("orderedList")}
			>
				<Icon.ListNumbers size="16" />
			</Button>
		</Flex>
	);
};

export const TipTapEditor: React.FC<TipTapEditorProps> = ({
	content,
	onChange,
	placeholder,
	defaultValue,
}) => {
	const editor = useEditor({
		extensions: [
			StarterKit,
			Placeholder.configure({
				placeholder: placeholder ?? "Start typing...",
			}),
		],
		content: defaultValue || content,
		onUpdate: ({ editor }) => {
			onChange(editor.getHTML());
		},
		editorProps: {
			attributes: {
				class: "prose prose-sm focus:outline-none max-w-none",
			},
		},
	});

	useEffect(() => {
		if (editor && defaultValue) {
			if (editor.getHTML() !== defaultValue) {
				editor.commands.setContent(defaultValue);
			}
		}
	}, [editor, defaultValue]);

	useEffect(() => {
		if (editor && !defaultValue && content) {
			if (editor.getHTML() !== content) {
				editor.commands.setContent(content);
			}
		}
	}, [editor, content, defaultValue]);

	return (
		<div className="border rounded-md p-2 border-gray-200 border-solid">
			<MenuBar editor={editor} />
			<EditorContent editor={editor} />
			<style>{`
				.ProseMirror {
					min-height: 100px;
					padding: 8px;
				}
				.ProseMirror:focus {
					outline: none;
				}
				.ProseMirror > * + * {
					margin-top: 0.75em;
				}
				.ProseMirror ul,
				.ProseMirror ol {
					padding: 0 1rem;
				}
				.ProseMirror p.is-empty::before {
					color: #adb5bd;
					content: attr(data-placeholder);
					float: left;
					height: 0;
					pointer-events: none;
				}
			`}</style>
		</div>
	);
};
