import { Meeting, useFavorites, useJoinMeeting, useMe } from "@mightybot/core";
import React, { useMemo, useCallback } from "react";
import { Flex, Text, Icon, Tooltip, Switch, Checkbox } from "@mightybot/web-ui";
import { TableRowContainer, Tag, HideMobile } from "./styled";
import { useNavigate } from "react-router-dom";
import { useIntegrationLogos } from "@mightybot/core-ui";
import { OverlappingAvatars } from "@mightybot/web-ui";
import { formatTimeRange } from "../../utils/helpers";

type MeetingsListRowProps = {
	meeting: Meeting;
	index: number;
	isSelected: boolean;
	onSelectChange: (id: string, checked: boolean) => void;
};

const renderTags = (tags: string[]) => {
	return tags.map((tag) => (
		<Tooltip key={tag} content={tag}>
			<Tag>
				<Text
					style={{
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
						maxWidth: "100px",
					}}
				>
					{tag}
				</Text>
			</Tag>
		</Tooltip>
	));
};

export const MeetingsListRow = React.memo(
	({ meeting, index, isSelected, onSelectChange }: MeetingsListRowProps) => {
		const navigate = useNavigate();
		const { data: user } = useMe();
		const { getIntegrationLogo } = useIntegrationLogos();
		const { isFavorite, toggleFavorite } = useFavorites("MEETING");
		const {
			joinMeeting,
			isLoading: isJoining,
			error: joinError,
			joinedMeeting,
		} = useJoinMeeting();

		const formatAttendeeData = useCallback((attendees: string[]) => {
			const attendeesList =
				typeof attendees === "string" ? JSON.parse(attendees) : attendees;
			return attendeesList.map((email: string) => ({
				avatar: "",
				alt: email,
				fallback: email[0].toUpperCase(),
			}));
		}, []);

		const renderParticipants = useCallback(
			(attendees: Meeting["attendees"]) => {
				if (!attendees || attendees.length === 0) return "No attendees";

				const attendeesList =
					typeof attendees === "string" ? JSON.parse(attendees) : attendees;
				const attendeeData = formatAttendeeData(attendeesList);
				const displayCount =
					attendeeData.length > 5 ? `+${attendeeData.length - 5}` : "";

				return (
					<Tooltip content={<Text size="1">{attendeesList.join(", ")}</Text>}>
						<Flex align="center" gap="4px">
							<OverlappingAvatars data={attendeeData.slice(0, 5)} size="1" />
							{displayCount && (
								<Text size="1" style={{ marginLeft: "4px" }}>
									{displayCount}
								</Text>
							)}
						</Flex>
					</Tooltip>
				);
			},
			[formatAttendeeData],
		);

		const handleMeetingClick = () => {
			const isTeamView = window.location.pathname.includes("/team/");
			const basePath = isTeamView ? "/team/meetings" : "/meetings";
			navigate(`${basePath}/${meeting.id}/details`);
		};

		const toggleBot = useCallback(
			async (meetingUrl: string) => {
				try {
					const joinMeetingResponse = await joinMeeting({
						meeting_url: new URL(meetingUrl),
					});
					return joinMeetingResponse.already_joined;
				} catch (error) {
					console.error("Failed to toggle MightyBot for meeting:", error);
					return false;
				}
			},
			[joinMeeting],
		);

		const meetingPlatformLogo = useMemo(
			() => getIntegrationLogo(`${meeting.meeting_platform}_plain`),
			[meeting.meeting_platform, getIntegrationLogo],
		);

		const category = meeting.meeting_category?.split("/")[0];
		const tags = [
			meeting.meeting_internal_external,
			meeting.meeting_type_department,
		].filter(Boolean);

		const onStarClick = (e: React.MouseEvent<SVGSVGElement>) => {
			e.stopPropagation();
			toggleFavorite(meeting.id);
		};

		return (
			<TableRowContainer p="8px 16px" width="100%" gap="10px">
				<Flex>
					<Checkbox
						checked={isSelected}
						onCheckedChange={(checked) =>
							onSelectChange(meeting.id, checked as boolean)
						}
					/>
				</Flex>
				<Flex style={{ flex: 0.2, fontSize: "12px", minWidth: "80px" }}>
					{formatTimeRange(meeting.start_time, meeting.end_time)}
				</Flex>

				<Flex
					gap="10px"
					align="center"
					style={{
						flex: 0.6,
						fontSize: "13px",
						overflow: "hidden",
						cursor: "pointer",
						minWidth: "150px",
					}}
					onClick={handleMeetingClick}
				>
					{meetingPlatformLogo ? (
						<img
							src={meetingPlatformLogo}
							alt={meeting.meeting_platform}
							style={{ width: "18px", height: "18px", flexShrink: 0 }}
						/>
					) : (
						<Icon.VideoCamera size={18} />
					)}
					<Tooltip content={meeting.title}>
						<Text
							weight="medium"
							style={{
								color: "var(--mb-blue-8)",
								fontSize: "13px",
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "100%",
							}}
						>
							{meeting.title}
						</Text>
					</Tooltip>
					<Icon.Star
						weight={isFavorite(meeting.id) ? "fill" : "regular"}
						size={16}
						style={{ cursor: "pointer" }}
						onClick={onStarClick}
						color="var(--mb-gray-7)"
					/>
				</Flex>
				<HideMobile
					style={{
						flex: 0.3,
						fontSize: "13px",
						overflow: "hidden",
					}}
				>
					<Tooltip content={category}>
						<Text
							style={{
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								width: "100%",
							}}
						>
							{category}
						</Text>
					</Tooltip>
				</HideMobile>
				<HideMobile
					gap="5px"
					style={{
						flex: 0.4,
						fontSize: "13px",
						overflow: "hidden",
					}}
				>
					{renderTags(tags as string[])}
				</HideMobile>
				<Flex
					style={{
						flex: 0.4,
						fontSize: "13px",
						minWidth: "100px",
					}}
				>
					{renderParticipants(meeting.attendees)}
				</Flex>
			</TableRowContainer>
		);
	},
	(prevProps, nextProps) => {
		return (
			prevProps.isSelected === nextProps.isSelected &&
			prevProps.meeting.id === nextProps.meeting.id &&
			prevProps.meeting.have_bot === nextProps.meeting.have_bot
		);
	},
);
