import styled, { keyframes } from "styled-components";
import { Button, Flex, mediaQueries, mediaMaxWidth } from "@mightybot/web-ui";
import { breakpoints } from "@mightybot/web-ui";

export const MeetingContentContainer = styled(Flex)`
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	margin: auto;
`;

export const MeetingContentWrapper = styled(Flex)`
	height: 100%;
	overflow: hidden;
	flex-direction: column;
	width: 100%;
	padding-bottom: 10px;
`;

export const MeetingContentTitleOuterContainer = styled(Flex)`
	width: 100%;
	align-items: center;
	justify-content: center;
	/* position: sticky; */
	top: 0;
	background: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(8px);
	webkit-backdrop-filter: blur(8px);
	z-index: 10;
	margin-bottom: 10px;
`;

export const MeetingContentTitleInnerContainer = styled(Flex)`
	width: 100%;
	border-bottom: 1px solid #ccc;
	padding: 20px 0px;
	gap: 10px;
	@media (max-width: ${breakpoints.tablet}) {
		padding: 12px;
	}
`;

export const MeetingContentWidgetContainer = styled(Flex)`
	position: absolute;
	padding: 3px;
	border-radius: 7px;
	max-width: 44px;
	max-height: 158px;
	background: linear-gradient(180deg, #0b265c 0%, #1851c2 100%);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	z-index: 1000;
	flex-direction: column;
	gap: 4px;
`;

export const MeetingContentScrollerOuterContainer = styled(Flex)`
	width: 100%;
	height: 100%;
	overflow: hidden;

	@media (max-width: ${breakpoints.tablet}) {
		padding: 0;
	}
`;

export const MeetingContentScrollerInnerContainer = styled(Flex)`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	flex-direction: column;
	gap: 24px;

	@media (max-width: ${breakpoints.tablet}) {
		gap: 16px;
	}
`;

export const MeetingContentPromptOuterContainer = styled(Flex)`
	position: sticky;
	bottom: 0;
	background: white;
	z-index: 1000;
	width: 100%;
	border-top: 1px solid var(--border-primary);

	@media (max-width: ${breakpoints.tablet}) {
	}
`;

export const MeetingContentPromptInnerContainer = styled(Flex)`
	width: 100%;
	max-width: 1050px;
	margin: 0 auto;
	padding: 16px 80px;
	@media (max-width: ${breakpoints.tablet}) {
		padding: 16px;
	}
`;

export const MeetingContentDetailsContainer = styled(Flex)`
	gap: 24px;

	@media (max-width: ${breakpoints.tablet}) {
		padding: 0 16px;
		gap: 16px;
	}

	@media (max-width: ${breakpoints.mobile}) {
		padding: 0 12px;
		gap: 12px;
	}
`;

const pulseAnimation = keyframes`
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--mb-color-light-blue-7);
  }
  100% {
    background-color: transparent;
  }
`;

export const FocusableContent = styled.div``;

export const GoToEndButton = styled(Button)`
	border-radius: 50%;
	width: 34px;
	height: 34px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--mb-gray-1);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	border: 1px solid var(--mb-gray-7);
	cursor: pointer;
	pointer-events: auto; // Re-enable pointer events for the button

	&:hover {
		background-color: var(--mb-gray-2);
	}
`;

export const GoToEndButtonContainer = styled(Flex)`
	height: 40px;
	width: 100%;
	justify-content: center;
	align-items: center;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
	pointer-events: none; // Allow clicks to pass through to underlying content
	opacity: 0;
	transition: opacity 0.2s ease-in-out;

	&.visible {
		opacity: 1;
	}
`;

export const MeetingContentPreviewContainer = styled(Flex)`
	gap: 10px;
`;

export const MeetingContentPreviewOutline = styled(Flex)`
	border-radius: 6px;
	border: 1px solid var(--mb-gray-5);
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
`;

export const MeetingContentPreviewItem = styled(Flex)<{ isLast?: boolean }>`
	gap: 10px;
	width: 100%;
	padding: 12px;
	flex-direction: column;
	padding: 10px;
	border-bottom: ${({ isLast }) =>
		isLast ? "none" : "1px solid var(--mb-gray-5)"};

	&:last-child {
		border-bottom: none;
	}
`;

export const FloatingGenerateDocButton = styled.div`
	position: fixed;
	bottom: 160px; // Adjust this value based on your navbar height
	right: 16px;
	z-index: 1000;
`;
