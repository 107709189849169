import React from "react";
import {
	Icon,
	Flex,
	Text,
	DropdownMenu,
	CustomReactMarkdown,
} from "@mightybot/web-ui";
import { formatDistanceToNow } from "date-fns";
import { TagContainer, FeedCardContainer, Cube } from "./styled";
import CategoryMapping from "./CategoryMapping.json";
import { useIntegrationLogos, MBLogoBlack } from "@mightybot/core-ui";
import { FeedItem, formatDescription, hasLinks } from "./Home";
import { FeedAction, FeedType, useEngagement } from "@mightybot/core";
import FeedExtras from "./FeedExtras";
import { customMarkdownStyles } from "./styled";
import { capitalize } from "lodash";

// Add this constant at the top of the file, after the imports
const DEFAULT_CATEGORY = {
	background: "#F0F0F0",
	icon: "#808080",
};

interface FeedCardProps {
	feed: FeedItem;
	last: boolean;
	onDismiss: (item: FeedItem) => void;
	type?: FeedType;
}

const FeedCard: React.FC<FeedCardProps> = ({
	feed,
	last,
	onDismiss,
	type = "feed",
}) => {
	const { getIntegrationLogo } = useIntegrationLogos();
	const appIcon = getIntegrationLogo(
		feed.app?.toLowerCase().replace(/ /g, "_"),
	);
	const { handleEngagementAction } = useEngagement();

	// Use the first app icon or fallback to MBLogoBlack
	const iconSrc = appIcon ? appIcon : MBLogoBlack;
	const isDefaultLogo = iconSrc === MBLogoBlack;

	const feedHasLinks = hasLinks(feed.description);

	const handleEngagement = (action: FeedAction) => {
		handleEngagementAction({
			signal_id: feed.id ?? null,
			action: action,
			source: "HOME_FEED",
		});
	};

	const handleFeedClick = (
		e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
	) => {
		handleEngagement("CLICK");
		if (feedHasLinks) {
			return;
		}
		e.preventDefault();
		feed.link && window.open(feed.link, "_blank", "noopener,noreferrer");
	};

	const handleDismiss = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		handleEngagement("DISMISS");
		onDismiss(feed);
	};

	const categoryStyle =
		CategoryMapping[feed.category as keyof typeof CategoryMapping] ||
		DEFAULT_CATEGORY;

	return (
		<FeedCardContainer
			role={feed.link && !feedHasLinks ? "link" : "none"}
			onClick={handleFeedClick}
			tabIndex={0}
			onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
				if (e.key === "Enter") {
					handleFeedClick(e);
				}
			}}
			title={feed.link && !feedHasLinks ? feed.link : ""}
			style={{
				cursor: feedHasLinks ? "default" : "pointer",
			}}
		>
			<Flex justify="between">
				<Flex style={{ gap: 6 }} align="center">
					<Flex
						justify="center"
						align="center"
						style={{
							width: 24,
							height: 24,
							backgroundColor: isDefaultLogo ? "#C4E75A" : "transparent",
							borderRadius: "50%",
						}}
					>
						<img
							src={iconSrc}
							alt="icon"
							style={{
								width: isDefaultLogo ? 16 : 24,
								height: isDefaultLogo ? 16 : 24,
							}}
						/>
					</Flex>
					{feed.title && type === "activity" && (
						<Text weight="bold" style={{ color: "var(--mb-blue-9)" }}>
							{capitalize(feed.title)}
						</Text>
					)}
					{feed.company_name && (
						<TagContainer
							justify="center"
							align="center"
							style={{
								backgroundColor: "var(--mb-blue-3)",
							}}
							key={feed.company_name}
						>
							<Text>{feed.company_name}</Text>
						</TagContainer>
					)}

					{feed.category && (
						<TagContainer
							justify="center"
							align="center"
							key={feed.category}
							style={{
								backgroundColor: categoryStyle.background,
							}}
						>
							<Cube color={categoryStyle.icon} />
							<Text>{feed.category}</Text>
						</TagContainer>
					)}
				</Flex>
			</Flex>
			<CustomReactMarkdown styles={customMarkdownStyles as any}>
				{formatDescription(feed.description)}
			</CustomReactMarkdown>
			<Flex justify="between" align="center" style={{ padding: "4px 0px" }}>
				<Text size="1" weight="light" style={{ color: "var(--mb-gray-9)" }}>
					{formatDistanceToNow(new Date(feed.timestamp))} ago
				</Text>
				<Flex style={{ gap: 15 }}>
					{/* TODO: Implement thumbs up/down */}
					{/* <Icon.ThumbsUp />
					<Icon.ThumbsDown /> */}
					<DropdownMenu.Root>
						<DropdownMenu.Trigger onClick={(e) => e.stopPropagation()}>
							<Icon.DotsThree style={{ cursor: "pointer" }} />
						</DropdownMenu.Trigger>
						<DropdownMenu.Content>
							<FeedExtras onDismiss={handleDismiss} />
						</DropdownMenu.Content>
					</DropdownMenu.Root>
				</Flex>
			</Flex>
		</FeedCardContainer>
	);
};

export default FeedCard;
