import { styled } from "styled-components";
import { Dialog } from "@mightybot/web-ui";

export const DialogContent = styled(Dialog.Content)`
	max-width: 400px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 24px;
`;
