import React, { useState, useRef, useEffect } from "react";
import {
	Dialog,
	Button,
	Flex,
	Text,
	Icon,
	Input,
	TextArea,
	Tabs,
} from "@mightybot/web-ui";
import { useSnippets } from "@mightybot/core";
import VideoJS from "../VideoPlayer/VideoJsPlayer";
import TranscriptView from "../VideoPlayer/TranscriptView";
import videojs from "video.js";
import {
	Content,
	DialogContent,
	VideoSection,
	DetailsSection,
	MetadataSection,
	DialogRoot,
} from "./styled";
import { capitalize } from "lodash";

interface EditSnippetDialogProps {
	snippetId: string;
	initialTitle: string;
	initialDescription?: string;
	videoUrl?: string;
	startTime?: number;
	endTime?: number;
	transcript?: any[];
	open: boolean;
	onOpenChange: (open: boolean) => void;
	onSuccess?: () => void;
}

const EditSnippetDialog: React.FC<EditSnippetDialogProps> = ({
	snippetId,
	initialTitle,
	initialDescription = "",
	videoUrl,
	startTime = 0,
	endTime = 0,
	transcript = [],
	open,
	onOpenChange,
	onSuccess,
}) => {
	const [title, setTitle] = useState(initialTitle);
	const [description, setDescription] = useState(initialDescription);
	const [currentTime, setCurrentTime] = useState(0);
	const [copiedId, setCopiedId] = useState<string | null>(null);
	const { getSnippet, activeSnippet, modifySnippet, isModifying } =
		useSnippets();
	const playerRef = useRef<ReturnType<typeof videojs> | null>(null);

	useEffect(() => {
		if (snippetId) {
			getSnippet(snippetId);
		}
	}, [snippetId]);

	const videoJsOptions = {
		controls: true,
		responsive: true,
		fluid: true,
		sources: [
			{
				src: videoUrl,
				type: "video/mp4",
			},
		],
	};

	const handleSeek = (time: number) => {
		if (playerRef.current) {
			playerRef.current.currentTime(time);
		}
	};

	const handleSubmit = async () => {
		try {
			await modifySnippet(snippetId, {
				clip_title: title,
				clip_description: description,
			});
			onOpenChange(false);
			onSuccess?.();
		} catch (error) {
			console.error("Failed to update snippet:", error);
		}
	};

	const onCopy = () => {
		navigator.clipboard.writeText(window.location.href);
		setCopiedId(snippetId);
		setTimeout(() => setCopiedId(null), 2000);
	};

	return (
		<DialogRoot open={open} onOpenChange={onOpenChange}>
			<DialogContent>
				<Flex
					gap="4"
					align="center"
					style={{
						borderBottom: "1px solid var(--gray-5)",
						padding: "0px 10px 0px 20px",
						height: "60px",
					}}
				>
					<Dialog.Title
						style={{
							margin: "4px 0px 0px 0px",
							fontSize: "18px",
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
						}}
					>
						{activeSnippet?.title}
					</Dialog.Title>
					<Dialog.Close>
						<Icon.X style={{ marginLeft: "auto", cursor: "pointer" }} />
					</Dialog.Close>
				</Flex>

				<Content>
					<Flex
						direction="column"
						style={{
							flex: 1,
							borderRight: "1px solid var(--gray-5)",
							background: "var(--gray-2)",
							minHeight: 0,
						}}
						className="py-[10px] px-[30px] sm:px-[16px]"
					>
						<Flex
							justify="between"
							align="center"
							style={{ gap: "10px", height: "34px" }}
							className="flex-wrap sm:mb-2"
						>
							<MetadataSection>
								<Icon.Clock className="w-4 h-4 text-gray-400" />
								<Text size="1" className="text-[13px]" color="gray">
									{Math.floor(
										activeSnippet?.container_metadata?.duration ?? 0 / 60,
									)}
									m
								</Text>
								<Text className="color-[var(--mb-color-dark-gray-4)] text-[9px]">
									•{" "}
								</Text>
								<Icon.CalendarBlank className="w-4 h-4 text-gray-400" />
								<Text className="text-[13px]" color="gray">
									{new Date(activeSnippet?.created_ts ?? "").toLocaleString(
										"en-US",
										{
											month: "short",
											day: "numeric",
											year: "numeric",
											hour: "numeric",
											minute: "2-digit",
											hour12: true,
										},
									)}
								</Text>
							</MetadataSection>
							{copiedId !== snippetId ? (
								<Icon.LinkSimple
									size="20px"
									weight="bold"
									className="cursor-pointer shrink-0"
									onClick={onCopy}
								/>
							) : (
								<Icon.Check className="w-4 h-4 text-green-500 shrink-0" />
							)}
						</Flex>
						<VideoSection>
							<VideoJS
								options={videoJsOptions}
								onReady={(player) => {
									playerRef.current = player;
								}}
								setCurrentTime={setCurrentTime}
								onExpand={() => {}}
								ref={playerRef}
								src={videoUrl}
								startTime={startTime}
								endTime={endTime}
								size="large"
							/>
						</VideoSection>
					</Flex>

					<DetailsSection>
						<Tabs.Root defaultValue="transcript" style={{ height: "100%" }}>
							<Tabs.List
								className="h-[54px] flex-shrink-0"
								style={{
									borderBottom: "1px solid var(--gray-5)",
									backgroundColor: "white",
								}}
							>
								{["transcript", "details", "share"].map((tab) => (
									<Tabs.Trigger
										key={tab}
										value={tab}
										className="h-full text-[16px] font-medium text-gray-12 cursor-pointer px-4"
									>
										{capitalize(tab)}
									</Tabs.Trigger>
								))}
							</Tabs.List>

							<div
								style={{
									height: "calc(100% - 54px)",
									overflow: "hidden",
									display: "flex",
									flexDirection: "column",
									minHeight: 0,
								}}
							>
								<Tabs.Content
									value="transcript"
									className="h-full pl-[20px] pt-[10px] overflow-y-auto flex-1 sm:pl-[16px]"
								>
									<TranscriptView
										currentTime={currentTime}
										onSeek={handleSeek}
										transcript={transcript}
										endTime={endTime}
										startTime={startTime}
									/>
								</Tabs.Content>

								<Tabs.Content
									value="details"
									className="h-full p-[20px] pr-0 overflow-y-auto flex-1 sm:p-[16px]"
								>
									<Flex
										direction="column"
										justify="between"
										style={{ height: "100%", minHeight: 0 }}
									>
										<Flex direction="column" gap="4">
											<Flex direction="column" gap="2">
												<Text size="2" weight="medium">
													Title
												</Text>
												<Input
													value={title}
													onChange={(e) => setTitle(e.target.value)}
													placeholder="Enter clip title"
												/>
											</Flex>

											<Flex direction="column" gap="2">
												<Text size="2" weight="medium">
													Description (Optional)
												</Text>
												<TextArea
													value={description}
													onChange={(e) => setDescription(e.target.value)}
													placeholder="Add a description for your clip"
													rows={4}
												/>
											</Flex>
										</Flex>

										<Flex
											justify="end"
											gap="3"
											style={{ marginTop: "20px" }}
											className="sm:flex-col sm:gap-2"
										>
											<Dialog.Close className="sm:w-full">
												<Button
													variant="soft"
													color="gray"
													className="sm:w-full"
												>
													Cancel
												</Button>
											</Dialog.Close>
											<Button
												onClick={handleSubmit}
												loading={isModifying}
												disabled={!title}
												className="sm:w-full"
											>
												Save Changes
											</Button>
										</Flex>
									</Flex>
								</Tabs.Content>

								<Tabs.Content
									value="share"
									className="h-full p-[20px] pr-0 overflow-y-auto flex-1 sm:p-[16px]"
								>
									<Flex direction="column" gap="4">
										<Text>Share settings coming soon...</Text>
									</Flex>
								</Tabs.Content>
							</div>
						</Tabs.Root>
					</DetailsSection>
				</Content>
			</DialogContent>
		</DialogRoot>
	);
};

export default EditSnippetDialog;
