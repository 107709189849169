/**
 * This file contains utility functions and constants for managing media queries and breakpoints.
 * It defines common breakpoints for responsive design, provides media query strings,
 * and includes functions to generate custom media query styles using styled-components.
 */
import { css } from 'styled-components';

/**
 * Common breakpoints for responsive design, defined as string values with 'px' units.
 */
export const breakpoints = {
	mobile: '480px',
	tablet: '768px',
	laptop: '1024px',
	desktop: '1200px',
	desktopL: '1360px',
};

/**
 * Breakpoints defined as number values without 'px' units, useful for numerical comparisons.
 */
export const breakpointNumbers = {
	mobile: 480,
	tablet: 768,
	laptop: 1024,
	desktop: 1200,
	desktopL: 1360,
};

/**
 * Pre-defined media query strings for each breakpoint, ready to use in styled-components.
 */
export const media = {
	mobile: `@media (max-width: ${breakpoints.mobile})`,
	tablet: `@media (max-width: ${breakpoints.tablet})`,
	laptop: `@media (max-width: ${breakpoints.laptop})`,
	desktop: `@media (max-width: ${breakpoints.desktop})`,
	desktopL: `@media (max-width: ${breakpoints.desktopL})`,
};

/**
 * Generates a styled-components compatible media query for a maximum width.
 * @param maxWidth - The maximum width in pixels for the media query.
 * @returns A function that can be used with styled-components' css helper.
 */
export const mediaMaxWidth =
	(maxWidth: number) =>
	(...args: Parameters<typeof css>): ReturnType<typeof css> => css`
		@media (max-width: ${maxWidth}px) {
			${css(...args)}
		}
	`;

/**
 * Generates a styled-components compatible media query for a minimum width.
 * @param minWidth - The minimum width in pixels for the media query.
 * @returns A function that can be used with styled-components' css helper.
 */
export const mediaMinWidth =
	(minWidth: number) =>
	(...args: Parameters<typeof css>): ReturnType<typeof css> => css`
		@media (min-width: ${minWidth}px) {
			${css(...args)}
		}
	`;
